import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Component({
  template: '<p>Signing in...</p>'

})
export class CallbackComponent implements OnInit {

  constructor(private oauthService: OAuthService,
    private router: Router,) { }

  ngOnInit() {
    var validIdToken = this.oauthService.hasValidIdToken();
    var validAccessToken = this.oauthService.hasValidAccessToken();
    if (validIdToken && validAccessToken) {
      this.router.navigate(['base'])
    } else {
      this.router.navigateByUrl('/unathorized')
    }
  }
}
