import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-admin-password',
  templateUrl: './reset-admin-password.component.html',
  styleUrls: ['./reset-admin-password.component.scss']
})
export class ResetAdminPasswordComponent implements OnInit {
  hide: boolean = true;
  constructor(public dialogRef: MatDialogRef<ResetAdminPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
