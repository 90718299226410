<div class="KundengruppeMainDiv card-shadow">
  <div class="KundengruppeSubDiv">
    <div *ngIf="!dataForPDF">
      <h2 class="KundengerüstHeader">{{ 'customersScaffolding' | translate}}
        <i class="material-icons" (click)="closeChart()" *ngIf="!openedFromSideMenu"
          style="float: right;color: black;cursor: pointer;font-size: 30px;margin-right: 5px;">
          close
        </i></h2>

      <div class="note" *ngIf="openedFromSideMenu && !selectedCalculationId">
        {{'calculationNotSelectedError' | translate}}
      </div>

      <div style="column-count:3">

        <div>
          <mat-label>{{'dimensions' | translate}}</mat-label><br>
          <mat-form-field appearance="outline" style="width: 95%;">
            <mat-select [disabled]="!spinnerFlag" [(ngModel)]="dimensionselected" required
              (selectionChange)="dimensionenOrKennzahlChanged()" placeholder="Wählen Dimensionen">
              <mat-option value="vermogen"> {{'Assets/riskClass' | translate}} </mat-option>
              <mat-option value="kundengruppe"> {{'CustomerGroup/accountStatus' | translate}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-label> {{'Measure'| translate}} </mat-label><br>
          <mat-form-field appearance="outline" style="width:85%;">
            <mat-select [disabled]="!spinnerFlag" [(ngModel)]="kennzahlSelected" required
              (selectionChange)="dimensionenOrKennzahlChanged()" placeholder="Wählen Kennzahl">
              <mat-option value="AnzahlHaushalte"> {{'NumberOfHouseholds'| translate}} </mat-option>
              <mat-option value="AnzahlPersonen"> {{'NumberOfPeople'| translate}} </mat-option>
              <mat-option value="Vermogen" *ngIf="dimensionselected === 'vermogen' "> {{'Assets'| translate}}
              </mat-option>
              <mat-option value="fungiblesVermogen" *ngIf="dimensionselected === 'vermogen'">
                {{'fungibleAssets' | translate}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div *ngIf="dataSource">
    
      <table class="KundengerüstTable VermogenTable w-100" *ngIf="showVermogenTable && spinnerFlag">
        <thead>
          <tr>
            <th id="assets">{{'assets' | translate}}</th>
            <th id="desc" *ngFor="let column of dataSource[0].riskCategories">{{column.description}}</th>
            <th id="total">{{'total' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vermogen of dataSource">
            <td>{{vermogen.vermogen }}</td>
            <td *ngFor="let column of vermogen.riskCategories">{{column.count | alterChartTableData}}</td>
            <td>{{vermogen.gesamt | alterChartTableData}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <table class="KundengerüstTable KundengerüstMainTable w-100" *ngIf="showKundengruppeTable && spinnerFlag">
      <thead>
        <tr>
          <th id="custgrp">{{'customerGroup' | translate}}</th>
          <th id="name" *ngFor="let column of dataSource[0].contractStatuses">{{column.name}}</th>
          <th id="total">{{'total' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let kundengruppe of dataSource; let i = index">
          <td class="KundengruppeTitle">
            <span>{{kundengruppe.rang | alterChartTableData}}</span>{{i !== dataSource.length-1 ? '&nbsp;&nbsp;&nbsp;&nbsp;' : ''}}{{kundengruppe.kundengruppe}}
          </td>
          <td *ngFor="let column of kundengruppe.contractStatuses">{{column.count | alterChartTableData}}</td>
          <td>{{kundengruppe.gesamt | alterChartTableData}}</td>
        </tr>
      </tbody>
    </table>


  </div>
</div>