import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectedPositioningStrategy, FormattedValuesFilteringStrategy, HorizontalAlignment, IgxGridCell, IgxGridComponent, NoOpScrollStrategy, VerticalAlignment } from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { AdministrationService } from '../../services/administration.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

@Component({
  selector: 'app-bank-activities',
  templateUrl: './bank-activities.component.html',
  styleUrls: ['./bank-activities.component.scss']
})
export class BankActivitiesComponent implements OnInit {

  constructor(
    private commonUtils: CommonUtilsService,
    private administrationService: AdministrationService,
    private clipboard: Clipboard,
    private translateService: TranslateService,
  ) { }


  @ViewChild('grid1', { read: IgxGridComponent, static: true })
    public grid1: IgxGridComponent;
    
  
  //@Output() spinnerEvent = new EventEmitter<boolean>();
  isBusy: boolean = true;
  isInEditMode: boolean = false;
  allowSave: boolean;
  frontendTime = new Date;
  infoTextBlock = '';

  ACTIVITY_TYPE = {
    Unset: 0,
    Import: 1,
    Calculation: 2,
    DeletionOfCaclulation: 3,
    //DeletionOfDeadline: 4
  }

  bankActivitiesResult: any;
  activitiesData = [];

  filterStrategy = new FormattedValuesFilteringStrategy();

  activityType = [
    {id: 1, name:	'Import'},
    {id: 2, name:	'Kalkulation'},
    {id: 3, name:	'Löschung'},
    // {id: 4, name:	'Löschung (Stichtag)'},
  ];

    public overlaySettings = {
      positionStrategy: new ConnectedPositioningStrategy({
          horizontalDirection: HorizontalAlignment.Left,
          horizontalStartPoint: HorizontalAlignment.Right,
          verticalStartPoint: VerticalAlignment.Top
      }),
      scrollStrategy: new NoOpScrollStrategy()
    };
    

  ngOnInit(): void {
    this.getData();
  }

  
  // Formatting cell content
  formatActivityType = (value: number) => {
    let found = this.activityType.find(x => x.id == value);
    if (found) {
      return found.name;
    }
    return value; 
  }

  formatDuration = (value: any) => {
    if (value) {
      let duration = this.activitiesData.find(x => x.duration.durationTimeSpan == value).duration;

      if (duration) {
        if (duration.days == 0 && duration.hours == 0 && duration.minutes == 0) {
          return this.translateService.instant('notDetected');
        }

        if (duration.days > 0)
          {
              var durationString = `${duration.days} Tage, ${duration.hours} Stunden, ${duration.minutes} Minuten`;
              if (duration.days == 1)
              {
                  durationString = `${duration.days} Tag, ${duration.hours} Stunden, ${duration.minutes} Minuten`;
              }
              
          }
          else
          {
            durationString = `${duration.hours} Stunden, ${duration.minutes} Minuten`;
          }
          return durationString;
      }
      else {
        return this.translateService.instant('notDetected');
      }
    }
    return this.translateService.instant('notDetected'); 
  }

  formatActivityStart = (value: Date) => {
    if (value) {
      var date = new Date(value);
      var datestring = ("0" + date.getDate()).slice(-2) + "." + 
                        ("0"+(date.getMonth()+1)).slice(-2) + "." +
                        date.getFullYear() + " - " + 
                        ("0" + date.getHours()).slice(-2) + ":" + 
                        ("0" + date.getMinutes()).slice(-2) + 
                        " Uhr";
      return datestring;
    }
    return this.translateService.instant('notDetected'); 
  }

  formatDeadlineDate = (value: Date) => {
    if (value) {
      var date = new Date(value);
      var datestring = ("0" + date.getDate()).slice(-2) + "." + 
                        ("0"+(date.getMonth()+1)).slice(-2) + "." +
                        date.getFullYear();
      return datestring;
    }
    return this.translateService.instant('notDetected'); 
  }


  // getting data
  getData() {
    this.refreshData();
  }

  refreshData() {
    this.isBusy = true;

    this.activitiesData = [];
    this.administrationService.getBankActivities().subscribe(((res: any) => {
      if (res) {
        this.frontendTime = new Date;
        if (res.Result) {
          this.bankActivitiesResult = res.Result;
          this.activitiesData = res.Result.activities;
        }
      }
      this.isBusy = false;
    }),err => {
      this.isBusy = false;
      this.commonUtils.errorSnackBar('error', 'Ein Fehler ist beim Laden der Bankaktivitäten aufgetreten.');
      console.error(err);
    });
  }
  

  // Info-Box
  handleOpening(event, cell: IgxGridCell) {
    if (cell?.row?.data?.activityType == this.ACTIVITY_TYPE.Import) {
      this.infoTextBlock = 'ClientId: ' + cell?.row?.data?.clientId + 
                          '\nBankname: ' + cell?.row?.data?.bankName + 
                          '\nAktivität: ' + this.formatActivityType(cell?.row?.data?.activityType) +
                          '\nUploadTimeStamp: ' + cell?.row?.data?.rawUploadedFiles[0]?.uploadTimeStamp +
                          '\nUploadedFiles Id: ' + cell?.row?.data?.rawUploadedFiles?.map(u => u.id).join(', ')
                          ;

      // cell?.row?.data?.rawUploadedFiles?.forEach(element => {
      //   this.infoTextBlock += '\n' + element.fileName + ' - Status: ' + element.description;
      // });
    }
    else if  (cell?.row?.data?.activityType == this.ACTIVITY_TYPE.Calculation || cell?.row?.data?.activityType == this.ACTIVITY_TYPE.DeletionOfCaclulation) {
      this.infoTextBlock = 'ClientId: ' + cell?.row?.data?.clientId + 
                          '\nBankname: ' + cell?.row?.data?.bankName + 
                          '\nAktivität: ' + this.formatActivityType(cell?.row?.data?.activityType) +
                          '\nCalculationTimeStamp: ' + cell?.row?.data?.rawCalculationDeadline?.calculationTimeStamp +
                          '\nCalculationId: ' + cell?.row?.data?.rawCalculationDeadline?.id
                          ;
    }
  }

  handleOpened(event, cell: IgxGridCell) {
  }

  handleClosed() {
  }

  copyInfoToClipboard() {
    this.clipboard.copy(this.infoTextBlock);
    this.commonUtils.successSnackBar("Informationen in Zwischenablage kopiert.")
  }

}

 
