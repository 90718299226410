import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class ModelCustomerGroupService {
  environment: any;
  endPoint: any;
  clientId: any;

  constructor(private http: HttpClient, private configurationservice: ConfigurationService,
    userSessionService: UserSessionService) {
    this.clientId = userSessionService.getClientId().toString();
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endPoint = `${this.environment.musterkundeDevEndpoint}/api/ModelCustomerGroupNeedFieldParts`;
  }


  getModelCustomerGroup(isBankSpecific) {
    let url = `${this.endPoint}/ModelCustomerGroups/${isBankSpecific}`;
    return this.http.get(url);
  }

  getModelCustomerNeedFieldPartsById(modelCustomerGroupId) {
    let url = `${this.endPoint}/ForModelCustomerGroups/${modelCustomerGroupId}`;
    return this.http.get(url);
  }

  getCustomerNeedsSolutionList() {
    let url = `${this.endPoint}/dropdown`;
    return this.http.get(url);
  }

  addNewNeedSolution(data) {
    let url = `${this.endPoint}`;
    return this.http.post(url, data);
  }
  // /api/ModelCustomerGroupNeedFieldParts

  // delete customerNeed
  deleteCustomerNeed(id) {
    let url = `${this.endPoint}/${id}`;
    return this.http.delete(url);
  }

  isExistanceCustomerNeed(id, isExistance) {
    let url = `${this.endPoint}/UpdateIsExistence/${id}/${isExistance}`;
    return this.http.get(url);
  }

  isCashFLowNeutralCustomerNeed(id, isCashFlowNeutral) {
    let url = `${this.endPoint}/UpdateIsCashFlowNeutral/${id}/${isCashFlowNeutral}`;
    return this.http.get(url);
  }

  updateRankOfCustomerNeeds(id, rank) {
    let url = `${this.endPoint}/UpdateRang/${id}/${rank}`;
    return this.http.get(url);
  }
}
