import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from 'src/app/configration/configuration.service';
import {LocalStorageService} from 'src/app/shared/services/localStorage.service';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  environment;
  endpoint;
  userDetails;
  readonly userDataKey;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private configurationService: ConfigurationService, private router: Router
  ) {
    this.environment = configurationService.getEnvironmentVariables();
    this.userDataKey = `id_token_claims_obj`;
  }

  parseUserInfo(userInfo) {
    return JSON.parse(userInfo);

  }

  getUserProfileDetails() {
    const userData = this.getUserData();

    return userData?.User;
    // if (userData) {
    //   return JSON.parse(userData.User);
    // }
  }

  async getUserInfoFromServer(){
    var customJsonData = (await this.http.get(this.environment.musterkundeDevEndpoint + "/api/userinfo").toPromise() as any).Result;
    var user = this.localStorageService.get(this.userDataKey);
    user["User"] = customJsonData;
    this.localStorageService.set(this.userDataKey, user);
    return this.getUserData().User;
  }

  getUserData() {
    //return this.localStorageService.get(this.userDataKey);

    var user = this.localStorageService.get(this.userDataKey);
    // var customJsonData =
    //         {
    //             FirstName: "Max",
    //             LastName: "Mustermann",
    //             UserName: "Max.Mustermann",
    //             IsDeleted: 0,
    //             LastLoginDate: "",
    //             Id: 1,
    //             Email: "bauer@eworks.de",
    //             CustomerModel:
    //             {
    //                 Id: "1",
    //                 CustomerId: "2",
    //                 name: "MUKU"
    //             },
    //             AdminPrivileges:  [{
    //               ProductId: 1
    //           }]
    //
    //         };
    // user["User"] = customJsonData;
            return user;

  }

  getClientId() {
    const user = this.getUserProfileDetails();
    if(!user){
      return null;
    }
    return user.CustomerModel.Id;
  }

  getOAuthClientId() {
    const userData = this.getUserData();
    return userData.aud;
  }

  getCustomerAndUserId() {
    const userData = this.getUserProfileDetails();
    return {
      userId: userData.Id,
      customerId: userData.CustomerModel.CustomerId
    };
  }
}
