import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-admin-dialog',
  templateUrl: './delete-admin-dialog.component.html',
  styleUrls: ['./delete-admin-dialog.component.scss']
})
export class DeleteAdminDialogComponent implements OnInit {
  name: any;
  constructor(public dialogRef: MatDialogRef<DeleteAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.name = data.name;
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }
  deleteUser() {
    this.dialogRef.close(true);
  }
}
