import {LabelType} from 'ng5-slider';
export const SALES_ORGANIZATION_TYPE = {
  BANK: {value: 'bank'},
  OFFICE: {value: 'office'},
  REGION: {value: 'region'},
  TELIMARKT: {value: 'telimarkt'},
  CONSULTANT_BANK: {value: 'consultantbank'},
  KOMPETENZZENTER: {value: 'kompetenzzenter'},
  TEAM: {value: 'team'},
  BERATER: {value: 'berater'}
};
export const client_id = '547114e3-666e-4730-8129-ae8ce8e37c6c';

export const SUPER_ADMIN = 'Superadmin';



export const SCOPE_TABS = {
  Analyst: [
    'Parameter',
    'VR Musterkundenansatz',
    'Einstellungen > Buchungsarten',
    'Einstellungen > Kalkulationsvariablen',
    'Einstellungen'
  ],
  Datamanager: [
    'Datentransfercenter',
    'Datentransfercenter > Datenexport',
    'Datentransfercenter > Kalkulationen',
    'Datentransfercenter > Datenimport',
    'Datentransfercenter > IdaBerichte',
    'Datentransfercenter > Dokumentation'
  ],
  Datenmanager: [
    'Datentransfercenter',
    'Datentransfercenter > Datenexport',
    'Datentransfercenter > Kalkulationen',
    'Datentransfercenter > Datenimport',
    'Datentransfercenter > IdaBerichte',
    'Datentransfercenter > Dokumentation'
  ],
  Administrator: [
    'Einstellungen > Benutzer',
    'Einstellungen',
  ],
  Megaexport: [
    'Datentransfercenter',
    'Datentransfercenter > Datenexport'
  ],
  user_admin: [
    'Einstellungen > Benutzer',
    'Einstellungen'
  ],
  Revisor: [
    'Datentransfercenter',
    'Datentransfercenter > Datenexport'
  ],
  CommonRoutes: [
    'VR Aktivitätenmanager',
    'Marketingfabrik'
  ]
}

export const ROLES_SCOPES = [
  {
    role: 'Superadmin',
    scope: ['Musterkunde.Client.API', 'Musterkunde.User.API'],
    claims: ['Musterkunde.Client.Update', 'Musterkunde.Client.Delete', 'Musterkunde.Client.Read',
      'Musterkunde.User.Create', 'Musterkunde.User.Delete', 'Musterkunde.User.Update', 'Musterkunde.User.Read']
  },
  {
    role: 'Rechte-Admin',
    scope: ['Musterkunde.User.API'],
    claims: ['Musterkunde.User.Create', 'Musterkunde.User.Delete', 'Musterkunde.User.Update', 'Musterkunde.User.Read']
  },
  {
    role: 'Analyst',
    scope: ['Musterkunde.Dashboard.API'],
    claims: ['Musterkunde.Dashboard.Read']
  },
  {
    role: 'BankAdministrator',
    scope: ['Musterkunde.DataCenter.API', 'Musterkunde.Configuration.API'],
    claims: ['Musterkunde.DataCenter.Upload', 'Musterkunde.DataCenter.Download',
      'Musterkunde.Configuration.Update', 'Musterkunde.Configuration.Read']
  },
  {
    role: 'Datenmanager',
    scope: ['Musterkunde.DataCenter.API'],
    claims: ['Musterkunde.DataCenter.Upload', 'Musterkunde.DataCenter.Download']
  },
  {
    role: 'Datamanager',
    scope: ['Musterkunde.DataCenter.API'],
    claims: ['Musterkunde.DataCenter.Upload', 'Musterkunde.DataCenter.Download']
  },
  {
    role: 'Revisor',
    scope: ['Musterkunde.Logging.API'],
    claims: ['Musterkunde.Logging.Dashboard.Read', 'Musterkunde.Logging.DataTransferCenter.Read']
  },
  {
    role: 'VRConsultingAccess',
    scope: ['Musterkunde.User.API', 'Musterkunde.Configuration.API', 'Musterkunde.Dashboard.API', 'Musterkunde.Logging.API'],
    claims: ['Musterkunde.User.Create', 'Musterkunde.User.Delete', 'Musterkunde.User.Update', 'Musterkunde.User.Read',
      'Musterkunde.Configuration.Update', 'Musterkunde.Configuration.Read',
      'Musterkunde.Dashboard.Read',
      'Musterkunde.Logging.Dashboard.Read', 'Musterkunde.Logging.DataTransferCenter.Read'
    ]
  },
  {
    role: 'Zentraler Administrator',
    scope: [],
    claims: []
  }
];



export const CATEGORIES = [
  {
    'id': 1,
    'name': 'Kerngeschäft'
  },
  {
    'id': 2,
    'name': 'Angrenzendes Gebiet'
  },
  {
    'id': 3,
    'name': 'Peripherie'
  },

];

export const FILE_INTERFACE = [

  {name: 'Customer', value: 'Kundenstammdaten', files: []},
  {name: 'ProcessTransaction', value: 'Umsatzdaten', files: []},
  {name: 'ProcessSavingsEur', value: 'Regelzahlung', files: []},
  {name: 'ProcessBalance', value: 'Bestandsdaten', files: []},
  {name: 'Security', value: 'Wertpapierstammdaten', files: []},
  {name: 'Product', value: 'Produktdaten', files: []},
  {name: 'Consultant', value: 'Beraterinformationen', files: []},
  {name: 'CustomerCriteria', value: 'Kundenkriterien', files: []},
  {name: 'FundAllocation', value: 'Fondszuordnung', files: []},
  {name: 'OrganisationUnit', value: 'Organisationsdaten', files: []},
  {name: 'OrganisationData', value: 'Organisationsdaten', files: []},
  {name: 'Dvo', value: 'DVO-Daten', files: []}
];
export const GET_PARENT_OPTIONS = {
  partmarket: 'CreditInstitutionPartMarket',
  region: 'creditinstitutionregion',
  department: 'creditinstitutiondepartment',
  consultant: 'consultant',
  Kompetenzzenter: 'consultantCompetenceCenter',
  team: 'consultantTeam'

};

export const TRANSACTION_STATUS = {
  TransactionFailed: 0,
  Complete: 1,
  InProgress: 2,
  NoCsvFile: 3,
  NotFindAnyTarget: 4,
  TransactionCancelled: 5,
  ReadyToDelete: 6,
  Deleting: 7,
  Deleted: 8,
  CustomerImportMissing: 9,

  // General Errors 
  ImportModeNotSupported: 10,
  HierarchyIdAlreadyInUse: 11,
  GettingRecordsFromCsvFailed: 12,
  CheckAnonymousKeyHashValueFailed: 13,
  MissingRootOrganizationUnit: 14,
  MissingConsultantSegment: 15,

  //Copy OrgaStructure Errors [50-59]
  DeadlineToCopyFromMissing: 51,
  DeadlineAlreadyExists: 52,
  MissingIdsToCopyFrom: 53,
  MissingIdsToCopy: 54,
  CopyOrgaStructureFailed : 55,
  CopyMissingParentProfitCenter: 56,

  // Specific Errors [100-255]
  OrganisationUnitDoesNotExist: 101,
  MissingDefaultRegionStructure: 102,
  MissingDefaultConsultantStructure: 103,
  MissingCreditInstituteConsultantStructure: 104,
  MissingProfitCenter: 105,
  MissingOrganizationUnit: 106,
  MissingProcessRecords: 107,
};

export const POTENTIAL_FILTERS = [
  {id: 1, name: 'Musterkundengruppe', values: [], select: false, excludeMusterkundeGruppenInCalculation: false},
  {id: 2, name: 'Einkommen / Vermögen / Kredit', values: [], select: false, minValue: null, maxValue: null},
  {id: 3, name: 'Gebietsstatus', values: [], select: false},
  {id: 4, name: 'Immobilienstatus', values: [], select: false},
  {id: 5, name: 'Kontonutzung', values: [], select: false},
  {id: 6, name: 'Risikoneigung', values: [], select: false}
];
export const VERMOGEN_FILTER = [
  {
    id: 1,
    name: 'Haushaltseinkommen', minValue: 0, maxValue: 500000, defaultMinValue: 0,
    defaultMaxValue: 500000, isSlider: true, step: 1000, directDefaultMaxValue: 9999999999, options: {
      floor: 0,
      ceil: 500000,
      step: 1000,
      minRange: 100,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        return value.toLocaleString('de');
      }
    }
  },
  {
    id: 2,
    name: 'Vermögen gesamt', minValue: 0, maxValue: 500000, defaultMinValue: 0, defaultMaxValue: 500000,
    isSlider: true, step: 5000, directDefaultMaxValue: 9999999999, options: {
      floor: 0,
      ceil: 500000,
      step: 1000,
      minRange: 100,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        return value.toLocaleString('de');
      }
    }
  },
  {
    id: 3,
    name: 'Vermögen liquide', minValue: 0, maxValue: 500000, defaultMinValue: 0,
    defaultMaxValue: 500000, isSlider: true, step: 5000, directDefaultMaxValue: 9999999999, options: {
      floor: 0,
      ceil: 500000,
      step: 1000,
      minRange: 100,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        return value.toLocaleString('de');
      }
    }
  },
  {
    id: 4,
    name: 'Kredite gesamt', minValue: 0, maxValue: 500000, defaultMinValue: 0,
    defaultMaxValue: 500000, isSlider: true, step: 5000, directDefaultMaxValue: 9999999999, options: {
      floor: 0,
      ceil: 500000,
      step: 1000,
      minRange: 100,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        return value.toLocaleString('de');
      }
    }
  },
  {
    id: 5,
    name: 'Kredite langfristig', minValue: 0, maxValue: 500000, defaultMinValue: 0,
    defaultMaxValue: 500000, isSlider: true, step: 5000, directDefaultMaxValue: 9999999999, options: {
      floor: 0,
      ceil: 500000,
      step: 1000,
      minRange: 100,
      noSwitching: true,
      translate: (value: number, label: LabelType): string => {
        return value.toLocaleString('de');
      }
    }
  }

];

export const AREA_STATUS = [
  {id: 1, name: 'Geschäftsgebiet'},
  {id: 2, name: 'Geschäftsgebiet angrenzend'},
  {id: 3, name: 'Deutschland'},
  {id: 4, name: 'Ausland'}
];

export const PROPERTY_STATUS = [
  {id: 1, name: 'Kein Immobilieneigentum nachgewiesen'},
  {id: 2, name: 'Immobilieneigentum nachgewiesen'}
];


export const RISK_CLASS = [
  {id: 1, name: 'Risikoklasse 1'},
  {id: 2, name: 'Risikoklasse 2'},
  {id: 3, name: 'Risikoklasse 3'},
  {id: 4, name: 'Risikoklasse 4'},
  {id: 5, name: 'Risikoklasse 5'},
  {id: 6, name: 'Risikoklasse 6'},

];

export const PROFIT_CENTER_FILTER = {
  selectedProfitCenterFilter: null,
  values: {name: null, id: null}
};

export const PRODUCT_PROVIDER_FILTER = [
  { id: 0, name: "Alle" },
  { id: 1, name: "Bank" },
  { id: 2, name: "Verbund" },
]

export const PRODUCT_STATUS_FILTER = [
  { id: 0, name: "Alle" },
  { id: 1, name: "Zugeordnet (Auto)" },
  { id: 2, name: "Zugeordnet (Bank)" },
  { id: 3, name: "Nicht zugeordnet" },
]

export const potentialAnalysisPdfData = {
  pdfData: null
};
export const ADDITIONAL_FILTER = [
  {
    id: 1, name: 'Älteste Person im Haushalt', matchValue: 'oldestPersonInHousehold',
    select: false, addInList: false, minValue: 0, maxValue: 100, defaultMinValue: 0, defaultMaxValue: 100, options: {
      floor: 0,
      ceil: 100,
      step: 1,
      minRange: 1,
      noSwitching: true,
      selectOneElement: false
      // maxRange:10
    }
  },

  {
    id: 2,
    name: 'Rating Haushalt',
    matchValue: 'ratingHousehold',
    multi: true,
    select: false,
    addInList: false,
    values: [],
  },
  {
    id: 3, name: 'Personenanzahl im Haushalt',
    matchValue: 'noOfPersonInHouse', select: false, addInList: false, minValue: 0,
    maxValue: 10, defaultMinValue: 0, defaultMaxValue: 10, options: {
      floor: 0,
      ceil: 10,
      step: 1,
      minRange: 1,
      noSwitching: true,
      selectOneElement: false
    }
  },
  {
    id: 4, name: 'Personenanzahl Erwachsene im Haushalt',
    matchValue: 'noOfAdultsInHouse', select: false, minValue: 0, maxValue: 10,
    defaultMinValue: 0, defaultMaxValue: 10, options: {
      floor: 0,
      ceil: 10,
      step: 1,
      minRange: 1,
      noSwitching: true,
      selectOneElement: false
    }
  },
  {
    id: 5, name: 'Personenanzahl Minderjährige im Haushalt',
    matchValue: 'noOfMinorsInHouse', select: false, addInList: false, minValue: 0,
    maxValue: 10, defaultMinValue: 0, defaultMaxValue: 10, options: {
      floor: 0,
      ceil: 10,
      step: 1,
      minRange: 1,
      noSwitching: true,
      selectOneElement: false
    }
  },
  {id: 6, name: 'Private Rechtsformausprägungen', matchValue: 'privateLegalForms', select: false, addInList: false},
  {
    id: 7, name: 'Kaufkraftkennziffern', matchValue: 'purchasingPower',
    select: false, addInList: false, minValue: 0, maxValue: 200, defaultMinValue: 0,
    defaultMaxValue: 200, options: {
      floor: 0,
      ceil: 200,
      step: 1,
      minRange: 1,
      noSwitching: true,
      selectOneElement: false
    }
  },
  {
    id: 8, name: 'Kundensegment Bank', matchValue: 'customerSegmentBank',
    select: false, addInList: false, minValue: 0, maxValue: 999, defaultMinValue: 0, defaultMaxValue: 999, options: {
      floor: 0,
      ceil: 999,
      step: 1,
      minRange: 1,
      noSwitching: true,
      selectOneElement: false
      // maxRange:10
    }
  },
  {
    id: 9, name: 'Kundensegment BVR', matchValue: 'customerSegmentBVR',
    select: false, addInList: false, values: []
    , select_options: [
      {shl_num: 100, shl_name: 'Privat-Banking-Kunden (Vermögen)'},
      {shl_num: 200, shl_name: 'Vermögende Betreuungskunden (Vermögen)'},
      {shl_num: 300, shl_name: 'Betreuungskunden (Vermögen)'},
      {shl_num: 400, shl_name: 'Service-Kunden (Vermögen)'},
      {shl_num: 101, shl_name: 'Privat-Banking-Kunden (Einkommen)'},
      {shl_num: 201, shl_name: 'Vermögende Betreuungskunden (Einkommen) '},
      {shl_num: 301, shl_name: 'Betreuungskunden (Einkommen)'},
      {shl_num: 401, shl_name: 'Service-Kunden (Einkommen)'},
    ]
  },
  {
    id: 10, name: 'Kundensegment VRCP', matchValue: 'customerSegmentVRCP',
    select: false, addInList: false,
    values: [], select_options: [
      {shl_num: 100, shl_name: 'VRCP VIP-Kunde (Vermögen)'},
      {shl_num: 200, shl_name: 'VRCP Premiumkunden (Vermögen)'},
      {shl_num: 300, shl_name: 'VRCP Betreuungskunden (Vermögen)'},
      {shl_num: 400, shl_name: 'VRCP Beratungskunden (Vermögen)'},
      {shl_num: 500, shl_name: 'VRCP Basiskunde (Vermögen)'},
      {shl_num: 101, shl_name: 'Vermögende Betreuungskunden (Einkommen) '},
      {shl_num: 201, shl_name: 'VRCP Premiumkunden (Einkommen)'},
      {shl_num: 301, shl_name: 'VRCP Betreuungskunden (Einkommen)'},
      {shl_num: 401, shl_name: 'VRCP Beratungskunden (Einkommen)'},
      {shl_num: 501, shl_name: 'VRCP Basiskunde (Einkommen)'},
    ]
  },


  {
    id: 11,
    name: 'Termine Vergangenheit',
    matchValue: 'datesPast',
    select: false,
    addInList: false,
    minValue: null,
    maxValue: null
  },
  {
    id: 12, name: 'Termine Zukunft', matchValue: 'datesFuture',
    select: false, addInList: false, minValue: null, maxValue: null, error: false
  },
];


export const SELECTED_ADDITIONAL_FILTER = [];


export const PRODUKT_SELECTED_FILTER = {
  selectedFilter: null,
  previousSearchString: null

};




export const PREVIOUS_ROUTE = {
  allowPReviousRoutes: false
};

export const CONSULTANT_IDS = {
  ids: []
};

export const DEPARTMENT_NUMBERS = {
  numbers: []
}

export const PROFITCENTER_TYPES = [
  {id: 1, name: 'Gesamtbank'},
  {id: 2, name: 'Teilbank'},
  {id: 3, name: 'Markt'},
  {id: 4, name: 'Teilmarkt'},
  {id: 5, name: 'Kompetenzcenter'},
  {id: 6, name: 'Zweigstelle'},
  {id: 7, name: 'Team'},
];

export const BRANCH_NODE_TYPES =[
  {id: 1, name: 'Kreditinstitut'},
  {id: 2, name: 'Region'},
  {id: 3, name: 'Zweigstelle'},
]

export const KUNDERGUST = [
  {dimensionselected: 'vermogen', kennzahlSelected: 'AnzahlHaushalte', data: null, frameworkNo: 3},
  {dimensionselected: 'vermogen', kennzahlSelected: 'AnzahlPersonen', data: null, frameworkNo: 4},
  {dimensionselected: 'vermogen', kennzahlSelected: 'Vermogen', data: null, frameworkNo: 5},
  {dimensionselected: 'vermogen', kennzahlSelected: 'fungiblesVermogen', data: null, frameworkNo: 6},

  {dimensionselected: 'kundengruppe', kennzahlSelected: 'AnzahlHaushalte', data: null, frameworkNo: 1},
  {dimensionselected: 'kundengruppe', kennzahlSelected: 'AnzahlPersonen', data: null, frameworkNo: 2},
];

export const VERMOGEN_REPORT_DATA = {
  data: null
};

export const AllDataLoadDetail = {
  loaded: true
};

export const BV_SIDEBAR_STATUS = {
  status: null
};

export const MUKU_SCOPE = 'openid profile Musterkunde.Configuration.API Musterkunde.Calculation.API Musterkunde.DataCenter.API Musterkunde.Dashboard.API Musterkunde.Logging.API Prozesscenter.IdentityManagement.Api IdentityManagement.ClaimGroup.Read';





