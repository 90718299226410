<!-- left -->
<div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap
  fxLayoutGap="0.5%" fxLayoutAlign="center">
  <div fxFlex="100%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
    <div class=" w-100 card-shadow m-b-100">
      <!-- <div class="table-container"> -->
      <div *ngIf="spinnerFlag" style="display: flex; justify-content: center; align-items: center">
        <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
      </div>
      <div class="w-100  card-shadow">
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" align="center" matSort class="w-100 tab-layout">

            <ng-container matColumnDef="name">
              <th width="100" mat-header-cell *matHeaderCellDef mat-sort-header> {{'designation' | translate}} </th>
              <td mat-cell *matCellDef="let element"> {{element.name}}</td>
            </ng-container>


            <ng-container matColumnDef="needTheme">
              <th width="90" mat-header-cell *matHeaderCellDef mat-sort-header>{{'consultingTopic' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.needTheme}}
              </td>
            </ng-container>

            <ng-container matColumnDef="needField">
              <th width="110" mat-header-cell *matHeaderCellDef mat-sort-header>{{'requiredField' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.needField}}
              </td>
            </ng-container>

            <ng-container matColumnDef="algorithm">
              <th width="180" mat-header-cell *matHeaderCellDef mat-sort-header>{{'algorithm' | translate}}</th>
              <td mat-cell class="word-break" *matCellDef="let element"> {{element.algorithm}}
              </td>
            </ng-container>


            <ng-container matColumnDef="personan">
              <th width="60" mat-header-cell *matHeaderCellDef>{{'persons' | translate}} </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.IsHousehold">{{'household' | translate}}</div>
                <div *ngIf="!element.IsHousehold">{{'person' | translate}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="datatype">
              <th width="80" mat-header-cell *matHeaderCellDef mat-sort-header>{{'dataType' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                {{element.dataType}}
              </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th width="80" mat-header-cell *matHeaderCellDef mat-sort-header> {{'value' | translate}}</th>
              <td mat-cell *matCellDef="let element">

                <span>
                  <!-- display value -->
                  <span *ngIf="element.edit"> {{getGermanDecimal(element.value)}}
                    <button mat-icon-button (click)="editInitiated(element)" *ngIf="isVrcpAdmin">
                      <i class="fs-16 material-icons"> edit </i>
                    </button>
                  </span>
                  <!-- edit value -->
                  <span *ngIf="!element.edit && isVrcpAdmin">
                    <div>
                      <div class="left-div">
                        <mat-form-field class="full-width w-90" appearance="outline">
                          <input matInput [(ngModel)]="element.editValue">
                        </mat-form-field>
                      </div>
                      <div class="edit-icons">
                        <button mat-icon-button (click)="editWert(element.id)"
                          [disabled]="!validateWertValue(element.dataType,element.editValue)"><i
                            class="material-icons">done
                          </i></button>
                        <button mat-icon-button (click)="clearEditWert(element.name)"><i
                            class="material-icons">clear</i></button>
                      </div>
                    </div>
                    <div>
                      <mat-error *ngIf="!validateWertValue(element.dataType,element.editValue)"
                        matTooltip="Der Wert entspricht nicht den Datenvorgaben" matTooltipClass="example-tooltip-red">
                        {{'valueDoesNotMatchDataSpecifications' | translate}}
                      </mat-error>
                    </div>
                  </span>
                </span>


              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </div>
      <mat-paginator class="m-t-5" [length]="totalData" showFirstLastButtons [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 30]">
      </mat-paginator>

    </div>
  </div>
</div>
