<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'consultantStructure' | translate}} > {{ isEdit ? 'Profitcenter Bearbeiten' :'Neues Profitcenter'}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>


  <div *ngIf="spinnerFlag" class="progress-spinner spin-position">
    <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
  </div>
  <mat-card class="m-t-50 m-b-50 p-t-30" *ngIf="!spinnerFlag">
    <mat-card-content>
      <mat-grid-list cols="12" rowHeight="300px">
        <mat-grid-tile flex-start flex-align-top [colspan]="7">
          <form class="w-100" novalidate [formGroup]="profitCenterForm">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'name' | translate}}</mat-label>
              <input matInput placeholder="Name" name="des" formControlName="name">
            </mat-form-field>

            <mat-form-field class="full-width mt-20" appearance="outline">
              <mat-label>{{'description' | translate}}</mat-label>
              <input matInput placeholder="Beschreibung" name="shortDes" formControlName="shortDescription">
            </mat-form-field>
            <!-- categorie -->

            <!-- parent profit center-->
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'parentProfitCenter' | translate}}</mat-label>
              <mat-select [disabled]="!canEditCategory" formControlName="parentProfitCenter"  >
                <mat-option *ngFor="let parent of parentProfitCenterList" [value]="parent.id">{{parent.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Kategorie</mat-label>
              <mat-select [disabled]="!canEditCategory" formControlName="categorie">
                <mat-option *ngFor="let category of categories" [value]="category.typeId">{{category.name}}</mat-option>
              </mat-select>
            </mat-form-field>

          </form>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
    <mat-card-actions class="m-b-5">
      <mat-grid-list cols="12" rowHeight="45px">
        <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
          <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" [disabled]="!profitCenterForm.valid"
            (click)="addEditProfitCenter()">{{'save' | translate}}</button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-actions>
  </mat-card>
</div>