<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="50px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'calculations' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>


  <mat-grid-list cols="12" class="m-t-50 m-b-50 p-t-30 tile-height" rowHeight="450px">
    <mat-grid-tile flex-start flex-align-top [colspan]="12">

      <div class="div-spinner" *ngIf="spinnerFlag">
        <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
      </div>

      <mat-accordion multi="true">
        <mat-expansion-panel class="expansion-panel" [ngClass]="{'m-b-10':(calculationListLength == i+1)}"
          *ngFor="let calculation of kalkulationList; let i = index" [expanded]="calculation.isExpanded" (opened)="openCalculation(calculation)" (closed)="closeCalculation(calculation)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'deadline' | translate}} {{calculation.deadlineDate | dateFormat}} 
            </mat-panel-title>

          </mat-expansion-panel-header>
          <div *ngIf="calculation.innerSpin" class="mat-spinner-div inner-spin m-b-10 m-l-5">
            <mat-spinner [diameter]="25" [strokeWidth]="2"></mat-spinner>
          </div>
          <div class="exp-content m-b-10" *ngIf="!calculation.innerSpin">
            <button *ngIf="calculation.isComplete" mat-raised-button class="custom-button" [disabled]="calculation.disable"
              (click)="navigateToNewCalulation(calculation)">{{'newCalculation' | translate}}</button>

            <!-- delete kalkulation -->

            <button *ngIf="!calculation.isDeleted" mat-raised-button class="custom-button btn-outline-primary" [disabled]="calculation.disable"
              (click)="deleteKeyDate(calculation)">{{'deleteKeyDateBtn' | translate}}</button>

            <p *ngIf="calculation.disable">{{'incompleteDataModel' | translate}}
              <a *ngIf="calculation.kalkulationFiles.length != 0"
                (click)="showKalkulationFileDetails(calculation)">{{'details' | translate}}</a>
              <a (click)="navigateToDataImportPage()">{{'forDataImport' | translate}}</a>
            </p>

            <div>
              <div *ngFor="let kalkulation of calculation.kalkulationDeadlines">
                <tr>
                  <td>{{'kalkulationLabel' | translate}} {{kalkulation.kalkulationNumber}}</td>
                  <td class="w-170">({{kalkulation.calculationTimeStamp  | dateFormat : 'dd/MM/yyyy HH:mm'}}
                    {{kalkulation.userName}} )</td>
                  <td class="w-370">
                    <!-- success -->
                    <span *ngIf="kalkulation.success">
                      <i class="material-icons green-check m-l-5 m-r-5">
                        check_circle
                      </i>
                    </span>
                    <!-- failure -->
                    <span *ngIf="kalkulation.failed">
                      <i class="material-icons error-icon m-l-5 m-r-5">
                        error_outline
                      </i> {{kalkulation.status}}
                    </span>

                    <span *ngIf="kalkulation.isDeleted || kalkulation.isCanceled" class="material-icons cross-icon m-l-5 m-r-5">
                      clear
                    </span>

                    <!-- progress -->
                    <div class="status-cal">


                      <span *ngIf="kalkulation.isdeleting || kalkulation.progress || kalkulation.isCanceling">
                        <span>
                          <mat-spinner [diameter]="20" [strokeWidth]="2"></mat-spinner>
                        </span>
                        <span class="detail">{{kalkulation.status}}</span>
                      </span>
                      
                      <a *ngIf="!kalkulation.isdeleting && !kalkulation.isCanceling"(click)="calculationFilesDialogBox(calculation.deadlineDate, kalkulation)">{{'details' | translate}}
                      </a>
                      <span>
                        <button *ngIf="kalkulation.progress && !kalkulation.isdeleting && !kalkulation.isCanceling" mat-raised-button
                          class="custom-button btn-outline-primary"
                          (click)="deleteKalkulation(kalkulation, 'stop', calculation)">{{'stopText' | translate}}</button>
                        <button *ngIf="!kalkulation.progress && !kalkulation.isdeleting && !kalkulation.isCanceling && kalkulation.success" mat-raised-button
                          class="custom-button btn-outline-primary"
                          (click)="deleteKalkulation(kalkulation, 'delete', calculation)">{{'delete' | translate }}</button>
                      </span>

                    </div>
                  </td>

                </tr>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-grid-tile>
  </mat-grid-list>
</div>