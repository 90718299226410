import {client_id, MUKU_SCOPE} from 'src/app/app.config';

export const staging = {
  envName: 'staging',
  
  production: false,

  staging: true,

  dev: false,

  client_id: client_id,

  originUrl: 'https://dev-frontend.vr-musterkundenansatz.de',

  identityUrl: 'https://login.microsoftonline.com/40a83bd0-2969-4a4d-8f68-6f3c7da61e4e/v2.0',

  scope: MUKU_SCOPE,

  version: '2.0',

  service_id: 2,

  //service endpoints

  musterkundeDevEndpoint: 'https://dev-api.vr-musterkundenansatz.de',

  datenImportendpoint: 'https://dev-datentransfer.vr-musterkundenansatz.de',

  devAuthManagementEndpoint: 'https://dev-api.vr-musterkundenansatz.de',

  datenExportEndpoint: 'https://dev-export.vr-musterkundenansatz.de'

};
