import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculation-variable-tab',
  templateUrl: './calculation-variable-tab.component.html',
  styleUrls: ['./calculation-variable-tab.component.scss']
})
export class CalculationVariableTabComponent implements OnInit {
  defaultSelectedIndex = 0;
  musterkundeType = "bv-musterkunde";
  activityManagerType = "bv-activity-mngr";
  constructor() { }

  ngOnInit() {
  }

}
