import { client_id, MUKU_SCOPE } from 'src/app/app.config';

export const environmentDev = {

  production: false,

  staging: false,

  dev: true,

  client_id: client_id,

  originUrl: 'http://localhost:4200',

  identityUrl: 'https://login.microsoftonline.com/40a83bd0-2969-4a4d-8f68-6f3c7da61e4e/v2.0',

  scope: MUKU_SCOPE,

  version: 0.1,

  service_id: 2,
  // service endpoints

  // musterkundeDevEndpoint: 'https://staging-musterkunde-calculation.emnicon.de',
  musterkundeDevEndpoint: 'http://localhost:60504',

  datenImportendpoint: 'http://localhost:29164',

  devAuthManagementEndpoint: 'http://localhost:60504',

  datenExportEndpoint: 'https://localhost:44300',
  // datenExportEndpoint: 'https://staging-musterkunde-export.emnicon.de'
  // datenExportEndpoint: 'https://staging-musterkunde-export.emnicon.de'


};
// // The file contents for the current environment will overwrite these during build.
// // The build system defaults to the dev environment which uses `environment.ts`, but if you do
// // `ng build --env=prod` then `environment.prod.ts` will be used instead.
// // The list of which env maps to which file can be found in `.angular-cli.json`.

