import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BussinessAreasService } from '../../service/bussiness-areas.service';
import { DeleteBussinessAreasDialogboxComponent } from '../delete-bussiness-areas-dialogbox/delete-bussiness-areas-dialogbox.component';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-geschaftsgebiete-list',
  templateUrl: './geschaftsgebiete-list.component.html',
  styleUrls: ['./geschaftsgebiete-list.component.scss']
})
export class GeschaftsgebieteListComponent implements OnInit {
  displayedColumns: string[] = ['postcode', 'place', 'categoryName', 'purchasingPower', ' '];
  dataSource = new MatTableDataSource();
  spinnerFlag = true;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  bussinessAreas: any[] = [];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  totalCount: any;
  constructor(private router: Router,
    private bussinessAreaService: BussinessAreasService,
    private commonUtils: CommonUtilsService,
    private matDialog: MatDialog,
    private translateService: TranslateService) {

  }

  ngOnInit() {
    this.bussinessAreaService.getAllBussinessAreas().subscribe((Response: any) => {
      this.bussinessAreas = Response.Result;
      this.bussinessAreas.forEach(data => {
        data.categoryName = data.category.name;
      });
      this.dataSource = new MatTableDataSource(this.bussinessAreas);
      this.totalCount = this.bussinessAreas.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinnerFlag = false;

    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });
  }


  getDisplayValueInDecimal(data: string) {
    data = data.toString();
    return data.replace('.', ',');
  }
  navigateToBussinessDistrict() {
    this.router.navigateByUrl('bv-home/add-bussiness-areas');
  }
  navigateToEditBussinessAreas(id) {
    this.router.navigateByUrl(`bv-home/edit-bussiness-areas/${id}`);
  }
  deleteBussinessDistrict(id, place) {


    const dialogRef = this.matDialog.open(DeleteBussinessAreasDialogboxComponent, {
      width: '400px',
      data: { place: place }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bussinessAreaService.deleteBussinessAreas(id).subscribe((response: any) => {
          const temp = this.dataSource.filteredData.filter((data: any) => data.id != id);
          this.dataSource = new MatTableDataSource(temp);
          this.commonUtils.successSnackBar(this.translateService.instant('bussinessAreaDeleted'));
        }, (err) => {
          this.commonUtils.errorSnackBar('error', null);
        });
      }
    });
  }
}
