<div class="root-container">

    <div class="title-container m-t-20 p-l-1vw p-t-1vw p-r-1vw">
      <mat-grid-list cols="2" rowHeight="50px">
        <mat-grid-tile flex-start flex-align-top class="grid-spacing">
          <h1 class="page-title">
            {{('bankAktivities') | translate}}
          </h1>
        </mat-grid-tile>
        <mat-grid-tile flex-end flex-align-top class="grid-spacing">
  
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  
  
    <div class="main-container">
  
        <!-- Top Section  -->
        <mat-card class="header-card m-t-20 m-b-0 m-l-20 m-r-20 p-t-10 p-b-0">
          <mat-card-content>
            <div class="d-flex flex-row" style="line-height: 30px; text-align: center;">
              <span>
                Aktuelle Zeit: <span class="bold-time" style="font-size: 1.1em;"> {{frontendTime | date:'dd.MM.yyyy - HH:mm (zzzz)'}}</span>
              </span>
            </div>
            <div class="d-flex flex-row" style="line-height: 30px; text-align: center;" *ngIf="bankActivitiesResult" >
              <span>
                Aktueller Stand nach Server-Zeit: <span class="bold-time"> {{bankActivitiesResult.databaseTimeStamp | date:'dd.MM.yyyy - HH:mm'}}</span>
              </span>
              
            </div>
              
  
          </mat-card-content>
  
        </mat-card>
  
  
        <!-- Data-Table -->
        <mat-card class="grid-card m-t-20 m-b-20 m-l-20 m-r-20 p-t-0 p-b-0 p-l-0 p-r-0" >
  
          <div *ngIf="isBusy" class="loading-spinner-container">
              <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
          </div>

          <igx-grid #grid1
          id="grid1"
          height="100%"
          width="100%"
          [autoGenerate]="false"
          [data]="activitiesData"
          [primaryKey]="'id'"
          [allowFiltering]="true"
          [filterMode]="'excelStyleFilter'"
          [filterStrategy]="filterStrategy"
          [rowEditable]="false"
          [showToolbar]="true"
          >
            <!-- <igx-grid-toolbar>
              <igx-grid-toolbar-actions>
  
              </igx-grid-toolbar-actions>
            </igx-grid-toolbar> -->
  
              <!--Toolbar-->
              <igx-grid-toolbar>
                <igx-grid-toolbar-title>Liste der Aktivitäten</igx-grid-toolbar-title>
  
                <!--
                    Everything between the toolbar tags except the default toolbar components/directives
                    will be projected as custom content.
                 -->
                 <button mat-button class="custom-button toolbar-button" (click)="refreshData()" [disabled]="isBusy">
                  <igx-icon class="toolbar-button-icon" fontSet="material">refresh</igx-icon>
                  Aktualisieren
                </button>
  
                <!-- <button igxButton="flat"  >
                  <igx-icon fontSet="material">clear</igx-icon>
                  Clear Sort
              </button> -->
  
                <igx-grid-toolbar-actions>
                  <igx-grid-toolbar-hiding></igx-grid-toolbar-hiding>
                </igx-grid-toolbar-actions>
            </igx-grid-toolbar>
  
            <!-- <ng-template igxExcelStyleHeaderIcon>
              <igx-icon fontSet="material">lens</igx-icon>
            </ng-template> -->
  
            <igx-grid-excel-style-filtering >
                <igx-excel-style-column-operations>
                    <igx-excel-style-header
                        [showPinning]="false"
                        [showHiding]="true"
                    >
                    </igx-excel-style-header>
  
                    <igx-excel-style-sorting></igx-excel-style-sorting>
                </igx-excel-style-column-operations>
  
                <!-- <igx-excel-style-filter-operations>
                    <igx-excel-style-search></igx-excel-style-search>
                </igx-excel-style-filter-operations> -->
            </igx-grid-excel-style-filtering>
  
  
            <ng-template igxRowEditText let-rowChangesCount>
              {{ 'igx-grid-changes' | translate }}: {{rowChangesCount}}
            </ng-template>
  
              <!-- <igx-column field="id" [sortable]="true" header="Id" [filterable]="false"></igx-column> -->
              <igx-column field="activityType" header="Aktivität" width="180px" [filterable]="true" [formatter]="formatActivityType"></igx-column>
              <igx-column field="bankName" [sortable]="true" header="Name der Bank" [filterable]="true"></igx-column>
              <igx-column field="deadlineDate" [sortable]="true" header="Stichtag" width="180px" [filterable]="true" [formatter]="formatDeadlineDate"></igx-column>
              <igx-column field="status" [sortable]="true" header="Status" [filterable]="false"></igx-column>
              <igx-column field="activityStart" [sortable]="true" header="Startzeitpunkt *" width="200px" [filterable]="false" [formatter]="formatActivityStart"></igx-column>
              <igx-column field="duration.durationTimeSpan" [sortable]="true" header="Dauer" [filterable]="false" [formatter]="formatDuration"></igx-column>


            <!-- Info Buttons In Columns -->
            <igx-column field="id" header=" " width="120px" [filterable]="false" [editable]="false" [disableHiding]="true">
              <ng-template igxCell let-cell="cell">
  
                <!-- Info -->
                <button mat-stroked-button class="m-r-10" matTooltip="Informationen" matTooltipPosition="left" [igxToggleAction]="dropdown2" [igxDropDownItemNavigation]="dropdown2" [overlaySettings]="overlaySettings">
                  <mat-icon>info</mat-icon>
                </button>
  
                <igx-drop-down #dropdown2 [width]="'600px'" (opened)="handleOpened($event, cell)" (opening)="handleOpening($event, cell)" (closed)="handleClosed()">
                  <div class="info-container">
                    <div class="dropdown-header-container">
                      <h4 class="dropdown-header">{{formatActivityType(cell?.row?.data?.activityType)}} - {{cell?.row?.data?.bankName}}</h4>
                      <div class="text-right">
                        <button mat-button class="custom-button toolbar-button" style="margin-right: 15px; margin-top: 10px;" (click)="copyInfoToClipboard()">
                          Kopieren
                        </button>
                      </div>
                    </div>
                    
  
                    <div class="info-inputs-container">
                      <div matInput class="info-text">{{infoTextBlock}}</div>
                      <!-- <div class="text-right">
                        <button mat-button class="custom-button toolbar-button" style="margin-bottom: 15px; margin-top: 10px;" (click)="copyInfoToClipboard()">
                          Kopieren
                        </button>
                      </div> -->
                    </div>
  
                  </div>
                </igx-drop-down>
  
              </ng-template>
            </igx-column>
  
              <igx-paginator [perPage]="50" [selectOptions]="[25, 50, 100]">
              </igx-paginator>
  
          </igx-grid>
        </mat-card>


        <!-- Footer for additional data  -->
        <mat-card class="footer-card m-t-0 m-b-20 m-l-20 m-r-20 p-t-15 p-b-0" >
          <mat-card-content>
              <div >
                <span>* Startzeitpunkte beziehen sich auf Server-Zeit.</span>
  
              </div>
          </mat-card-content>
        </mat-card>
  
      </div>
  
  </div>
  
  
