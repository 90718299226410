import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteAdminDialogComponent } from '../delete-admin-dialog/delete-admin-dialog.component';
import { BenutzerService } from 'src/app/benutzer-verwaltung/einstellugen/service/benutzer.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Component({
  selector: 'app-manage-mandanten-administratoren',
  templateUrl: './manage-mandanten-administratoren.component.html',
  styleUrls: ['./manage-mandanten-administratoren.component.scss']
})
export class ManageMandantenAdministratorenComponent extends MatPaginatorIntl implements OnInit {
  totalCount: any;
  pageIndex = 0;
  pageSize = 10;
  administratorList: any[] = [];
  mandatenId: any;
  constructor(private router: Router,
    private dialog: MatDialog,
    private benutzerService: BenutzerService,
    private route: ActivatedRoute,
    private commonUtils: CommonUtilsService) {
    super();
    this.getAdministratorList(this.pageIndex, this.pageSize);
  }
  administrators: any[] = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['FirstName', 'UserName', 'Status', 'Rollen', 'Letzte Aktivität', ' '];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  ngOnInit() {
    this.mandatenId = this.route.snapshot.paramMap.get('id');
  }



  getAdministratorList(pageIndex, pageSize) {
    this.benutzerService.getAllUsers(pageIndex, pageSize).subscribe((response: any) => {
      this.administratorList = response.Result.Results;
      //  assign roles from claims
      this.administratorList.forEach(element => {
        element.roles = this.getUserRole(element.UserClaims);

          // last activity
      if (element.LastLoginDate == null) {
        element.LastLoginDate = new Date().toISOString();
      }

      });

      //  initialize dataSource properties
      this.totalCount = response.Result.totalCount;
      this.dataSource = new MatTableDataSource(this.administratorList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;


    });
  }



  paginationEvent(event) {
    this.pageIndex = event.pageIndex;
    this.getAdministratorList(event.pageIndex, event.pageSize);

  }

  navigateToAddAdministrator() {
    this.router.navigateByUrl('home/add-administrator');
  }
  navigateToEditAdministrator(id) {
    this.router.navigateByUrl(`home/edit-administrator/${id}/${this.mandatenId}`);
  }
  // get user roles
  getUserRole(claims) {
    const claimArray: any[] = [];
    claims.forEach(claim => {
      claimArray.push(claim.ClaimValue);
    });
    return this.commonUtils.getClaimsAndReturnRoles(claimArray);
  }

  deleteAdminDialog(id, name) {
    const dialogRef = this.dialog.open(DeleteAdminDialogComponent, {
      width: '400px',
      data: { name: name }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.benutzerService.deleteUser(id).subscribe((Response) => {
          const temp = this.dataSource.filteredData.filter((data: any) => data.Id != id);
          this.dataSource = new MatTableDataSource(temp);

        });
      }
    });
  }
}
