<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="50px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'salesOrganization' | translate}} >
        {{(branchOrganisation ? 'branchStructure' : 'consultantStructure') | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">

    </mat-grid-tile>
  </mat-grid-list>
</div>
<mat-grid-list cols="12" rowHeight="550px" class="m-t-50 m-b-50">


  <div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap
    fxLayoutGap="0.5%" fxLayoutAlign="center">

    <!-- left -->
    <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
      <div *ngIf="spinnerFlag">
        <div class="div-spinner">
          <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
        </div>
      </div>
      <div>

        <!--------------------------------- Code after new API integrated --------------------- -->
        <!-- new consultant organisation -->
        <div *ngIf="consultantOrganisation">
          <app-consultant-orgnaization-tab (spinnerEvent)="setSpinner($event)"></app-consultant-orgnaization-tab>
        </div>
        <div *ngIf="branchOrganisation">
          <app-branch-organisation-tab (spinnerEvent)="setSpinner($event)"></app-branch-organisation-tab>
        </div>
      </div>
    </div>

    <!-- right -->
    <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="">
      <div class="d-flex w-100 fd-col" class="sidebar-form" fxLayout="column" fxLayout.md="row" fxLayout.sm="row"
        fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="flex-start">

        <!-- switch to branch structure -->
        <button mat-raised-button class="custom-button m-b-10" (click)="switch('branch')" [disabled]="branchOrganisation">

          {{'editBranchOrganisation' | translate}}</button>

        <!-- switch to consultant structure -->
        <button mat-raised-button class="custom-button m-b-10" (click)="switch('consultant')" [disabled]="consultantOrganisation">

          {{'editConsultantOrganisation' | translate}}</button>
      </div>
    </div>
  </div>
</mat-grid-list>
