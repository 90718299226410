import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FILE_INTERFACE } from 'src/app/app.config';

@Component({
  selector: 'app-kalkulation-files-dialog',
  templateUrl: './kalkulation-files-dialog.component.html',
  styleUrls: ['./kalkulation-files-dialog.component.scss']
})
export class KalkulationFilesDialogComponent implements OnInit {

  files: any[] = [];
  interfaceFiles = FILE_INTERFACE;
  kalkulationNo: any;
  deadlineDate: any;
  noFiles: any;
  constructor(public dialogRef: MatDialogRef<KalkulationFilesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.clearInterface();
    this.files = this.data.files;
    this.kalkulationNo = this.data.kalkulationNo;
    this.deadlineDate = this.data.deadline;
    this.getInterfaceFiles();
  }

  getInterfaceFiles() {
    this.files.forEach(file => {
      if (file) {
        file.uploadedFile.transferType.name = file.uploadedFile.transferType.name.trim();
        this.interfaceFiles.forEach(interfaceFile => {
          if (interfaceFile.name === file.uploadedFile.transferType.name) {
            file.type = interfaceFile.value;
            interfaceFile.files.push(file);
          }
        });
      }
    });
    const temp = this.interfaceFiles.filter(element => element.files.length !== 0);
    this.interfaceFiles = temp;
    if (this.interfaceFiles.length === 0) {
      this.noFiles = true;
    }
  }


  ngOnInit() {
  }
  clearInterface() {
    this.interfaceFiles.forEach(element => {
      element.files = [];
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
