import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-all-deletd-dialog',
  templateUrl: './all-deletd-dialog.component.html',
  styleUrls: ['./all-deletd-dialog.component.scss']
})
export class AllDeletdDialogComponent implements OnInit {

  deadline: number;
  endDate: Date;
  constructor(
    public dialogRef: MatDialogRef<AllDeletdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.deadline = new Date(this.data.calculation.deadlineDate).getDate();
    this.endDate = new Date(this.data.calculation.kalkulationDeadlines[0].calculationEndTimeStamp)
  }
  
  abort() {
    this.dialogRef.close();
  }

}
