<div>
    <h4 class="m-t-0" mat-dialog-title *ngIf="isUserActive">{{'disableUser' | translate}}</h4>
    <h4 class="m-t-0" mat-dialog-title *ngIf="!isUserActive">{{'activateUser' | translate}}</h4>

    <p *ngIf="!data.isUserDeleted">{{'disableUserNote' | translate}}</p>
    <p *ngIf="data.isUserDeleted">{{'userHasBeenReactivated' | translate}}</p>
    <div mat-dialog-actions align="end">
      <button mat-raised-button id="stop" color="basic" (click)="closeDialog()">{{'stop' | translate}}</button>
      <button mat-raised-button id="continue" cdkFocusInitial class="custom-button m-l-10" (click)="confirmAction()">{{'continue' | translate}}</button>
    </div>
  </div>
