import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/user-profile/services/user-profile.service';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-bv-header-bar',
  templateUrl: './bv-header-bar.component.html',
  styleUrls: ['./bv-header-bar.component.scss']
})
export class BvHeaderBarComponent implements OnInit {
  userDetails: any;
  constructor(public oauthService: OAuthService, private userProfileService: UserProfileService,
    public localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.userDetails = this.userProfileService.getUserProfileDetails();
  }

  logout() {
    this.oauthService.logOut();
    localStorage.clear();
    this.localStorageService.set('currentRoute', null);
  }
}
