<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'dateimport' | translate}} > {{'newUpload' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>


  <mat-grid-list cols="12" rowHeight="700px" class="m-t-50 m-b-10 p-t-30" >
    <mat-grid-tile flex-start flex-align-top [colspan]="12" class="grid-spacing">
      <mat-horizontal-stepper #stepper [linear]="true" class="card-shadow m-b-10">
        <!-- First Step -->
        <mat-step [stepControl]="fileUploadStep" [completed]="firstStepComplete">
          <ng-template matStepLabel>Upload</ng-template>
          <span>
            <div class="upload-container"
              [ngClass]="{'upload-container' : files.length == 0 , 'upload-container-resize' : files.length != 0}">
              <form>
                  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="droppedFile($event)" >
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                      <div class="form-group">
                        <label>
                          <div class="label-content">{{'zipFileUploadText' | translate}}
                            <a>{{'choose' | translate}}</a>
                          </div>
                          <input type="file" #inputFile name="file" (change)="onSelectedFile($event)"
                            accept="application/zip" multiple class="d-none" />
                        </label>
                      </div>
                    </ng-template>
                  </ngx-file-drop>
              </form>
            </div>
            <mat-card class="upload-box-wrapper" *ngFor="let file of files">
              <div class="upload-box">
                <div class="folder">
                  <img src="../../../../../assets/images/folder-zip.jpg" alt="">
                </div>
                <div class="upload-inner" *ngIf="!file.isUploaded">
                  <i class="ion-close close-arrow" (click)="closeFileCard(file.id)"></i>
                  <div class="m-b-20" class="pwd-wrap">
                    {{file.name}} -
                    <span *ngIf="file.inQueue">{{'passwordForDecompressionNecessary' | translate}}</span>
                    <span *ngIf="file.isProgress">{{'decompressionInProgress' | translate}}</span>
                  </div>
                  <div *ngIf="file.inQueue">
                    <div class="file-input-wrapper">
                      <mat-form-field class="full-width pwd-input-text" appearance="outline">
                        <mat-label>{{'password' | translate}}</mat-label>
                        <input matInput type="Password" [(ngModel)]="file.password">
                      </mat-form-field>
                    </div>
                    <div class="file-input-wrapper">
                      <button mat-raised-button class="custom-button" (click)="uploadOneFile(file.id, file.password)">
                        {{'dataDevelopmentKey' | translate}}
                        <i class="material-icons">
                          arrow_forward
                        </i>

                      </button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div *ngIf="file.isProgress">
                    <mat-spinner [diameter]="25" [strokeWidth]="2"></mat-spinner>
                  </div>
                </div>


                <div class="upload-inner" *ngIf="file.isError">
                  <i class="ion-close close-arrow" (click)="deleteZipFile(file.name)"></i>
                  <div class="text-red">{{file.name}} -
                    <span>{{file.errorDescription}}</span>
                  </div>
                  <div>
                  </div>
                </div>

                <div class="upload-inner" *ngIf="file.isSuccess">
                  <i class="ion-close close-arrow" (click)="deleteZipFile(file.name)"></i>
                  <div class="text-green">{{file.name}} -
                    <i class="material-icons green-check m-l-5">
                      check_circle
                    </i>
                  </div>
                  <div class="success-file">
                    <ul>
                      <div *ngFor="let extractedFile of file.uploadedFiles; let i = index">
                        <li *ngIf="i< 3 && file.showLess">
                          {{extractedFile.fileName}}
                        </li>
                        <li *ngIf="!file.showLess">
                          {{extractedFile.fileName}}
                        </li>
                      </div>
                      <li *ngIf="file.showLess">
                        <div *ngIf="file.uploadedFiles.length > 3">
                          <a (click)="showListOfFileIdExceeds(file.id)">Mehr zeigen</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </mat-card>

            <div class="m-t-20">
              <button mat-raised-button color="basic" class="upload-btn"
                (click)="clearNavigateToList()">{{'stop' | translate}}</button>
              <button matStepperNext mat-raised-button (click)="continueToNextStep()" 
                class="upload-btn custom-button float-right"
                [disabled]="disableContinueButton()">{{'continueW' | translate}}</button>
            </div>
          </span>
        </mat-step>
        <!-- Second Step -->
        <mat-step [stepControl]="fileMappingStep">
          <ng-template matStepLabel>{{'dataMapping' | translate}}</ng-template>

          <div *ngFor="let file of files" class="m-t-10"> {{file.name}}
            <mat-card *ngFor="let csvFile of file.uploadedFiles">
              <div class="upload-box">
                <div class="folder">
                  <img src="../../../../../assets/images/nav-icons.png" alt="">
                </div>
                <div class="upload-inner">
                  <div class="text-green">{{csvFile.fileName}}
                    <i class="material-icons green-check m-l-5">
                      check_circle
                    </i>
                  </div>

                  <div class="m-t-10">
                    <form>
                      <mat-label>{{csvFile.interfaceType}}</mat-label>
                    </form>
                  </div>

                </div>
              </div>
            </mat-card>
          </div>
          <div class="m-t-20">
            <button matStepperPrevious mat-raised-button color="basic"
              class="upload-btn">{{'back' | translate}}</button>
              <button matStepperNext mat-raised-button (click)="continueToOrgaSelectionStep()"
              class="custom-button upload-btn grey-text float-right">{{'continueW' | translate}}</button>
          </div>
        </mat-step>
        <!-- Final Step -->
        <mat-step [stepControl]="fileOrgaStructStep">
          <ng-template matStepLabel>{{'Organisationsstruktur ' | translate}}</ng-template>
          <div style="max-width: 700px;">
            <mat-radio-group name="opList"  fxLayout="column" [(ngModel)]="selectedOrgaStructImportOption" (ngModelChange)="onOrgaStructImportOptionChanged($event)">
              <mat-radio-button class="m-b-10" *ngFor="let op of orgaStructImportOptions" 
              [value]="op.value" name="opList" >{{op.name}} {{selectedOrgaStructImportOption.name}}</mat-radio-button>
            </mat-radio-group>

                <div *ngIf="selectedOrgaStructImportOption == 1" class="d-flex flex-row" >
                 <span *ngIf="isOrgaDataUploadFilePresent" class="info-text">{{'importOptionFromImportInfo' | translate}}</span>
                 <span *ngIf="!isOrgaDataUploadFilePresent" class="warn-text">{{'importOptionFromImportWarn' | translate}}</span>
                </div>

                <div *ngIf="selectedOrgaStructImportOption == 2" >
                  <div class="info-text">{{'importOptionFromExisitingDeadlineInfo' | translate}}</div>
                  <div class="d-flex flex-row m-t-5">
                    <mat-form-field class="m-b-0"  appearance="outline">
                      <mat-label>{{'deadline' | translate}}</mat-label>
                      <mat-select [(ngModel)]="selectedDeadline" (ngModelChange)="onDeadlineSelectionChanged($event)" [disabled]="deadlines.length == 0"> 
                        <mat-option *ngFor="let deadline of deadlines" [value]="deadline">{{deadline.deadlineDate | date}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
      
                    <!-- <mat-form-field class="m-l-10 m-b-0" appearance="outline">
                      <mat-label>{{'hierarchy' | translate}}</mat-label>
                      <mat-selection-list multiple [(ngModel)]="selectedHierarchies" [disabled]="hierarchies.length == 0" role="listbox">
                        <mat-list-option *ngFor="let hierarchy of hierarchies" [value]="hierarchy" role="option">{{hierarchy.hierarchyDescription}}</mat-list-option>
                      </mat-selection-list>
                    </mat-form-field> -->

                    <mat-form-field class="m-l-10 m-b-0" appearance="outline">
                      <mat-label>{{'hierarchy' | translate}}</mat-label>
                      <!-- Important part, input with matInput -->
                      <!-- <input matInput [ngStyle]="{'display:': (hierarchies.length == 0 ? 'none!important;' : 'inherit;')}" [disabled]="true" value="Nicht vorhanden"> -->
                      <input matInput style="display: none!important;" [disabled]="true" value="Nicht vorhanden">

                      <mat-selection-list class="mat-selection-list-negative-margin" [(ngModel)]="selectedHierarchies" (ngModelChange)="onChanged($event)" [disabled]="hierarchies.length == 0" role="listbox">
                          <mat-list-option checkboxPosition="before" *ngFor="let hierarchy of hierarchies" [value]="hierarchy" [disableRipple]="true">
                              {{hierarchy.hierarchyDescription}}
                          </mat-list-option>
                      </mat-selection-list>

                      <!-- <mat-checkbox *ngFor="let hierarchy of hierarchies" >
                            {{hierarchy.hierarchyDescription}}
                            </mat-checkbox> -->
                   </mat-form-field>

                  </div>
                </div>
                <div *ngIf="selectedOrgaStructImportOption == 3" class="d-flex flex-row" >
                  <span class="info-text">{{'importOptionReplaceExisitingInfo' | translate}}</span>
                </div>
                  
          </div>

          <div class="m-t-20">
            <button matStepperPrevious mat-raised-button color="basic"
              class="upload-btn">{{'back' | translate}}</button>
              <button matStepperNext mat-raised-button class="custom-button upload-btn grey-text float-right"
              [disabled]="selectedOrgaStructImportOption == 2 && selectedHierarchies.length < 1"
              (click)="redirectToEncryptionKeyDialog()">{{'import' | translate}}</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-grid-tile>
  </mat-grid-list>
</div>
