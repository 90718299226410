import { Component, OnInit } from '@angular/core';
import { SALES_ORGANIZATION } from 'src/app/tests/sales-organization-list';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router , NavigationExtras} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { SalesOrganisationService } from '../../service/sales-organisation.service';
import { GET_PARENT_OPTIONS } from '../../../../app.config';
import { Consultant } from '../../models/consultant';

@Component({
  selector: 'app-vertriebs-edit-consultant',
  templateUrl: './vertriebs-edit-consultant.component.html',
  styleUrls: ['./vertriebs-edit-consultant.component.scss']
})
export class VertriebsEditConsultantComponent implements OnInit {
  id: any;
  bankId: any;
  telimarketId: any;
  teamId: any;
  competenceId: any;
  salesOrganization: any[] = SALES_ORGANIZATION;
  consultantForm: FormGroup;
  consultantDetails: any;
  parentNodeName: any;
  parentNodeOptions: any[] = [];
  consultantObject = new Consultant();
  navigationExtras: NavigationExtras;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private salesOrganisationService: SalesOrganisationService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.telimarketId = this.route.snapshot.paramMap.get('telimarketId');
    this.bankId = this.route.snapshot.paramMap.get('bankId');
    this.teamId = this.route.snapshot.paramMap.get('teamId');
    this.competenceId = this.route.snapshot.paramMap.get('competenceId');
    this.initForm();
    this.getConsultantById();
    this.getParentOptions();
  }


  ngOnInit() {
    this.navigationExtras = {
      queryParams: {
        'consultantOrganisation': true
      }
    };
  }

  initForm() {
    this.consultantForm = new FormGroup({
      consultantName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100)])),
      consultantNo: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.maxLength(500)),
      parentNode: new FormControl('')
    });
  }
  setForm(value) {

    let parent: any;
    if (value.consultantTeamId) {
      parent = 'ConsultantTeam-' + value.consultantTeamId;

    } else if (value.consultantCompetenceCenterId) {
      parent = 'ConsultantCompetenceCenter-' + value.consultantCompetenceCenterId;
    } else if (value.creditInstitutionPartMarketId) {
      parent = 'CreditInstitutionPartMarket-' + value.creditInstitutionPartMarketId;
    } else {
      parent = 'CreditInstitution-' + value.creditInstitutionId;
    }

    this.consultantForm = new FormGroup({
      consultantName: new FormControl(value.name && value.name.trim(), Validators.compose([
        Validators.required,
        Validators.maxLength(100)])),
      consultantNo: new FormControl(value.consultantClientId && value.consultantClientId.trim(), Validators.required),
      comment: new FormControl(value.description && value.description.trim(), Validators.maxLength(500)),
      parentNode: new FormControl(parent)
    });
  }
  getConsultantById() {
    this.salesOrganisationService.getConsultantById(this.id).subscribe((response: any) => {
      this.setForm(response.Result);
      this.consultantObject = response.Result;
    });
  }


  getParentOptions() {
    const data = {
      dropdownType: GET_PARENT_OPTIONS.consultant

    };
    this.salesOrganisationService.getParentOptions(data).subscribe((response: any) => {
      this.parentNodeOptions = response.Result;
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.consultantForm.controls[controlName].hasError(errorName);
  }

  clearForm() {
    if (!this.consultantForm.dirty) {
      this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);

    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
      this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);

        }
      });

    }
  }
  editConsultant() {
    this.consultantObject.id = this.id;
    this.consultantObject.consultantClientId = this.consultantForm.value.consultantNo;
    this.consultantObject.consultantTeamId = null;
    this.consultantObject.creditInstitutionId = null;
    this.consultantObject.consultantCompetenceCenterId = null;
    this.consultantObject.creditInstitutionPartMarketId = null;
    this.consultantObject.name = this.consultantForm.value.consultantName;
    this.consultantObject.description = this.consultantForm.value.comment;
    if (this.consultantForm.value.parentNode.includes('ConsultantTeam')) {
      this.consultantObject.consultantTeamId = this.consultantForm.value.parentNode
      .substring(this.consultantForm.value.parentNode.indexOf('-') + 1);
    } else if (this.consultantForm.value.parentNode.includes('ConsultantCompetenceCenter')) {
      this.consultantObject.consultantCompetenceCenterId = this.consultantForm.value.parentNode
      .substring(this.consultantForm.value.parentNode.indexOf('-') + 1);

    } else if (this.consultantForm.value.parentNode.includes('CreditInstitutionPartMarket')) {

      this.consultantObject.creditInstitutionPartMarketId = this.consultantForm.value.parentNode
      .substring(this.consultantForm.value.parentNode.indexOf('-') + 1);
    } else {
      this.consultantObject.creditInstitutionId = this.consultantForm.value.parentNode
      .substring(this.consultantForm.value.parentNode.indexOf('-') + 1);
    }
    this.salesOrganisationService.editConsultant(this.id, this.consultantObject).subscribe((response) => {
      this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);

    });
  }
}
