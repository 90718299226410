<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        <div class="w-px-500">
          {{'bussinessAreas' | translate}} > {{'newAreaLabel' | translate}}
        </div>
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>


  <div class="div-spinner" *ngIf="spinnerFlag">
    <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
  </div>

  <mat-card class="m-t-50 m-b-10 p-t-30" *ngIf="!spinnerFlag">
    <mat-card-content>
      <mat-grid-list cols="12" rowHeight="250px">
        <mat-grid-tile flex-start flex-align-top [colspan]="6">
          <form class="w-100" novalidate [formGroup]="bussinessAreasForm" novalidate>
            <mat-grid-list cols="12" rowHeight="70px">

              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
                <mat-form-field class="full-width mt-20" appearance="outline">
                  <mat-label>{{'postcode' | translate}}</mat-label>
                  <input matInput placeholder="PLZ" name="PLZ" type="number"
                    (keypress)="getCharValidation($event)"
                    formControlName="PLZ">
                  <mat-error *ngIf="hasError('PLZ', 'postCodeValidation')">{{'postCodeAlreadyExists' | translate}}
                  </mat-error>
                  <mat-error *ngIf="hasError('PLZ', 'required')">{{'postcode' | translate }}
                    {{'isRequired' | translate}}</mat-error>
                  <mat-error *ngIf="hasError('PLZ', 'max')">
                    {{'maxLength5Msg' | translate}}</mat-error>
                <mat-error *ngIf="hasError('PLZ', 'min')">
                  {{'minLength5Msg' | translate}}</mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
                <mat-form-field class="full-width mt-20" appearance="outline">
                  <mat-label>{{'place' | translate}}</mat-label>
                  <input matInput placeholder="Ort" name="Ort" formControlName="Ort">
                  <mat-error *ngIf="hasError('Ort', 'required')">{{'place' | translate}} {{'isRequired' | translate}}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>



              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width mt-20" appearance="outline">
                  <mat-label>{{'assignment' | translate}} </mat-label>
                  <mat-select formControlName="Zuordnung">
                    <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>
                  </mat-select>
                </mat-form-field>



              </mat-grid-tile>
              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width mt-20" appearance="outline">
                  <mat-label>{{'purchasingPower' | translate}}</mat-label>
                  <input matInput placeholder="Kaufkraft" name="Kaufkraft" formControlName="Kaufkraft"
                  (keypress)="isValidKaufkraft($event)">
                  <mat-error *ngIf="hasError('Kaufkraft', 'pattern') && isTooManyDecimalPointError()">
                    {{'tooManyDecimalPlacesErrorMsg' | translate}}
                  </mat-error>
                  <mat-error *ngIf="hasError('Kaufkraft', 'pattern') && !isTooManyDecimalPointError()">
                    {{'maxOneDecimalPointHint' | translate}}
                  </mat-error>
                  <mat-error *ngIf="hasError('Kaufkraft', 'required')">{{'purchasingPower' | translate}}
                    {{'isRequired' | translate}}</mat-error>
                  <mat-hint>{{'maxOneDecimalPointHint' | translate}}</mat-hint>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
          </form>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
    <mat-card-actions class="m-b-5">
      <mat-grid-list cols="12" rowHeight="45px">
        <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
          <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" [disabled]="!bussinessAreasForm.valid"
            *ngIf="id == null" (click)="addBussinessAreas()">{{'add' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" *ngIf="id != null"
            (click)="updateBussinessArea()" [disabled]="!bussinessAreasForm.valid">{{'save' | translate}}</button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-actions>
  </mat-card>
</div>