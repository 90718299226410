<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="60px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'bookingForms' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">

    </mat-grid-tile>
  </mat-grid-list>
</div>

  <div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
      <div class="table-responsive px-5">
        <div>

          <mat-label class="info-text d-flex nowrap p-30 note-width m-b-10 m-r-10">
            <i class="material-icons">info</i>
            <span class="m-l-10">{{'bookingFormsInfo' | translate}}
            </span>
          </mat-label>


          <div class="div-spinner">
            <mat-spinner class="custom-field" mode="indeterminate" *ngIf="spinnerFlag"></mat-spinner>
          </div>
          <div class="card-shadow paginator-width ">
            <table mat-table [dataSource]="dataSource" matSort class="custom-table fixed-table nowrap-table m-b-0 scroll-content w-100">

              <!-- Position Column -->
              <ng-container matColumnDef="name">
                <th width="150" mat-header-cell *matHeaderCellDef mat-sort-header>{{'booking' | translate}} </th>
                <td mat-cell *matCellDef="let booking">{{booking.name}}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="description">
                <th width="200" mat-header-cell *matHeaderCellDef mat-sort-header> {{'designation' | translate}} </th>
                <td mat-cell *matCellDef="let booking"> {{booking.description}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="Buchungsgruppe"  >
                <th width="400" mat-header-cell *matHeaderCellDef  mat-sort-header> {{'bookingGroup' | translate}} </th>
                <td mat-cell *matCellDef="let booking"> {{booking.bookingTypeGroup.name}}</td>
              </ng-container>
              <ng-container matColumnDef=" ">
                <th width="40"mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let booking" class="text-right">
                  <button mat-icon-button *ngIf="isVRCPAdmin" (click)="editBooking(booking)"> 
                    <i class="fs-16 material-icons"> edit </i>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
            <mat-paginator class="m-t-5 paginator-width" #paginator [length]="totalCount" [pageSizeOptions]="[10, 25, 30]">
            </mat-paginator>
          </div>

          <!-- table end -->

        </div>

      </div>
    </div>
    <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="">
      <div class="m-t-5 m-l-10 w-100">
        <mat-form-field class="w-100 search-input">
          <input matInput placeholder="Suchen" (keyup)="applyFilter($event.target.value)" value="">
        </mat-form-field>
      </div>
    </div>
  </div>
