import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/components/login/login.component';
import { BvHomeComponent } from './benutzer-verwaltung/bv-common/bv-home/bv-home.component';
import { BenutzerListComponent } from './benutzer-verwaltung/einstellugen/components/benutzer-list/benutzer-list.component';
import { BenutzerAddComponent } from './benutzer-verwaltung/einstellugen/components/benutzer-add/benutzer-add.component';
import { BenutzerEditComponent } from './benutzer-verwaltung/einstellugen/components/benutzer-edit/benutzer-edit.component';
import { DataImportListComponent } from './benutzer-verwaltung/data-transfer-center/components/data-import-list/data-import-list.component';
import { NewDataImportComponent } from './benutzer-verwaltung/data-transfer-center/components/new-data-import/new-data-import.component';
import { KalkulatiotenListComponent } from './benutzer-verwaltung/data-transfer-center/components/kalkulatioten-list/kalkulatioten-list.component';
import { NewKalkulatiotenComponent } from './benutzer-verwaltung/data-transfer-center/components/new-kalkulatioten/new-kalkulatioten.component';
import { GeschaftsgebieteListComponent } from './benutzer-verwaltung/parameter/components/geschaftsgebiete-list/geschaftsgebiete-list.component';
import { AddEditGeschaftsgebieteComponent } from './benutzer-verwaltung/parameter/components/add-edit-geschaftsgebiete/add-edit-geschaftsgebiete.component';
import { VertriebsOrganizationListComponent } from './benutzer-verwaltung/parameter/components/vertriebs-organization-list/vertriebs-organization-list.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { AuthGuard } from './auth/auth.guard';
import { BaseComponent } from './base/base/base.component';
import { VertriebsAddConsultantComponent } from './benutzer-verwaltung/parameter/components/vertriebs-add-consultant/vertriebs-add-consultant.component';
import { VertriebsEditConsultantComponent } from './benutzer-verwaltung/parameter/components/vertriebs-edit-consultant/vertriebs-edit-consultant.component';
import { BeratungBedarfAndLosungComponent } from './benutzer-verwaltung/parameter/components/beratung-bedarf-and-losung/beratung-bedarf-and-losung.component';
import { MusterkundengruppenListComponent } from './benutzer-verwaltung/parameter/components/musterkundengruppen-list/musterkundengruppen-list.component';
import { AssetklassenListComponent } from './benutzer-verwaltung/parameter/components/assetklassen-list/assetklassen-list.component';
import { AssetklassenEditComponent } from './benutzer-verwaltung/parameter/components/assetklassen-edit/assetklassen-edit.component';
import { BuchungsartenListComponent } from './benutzer-verwaltung/parameter/components/buchungsarten-list/buchungsarten-list.component';
import { ProduktzuordnungenListComponent } from './benutzer-verwaltung/parameter/components/produktzuordnungen-list/produktzuordnungen-list.component';
import { EditProduktzuordnungenComponent } from './benutzer-verwaltung/parameter/components/edit-produktzuordnungen/edit-produktzuordnungen.component';
import { PotentialAnalysisMainpageComponent } from './benutzer-verwaltung/v-musterkunde/components/potential-analysis-mainpage/potential-analysis-mainpage.component';
import { PotentialanalysisVermogenReportComponent } from './benutzer-verwaltung/v-musterkunde/components/potentialanalysis-vermogen-report/potentialanalysis-vermogen-report.component';
import { PotentialanalysePdfGenerateComponent } from './benutzer-verwaltung/v-musterkunde/components/potentialanalyse-pdf-generate/potentialanalyse-pdf-generate.component';
import { VrActivityMngrMainpageComponent } from './benutzer-verwaltung/vr-activity-manager/vr-activity-mngr-mainpage/vr-activity-mngr-mainpage.component';
import { MarketingFabrikMainPageComponent } from './benutzer-verwaltung/marketing-fabrik/marketing-fabrik-main-page/marketing-fabrik-main-page.component';
import { VrMusterkundeComponent } from './benutzer-verwaltung/v-musterkunde/components/vr-musterkunde/vr-musterkunde.component';
import { AddProfitCenterComponent } from './benutzer-verwaltung/add-profit-center/add-profit-center.component';
import { DataExportListComponent } from './benutzer-verwaltung/data-transfer-center/components/data-export-list/data-export-list.component';
import { UnathorizedComponent } from './auth/unathorized/unathorized.component';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
import { AddConsultantComponent } from './benutzer-verwaltung/parameter/components/add-consultant/add-consultant.component';
import { CalculationVariableTabComponent } from './benutzer-verwaltung/einstellugen/components/calculation-variable-tab/calculation-variable-tab.component';
import { BuchungsartenEditModalComponent } from './benutzer-verwaltung/parameter/components/buchungsarten-edit/buchungsarten-edit.component';
import { AddEditZweigstellenComponent } from './benutzer-verwaltung/parameter/components/add-edit-zweigstellen/add-edit-zweigstellen.component';
import { ConsultantSegmentsListComponent } from './benutzer-verwaltung/parameter/components/consultant-segments-list/consultant-segments-list.component';
import { AddEditConsultantSegmentComponent } from './benutzer-verwaltung/parameter/components/add-edit-consultant-segment/add-edit-consultant-segment.component';
import { DuplicateConsultantOrganizationComponent } from './benutzer-verwaltung/parameter/components/duplicate-consultant-organization/duplicate-consultant-organization.component';
import { ConsultantStructureList } from './tests/fixtures/consult-structure';
import { ConsultantStructureGridComponent } from './benutzer-verwaltung/parameter/components/consultant-structure-grid/consultant-structure-grid.component';
import { BankActivitiesComponent } from './administration/components/bank-activities/bank-activities.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'base',
    pathMatch: 'full',
  },
  {
    path: 'base',
    component: BaseComponent, canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'bv-home', component: BvHomeComponent, canActivate: [AuthGuard],
    children: [

      {
        path: 'benutzerlist', component: BenutzerListComponent, canActivate: [AuthGuard], data: {
          allowedRoles: ['Rechte-Admin', 'VRConsultingAccess'],
          allowedClaims: ['Musterkunde.Configuration.Read', 'Musterkunde.User.Read'],
          allowedScopes: ['VRCP-Admin', 'user_admin', 'Administrator'],
        },
      },
      {
        path: 'calculation-variable', component: CalculationVariableTabComponent, canActivate: [AuthGuard], data: {
          allowedRoles: ['Rechte-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Configuration.Read'], allowedScopes: ['VRCP-Admin', 'Analyst'],
        },
      },
      {
        path: 'benutzeradd',
        component: BenutzerAddComponent,
        canActivate: [AuthGuard],
        data: {
          allowedRoles: ['Rechte-Admin', 'VRConsultingAccess'],
          allowedClaims: ['Musterkunde.Configuration.Read', 'Musterkunde.User.Read'],
          allowedScopes: ['VRCP-Admin', 'user_admin', 'Administrator'],
        },
      },
      { path: 'benutzeradd', component: BenutzerAddComponent, canActivate: [AuthGuard], data: { allowedRoles: ['Rechte-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Configuration.Read', 'Musterkunde.User.Read'], allowedScopes : ["VRCP-Admin", "user_admin", "Administrator"] } },
      { path: 'benutzeredit/:id', component: BenutzerEditComponent, canActivate: [AuthGuard], data: { allowedRoles: ['Rechte-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Configuration.Read', 'Musterkunde.User.Read'], allowedScopes : ["VRCP-Admin", "user_admin", "Administrator"] } },

      { path: 'dataimport-list', component: DataImportListComponent, canActivate: [AuthGuard], data: { allowedRoles: ['Datenmanager', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.DataCenter.Download'], allowedScopes : ["VRCP-Admin", "Datamanager", "Datenmanager"] } },
      { path: 'new-dataimport', component: NewDataImportComponent, canActivate: [AuthGuard], data: { allowedRoles: ['Datenmanager', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.DataCenter.Download'], allowedScopes : ["VRCP-Admin", "Datamanager","Datenmanager"] } },
      { path: 'kalkulation-list', component: KalkulatiotenListComponent, canActivate: [AuthGuard], data: { allowedRoles: ['Datenmanager', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.DataCenter.Download'], allowedScopes : ["VRCP-Admin", "Datamanager","Datenmanager"] } },
      { path: 'new-kalkulation', component: NewKalkulatiotenComponent, canActivate: [AuthGuard], data: { allowedRoles: ['Datenmanager', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.DataCenter.Download'], allowedScopes : ["VRCP-Admin", "Datamanager","Datenmanager"] } },




      {
        path: 'consultant-segments-list',
        component: ConsultantSegmentsListComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes : ["VRCP-Admin", "Analyst"] }
      },
      {
        path: 'add-consultant-segments/:orgaStructHierarchyId/:deadline',
        component: AddEditConsultantSegmentComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'],
        allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes : ["VRCP-Admin", "Analyst"] }
      },
      {
        path: 'edit-consultant-segments/:id/:orgaStructHierarchyId/:deadline',
        component: AddEditConsultantSegmentComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes : ["VRCP-Admin", "Analyst"] }
      },

      {
        path: 'duplicate-consultant-organization/:deadline',
        component: DuplicateConsultantOrganizationComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes : ["VRCP-Admin", "Analyst"] }
      },
      {
        path: 'edit-hierarchy/:orgaStructHierarchyId',
        component: DuplicateConsultantOrganizationComponent,
        canActivate: [AuthGuard],
        data: { allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes : ["VRCP-Admin", "Analyst"] }
      },

      {
        path: 'bussiness-areas',
        component: GeschaftsgebieteListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'add-bussiness-areas',
        component: AddEditGeschaftsgebieteComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'edit-bussiness-areas/:id',
        component: AddEditGeschaftsgebieteComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'sales-organization-list/:expanded',
        component: VertriebsOrganizationListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'consultant-structure-grid',
        component: ConsultantStructureGridComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'sales-organization-add-consultant/:id/:partMarketId/:competenceId/:teamId',
        component: VertriebsAddConsultantComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'sales-organization-edit-consultant/:id/:teamId/:competenceId/:telimarketId/:bankId',
        component: VertriebsEditConsultantComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'consultation-needs-solution',
        component: BeratungBedarfAndLosungComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'musterkunde-customer-group',
        component: MusterkundengruppenListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'asset-classes',
        component: AssetklassenListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'asset-classes-edit/:id',
        component: AssetklassenEditComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'booking-forms',
        component: BuchungsartenListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Configuration.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'edit-booking-form/:id',
        component: BuchungsartenEditModalComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Configuration.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'product-classification/:filter',
        component: ProduktzuordnungenListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },

      {
        path: 'edit-product-classification/:id',
        component: EditProduktzuordnungenComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'potential-analysis-mainpage', component: PotentialAnalysisMainpageComponent, canActivate: [AuthGuard], data: {
          allowedRoles: ['Analyst', 'VRConsultingAccess'],
          allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst'],
        },
      },
      {
        path: 'potential-analysis-vermogen', component: PotentialanalysisVermogenReportComponent, canActivate: [AuthGuard], data: {
          allowedRoles: ['Analyst', 'VRConsultingAccess'],
          allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin'],
        },
      },
      {
        path: 'potential-analysis-pdf',
        component: PotentialanalysePdfGenerateComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Analyst', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'vr-activity-mngr-mainpage',
        component: VrActivityMngrMainpageComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Analyst', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read']},
      },
      {
        path: 'marketing-fabrik-mainpage',
        component: MarketingFabrikMainPageComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Analyst', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read']},
      },
      {path: 'v-musterkunde', component: VrMusterkundeComponent, canActivate: [AuthGuard], data: {allowedRoles: ['Analyst', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read']}},
      {
        path: 'add-profitcenter/:edit/:id',
        component: AddProfitCenterComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'edit-profitcenter/:edit/:id',
        component: AddProfitCenterComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'data-export-list',
        component: DataExportListComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenmanager', 'VRConsultingAccess', 'Megaexport'], allowedClaims: ['Musterkunde.DataCenter.Download'], allowedScopes: ['VRCP-Admin', 'Datamanager', 'Revisor', 'Datenmanager', 'Megaexport']},
      },
      {
        path: 'add-consultant/:edit/:id',
        component: AddConsultantComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'edit-consultant/:edit/:id',
        component: AddConsultantComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'add-edit-zweigstellen/:edit/:canAddRegion/:id',
        component: AddEditZweigstellenComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin', 'Analyst']},
      },
      {
        path: 'bank-activities',
        component: BankActivitiesComponent,
        canActivate: [AuthGuard],
        data: {allowedRoles: ['Datenbank-Admin', 'VRConsultingAccess'], allowedClaims: ['Musterkunde.Dashboard.Read'], allowedScopes: ['VRCP-Admin']},
      },
    ],
  },
  {path: 'unathorized', component: UnathorizedComponent },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})


export class AppRoutingModule {
}
