
export class BussinessArea {
    'id' = 0;
    'clientId': number;
    'postcode': number;
    'place': string;
    'categoryId': number;
    'category': {
        'id': number;
        'name': string;
    };
    'purchasingPower': number;
}
