import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injector, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import {UserSessionService} from "../../user-profile/services/user-session.service";
@Injectable()
export class customInterceptor implements HttpInterceptor {
    private oauthService: OAuthService;

    constructor(private injector: Injector, private userService: UserSessionService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;
        if (this.oauthService === undefined) {
            this.oauthService = this.injector.get(OAuthService);
        }
        if (this.oauthService !== undefined) {
            const token = this.oauthService.getAccessToken();


            if (token !== '') {
                const tokenValue = 'Bearer ' + token;
                const mkClientId = this.userService.getClientId();
                const header = {
                  'Authorization': tokenValue,
                  'mkclient_id': mkClientId ? mkClientId.toString() : ''
                }
                requestToForward = req.clone({ setHeaders: header });
            }
        } else {
            console.debug('OAuthService undefined: NO auth header!');
        }

        return next.handle(requestToForward).pipe(
            map(event => {
                return event;
            }),
            catchError(error => {
                return observableThrowError(error);
            }),
        );
    }
}
