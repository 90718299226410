import { Component, OnInit, ViewChild } from '@angular/core';
import { PRODUCT_CLASSIFICATIONS_OLD } from 'src/app/tests/fixtures/product-classification';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductCombinationsService } from '../../service/product-combinations.service';
import { PRODUKT_SELECTED_FILTER, PRODUCT_PROVIDER_FILTER, PRODUCT_STATUS_FILTER } from '../../../../app.config';

@Component({
  selector: 'app-produktzuordnungen-list',
  templateUrl: './produktzuordnungen-list.component.html',
  styleUrls: ['./produktzuordnungen-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProduktzuordnungenListComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  productProviderFilter = 0;
  productStatusFilter = 0;
  productClassifications: any;
  productProviders: any[] = PRODUCT_PROVIDER_FILTER;
  productStatuses: any[] = PRODUCT_STATUS_FILTER;
  dataSource: MatTableDataSource<any>;
  columnsToDisplay = ['arrow', 'Name', 'Product01', 'Product02', 'ISIN', 'Partner.Name', 'Zuordnungen'];
  expandedElement: any | null;
  totalCount: any;
  pageIndex = 0;
  pageSize = 10;
  searchString = '';
  activeColumnSorted = '';
  activeColumnSortedDirection = 'asc';
  expandedElements: [] = [];
  oldData = PRODUCT_CLASSIFICATIONS_OLD;
  spinnerFlag = true;
  selectedFilter = PRODUKT_SELECTED_FILTER;
  filterValue: string = '';


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private produktCombinationService: ProductCombinationsService) {
    //  to apply previous filter when user comes again to this page
  }

  resetStandardData(id) {
    let resetProduct: any;
    PRODUCT_CLASSIFICATIONS_OLD.forEach(product => {
      if (product.id == id) {
        resetProduct = product;
      }
    });
    this.dataSource.filteredData.forEach(d => {
      if (d.id === id) {
        d = resetProduct;
      }
    });
    this.dataSource = new MatTableDataSource(PRODUCT_CLASSIFICATIONS_OLD);

  }


  ngOnInit() {
    // search filter
    if (this.selectedFilter.previousSearchString) {
      this.searchString = this.selectedFilter.previousSearchString;
    }

    // dropdown filter
    const applyPreviousFilter = this.activatedRoute.snapshot.paramMap.get('filter');
    if (applyPreviousFilter == 'true' && this.selectedFilter.selectedFilter) {
      this.productProviderFilter = this.selectedFilter.selectedFilter;
    }
    this.filterData(null);
  }

  getProductCombinations(index, size, activeColumn, orderBy) {
    const filterValues: any = {
      index: index + 1,
      size: size,
      activeColumn: activeColumn,
      orderBy: orderBy,
      searchString: this.searchString,
      productProvider: this.productProviderFilter,
      productStatus: this.productStatusFilter
    };

    this.produktCombinationService.getProductCombinations(filterValues).subscribe((response: any) => {
      this.totalCount = response.Result.totalCount;
      this.productClassifications = response.Result.results;
      this.dataSource = new MatTableDataSource(response.Result.results);
      this.dataSource.sort = this.sort;
      this.totalCount = response.Result.totalCount;
      this.spinnerFlag = false;
    }, (err) => {
      this.spinnerFlag = false;
    });
  }

  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterData(event) {
    this.pageIndex = 0;
    this.spinnerFlag = true;
    this.getProductCombinations(this.pageIndex, this.pageSize,
      this.activeColumnSorted || 'Name', this.activeColumnSortedDirection || 'asc');
  }

  editProdukt(id) {
    this.selectedFilter.selectedFilter = this.productProviderFilter;
    this.router.navigateByUrl(`bv-home/edit-product-classification/${id}`);
  }

  paginationEvent(event) {
    this.spinnerFlag = true;

    this.pageIndex = event.pageIndex;
    if (this.pageSize !== event.pageSize) {
      this.pageIndex = event.pageIndex = 0;
    }
    this.pageSize = event.pageSize;
    this.getProductCombinations(event.pageIndex, event.pageSize,
      this.activeColumnSorted || 'Name', this.activeColumnSortedDirection || 'asc');

  }

  sortData(sort) {
    this.spinnerFlag = true;
    this.activeColumnSorted = sort.active;
    this.activeColumnSortedDirection = sort.direction;
    this.getProductCombinations(this.pageIndex, this.pageSize, sort.active, sort.direction);
  }

  searchData(clearSearch: boolean = false) {
    if (clearSearch) {
      this.searchString = '';
    }

    this.spinnerFlag = true;
    this.selectedFilter.previousSearchString = this.searchString;
    this.pageIndex = 0;
    this.paginator.pageIndex = 0;
    this.getProductCombinations(this.pageIndex, this.pageSize, this.activeColumnSorted, this.activeColumnSortedDirection);
  }
}