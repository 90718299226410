<!-- Page Heading -->
<div id="parentPDFHTML">
  <div>
    <!-- class="p-l-3vw p-r-3vw" -->
    <div class="PageTitlePDF">
      <h1 class="page-title main-title">
        Übersicht Potenziale
      </h1>
      <h3 class="mainSub-title">
        {{profitCenterFilter}} <br>
        {{kalkulationHeader}}
      </h3>
    </div>
    <mat-grid-list cols="12" rowHeight="700px" class="m-t-20 m-b-50" style="width: 1100px">
      <mat-grid-tile flex-start flex-align-top [colspan]="12" class="grid-spacing">
        <div class=" w-100 potentialAnalysisContent">
          <mat-accordion multi="true" class="custom example-headers-align mat-elevation-z8 w-100">
            <mat-expansion-panel class="analysis-tab border-wrapper pdfAnalysis-tab"
              *ngFor="let needAndSolution of customerNeeds; let i = index" id="mainList1{{i}}" [expanded]="true">

              <div class="customerNeedTitle">
                <div [ngStyle]="getLeftBorder(needAndSolution.themeColor)" class="customerNeed{{i}}">
                  <mat-expansion-panel-header class="analysis-title">
                    <mat-panel-title>
                      <span class="title" [ngStyle]="getThemeColor(needAndSolution.themeColor)">
                        {{needAndSolution.description}}
                      </span>
                      <span class="progressbar-left grey-text">{{'actualValue' | translate}}</span>
                      <span class=" progressbar-right grey-text">{{'potential' | translate}}</span>
                      <span class=" point grey-text"> {{'creation' | translate}}</span>
                    </mat-panel-title>

                  </mat-expansion-panel-header>
                  <mat-expansion-panel hideToggle #expId
                    *ngFor="let needs of needAndSolution.needFields; let i = index">
                    <mat-expansion-panel-header class="pt-10 analysis-content">
                      <mat-panel-title>
                        <span class="title">
                          {{needs.name}} ({{needs.cashFlowType}})
                        </span> 
                        <span class="progress-bar">
                          <mat-progress-bar mode="determinate" [value]="needs.inventionValue"></mat-progress-bar>
                          <span class="left-digit">{{getDecimalPoint(needs.actualValue)}}</span>
                          <span class="right-digit">{{getDecimalPoint(needs.potencialValue)}}</span>
                        </span>
                        <span class=" point ">{{needs.inventionValue | alterChartTableData : true}}</span>
                        <!-- </div> -->
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                  </mat-expansion-panel>
                </div>
              </div>
              <div class="customerNeedDetail{{i}}">
                <div [ngStyle]="getLeftBorder(needAndSolution.themeColor)">
                  <h1 class="page-title sub-title" style="content: hidden"> {{needAndSolution.description}} </h1>
                  <mat-expansion-panel hideToggle #expId *ngFor="let needs of needAndSolution.needFields; let j = index"
                    [expanded]="true" class="customerNeedField{{j}}">
                    <div class="customerNeedFieldDiv{{j}}">
                      <mat-expansion-panel-header class="pt-10 analysis-content needsHeader">
                        <mat-panel-title>
                          <div class="inner-tab">
                            <span class="title">
                              {{needs.name}}  ({{needs.cashFlowType}})
                            </span>
                            <span class="progress-bar p-10">
                              <mat-progress-bar mode="determinate" [value]="needs.inventionValue"></mat-progress-bar>
                              <span class="left-digit">{{getDecimalPoint(needs.actualValue)}}</span>
                              <span class="right-digit">{{getDecimalPoint(needs.potencialValue)}}</span>
                            </span>
                            <span class=" point ">{{needs.inventionValue | alterChartTableData : true}}</span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <mat-list class="tab-list tab-list-pdf">
                        <mat-list-item class="expansion-inner-list"
                          *ngFor="let solution of needs.needFieldParts; let k = index">
                          <span class="title">{{solution.category}}</span>
                          <span class="solution">{{solution.name}}</span>
                          <span class="progress-bar">
                            <mat-progress-bar mode="determinate" [value]="solution.inventionValue"></mat-progress-bar>
                            <span class="left-digit">{{getDecimalPoint(solution.actualValue)}}</span>
                            <span class="right-digit">{{getDecimalPoint(solution.potencialValue)}}</span>
                          </span>
                          <span class="point">{{solution.inventionValue | alterChartTableData : true}}</span>

                        </mat-list-item>
                      </mat-list>
                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-grid-tile>
      <mat-grid-tile flex-start flex-align-top [colspan]="3" class="grid-spacing">
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<div id="page1"></div>
<div id="page2"></div>
<div id="page3"></div>
<div id="page4"></div>
<div id="page5"></div>
<div id="page6"></div>
<div id="page8"></div>
<div id="page9"></div>
<div id="demoCustomerNeedDetail"></div>