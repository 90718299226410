import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BenutzerService } from '../../service/benutzer.service';
import { Benutzer } from '../../models/benutzer.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteUserConfirmDialogComponent } from '../delete-user-confirm-dialog/delete-user-confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { InvitationService } from '../../service/invitation.service';
import { ClaimGroupService } from '../../service/claim-group.service';

@Component({
  selector: 'app-benutzer-list',
  templateUrl: './benutzer-list.component.html',
  styleUrls: ['./benutzer-list.component.scss'],
})
export class BenutzerListComponent extends MatPaginatorIntl implements OnInit {
  benutzers: Benutzer[] = [];
  totalCount: any;
  pageIndex = 0;
  payload: any = {
    ClaimGroupIds: [],
    SearchKey: '',
  };
  pageSize = 10;
  spinnerFlag = true;
  role = 'Alle';
  dataSource: any;
  itemsPerPageLabel = 'Elemente pro Seite';
  displayedColumns: string[] = ['FirstName', 'UserName', 'Status', 'Rollen', 'Letzte Aktivität', ' '];
  displayedColumnsInvite: string[] = ['FirstName', 'UserName', 'Rollen', ' '];
  invitedUsers;
  dataSourceInvite;
  totalCountInvite = 0;
  pageIndexInvite = 0;
  pageSizeInvite = 10;
  timeout;
  selectedTab = 'UsersWithAcceptedInvitation';

  @ViewChild('userSort', {static: false}) sort: MatSort;
  @ViewChild('invitedSort', {static: false}) invitedSort: MatSort;
  claimGroups: any;

  constructor(private router: Router,
              private benutzerService: BenutzerService,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private commonUtils: CommonUtilsService,
              private claimGroupService: ClaimGroupService,
              private invitationService: InvitationService) {
    super();
  }

  ngOnInit() {
    this.getAllUsers(this.pageIndex, this.pageSize);
    this.getClaimGroups();
  }

  getClaimGroups() {
    this.claimGroupService.getClaimGroups().subscribe((response: any) => {
      this.claimGroups = response.Result;
    });
  }

  addUser() {
    this.router.navigateByUrl('bv-home/benutzeradd');
  }

  getAllUsers(index, size) {
    this.benutzerService.searchUsers(index + 1, size, this.payload).subscribe(async (response: any) => {
      this.benutzers = await response.Result.Results;
      this.dataSource = new MatTableDataSource(this.benutzers);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: any, property) => {
        return item[property].toLowerCase();
      };
      this.benutzers.forEach(benutzer => {
        benutzer.roles = benutzer.ClaimGroups.map((group: any) => group.Name).sort();
        // last activity
        if (!benutzer.LastLoginDate) {
          benutzer.hasLoginDate = false;
          benutzer.LastLoginDate = 'Nicht verfügbar';
        } else {
          benutzer.hasLoginDate = true;
          benutzer.LastLoginDate = new Date(benutzer.LastLoginDate).toLocaleString('de-DE', {timeZone: 'Europe/Berlin'});

        }
      });
      this.totalCount = response.Result.TotalCount;
      await this.getAllInvitation(this.pageIndexInvite, this.pageSizeInvite);
      this.spinnerFlag = false;

    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });
  }


  async getAllInvitation(index, size) {
    this.payload.SearchText = this.payload.SearchKey;
    const invitationPayload: any = {
      SearchText: this.payload.SearchKey,
    };
    if (this.payload.ClaimGroupIds.length > 0) {
      invitationPayload.ClaimGroupId = this.payload.ClaimGroupIds[0];
    }
    this.invitationService.getAllInvitation(index + 1, size, invitationPayload).subscribe(async (response: any) => {
      this.invitedUsers = await response.Result.Results;
      this.invitedUsers.forEach(invitedUser => {
        if (invitedUser.ClaimGroups) {
          invitedUser.roles = invitedUser.ClaimGroups.map((group: any) => group.Name);
        } else {
          invitedUser.roles = [];
        }
      });
      this.totalCountInvite = response.Result.TotalCount;
      // last activity
      this.dataSourceInvite = new MatTableDataSource(this.invitedUsers);
      this.dataSourceInvite.sort = this.invitedSort;
      this.dataSourceInvite.sortingDataAccessor = (item: any, property) => {
        return item[property].toLowerCase();
      };
      this.spinnerFlag = false;

    });
  }

  paginationEvent(event) {
    this.spinnerFlag = true;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllUsers(event.pageIndex, event.pageSize);
  }

  paginationEventInvite(event) {
    this.spinnerFlag = true;
    this.pageIndexInvite = event.pageIndex;
    this.getAllInvitation(event.pageIndex, event.pageSize);
  }

  editUser(id) {
    this.router.navigateByUrl(`bv-home/benutzeredit/` + id);
  }

  confirmDialogToDeleteUser(id, user) {
    const dialogRef = this.dialog.open(DeleteUserConfirmDialogComponent, {
      width: '400px',
      data: {id: id, name: user.FirstName + ' ' + user.LastName},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinnerFlag = true;
        user.IsDeleted = !user.IsDeleted;
        this.benutzerService.updateUser(id, user).subscribe((response: any) => {
          // const temp = this.dataSource.filteredData.filter(u => u.Id != id);
          // this.dataSource = new MatTableDataSource(temp);
          this.commonUtils.successSnackBar(this.translateService.instant('userDeletedSuccessfully'));
          this.spinnerFlag = false;
        }, (err) => {
          this.spinnerFlag = false;
          this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage);
        });
      }
    });
  }

  applyFilter(event) {
    this.spinnerFlag = true;
    if (this.selectedTab == 'UsersWithPendingInvitation') {
      this.getAllInvitation(this.pageIndex, this.pageSize);
    } else {
      this.getAllUsers(this.pageIndex, this.pageSize);
    }
  }

  searchInvitedUser(event) {
    if (this.selectedTab == 'UsersWithPendingInvitation') {
      if (event.target.value) {
        this.spinnerFlag = true;
        this.invitationService.getInvitationByEmail(event.target.value).subscribe((res: any) => {
          this.dataSourceInvite = new MatTableDataSource([res.Result]);
          this.spinnerFlag = false;
        }, (err) => {
          this.commonUtils.errorSnackBar(
            'error',
            err.error.ResponseException.ValidationErrors ? err.error.ResponseException.ValidationErrors[0].Message : err.error.ResponseException.ExceptionMessage,
          );
          this.dataSourceInvite = new MatTableDataSource([]);
          this.spinnerFlag = false;

        });
      } else {
        this.dataSourceInvite = new MatTableDataSource(this.invitedUsers);
        this.spinnerFlag = false;
      }
    }
  }

  searchOnFocusOut(event) {
    this.spinnerFlag = true;
    this.invitationService.getInvitationByEmail(event.target.value).subscribe((res: any) => {
      this.dataSourceInvite = new MatTableDataSource(res.Result);
      this.spinnerFlag = false;

    });
  }

  filterData(event) {
    // [ IncludeDeletedAndNonDeletedUsers, IncludeOnlyDeletedUsers, IncludeOnlyNonDeletedUsers ]
    {
      this.spinnerFlag = true;
      if (event.value === 'Aktive Benutzer') {
        this.payload.UserDeletedFilter = 'IncludeOnlyNonDeletedUsers';
        this.payload.ClaimGroupIds = [];
        if (this.selectedTab == 'UsersWithAcceptedInvitation') {
          this.getAllUsers(this.pageIndex, this.pageSize);
        } else {
          this.getAllInvitation(this.pageIndex, this.pageSize);
        }
      } else if (event.value === 'Inaktive Benutzer') {
        this.payload.UserDeletedFilter = 'IncludeOnlyDeletedUsers';
        this.payload.ClaimGroupIds = [];
        if (this.selectedTab == 'UsersWithAcceptedInvitation') {
          this.getAllUsers(this.pageIndex, this.pageSize);
        } else {
          this.getAllInvitation(this.pageIndex, this.pageSize);
        }
      } else if (event.value === 'Alle') {
        delete this.payload.UserDeletedFilter;
        this.payload.SearchKey = '';
        this.payload.ClaimGroupIds = [];
        if (this.selectedTab == 'UsersWithAcceptedInvitation') {
          this.getAllUsers(this.pageIndex, this.pageSize);
        } else {
          this.getAllInvitation(this.pageIndex, this.pageSize);
        }
      } else {
        delete this.payload.UserDeletedFilter;
        this.payload.ClaimGroupIds = [event.value];
        if (this.selectedTab == 'UsersWithAcceptedInvitation') {
          this.getAllUsers(this.pageIndex, this.pageSize);
        } else {
          this.getAllInvitation(this.pageIndex, this.pageSize);
        }
      }

    }
  }

  // getRole of user
  getUserRole(claims) {
    const claimArray: any[] = [];
    claims.forEach(claim => {
      claimArray.push(claim.ClaimValue);
    });
    return this.commonUtils.getClaimsAndReturnRoles(claimArray);
  }

  onTabChanged(event) {

    if (event.index == 0) {
      this.selectedTab = 'UsersWithAcceptedInvitation';
    } else {
      this.selectedTab = 'UsersWithPendingInvitation';

    }
  }


  sendInvitation(user) {
    this.spinnerFlag = true;
    this.invitationService.sendInvitation(user.Id).subscribe((res) => {
      this.spinnerFlag = false;
      this.commonUtils.successSnackBar(`${this.translateService.instant('invitationTo')} ${user.Email} ${this.translateService.instant('successFullySent')}.`);
    }, (error) => {
      this.commonUtils.errorSnackBar('error', this.translateService.instant('emailCouldNotBeSentContactAdministrator'));
    });
  }

}
