<div class="p-l-1vw p-t-1vw p-r-1vw">
    <mat-grid-list cols="12" rowHeight="70px">
        <mat-grid-tile flex-start flex-align-top class="grid-spacing" colspan="8">
            <h1 class="page-title">
                <span>{{'settings' | translate}}</span>
                <div class="w-px-500">
                    {{'kalkulationVariable' | translate}}
                </div>
            </h1>
        </mat-grid-tile>
        <mat-grid-tile flex-end flex-align-top class="grid-spacing" colspan="4">

        </mat-grid-tile>


    </mat-grid-list>
    <app-parameter-mgmt-list [type]="musterkundeType"></app-parameter-mgmt-list>

</div>