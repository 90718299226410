import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private oauthService: OAuthService;
  constructor(private http: HttpClient, private injector: Injector) {
  }

  public getToken(): string {
    let token;
    if (this.oauthService === undefined) {
      this.oauthService = this.injector.get(OAuthService);
    }
      if (this.oauthService !== undefined) {
          token = this.oauthService.getIdToken();
    }

    return token;
  }
  public isTokenExpired(token?: string): boolean {

    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = new Date(this.oauthService.getAccessTokenExpiration());
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }
}
