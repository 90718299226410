import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterMgmtService {
  clientId: any;
  environment: any;
  endpoint: any;
  constructor(private http: HttpClient,
    private userSessionService: UserSessionService,
    private configurationService: ConfigurationService) {
    this.clientId = userSessionService.getClientId().toString();
    this.environment = this.configurationService.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/PlaceholderNeedAlgorithms`;
  }

  getParameterList() {
    const url = this.endpoint;
    return this.http.get(url);
  }

  updateParameter(id, value) {
    const url = `${this.endpoint}/value/${id}?value=${+value}`;
    return this.http.put(url, {});
  }
}
