import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root',
})
export class PotentialAnalyseService {

  private endpoint;
  private environment;
  clientId: any;
  pdfData: any;
  contractStatusEndPoint: any;
  profitCenterEndpoint: any;
  departmentEndPoint: any;


  createHeaderObject(clientId = null) {
    let headers: any;
    if (clientId) {
      headers = {
        clientid: this.clientId,
      };
    }
    return {headers: new HttpHeaders(headers)};
  }

  constructor(
    private http: HttpClient,
    private commonUtils: CommonUtilsService, private configurationservice: ConfigurationService,
    private userSessionService: UserSessionService,
  ) {

    this.clientId = userSessionService.getClientId()?.toString();
    this.environment = configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/PotentialAnalysis`;
    this.contractStatusEndPoint = `${this.environment.musterkundeDevEndpoint}/api/ContractStatuses`;
    this.profitCenterEndpoint = `${this.environment.musterkundeDevEndpoint}/api/ProfitCenter`;
    this.departmentEndPoint = `${this.environment.musterkundeDevEndpoint}/api/Department`;
  }

  getPotentialAnalyse(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = `${this.endpoint}/GetPotentialAnalysis`;
    return this.http.post(url, body, this.createHeaderObject());
  }

  applyFilters(data) {
    const url = `${this.endpoint}/ApplyFilters`;
    return this.http.post(url, data, this.createHeaderObject());
  }

  getCsvExport(data) {

    const url = `${this.endpoint}/ExportCsv`;
    return this.http.post(url, data, {responseType: 'blob'});
  }

  getCalculationDates() {
    const url = `${this.endpoint}/CalculationdateForFilter`;
    return this.http.get(url, this.createHeaderObject());
  }

  getRiskCategories() {
    const url = `${this.environment.musterkundeDevEndpoint}/api/RiskCategories`;
    return this.http.get(url);
  }

  getPDFdata() {
    return this.pdfData;
  }

  setPDFdata(data) {
    this.pdfData = data;
  }

  getRatings() {
    const url = `${this.endpoint}/RatingForFilter`;
    return this.http.get(url, this.createHeaderObject());
  }

  getQualityReportData(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetQualityAnalyse';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework01(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetCustomerFramework01';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework02(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetCustomerFramework02';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework03(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetCustomerFramework03';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework04(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetCustomerFramework04';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework05(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetCustomerFramework05';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework06(guidOfAppliedFilter, consultantId, departmentId) {
    const body = this.setFrameworkBody(guidOfAppliedFilter, consultantId, departmentId);
    const url = this.endpoint + '/GetCustomerFramework06';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework07(guidOfAppliedFilter, consultantId) {
    const body = {
      guid: guidOfAppliedFilter,
      consultantIds: consultantId,
    };
    const url = this.endpoint + '/GetCustomerFramework07';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getCustomerFramework08(guidOfAppliedFilter, consultantId) {
    const body = {
      guid: guidOfAppliedFilter,
      consultantIds: consultantId,
    };
    const url = this.endpoint + '/GetCustomerFramework08';
    return this.http.post(url, body, this.createHeaderObject());
  }

  getContractStatuses() {
    const url = this.contractStatusEndPoint;
    return this.http.get(url);
  }

  getProfitCenter() {
    const url = `${this.profitCenterEndpoint}/consultantStructure`;
    return this.http.get(url, this.createHeaderObject(true));
  }

  getProfitCenterByHierarchyId(orgaStructHierarchyId) {
    const url = `${this.profitCenterEndpoint}/consultantStructureByHierarchy/?orgaStructHierarchyId=${orgaStructHierarchyId}`;
    return this.http.get(url, this.createHeaderObject(true));
  }

  getDepartmentStructures() {
    const url = `${this.profitCenterEndpoint}/departmentStructure`;
    return this.http.get(url, this.createHeaderObject(true));
  }

  deleteDepartment(id) {
    const url = `${this.departmentEndPoint}/${id}`;
    return this.http.delete(url, this.createHeaderObject(true));
  }

  getDepartmentById(id) {
    const url = `${this.departmentEndPoint}/${id}`;
    return this.http.get(url, this.createHeaderObject(true));
  }

  updateDepartment(depObj) {
    const url = `${this.departmentEndPoint}`;
    return this.http.put(url, depObj);
  }

  getRegionTypes() {
    const url = `${this.profitCenterEndpoint}/profitCenterRegioTypes`;
    return this.http.get(url);
  }

  getRegionFilters() {
    const url = `${this.profitCenterEndpoint}/RegionFilter`;
    return this.http.get(url, this.createHeaderObject(true));
  }

  addDepartment(department) {
    department.clientId = this.clientId;
    const url = `${this.departmentEndPoint}`;
    return this.http.post(url, department);
  }

  setFrameworkBody(guidOfAppliedFilter, consultantId, departmentIds) {
    const body = {
      guid: guidOfAppliedFilter,
      consultantIds: consultantId,
      departmentIds: departmentIds,
    };
    return body;
  }
}
