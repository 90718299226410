import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogBoxComponent } from '../../../../shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { BussinessArea } from '../../models/bussiness-ares';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { BussinessAreasService } from '../../service/bussiness-areas.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Component({
  selector: 'app-add-edit-geschaftsgebiete',
  templateUrl: './add-edit-geschaftsgebiete.component.html',
  styleUrls: ['./add-edit-geschaftsgebiete.component.scss']
})
export class AddEditGeschaftsgebieteComponent implements OnInit {
  bussinessAreasForm: FormGroup;
  id: any;
  bussinessArea: any;
  label: any;
  bussinessAreas = new BussinessArea();
  categories: any;
  editBussinessArea: any;
  userId: any;
  bussinessAreaArray: any[] = [];
  spinnerFlag: any = true;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private userSessionService: UserSessionService,
    private dialog: MatDialog,
    private bussinessAreaService: BussinessAreasService,
    private translateService: TranslateService,
    private commonUtils: CommonUtilsService) {
    this.getBussinessAreas();
    this.userId = this.userSessionService.getCustomerAndUserId().userId;
  }

  ngOnInit() {
    this.initializeForms();

  }
  initializeForms() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == null) {
      this.initForm();
      this.getCategories();
      this.label = this.translateService.instant('newAreaLabel');
    } else {
      this.initForm();
      this.getBussinessAreaById(this.id);
      this.label = this.translateService.instant('newAreaLabel');

    }
  }


  getCategories() {
    this.bussinessAreaService.getCategories().subscribe((response: any) => {
      this.categories = response.Result;
      if (this.id) {
        this.initFormWithData(this.editBussinessArea);
        this.spinnerFlag = false;
      }
    });
  }

  initFormWithData(data) {

    let zourdung: any;
    this.categories.forEach(element => {
      if (data.categoryId == element.id) {
        zourdung = element.id;
      }
    });
    this.bussinessAreasForm = new FormGroup({
      PLZ: new FormControl(data.postcode, [Validators.required, Validators.max(99999)]),
      Ort: new FormControl(data.place, Validators.required),
      Zuordnung: new FormControl(zourdung),
      Kaufkraft: new FormControl(data.purchasingPower.toString()
        .replace('.', ','), [Validators.pattern('^[0-9]{1,3}(\,[0-9]?)?$'), Validators.required])
    });
  }
  initForm() {
    this.bussinessAreasForm = new FormGroup({
      PLZ: new FormControl('', [Validators.required, Validators.max(99999), Validators.min(10000), this.postCodeValidation.bind(this)]),
      Ort: new FormControl('', Validators.required),
      Zuordnung: new FormControl(''),
      Kaufkraft: new FormControl('', [Validators.pattern('^[0-9]{1,3}(\,[0-9]?)?$'), Validators.required])
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.bussinessAreasForm.controls[controlName].hasError(errorName);
  }

  getBussinessAreas() {
    this.bussinessAreaService.getAllBussinessAreas().subscribe((response: any) => {
      this.bussinessAreaArray = response.Result;
      this.spinnerFlag = false;
    });
  }

  getBussinessAreaById(id) {
    this.bussinessAreaService.getBussinessAreasById(this.id).subscribe((response: any) => {
      this.editBussinessArea = response.Result;
      this.getCategories();

    });
  }

  addBussinessAreas() {
    const data = this.setBussinessAreaObjectFromForm();
    this.bussinessAreaService.saveBussinessArea(data).subscribe((response: any) => {
      this.router.navigateByUrl('bv-home/bussiness-areas');
      this.commonUtils.successSnackBar(this.translateService.instant('bussinessAreaAdded'));
    }, (err => {
      this.commonUtils.errorSnackBar('error', null);
    }));

  }


  setBussinessAreaObjectFromForm() {
    const bussinessAreas = new BussinessArea();
    if (this.id) {
      bussinessAreas.id = this.id;
    }
    bussinessAreas.clientId = this.userSessionService.getClientId();
    bussinessAreas.place = this.bussinessAreasForm.value.Ort;
    bussinessAreas.postcode = this.bussinessAreasForm.value.PLZ;
    bussinessAreas.purchasingPower = this.bussinessAreasForm.value.Kaufkraft.toString().replace(',', '.');
    bussinessAreas.categoryId = this.bussinessAreasForm.value.Zuordnung;
    return bussinessAreas;
  }


  clearForm() {
    if (!this.bussinessAreasForm.dirty) {
      this.router.navigateByUrl('/bv-home/bussiness-areas');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/bussiness-areas');
        }
      });

    }
  }

  updateBussinessArea() {
    const data = this.setBussinessAreaObjectFromForm();
    this.bussinessAreaService.updateBussinessAreas(this.id, data).subscribe((response) => {
      this.router.navigateByUrl('bv-home/bussiness-areas');
      this.commonUtils.successSnackBar(this.translateService.instant('bussinessAreaUpdated'));
    }, (err) => {
      this.commonUtils.errorSnackBar('error', null);
    });
  }

  private postCodeValidation(control: AbstractControl): { [key: string]: boolean } | null {
    const isPostCode = this.bussinessAreaArray.find(x => x.postcode == control.value);
    if (control.value !== undefined && isPostCode !== undefined) {
      return { 'postCodeValidation': true };
    }
    return null;
  }
  getCharValidation(event) {
    if (event.charCode == 45) {
      return false;
    }
    if (this.bussinessAreasForm.value.PLZ) {
      return this.bussinessAreasForm.value.PLZ.toString().length < 5;
    }
  }
  isValidKaufkraft(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    return (charCode == 44 && this.bussinessAreasForm.value.Kaufkraft.split(',').length < 2)
      || !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
  isTooManyDecimalPointError() {
    return this.bussinessAreasForm.value.Kaufkraft.split(',')[0].length < 4;
  }
}
