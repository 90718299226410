import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonUtilsService } from '../../../shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BookingtypesService {

  environment: any;
  endpoint: any;
  typegroupendpoint: string;
  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService, private configurationservice: ConfigurationService) {
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/BookingTypes`;
    this.typegroupendpoint = `${this.environment.musterkundeDevEndpoint}/api/BookingTypeGroups`;
  }


  getBookingTypes() {
    const url = this.endpoint;
    return this.http.get(url);
  }

  editBookingType(body) {
    const url = `${this.endpoint}/${body.id}`
    return this.http.put(url, body);
  }

  getBookingTypeGroups(): Observable<any> {
    return this.http.get(`${this.typegroupendpoint}`)
  }
}
