<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        <div class="w-px-500">
          {{'assetClasses' | translate}}
        </div>
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="12" rowHeight="500px" class="m-t-50 m-b-50">
    <mat-grid-tile flex-start flex-align-top [colspan]="9" class="grid-spacing">
        <div class="div-spinner" *ngIf="spinnerFlag">
            <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
          </div>
      <!-- table -->
      <div>
        <table mat-table [dataSource]="dataSource" matSort class="custom-table fixed-table nowrap-table m-b-0 card-shadow">
          <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

          <ng-container matColumnDef="name">
            <th width="85" mat-header-cell *matHeaderCellDef mat-sort-header>{{'riskCategory' | translate}} </th>
            <td mat-cell *matCellDef="let riskValue">{{riskValue.name}}
          </ng-container>

          <ng-container matColumnDef="Liquidität">
            <th width="60" mat-header-cell *matHeaderCellDef> {{'liquidity' | translate}} </th>
            <td mat-cell *matCellDef="let riskValue"> {{riskValue.liquidityPercentage}} %
              <sup *ngIf="riskValue.first">*
              </sup>
            </td>
          </ng-container>


          <ng-container matColumnDef="Geldwerte">
            <th width="60" mat-header-cell *matHeaderCellDef> {{'monetaryValues' | translate}} </th>
            <td mat-cell *matCellDef="let riskValue">{{riskValue.shareMonetaryValue}} %
              <sup *ngIf="riskValue.second">*</sup>
            </td>
          </ng-container>

          <ng-container matColumnDef="Substanzwerte">
            <th width="60" mat-header-cell *matHeaderCellDef> {{'valueStocks' | translate}} </th>
            <td mat-cell *matCellDef="let riskValue"> {{riskValue.proportionSubstanceValues}} %
              <sup *ngIf="riskValue.third">*</sup>
            </td>
          </ng-container>

          <ng-container matColumnDef="Sachwerte">
            <th width="60" mat-header-cell *matHeaderCellDef> {{'nonCashAssets' | translate}} </th>
            <td mat-cell *matCellDef="let riskValue">{{riskValue.shareAssets}} %
              <sup *ngIf="riskValue.fourth">*</sup>
            </td>
          </ng-container>

          <ng-container matColumnDef="Alternativ">
            <th width="60" mat-header-cell *matHeaderCellDef> {{'alternative' | translate}} </th>
            <td mat-cell *matCellDef="let riskValue"> {{riskValue.shareAlternatives}} %
              <sup *ngIf="riskValue.fifth">*</sup>
            </td>
          </ng-container>

          <ng-container matColumnDef=" ">
            <th width="90" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let riskValue">
              <button mat-icon-button matTooltip="Zurücksetzen" *ngIf="showRefresh(riskValue)"
                (click)="resetValueToNationalValue(riskValue.id)">
                <i class="fs-16 material-icons">
                  refresh
                </i>
              </button>
              <button mat-icon-button matTooltip="Bearbeiten" (click)="editAssetClass(riskValue.id)">
                <i class="fs-16 material-icons"> edit </i>
              </button>
            </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
        <!-- table ends -->
        <div class="m-t-40">
          <mat-label>* {{'bankSpecificWeightingDifferentFromNationWideAssetClass' | translate}}</mat-label>
        </div>
      </div>


    </mat-grid-tile>
  </mat-grid-list>
</div>