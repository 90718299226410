import { Component, OnInit } from '@angular/core';
import { ProfitCenter } from '../parameter/models/profitcenter';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesOrganisationService } from '../parameter/service/sales-organisation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-profit-center',
  templateUrl: './add-profit-center.component.html',
  styleUrls: ['./add-profit-center.component.scss'],
})
export class AddProfitCenterComponent implements OnInit {
  profitCenter = new ProfitCenter();
  profitCenterForm: FormGroup;
  profitCenterId;
  parentProfitCenter: any;
  canEditCategory = true;
  parentProfitCenterList: any;
  isEdit = false;
  buttonLabel: any;
  spinnerFlag = true;
  mainLabel: any;
  categories: any[] = [];
  childProfitCenters: any;

  deadline: any;
  organisationStructureHierarchyId: any;
  parentProfitCenterId: number;

  constructor(public salesOrganisationService: SalesOrganisationService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
    this.isEdit = (this.route.snapshot.paramMap.get('edit') === 'true');
    this.organisationStructureHierarchyId = this.route.snapshot.paramMap.get('orgaStructId');
    this.deadline = this.route.snapshot.paramMap.get('deadline');


    this.initForm();

    if (this.isEdit) {
      this.profitCenterId = this.route.snapshot.paramMap.get('id');
    } else {
      this.parentProfitCenterId = +this.route.snapshot.paramMap.get('id');
    }

  }

  ngOnInit() {
    if (this.isEdit) {
      this.salesOrganisationService.getProfitCenterById(this.profitCenterId).subscribe(async (res: any) => {
        this.parentProfitCenter = await res.Result;
        this.profitCenterForm = new FormGroup({
          name: new FormControl(this.parentProfitCenter.description, Validators.compose([
            Validators.required])),
          shortDescription: new FormControl(this.parentProfitCenter.shortDescription),
          parentProfitCenter: new FormControl('', Validators.required),
          categorie: new FormControl(''),
          capacity: new FormControl(this.parentProfitCenter.capacity),
          hiddenDummyControl: new FormControl(''),
        });
        this.salesOrganisationService.getChildProfitcentersById(this.profitCenterId).subscribe(async (response: any) => {
          this.childProfitCenters = await response.Result.childProfitCenter;
          this.getCategories();
        });

        if (this.parentProfitCenter.profitCenterConsultantStructureId == 1) {
          this.canEditCategory = false;
          this.profitCenterForm.get('parentProfitCenter').clearValidators();
          this.profitCenterForm.get('parentProfitCenter').updateValueAndValidity();
        }
        this.profitCenter.organisationUnitId = this.parentProfitCenter.organisationUnitId;
        this.profitCenter.parentProfitCenterId = this.parentProfitCenter.parentProfitCenterId;
        this.profitCenter.profitCenterRegionStructureId = this.parentProfitCenter.profitCenterRegionStructureId;
        this.profitCenter.profitCenterConsultantStructureId = this.parentProfitCenter.profitCenterConsultantStructureId;
      });
    } else {
      this.getCategories();
    }
  }


  getCategories() {
    this.spinnerFlag = true;
    this.salesOrganisationService.getConsultantStructuresForProfitCenter().subscribe((res: any) => {
      this.categories = res.Result;
      if (this.canEditCategory) {
        this.categories = this.categories.filter(category => category.typeId != 1);
      }
      if (this.isEdit) {
        this.setCategory();
      }
      this.getParentProfitCenter();
    });
  }

  setCategory() {
    this.categories.forEach(category => {
      if (category.typeId == this.parentProfitCenter.profitCenterConsultantStructureId) {
        this.profitCenterForm.controls['categorie'].setValue(category.typeId);
      }
    });
  }


  initForm() {
    this.profitCenterForm = new FormGroup({
      name: new FormControl('', Validators.compose([
        Validators.required])),
      shortDescription: new FormControl(''),
      parentProfitCenter: new FormControl('', Validators.required),
      categorie: new FormControl('', Validators.required),
    });
  }

  setProfitCenterFromForm() {
    this.profitCenter.description = this.profitCenterForm.value.name;
    this.profitCenter.shortDescription = this.profitCenterForm.value.shortDescription;
    this.profitCenter.parentProfitCenterId = this.profitCenterForm.value.parentProfitCenter;
    this.profitCenter.profitCenterConsultantStructureId = this.profitCenterForm.value.categorie;
    this.profitCenter.organisationStructureHierarchyId = this.organisationStructureHierarchyId;
    this.profitCenter.deadline = this.deadline;
  }

  addEditProfitCenter() {
    this.setProfitCenterFromForm();
    if (this.isEdit) {
      this.salesOrganisationService.editProfitCenter(this.profitCenterId, this.profitCenter).subscribe(res => {
        this.navigateToList(false);
      });
    } else {
      this.salesOrganisationService.addProfitCenters(this.profitCenter).subscribe(res => {
        this.navigateToList(false);
      });
    }
  }

  getParentProfitCenter() {
    this.salesOrganisationService.getConsultantStructureByHierarchyId(this.organisationStructureHierarchyId).subscribe((res: any) => {
      this.parentProfitCenterList = res.Result;
      this.parentProfitCenterList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      if (this.isEdit) {
        const childIds = this.childProfitCenters.map(({id}) => id);
        childIds.forEach((childId) => {
          this.parentProfitCenterList = this.parentProfitCenterList.filter((parent) => ((parent.id != childId) && (parent.id != this.parentProfitCenter.id)));
        });
        this.parentProfitCenterList.forEach(parent => {
          if (parent.id == this.parentProfitCenter.parentProfitCenterId) {
            this.profitCenterForm.controls['parentProfitCenter'].setValue(parent.id);
          }
        });
      } else {
        this.profitCenterForm.controls['parentProfitCenter'].setValue(this.parentProfitCenterId);
      }
      this.spinnerFlag = false;
    });
  }

  clearForm() {
    if (!this.profitCenterForm.dirty) {
      this.navigateToList(true);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px',
      });

      dialogRef.afterClosed()
        .subscribe(result => {
          if (result) {
            this.navigateToList(true);
          }
        });
    }
  }

  private navigateToList(expanded: boolean) {
    this.router.navigateByUrl(`bv-home/sales-organization-list/${expanded}?consultantOrganisation=true`);
  }
}
