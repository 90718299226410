import { Component, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from './shared/services/localStorage.service';
import { changei18n } from '@infragistics/igniteui-angular';
import { infragisticsPaginatorResourceStringsGerman, infragisticsResourceStringsGerman } from 'src/assets/i18n/de-igx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MusterKunde-frontend';
  isUnAuthorizedPage: any;
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private cdr:ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {
    this.storeRouteStatus();
    this.translateService.setDefaultLang('de');
    this.initInfragisticsLabelsGerman();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  storeRouteStatus() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url != '' && event.url != '/' && event.url != '/unauthorized' && event.url.indexOf('callback') === -1) {
          this.localStorageService.set('currentRoute', event.url);
        }
        // Logic to test unauthorized
        const location = window.location.pathname;
        if (location === '/' || location === '/reset-password' || location === '/forgot-password') {
          this.isUnAuthorizedPage = true;
        } else {
          this.isUnAuthorizedPage = false;
        }
      }
    });
  }

  private initInfragisticsLabelsGerman() {
    // console.log(infragisticsResourceStringsGerman);
    // console.log(infragisticsPaginatorResourceStringsGerman);

    changei18n(infragisticsResourceStringsGerman);
    changei18n(infragisticsPaginatorResourceStringsGerman as any);
  }

}
