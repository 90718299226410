import { X } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConsultantSegmentService } from '../../service/consultant-segment.service';
import { SalesOrganisationService } from '../../service/sales-organisation.service';

@Component({
  selector: 'app-add-edit-consultant-segment',
  templateUrl: './add-edit-consultant-segment.component.html',
  styleUrls: ['./add-edit-consultant-segment.component.scss']
})
export class AddEditConsultantSegmentComponent implements OnInit {

  displayedColumns: string[] = ['consultant', 'consultantsCurrentConsultantSegment'];
  dataSource = new MatTableDataSource();
  spinnerFlag = true;
  consultantSegmentForm: FormGroup;
  consultantSegmentId: any;
  organisationStructureHierarchyId: any;
  isEdit: any;
  consultantSegmentObject = new ConsultantSegment();
  consultantIfEdit: any;
  parentProfitCenterList: any;
  deadline: any;
  organisationalLengthErr = false;

  consultantsChecksChanged: boolean;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  consultants: any[] = [];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  totalCount: any;

  
  constructor(private route: ActivatedRoute,
    private consultantSegmentService: ConsultantSegmentService,
    private commonUtils: CommonUtilsService,
    private router: Router,
    private dialog: MatDialog,
    ) {
    this.consultantSegmentId = this.route.snapshot.paramMap.get('id');
    //this.isEdit = (this.route.snapshot.paramMap.get('edit') === 'true');
    this.initForm();

    // if (this.isEdit) {
    //   this.getConsultantSegmentById();
    // } else {
    //   this.getConsultants();
    // }

  }


  ngOnInit(): void {

    this.consultantSegmentId = this.route.snapshot.paramMap.get('id');
    this.organisationStructureHierarchyId = this.route.snapshot.paramMap.get('orgaStructHierarchyId');
    this.deadline = this.route.snapshot.paramMap.get('deadline');

    if (this.consultantSegmentId){
      this.isEdit = true;
      this.getConsultantSegmentById();
    }


    this.consultantSegmentService.getAllConsultantsByHierarchie(this.organisationStructureHierarchyId).subscribe((Response: any) => {
      this.consultants = Response.Result;

      if (this.isEdit) {
        this.consultants.forEach(element => {

          if (element.consultantSegment?.id == this.consultantSegmentId) {
            element.isChecked = true;
          } else {
            element.isChecked = false;
          }
        });
      }
      
      
      
      this.dataSource = new MatTableDataSource(this.consultants);
      this.totalCount = this.consultants.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinnerFlag = false;

      
    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });

    

  }

  
  initForm() {
    this.consultantSegmentForm = new FormGroup({
      name: new FormControl('', Validators.compose([
        Validators.required])),
      
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.consultantSegmentForm.controls[controlName].hasError(errorName);
  }
  

  disableSave() {
    if (this.consultantSegmentForm.valid && (this.consultantSegmentForm.dirty || this.consultantsChecksChanged) && (!this.organisationalLengthErr) ) {
      return false;
    }
    return true;
  }

  clearForm() {
    if (!this.consultantSegmentForm.dirty && !this.consultantsChecksChanged) {
      this.router.navigateByUrl('/bv-home/consultant-segments-list');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('/bv-home/consultant-segments-list');
        }
      });
    }
  }

  getConsultantSegmentById() {
    this.consultantSegmentService.getConsultantSegmentById(this.consultantSegmentId).subscribe((res: any) => {
      this.consultantIfEdit = res.Result;
      if (this.isEdit) {
        this.setInitialObject();
      }
    });
  }

  setInitialObject() {
    if (this.isEdit) {
      // set form
      this.consultantSegmentForm.controls['name'].setValue(this.consultantIfEdit.name);
    }
  }

  checkChanged(consultant: any) {
    this.consultantsChecksChanged = true;
    this.consultants.find(x => x.id == consultant.id).hasChanged = true;
  }



  addUpdateConsultantSegment() {
    this.consultantSegmentObject.name = this.consultantSegmentForm.value.name;
    this.consultantSegmentObject.organisationStructureHierarchyId = this.organisationStructureHierarchyId;
    this.consultantSegmentObject.deadline = this.deadline;
    this.consultants.forEach(element => {
      if (element.hasChanged) {
        let consultantAssignment: ConsultantAssignment = {
          consultantId: element.id,
          isAssigned: element.isChecked
        }
        this.consultantSegmentObject.assignedConsultants.push(consultantAssignment);
      }
    });
    if (this.isEdit) {
      this.consultantSegmentService.updateConsultantSegment(this.consultantSegmentId, this.consultantSegmentObject).subscribe(res => {
        this.router.navigateByUrl('/bv-home/consultant-segments-list');
      });
    } else {
      this.consultantSegmentService.saveConsultantSegment(this.consultantSegmentObject).subscribe(res => {
        this.router.navigateByUrl('/bv-home/consultant-segments-list');
      }, err => {
        this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage, 3000);
      });
    }
  }
}

export class ConsultantSegment {
  'name': string = null;
  'assignedConsultants': ConsultantAssignment[] = [];
  'organisationStructureHierarchyId': number;
  'deadline': any;
}

export class ConsultantAssignment {
  'consultantId': string = null;
  'isAssigned': boolean;
}

