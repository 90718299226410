<div class="p-l-1vw p-r-1vw navigation">
  <div class="top-bar">
    <app-bv-header-bar></app-bv-header-bar>
  </div>
</div>


<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav opened>

    <div class="custom-sidebar" [ngClass]="{'sidebar-close':sidebarToggle}">

      <ul class="main-nav-list main-nav-list-container">
        <div>
        <!-- <li *ngIf="hasNewPermission('VR Musterkundenansatz')">
          <a class="menu-item" [ngClass]="{'selected':selectVrMusterkunde}"
             (click)="showVrMusterkunde()">{{'vrMusterKunde' | translate}}</a> -->

          <ul class="main-nav-list" *ngIf="hasNewPermission('VR Musterkundenansatz')">
            <li>
              <a class="menu-item" *ngIf="!noCalculation"
                 [ngClass]="{'selected':selectPopentialAnaylis}"
                 (click)="navigatePotentialAnalysis()">{{'potentialanaysis' | translate}} </a>
            </li>
            <li>
              <a class="menu-item" *ngIf="!noCalculation"
                 [ngClass]="{'selected':selectCustomerScaffolding}"
                 (click)="navigateToCustomerScaffolding()">{{'customersScaffolding' | translate}}</a>
            </li>
            <li>
              <a class="menu-item" *ngIf="!noCalculation"
                 [ngClass]="{'selected':selectQualityReport}"
                 (click)="navigateToQualityReport()">{{'qualityReportInMenu' | translate}}</a>
            </li>
          </ul>

          <mat-divider [ngClass]="{'d-none': getToggleStatus() }"
                     *ngIf="hasNewPermission('VR Musterkundenansatz') && !noCalculation"
                     class="m-t-20 m-b-20 custom-divider">
        </mat-divider>

        <!-- </li> -->


        <!-- <li *ngIf="hasNewPermission('VR Aktivitätenmanager')">
          <a class="menu-item" [ngClass]="{'selected':selectVrActivityMngr}"
             (click)="navigateToActivityManager()">{{'vrAcivityMngr' | translate}}</a>
        </li> -->


        <!-- <li *ngIf="hasNewPermission('Marketingfabrik')">
          <a class="menu-item" [ngClass]="{'selected' : selectMarketingFabrik}"
             (click)="navigateToMarketingFabrik()">{{'marketingFabrik' | translate}}</a>
        </li> -->


        <!-- <li *ngIf="hasNewPermission('Datentransfercenter')">
          <a class="menu-item" [ngClass]="{'selected':selectDataTransfer}"
             (click)="showDataTransfer()">{{'dataTransferCenter' | translate}}</a> -->
          <ul class="main-nav-list" *ngIf="hasNewPermission('Datentransfercenter')">
            <li >
              <a class="menu-item"
                 [ngClass]="{'selected':selectDataImport}"
                 *ngIf="hasNewPermission('Datentransfercenter > Datenimport')"
                 (click)="navigateToDateImport()">{{'dateimport' | translate}}</a>
            </li>
            <li >
              <a class="menu-item"
                 [ngClass]="{'selected':selectKalkulation}"
                 *ngIf="hasNewPermission('Datentransfercenter > Kalkulationen')"
                 (click)="navigateToKalkulation()">{{'calculations' | translate}}</a>
            </li>



            <!-- <li >
              <a class="menu-item"
                 [ngClass]="{'selected':selectIdaBerichte}"
                 *ngIf="hasNewPermission('Datentransfercenter > IdaBerichte')"
                 (click)="navigateToKalkulation()">{{'idaReports' | translate}}</a>
            </li>
            <li >
              <a class="menu-item"
                 [ngClass]="{'selected':selectDokumentation}"
                 *ngIf="hasNewPermission('Datentransfercenter > Dokumentation')"
                 (click)="navigateToKalkulation()">{{'documentation' | translate}}</a>
            </li> -->




            <li >
              <a class="menu-item"
                 [ngClass]="{'selected':selectDataexport}"
                 *ngIf="hasNewPermission('Datentransfercenter > Datenexport')"
                 (click)="navigateToDateExport()">{{'datenExport' | translate}}</a>
            </li>
          </ul>
        <!-- </li> -->
        <mat-divider [ngClass]="{'d-none': getToggleStatus() }"
                     *ngIf="hasNewPermission('VR Aktivitätenmanager') || hasNewPermission('Marketingfarbrik') || hasNewPermission('Datentransfercenter')"
                     class="m-t-20 m-b-20 custom-divider">
        </mat-divider>

        <!-- divider -->
        <!-- parameter -->

        <li *ngIf="hasNewPermission('Parameter')">
          <!-- <a class="menu-item" [ngClass]="{'selected':selectParameter}"
             (click)="showParameter()">{{'parameter' | translate}}</a> -->
          <a class="menu-item"
             (click)="showParameter()">{{'parameter' | translate}}</a>
          <ul class="sub-menu">

            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectAssetClasses}"
                 (click)=navigateToAssetClasses();>{{'assetClasses' | translate}}</a>
            </li>


            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectConsultatNeedAndSolution}"
                 (click)=navigateToConsultantNeedsAndSolution();>{{'consultantNeedsAndSolution' | translate}}</a>
            </li>

            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectMusterkundeCustomerGroup}"
                 (click)=navigateToModalCustomerGroup();>{{'sampleGroupCostomers' | translate}}</a>
            </li>
            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectbussinessAreas}"
                 (click)="navigateTobBussinssArea()">{{'bussinessAreas' | translate}}</a>
            </li>


            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectProductClassification}"
                 (click)="navigateToProductClassification()">{{'productClassification' | translate}}</a>
            </li>


            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectSalesOrganization}"
                 (click)="navigateToSalesOrganization()">{{'salesOrganization' | translate}}</a>
            </li>

            <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectConsultantStructure}"
                 (click)="navigateToConsultantStructure()">{{'consultantStructureGrid' | translate}}</a>
            </li>

            <!-- <li *ngIf="selectParameter">
              <a class="menu-item" [ngClass]="{'selected':selectConsultantSegments}"
                 (click)="navigateToConsultantSegments()">{{'consultantSegments' | translate}}</a>
            </li> -->

          </ul>
        </li>

        <!-- einstellugen -->
        <li *ngIf="hasNewPermission('Einstellungen')">
          <!-- <a class="menu-item" [ngClass]="{'selected':selectEinstellungen}"
             (click)="showEinstellugen()">{{'einstellungen' | translate}}</a> -->
          <a class="menu-item"
             (click)="showEinstellugen()">{{'einstellungen' | translate}}</a>


          <ul class="sub-menu">
            <li *ngIf="selectEinstellungen">
              <a class="menu-item"
                 [ngClass]="{'selected':selectBenutzer}"
                 *ngIf="hasNewPermission('Einstellungen > Benutzer')"
                 (click)=navigateToUserList();>{{'user' | translate}}</a>
            </li>
            <li *ngIf="selectEinstellungen">
              <a class="menu-item" [ngClass]="{'selected':selectBookongForm}"
                 *ngIf="hasNewPermission('Einstellungen > Buchungsarten')"
                 (click)=navigateToBookingForm();>{{'bookingForms' | translate}}</a>
            </li>


            <li *ngIf="selectEinstellungen">
              <a class="menu-item" *ngIf="hasNewPermission('Einstellungen > Kalkulationsvariablen')"
                 [ngClass]="{'selected': selectCalculationVariables}"
                 (click)=navigateToKalkulationVariables();>{{'kalkulationVariable' | translate}}</a>
            </li>


            <!-- code may be used in future -->
            <!-- service access -->
            <!-- <li *ngIf="selectEinstellungen">
                          <a class="menu-item" *ngIf="hasPermission('Einstellungen')"
                              [ngClass]="{'selected':selectServiceZugriff}"
                              (click)="navigateToServiceZugriff()">{{'serviceAccess' | translate}}</a>
                      </li> -->
          </ul>
        </li>
        <li>
          <a class="menu-item"
             (click)="showMandantenverwaltung()"
             *ngIf="hasNewPermission('Mandantenverwaltung')"
          >{{'clientManagement' | translate}}</a>
        </li>
        <li>
          <a class="menu-item" [ngClass]="{'selected':selectBankActivities}"
             (click)="navigateToBankActivities()"
             *ngIf="hasNewPermission('Mandantenverwaltung')"
          >{{'bankAktivities' | translate}}</a>
        </li>
        </div>
        <div>
          <li>
            <a class="menu-item home-icon" (click)="clickHomeButton()">
              <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
            </a>
          </li>
        </div>

      </ul>
      <div class="toggle-wrapper" (click)="toggleSidebar()">
        <div class="toggle-icon">

        </div>
      </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'wrapper-left':sidebarToggle}">

    <div class="nav-contant">

      <div class="main-wrapper m-b-20">


        <div>

          <router-outlet></router-outlet>
        </div>
      </div>
      <div>
        <app-footer-bar></app-footer-bar>

      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
