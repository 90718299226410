<!--main content-->
<!-- Page Heading -->
<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'dateimport' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>
</div>
<!-- End: Page Heading -->
<!-- New Mailn content -->

<div class="container maincontent-wrapper"
     fxLayout="row"
     fxLayout.md="column"
     fxLayout.sm="column"
     fxLayoutWrap
     fxLayoutGap="0.5%"
     fxLayoutAlign="center">

  <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">

    <div class=" w-100 card-shadow">
      <div class="data-table-container">
        <div class="div-spinner" *ngIf="spinnerFlag">
          <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
        </div>
        <table mat-table [dataSource]="dataSource" matSort class="w-100">


          <ng-container matColumnDef="fileName">
            <th width="170" mat-header-cell *matHeaderCellDef mat-sort-header>{{'dateiname' | translate}} </th>
            <td mat-cell *matCellDef="let data"> {{data.fileName}}

            </td>
          </ng-container>

          <ng-container matColumnDef="interfaceType">
            <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'assignment' | translate}} </th>
            <td mat-cell *matCellDef="let data"> {{data.interfaceType}}
              <div *ngIf="data.interfaceType == null">-</div>

            </td>
          </ng-container>

          <ng-container matColumnDef="uploadTimeStamp">
            <th width="190" mat-header-cell *matHeaderCellDef mat-sort-header> {{'time' | translate}}</th>
            <td mat-cell *matCellDef="let data"> {{getLocalTime(data.uploadTimeStamp)}}

            </td>
          </ng-container>

          <ng-container matColumnDef="userName">
            <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'user' | translate}} </th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="isUserNameNull(data.userName)">-</div>
              {{data.userName}}

            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th width="150" mat-header-cell *matHeaderCellDef> {{'status' | translate}} </th>
            <td mat-cell *matCellDef="let data">
              <div *ngIf="data.statusId == transactionStatus.InProgress">
                <div class="mat-spinner-div">
                  <mat-progress-spinner [value]="data.progress"
                                        [diameter]="25"
                                        [strokeWidth]="2"
                                        [mode]="'determinate'">
                  </mat-progress-spinner>
                </div>
                <div class="grey-text m-t-10">{{data.progress || 0}}% {{'closed' | translate}}
                </div>
              </div>

              <div class="icon-div" *ngIf="data.statusId == transactionStatus.Complete">
                <i class="material-icons green-check m-r-5">
                  check_circle_outline
                </i>
              </div>

              <div *ngIf="data.statusId == transactionStatus.TransactionFailed
                          || data.statusId == transactionStatus.ImportModeNotSupported
                          || data.statusId == transactionStatus.CheckAnonymousKeyHashValueFailed">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'importFailed' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.NoCsvFile">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red"> {{'noCsvFile' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.NotFindAnyTarget">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'noGoalFound' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.TransactionCancelled">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'transactionCancelled' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.CustomerImportMissing">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'missingImport' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.HierarchyIdAlreadyInUse">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'hierarchyIdAlreadyInUse' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.GettingRecordsFromCsvFailed">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'gettingRecordsFromCsvFailed' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.MissingRootOrganizationUnit">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'missingRootOrganizationUnit' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.DeadlineToCopyFromMissing
                          || data.statusId == transactionStatus.MissingIdsToCopyFrom
                          || data.statusId == transactionStatus.MissingIdsToCopy
                          || data.statusId == transactionStatus.CopyOrgaStructureFailed">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'copyOrgaStructureFailed' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.DeadlineAlreadyExists">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'copyOrgaStructureFailedDeadlineAlreadyExists' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.CopyMissingParentProfitCenter">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'copyMissingParentProfitCenter' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.OrganisationUnitDoesNotExist
                        || data.statusId == transactionStatus.MissingDefaultRegionStructure
                        || data.statusId == transactionStatus.MissingDefaultConsultantStructure
                        || data.statusId == transactionStatus.MissingCreditInstituteConsultantStructure
                        || data.statusId == transactionStatus.MissingProfitCenter
                        || data.statusId == transactionStatus.MissingOrganizationUnit
                        || data.statusId == transactionStatus.MissingProcessRecords
                        || data.statusId == transactionStatus.MissingConsultantSegment">
                <i class="material-icons error-icon m-r-5">
                  error_outline
                </i>
                <span class="text-red">{{'specificProcessingErrorOccured' | translate}}
                </span>
              </div>

              <div *ngIf="data.statusId == transactionStatus.Deleting || data.statusId == transactionStatus.ReadyToDelete"
                   fxLayout="row"
                   fxLayoutAlign="start center"
                   fxLayoutGap="8px">
                <mat-progress-spinner [diameter]="18"
                                      [strokeWidth]="2"
                                      [mode]="'indeterminate'">
                </mat-progress-spinner>
                <div class="grey-text">{{'deleting' | translate}}
                </div>
              </div>

              <div class="icon-div" *ngIf="data.statusId == transactionStatus.Deleted">
                <i class="material-icons error-icon m-r-5">
                  cancel
                </i>
              </div>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>

      </div>
      <mat-paginator class="m-t-5"
                     [length]='totalLength'
                     showFirstLastButtons
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 25, 30]">
      </mat-paginator>
    </div>
  </div>
  <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="">
    <div class="d-flex w-100 fd-col sidebar-form m-b-10"
         fxLayout="column"
         fxLayout.lt-lg="row"
         fxLayoutGap="16px"
         fxLayoutAlign="flex-start">
      <button mat-raised-button
              class="custom-button"
              (click)="navigateToNewDataImport()"
              [disabled]="importDeletionIsInProgress">
        {{'newUpload' | translate}}</button>
      <button mat-flat-button
              *ngIf="canCancelImport"
              (click)="cancelImport()"
              class="reverse-custom-button">
        <ng-container *ngIf="!isCancellingImport; else loader">
          {{'cancelImport' | translate}}
        </ng-container>
        <ng-template #loader>
          <mat-progress-spinner mode="indeterminate" diameter="18" class="button-spinner"></mat-progress-spinner>
        </ng-template>
      </button>
    </div>
  </div>
</div>


<!-- End: New Main Content -->
