import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PotentialAnalyseService } from 'src/app/benutzer-verwaltung/v-musterkunde/services/potential-analyse.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ExpandedState } from '../consultant-orgnaization-tab/consultant-orgnaization-tab.component';
import { BRANCH_NODE_TYPES } from 'src/app/app.config';
import { SalesOrganisationService } from '../../service/sales-organisation.service';

@Component({
  selector: 'app-branch-organisation-tab',
  templateUrl: './branch-organisation-tab.component.html',
  styleUrls: ['./branch-organisation-tab.component.scss'],
})
export class BranchOrganisationTabComponent implements OnInit {
  @Output() spinnerEvent = new EventEmitter<boolean>();
  parentDepartmentStructure: any[] = [];
  expansionState = [];
  spinnerFlag = false;
  elementToBeRemoved = [];

  constructor(
    private potentialAnalysisService: PotentialAnalyseService,
    private salesOrganisationService: SalesOrganisationService,
    private router: Router,
    private dialog: MatDialog,
    private commonUtils: CommonUtilsService,
  ) {
  }

  ngOnInit(): void {
    this.expansionState = this.commonUtils.getBranchOrganisationStoreState();
    this.getDepartmentStructure();
  }

  getDepartmentStructure() {
    this.potentialAnalysisService.getDepartmentStructures().subscribe((response: any) => {
      this.parentDepartmentStructure = response.Result;
      this.setExpansionState(this.parentDepartmentStructure);
      this.expansionState.forEach(states => {
        this.setPreviousStateOfBranchOrganisation(this.parentDepartmentStructure, states);
      });
      this.expansionState = [];
      this.spinnerEvent.emit(false);
    }, err => {
      this.spinnerEvent.emit(false);
    });
  }

  getType(id) {
    const type = BRANCH_NODE_TYPES.filter(p => p.id == id)[0];
    return type ? `(${type.name})` : null;
  }

  storeState(data) {
    data.expanded = !data.expanded;
    this.storeExpansionStateBeforeNavigation(this.parentDepartmentStructure);
    this.deleteClosedExpansionState();
    this.elementToBeRemoved = [];

    this.commonUtils.setBranchOrganisationState(this.expansionState);
  }

  storeExpansionStateBeforeNavigation(departmentList) {
    departmentList.forEach(dep => {
      if (!this.expansionState.find(state => state.id === dep.id)) {
        const expandedState = new ExpandedState();
        expandedState.id = dep.id;
        expandedState.state = dep.expanded;
        this.expansionState.push(expandedState);
      } else {
        this.expansionState.find(element => element.id == dep.id).state = dep.expanded;
      }

      if (dep.childProfitCenter.length != 0) {
        this.storeExpansionStateBeforeNavigation(dep.childProfitCenter);
      }
    });
  }

  setExpansionState(beraterList) {
    beraterList.forEach((list) => {
      list.expanded = false;
      if (list.childProfitCenter) {
        this.setExpansionState(list.childProfitCenter);
      }
    });
  }

  setPreviousStateOfBranchOrganisation(list, states) {
    list.forEach(element => {
      if (element.id == states.id) {
        element.expanded = states.state;
      }
      if (element.childProfitCenter) {
        this.setPreviousStateOfBranchOrganisation(element.childProfitCenter, states);
      }
    });
  }

  deleteClosedExpansionState() {
    for (let i = 0; i < this.expansionState.length; i++) {
      const obj = this.expansionState[i];
      if (this.elementToBeRemoved.indexOf(obj.id) !== -1) {
        this.elementToBeRemoved.splice(i, 1);
        i--;
      }
    }
  }

  addEditBranchNode(isEdit: boolean, canAddRegion: boolean, id) {
    this.router.navigateByUrl(`bv-home/add-edit-zweigstellen/${isEdit}/${canAddRegion}/${id}`);
  }
}
