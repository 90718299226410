import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from 'src/app/configration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  environment: any;
  endpoint: any;
  clientId: any;

  constructor(
    private http: HttpClient,
    private configurationservice: ConfigurationService,
    ) {
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/Administration`;
   }


  getBankActivities() {
    const url = `${this.endpoint}`;
    return this.http.get(url);
  }
}
