<div class="p-l-1vw p-t-1vw p-r-1vw">
    <mat-grid-list cols="2" rowHeight="70px">
      <mat-grid-tile flex-start flex-align-top class="grid-spacing">
        <h1 class="page-title">
          {{'consultantSegments' | translate}}
        </h1>
      </mat-grid-tile>
      <mat-grid-tile flex-end flex-align-top class="grid-spacing">
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <mat-card class="m-t-0 m-l-30 m-r-30 m-b-0 p-t-10 p-b-0" >
    <mat-card-content>
        <div class="d-flex flex-row" >
            <mat-form-field class="m-b-0"  appearance="outline">
                <mat-label>{{'deadline' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedDeadline" (ngModelChange)="onDeadlineSelectionChanged($event)" [disabled]="deadlines.length == 0"> 
                  <mat-option *ngFor="let deadline of deadlines" [value]="deadline">{{deadline.deadlineDate | date}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="m-l-10 m-b-0" appearance="outline">
                <mat-label>{{'hierarchy' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedHierarchy" (ngModelChange)="onHierarchySelectionChanged($event)" [disabled]="hierarchies.length == 0">
                  <mat-option *ngFor="let hierarchy of hierarchies" [value]="hierarchy">{{hierarchy.hierarchyDescription}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        
    </mat-card-content>
    
  </mat-card>
  
  
    <div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap fxLayoutGap="0.5%"
      fxLayoutAlign="center">
      <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
        <div class=" w-100 card-shadow">
          <div class="table-container">
            <div class="div-spinner" *ngIf="spinnerFlag">
              <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
            </div>
            <table mat-table [dataSource]="dataSource" align="center" matSort class="w-100">
  
              <!-- Data-Columns -->
              <ng-container matColumnDef="consultantSegment">
                <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'consultantSegment' | translate}} </th>
                <td mat-cell *matCellDef="let consultantSegment"> {{consultantSegment.name}}</td>
              </ng-container>

              <!-- Edit Delete Buttons -->
              <ng-container matColumnDef=" ">
                <th width="60" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let consultantSegment" class="text-right">
                  <button mat-icon-button (click)="navigateToEditConsultantSegment(consultantSegment.id)">
                    <i class="fs-16 material-icons"> edit </i>
                  </button>
                  <button mat-icon-button (click)="deleteConsultantSegment(consultantSegment.id, consultantSegment.name)">
                    <i class="fs-16 material-icons"> delete </i>
                  </button>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
          </div>
          <mat-paginator class="m-t-5" [length]='totalCount' showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 30]">
          </mat-paginator>
  
        </div>
      </div>
  
      <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="sidebar-btn">
        <div class="d-flex  fd-col">
          <button mat-raised-button class="custom-button" (click)="navigateToAddConsultantSegment()" [disabled]="!selectedHierarchy">
            <i class="material-icons">
              add
            </i>
            {{'addConsultantSegment' | translate}}</button>
        </div>
        <div>
        </div>
      </div>
  
    </div>
  