import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit
} from '@angular/core';
import { KUNDERGUST, AllDataLoadDetail } from 'src/app/app.config';

@Component({
  selector: 'app-potentialanalysis-kundengerust-list',
  templateUrl: './potentialanalysis-kundengerust-list.component.html',
  styleUrls: ['./potentialanalysis-kundengerust-list.component.scss']
})
export class PotentialanalysisKundengerustListComponent implements OnInit, AfterViewInit {

  @Input('dataSource') dataSource: any;
  @Input('dataForPDF') dataForPDF: any;
  @Input() selectedCalculationId: any;
  @Input() openedFromSideMenu: any;
  @Output() closeReport = new EventEmitter<string>();
  @Output() onInitDoneEvent: EventEmitter<any> = new EventEmitter();
  spinnerFlag = AllDataLoadDetail.loaded;
  dimensionselected: any;
  kennzahlSelected: any;
  showVermogenTable = false;
  showKundengruppeTable = false;
  displayedColumns = ['Kundengruppe', 'BankUndVerbundvertrag', 'NurBankkonto', 'NurVerbundvertrag', 'OhneVertrag', 'Gesamt'];
  intervalEvent: any;
  potentialAnalyse: any;


  constructor() { }

  ngOnInit() {
    if (!this.dataSource) {
      this.dataSource = [];
    }
    if (this.dataForPDF) {
      this.spinnerFlag = true;
      this.showVermogenTable = true;
      this.showKundengruppeTable = true;
    }
  }

  ngAfterViewInit(): void {
    this.onInitDoneEvent.emit();
  }

  dimensionenOrKennzahlChanged() {
    if (!this.dimensionselected || !this.kennzahlSelected) {
      return;
    }
    KUNDERGUST.forEach(kundengerust => {
      if (this.dimensionselected == kundengerust.dimensionselected && this.kennzahlSelected == kundengerust.kennzahlSelected && kundengerust.data != null) {
        this.dataSource = kundengerust.data;
        kundengerust.dimensionselected == 'vermogen' ? this.showVermogenTable = true : this.showVermogenTable = false;
        kundengerust.dimensionselected == 'kundengruppe' ? this.showKundengruppeTable = true : this.showKundengruppeTable = false;
      }
    });

  }

  closeChart() {
    this.closeReport.next();
  }

  parentChartGeneration(value) {
    this.selectedCalculationId = value;
    this.dimensionenOrKennzahlChanged();
  }

}
