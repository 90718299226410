<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        <span>{{'settings' | translate}}</span>
        {{'serviceAccess' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>


  <!-- <mat-card  class="m-t-50 m-b-50 p-t-30">
    <mat-card-content> -->
  <mat-grid-list cols="12" rowHeight="300px"  class="m-t-50 m-b-50 p-t-30">
    <mat-grid-tile flex-start flex-align-top [colspan]="9" class="grid-spacing">
      <div class="note">
        <div>
          <mat-label class="m-b-10" class="info-text d-flex nowrap p-30">
            <i class="material-icons">info</i>
            <span class="m-l-10">{{'serviceZugriffPageNote' | translate}}
            </span>
          </mat-label>
        </div>
        <div class=" w-100 m-t-20 card-shadow">
          <table mat-table [dataSource]="dataSource" matSort class="custom-table fixed-table  w-100 m-b-0">
            <ng-container matColumnDef="FirstName">
              <th width="180" mat-header-cell *matHeaderCellDef mat-sort-header>{{'name' | translate}}</th>
              <td class="fw-500" mat-cell *matCellDef="let data">
                <mat-checkbox class="custom-checkbox">{{data.FirstName}}
                  <span *ngIf="data.LastName && data.FirstName">,</span> {{data.LastName}} </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="Organisation">
              <th width="180" mat-header-cell *matHeaderCellDef mat-sort-header>Organisation</th>
              <td class="fw-500" mat-cell *matCellDef="let data">
                {{data.Organisation}}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile flex-start flex-align-top [colspan]="3" class="grid-spacing">
      <div class="d-flex w-100 fd-col">
        <mat-form-field>
          <mat-select matNativeControl [(value)]="role" required (selectionChange)="filterData($event)">
            <mat-option value="Alle">{{'all' | translate}}</mat-option>
            <mat-option value="Mit Zugriffsberechtigung">{{'withAccessRight' | translate}}</mat-option>
            <mat-option value="Ohne Zugriffsberechtigung">{{'withoutAccessAuthorization' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>


        <form class="m-t-5">
          <mat-form-field class="w-100">
            <input matInput placeholder="Suchen" (keyup)="applyFilter($event.target.value)" value="">
          </mat-form-field>
        </form>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <!-- </mat-card-content> -->
  <!-- </mat-card> -->
</div>