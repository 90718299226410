<div>
    <h4 class="m-t-0" mat-dialog-title>Vertriebsstruktur Eintrag löschen</h4>
    <p>{{'sureYouWantToDeleteStart' | translate}} {{consultant.name}} {{'sureYouWantToDeleteEnd' | translate}}?


    </p>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="basic" id="abort" (click)="closeDialog(false)">{{'stop' | translate}}</button>
        <button mat-raised-button cdkFocusInitial id="continue" class="custom-button m-l-10"
            (click)="closeDialog(true)">{{'continue' | translate}}</button>
    </div>
</div>