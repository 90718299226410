<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'bussinessAreas' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>
</div>


  <div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap fxLayoutGap="0.5%"
    fxLayoutAlign="center">
    <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
      <div class=" w-100 card-shadow">
        <div class="table-container">
          <div class="div-spinner" *ngIf="spinnerFlag">
            <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
          </div>
          <table mat-table [dataSource]="dataSource" align="center" matSort class="w-100">

            <ng-container matColumnDef="postcode">
              <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'postcode' | translate}} </th>
              <td mat-cell *matCellDef="let bussinessDistrict"> {{bussinessDistrict.postcode}}</td>
            </ng-container>


            <ng-container matColumnDef="place">
              <th width="150" mat-header-cell *matHeaderCellDef mat-sort-header>{{'place' | translate}}</th>
              <td mat-cell *matCellDef="let bussinessDistrict">
                <div *ngIf="bussinessDistrict.place.length > 13" matTooltip="{{bussinessDistrict.place}}">
                  {{bussinessDistrict.place ? bussinessDistrict.place : '-'}}</div>
                <div *ngIf="bussinessDistrict.place.length < 13">{{bussinessDistrict.place ? bussinessDistrict.place : '-'}}</div>

              </td>
            </ng-container>

            <ng-container matColumnDef="categoryName">
              <th width="140" mat-header-cell *matHeaderCellDef mat-sort-header>{{'assignment' | translate}}</th>
              <td mat-cell *matCellDef="let bussinessDistrict">
                {{bussinessDistrict.categoryName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="purchasingPower">
              <th width="80" mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchasingPower' | translate}}</th>
              <td mat-cell *matCellDef="let bussinessDistrict">
                {{getDisplayValueInDecimal(bussinessDistrict.purchasingPower)}}
              </td>
            </ng-container>


            <ng-container matColumnDef=" ">
              <th width="60" mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let bussinessDistrict" class="text-right">
                <button mat-icon-button (click)="navigateToEditBussinessAreas(bussinessDistrict.id)">
                  <i class="fs-16 material-icons"> edit </i>
                </button>
                <button mat-icon-button (click)="deleteBussinessDistrict(bussinessDistrict.id, bussinessDistrict.place)">
                  <i class="fs-16 material-icons"> delete </i>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
        <mat-paginator class="m-t-5" [length]='totalCount' showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 30]">
        </mat-paginator>

      </div>
    </div>

    <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="sidebar-btn">
      <div class="d-flex  fd-col">
        <button mat-raised-button class="custom-button" (click)="navigateToBussinessDistrict()">
          <i class="material-icons">
            add
          </i>
          {{'addArea' | translate}}</button>
      </div>
      <div>
      </div>
    </div>

  </div>
