<div class="p-l-1vw p-t-1vw p-r-1vw">
  <div>
    <h3 class="fw-500">
      {{'detailsCalculation' | translate}} {{kalkulationNo}} {{'deadline' | translate}} {{deadlineDate | dateFormat}}
    </h3>
  </div>



  <div>


    <div class="calculation-list">
      <div class="calculation-list--item" *ngFor="let files of interfaceFiles">
        <div *ngIf="files.files != undefined">
          <h4 class="list-header" *ngIf="files.files.length > 0">{{'used' | translate}} {{files.value}} </h4>
          <div>
            <!-- <p class="list-sub-text" *ngIf="files.files.length > 0">{{'youMustSelectAtLeastOneFile' | translate}}
                </p> -->
            <ul class="check-list">
              <li *ngFor="let file of files.files">
                <mat-label class="m-r-90">{{file.uploadedFile.fileName}}
                </mat-label>
                <span class="sub-text">{{'imported' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}</span>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <div class="no-file-msg" *ngIf="noFiles">
      {{'no Files' | translate}}
    </div>

    <div mat-dialog-actions class="m-t-20" align="end">
      <button mat-raised-button color="basic" (click)="closeDialog()">{{'hide' | translate}}</button>
    </div>
  </div>


</div>