<div class="p-l-1vw p-t-1vw p-r-1vw">
    <div>
      <h3 class="fw-500">
       {{'deleteKeyDateBtn' | translate}}
      </h3>
    </div>
  
    <div>

      <div class="calculation-list">
       {{'deleteDeadlineStart' | translate }} {{calculation.deadlineDate  | dateFormat : 'dd/MM/yyyy' }} 
       {{'deleteDeadlineEnd' | translate}}
      </div>
  
      <div mat-dialog-actions class="m-t-20 btn-center"  >
        <button mat-raised-button  class="btn-outline" (click)="closeDialog(false)">{{'stop' | translate}}</button>
        <button mat-raised-button cdkFocusInitial class="custom-button m-l-10" (click)="closeDialog(true)">{{'continue' | translate}}</button>
    
        <!-- <button mat-raised-button color=" "  (click)="closeDialog()">{{'continue' | translate}}</button>
        <button mat-raised-button color=" "  (click)="closeDialog()">{{'stop' | translate}}</button> -->
      </div>
    </div>
  
  
  </div>