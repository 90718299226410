<div class="p-l-1vw p-t-1vw p-r-1vw">

  <mat-grid-list cols="3" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top [colspan]="2" class="grid-spacing">
      <h1 class="page-title">
        {{'productClassification' | translate}} > {{'editAsignment' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top [colspan]="1" class="grid-spacing">

    </mat-grid-tile>
  </mat-grid-list>


  <mat-grid-list cols="12" rowHeight="800px" class="m-t-50 m-b-50">
    <mat-grid-tile flex-start flex-align-top [colspan]="12" class="grid-spacing overflow-tile">
      <div *ngIf="spinnerFlag" class="progress-spinner spin-position">
        <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
      </div>
      <div *ngIf="!spinnerFlag">
        <div class="p-20">
          <mat-label class="fw-600">{{'productCombination' | translate}}</mat-label>
        </div>
        <form class="w-100" [formGroup]="produktEditForm" novalidate>
          <mat-grid-list cols="12" rowHeight="70px">
            <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'productName' | translate}}</mat-label>
                <input matInput placeholder="Produktname" formControlName="Produktname" name="title">
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'isinNo' | translate}}</mat-label>
                <input matInput placeholder="ISIN" formControlName="ISIN" name="title">
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'productId1' | translate}}</mat-label>
                <input matInput placeholder="ProduktId1" formControlName="ProduktId1" name="ProduktId1">

              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'productId2' | translate}}</mat-label>
                <input matInput placeholder="ProduktId2" formControlName="ProduktId2" name="ProduktId2">
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="6">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'providers' | translate}}</mat-label>
                <input matInput placeholder="Anbieter" formControlName="providers" name="providers">
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </form>

        <div class="p-20">
          <mat-label class="fw-500"> {{'assignmentNumber' | translate}}</mat-label>
        </div>



        <form [formGroup]="editAssignmentForm" class="form-horizontal">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="w-250">{{'solution' | translate}}</th>
                <th class="w-150">{{'proportionOf' | translate}} {{'payment' | translate}} </th>
                <th class="w-150">{{'proportionOf' | translate}} {{'assets' | translate}}</th>
                <th class="w-150">{{'proportionOf' | translate}} {{'credit' | translate}} </th>
                <th class="w-150">{{'ownershipNecessary' | translate}} </th>
                <th class="w-150"></th>
              </tr>
            </thead>
            <tbody formArrayName="assignmentArray">
              <tr *ngFor="let item of getControls(); let i = index" [formGroupName]="i">
                <td class="w-250">

                  <!-- 1st  -->
                  <mat-form-field appearance="outline" class="form-field w-250"
                    [ngClass]="{'error-outline' : item.controls.noSelected.value}">
                    <mat-label>{{'pleaseChoose' | translate}} </mat-label>
                    <input type="text" matInput formControlName="needAndSolution"
                      (ngModelChange)="inputChangeForNeed($event)" [value]="needAndSolutionName"
                      [matAutocomplete]="autoGroup">
                  </mat-form-field>
                  <mat-autocomplete #autoGroup="matAutocomplete">

                    <mat-optgroup *ngFor="let group of filteredNeedsAndSolutions[i] | async"
                      [label]="getOptionGroupName(group)">
                      <mat-option *ngFor="let neededSolution of group.needSolutions"
                        [value]="neededSolution.needSolutionName">
                        {{neededSolution.needSolutionName}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>


                </td>
                <td class="w-150">
                  <mat-form-field appearance="outline" class="form-field w-150"
                    [ngClass]="{'error-outline' : (ZahlungTotal > 100 || item.controls.errorZahlung.value) }">
                    <input type="number" matInput formControlName="anteilZahlung" (change)="checkTotalValues()"
                      [value]="" class="form-control">
                    <div matSuffix>% </div>
                  </mat-form-field>
                </td>
                <td class="w-150">
                  <mat-form-field appearance="outline" class="form-field w-150"
                    [ngClass]="{'error-outline' : (VermogenTotal > 100 || item.controls.errorVermogen.value) }">
                    <input class="form-control" matInput type="number" (change)="checkTotalValues()"
                      formControlName="anteilVermogen">
                    <div matSuffix>% </div>

                  </mat-form-field>
                </td>
                <td class="w-150">
                  <mat-form-field appearance="outline" class="form-field w-150"
                    [ngClass]="{'error-outline' : (KreditTotal > 100 || item.controls.errorKredit.value) }">
                    <input class="form-control" matInput type="number" (change)="checkTotalValues()"
                      formControlName="anteilKredit">
                    <div matSuffix>% </div>

                  </mat-form-field>
                </td>

                <td class="w-150">
                  <mat-form-field appearance="outline" class="form-field w-150"
                    [ngClass]="{'error-outline' : (KreditTotal > 100 || item.controls.errorKredit.value) }">
                    <mat-select formControlName="neccessaryOwnership">
                      <mat-option  [value]="true">ja</mat-option>
                      <mat-option  [value]="false">nein</mat-option>
                    </mat-select>
                  </mat-form-field>

                


                </td>

                <td>

                </td>

                <td>
                  <i class="material-icons delete-icon" *ngIf="editProductCombinationModel.Result.clientId !== 0"
                    (click)="deleteProductCombination(i)">delete</i>
                </td>
              </tr>
            </tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </form>


        <!-- error -->

        <div>
          <button mat-raised-button class="custom-button" (click)="onAddItem(null)"
            [disabled]="editProductCombinationModel.Result.clientId === 0">
            <i class="material-icons">add</i>
            {{'addSolution' | translate}}
          </button>
        </div>

        <!-- <button (click)="addNewSolution()">add</button> -->
        <div class="m-t-50 ">
          <div class="error-display">
            <div class="error-text m-t-10" *ngIf="assetValuesNull">
              <div> {{'errorProductCannotBeSaved' | translate}}</div>
              <div>- {{'errorContainsTextWithoutShares' | translate}}</div>
            </div>
            <div class="error-text m-t-10" *ngIf="noNeedIsSelected">
              <div> {{'errorProductCannotBeSaved' | translate}}</div>
              <div>- {{'errorPleaseSelectASolution' | translate}}</div>
            </div>
            <div class="error-text m-t-10" *ngIf="exceeds100">
              <div> {{'errorProductCannotBeSaved' | translate}}</div>
              <div>- {{'sumExeeds100Error' | translate}}
              </div>
            </div>
          </div>
          <span>
            <button mat-raised-button color="basic" class="m-l-10"
              (click)="navigateBackToProduktList()">{{'stop' | translate}}</button>
            <button mat-raised-button class="custom-button m-l-20" (click)="addNewSolution()"
              *ngIf="editProductCombinationModel.Result.clientId !== 0" [disabled]="disableSaveButton()">
              {{'save' | translate}}</button>
          </span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>