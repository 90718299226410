<div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap
  fxLayoutGap="0.5%" fxLayoutAlign="center">
  <div fxFlex="85%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
    <form class="w-100">
      <mat-list class="custom-edit-list">

        <mat-list-item>
          <!-- displat username -->
          <div *ngIf="editName" class="d-flex align-center">
            <span class="d-flex w-100 m-b-5">
              <b>{{'name' | translate}}</b>
            </span>
            <span class="d-flex w-100 align-center">
              <span>{{mandaten.Name}}</span>
              <button mat-icon-button button-sm class="edit-custom-icons" (click)="openUserNameEditBox()">
                <i class="fs-16 material-icons"> edit </i>
              </button>
            </span>
          </div>
          <!-- editbox username -->
          <div *ngIf="!editName" class="d-flex align-center">
            <mat-form-field class="no-spacing" appearance="outline">
              <mat-label>{{'name' | translate}}</mat-label>
              <input matInput placeholder="name" [(ngModel)]="mandaten.Name" name="name">
            </mat-form-field>
            <button mat-icon-button class="edit-custom-icons" (click)="editUserName()">
              <i class="material-icons">
                check
              </i>
            </button>
            <button mat-icon-button class="edit-custom-icons" (click)="clearUserName()">
              <i class="material-icons">
                clear
              </i>
            </button>
          </div>
        </mat-list-item>

        <mat-list-item>
          <!--display address -->
          <div *ngIf="editAddress" class="d-flex align-center">
            <span class="d-flex w-100 m-b-5">
              <b>{{'address' | translate}}</b>
            </span>
            <span class="d-flex w-100 align-center">
              <span class="w-100">{{mandaten.Address}} </span>
              <span>{{mandaten.ZipCode}}</span>
              <button mat-icon-button button-sm class="edit-custom-icons" (click)="openAddressEditBox()">
                <i class="fs-16 material-icons"> edit </i>
              </button>
            </span>
          </div>

          <!-- editbox address -->
          <div *ngIf="!editAddress" class="d-flex align-center w-100">
            <div class="d-flex align-center w-100 p-b-30">
              <mat-form-field class="no-spacing w-50" appearance="outline">
                <mat-label>{{'streetAndHouseNo' | translate}}({{'optional' | translate}})</mat-label>
                <input matInput placeholder="StraBe und Hausnummer" [(ngModel)]="mandaten.Address"
                  name="strabeAndHausnummer">
              </mat-form-field>
            </div>
            <div class="d-flex align-center w-100">
              <mat-form-field class="no-spacing m-r-20" appearance="outline">
                <mat-label>{{'plz' | translate}}</mat-label>
                <input matInput placeholder="PLZ" [(ngModel)]="mandaten.ZipCode" name="plz">
              </mat-form-field>
              <!-- <mat-form-field class="no-spacing" appearance="outline">
                <mat-label>{{'city' | translate}}</mat-label>
                <input matInput placeholder="Stadt" [(ngModel)]="stadt" name="stadt">
              </mat-form-field> -->
              <button mat-icon-button class="edit-custom-icons" (click)="updateAddress()">
                <i class="material-icons">
                  check
                </i>
              </button>
              <button mat-icon-button class="edit-custom-icons" (click)="clearAddress()">
                <i class="material-icons">
                  clear
                </i>
              </button>
            </div>
          </div>
        </mat-list-item>


        <mat-list-item>
          <a>{{'toTheCustomerSystem' | translate}}</a>
        </mat-list-item>
      </mat-list>

      <button mat-raised-button class="custom-button back-button" (click)="navigateBackToMandatenList()">
        {{'back' | translate}}
      </button>

    </form>
  </div>
  <div fxFlex="15%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="sidebar-btn">
    <button mat-raised-button color="warn" (click)="deleteMandantDialog()">
      {{'deleteClientLabel' | translate}}
    </button>
  </div>