<div>
  <mat-label class="fw-500">Teilbedarf hinzufügen</mat-label>
</div>

<div class="m-t-20" *ngIf="hideContent">
  <mat-accordion multi="true" class="w-100  modal-accordian">
    <mat-expansion-panel [expanded]="false" *ngFor="let needAndSolution of customerNeeds" class="inner-container"
      [ngStyle]="getLeftBorderColor(needAndSolution.needThemeName)">
      <mat-expansion-panel-header class="border-wrapper">
        <mat-panel-title>
          {{needAndSolution.needThemeName}}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <!-- sub expansion panel -->
      <mat-expansion-panel hideToggle #expId class="sub-inner-container"
        *ngFor="let needField of needAndSolution.needFields">
        <mat-expansion-panel-header class="pt-10">
          <mat-panel-title>
            <i class="material-icons" *ngIf="!expId.expanded">
              keyboard_arrow_right
            </i>
            <i class="material-icons" *ngIf="expId.expanded">
              keyboard_arrow_down
            </i>
            <span>
              <div class="p-t-5">
                {{needField.needFieldName}}</div>
            </span>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-list *ngFor="let neeFieldPart of needField.needFieldPartCategories">
          <mat-list-item class="expansion-inner-list sub-list" (click)="addPartialNeeds(neeFieldPart.needFieldPartId)">
            <span class="expantion-list-left">
              {{neeFieldPart.needFieldPartCategoryName}}
            </span>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button class="custom-button" class="m-t-5" (click)="closeDialog()">{{'stop' | translate}}</button>
</div>