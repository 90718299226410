import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FILE_INTERFACE } from 'src/app/app.config';


@Component({
  selector: 'app-show-file-details-for-calculation',
  templateUrl: './show-file-details-for-calculation.component.html',
  styleUrls: ['./show-file-details-for-calculation.component.scss']
})
export class ShowFileDetailsForCalculationComponent implements OnInit {
  files: any;
  interfaceTypes = FILE_INTERFACE;
  interfacePresent: any[] = [];
  constructor(private translate: TranslateService,
    public dialogRef: MatDialogRef<ShowFileDetailsForCalculationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.files = data.calculation.kalkulationFiles;
    this.files.forEach(file => {
      file.uploadedFile.transferType.name = file.uploadedFile.transferType.name.trim();
      this.interfaceTypes.forEach(interfase => {
        if (interfase.name == file.uploadedFile.transferType.name) {
          file.uploadedFile.interfaceType = interfase.value;
          this.interfacePresent.push(interfase.value);
        }
      });

    });
  }


  isInterfacePresent(interfase) {
    return this.interfacePresent.includes(interfase);
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
