/**
 * ----------------------------------------------
 * German translations for Infragisitcs components
 * ----------------------------------------------
 */

import { IPaginatorResourceStrings, IResourceStrings } from "@infragistics/igniteui-angular";


// Grid Component
export const infragisticsResourceStringsGerman: IResourceStrings = {
    igx_grid_actions_add_child_label: "Kind hinzufügen",
    igx_grid_actions_add_label: "Hinzufügen",
    igx_grid_actions_delete_label: "Löschen",
    igx_grid_actions_edit_label: "Bearbeiten",
    igx_grid_actions_jumpDown_label: "Springe runter",
    igx_grid_actions_jumpUp_label: "Springe hoch",
    igx_grid_actions_pin_label: "Fixierung setzen",
    igx_grid_actions_unpin_label: "Fixierung aufheben",
    igx_grid_add_row_label: "Zeile hinzufügen",
    igx_grid_advanced_filter_add_condition: "Zustand",
    igx_grid_advanced_filter_and_group: '"Und" Gruppe',
    igx_grid_advanced_filter_and_label: "und",
    igx_grid_advanced_filter_column_placeholder: "Spalte auswählen",
    igx_grid_advanced_filter_create_and_group: '"Und" Gruppe erstellen',
    igx_grid_advanced_filter_create_or_group: '"Oder" Gruppe erstellen',
    igx_grid_advanced_filter_delete: "Löschen",
    igx_grid_advanced_filter_delete_filters: "Filter löschen",
    igx_grid_advanced_filter_end_group: "Gruppen Ende",
    igx_grid_advanced_filter_initial_text: 'Beginnen Sie mit dem Erstellen einer Gruppe von Zuständen die mit "Und" oder "Oder" verbunden sind',
    igx_grid_advanced_filter_or_group: '"Oder" Gruppe',
    igx_grid_advanced_filter_or_label: "oder",
    igx_grid_advanced_filter_title: "Erweitertes Filtern",
    igx_grid_advanced_filter_ungroup: "Gruppierung aufheben",
    igx_grid_advanced_filter_value_placeholder: "Wert",
    igx_grid_complex_filter: "Complexer Filter",
    igx_grid_emptyFilteredGrid_message: "Keine passenden Daten vorhanden.",
    igx_grid_emptyGrid_message: "Keine Daten vorhanden.",
    igx_grid_excel_add_to_filter: "Aktuelle Auswahl als Filter hinzufügen",
    igx_grid_excel_apply: "Übernehmen",
    igx_grid_excel_blanks: "(Leer)",
    igx_grid_excel_boolean_filter: "Boolean-Filter",
    igx_grid_excel_cancel: "Abbrechen",
    igx_grid_excel_custom_dialog_add: "Filter hinzufügen",
    igx_grid_excel_custom_dialog_clear: "Filter entfernen",
    igx_grid_excel_custom_dialog_header: "Eigener automatischer Filter für Spalte: ",
    igx_grid_excel_custom_filter: "Eigener Filter...",
    igx_grid_excel_date_filter: "Datums-Filter",
    igx_grid_excel_filter_clear: "Spalten-Filter entfernen",
    igx_grid_excel_filter_moving_header: "Verschieben",
    igx_grid_excel_filter_moving_left: "Nach links verschieben",
    igx_grid_excel_filter_moving_left_short: "Links",
    igx_grid_excel_filter_moving_right: "Nach rechts verschieben",
    igx_grid_excel_filter_moving_right_short: "Rechts",
    igx_grid_excel_filter_sorting_asc: "Aufsteigend",
    igx_grid_excel_filter_sorting_asc_short: "Aufst.",
    igx_grid_excel_filter_sorting_desc: "Absteigend",
    igx_grid_excel_filter_sorting_desc_short: "Abst.",
    igx_grid_excel_filter_sorting_header: "Sortieren",
    igx_grid_excel_hide: "Spalte ausblenden",
    igx_grid_excel_number_filter: "Nummerischer Filter",
    igx_grid_excel_pin: "Spalte fixieren",
    igx_grid_excel_search_placeholder: "Suchen",
    igx_grid_excel_select: "Spalte auswählen",
    igx_grid_excel_select_all: "Alles auswählen",
    igx_grid_excel_select_all_search_results: "Alle Suchergebnisse auswählen",
    igx_grid_excel_show: "Spalte anzeigen",
    igx_grid_excel_text_filter: "Nach Text filtern",
    igx_grid_excel_unpin: "Spalten-Fixierung aufheben",
    igx_grid_filter: "Filter",
    igx_grid_filter_after: "Nach",
    igx_grid_filter_all: "Alle",
    igx_grid_filter_before: "Vor",
    igx_grid_filter_condition_placeholder: "Filter auswählen",
    igx_grid_filter_contains: "Enthält",
    igx_grid_filter_doesNotContain: "Enthält nicht",
    igx_grid_filter_doesNotEqual: "Ungleich",
    igx_grid_filter_empty: "Leer",
    igx_grid_filter_endsWith: "Endet mit",
    igx_grid_filter_equals: "Gleich",
    igx_grid_filter_false: "Falsch",
    igx_grid_filter_greaterThan: "Größer als ",
    igx_grid_filter_greaterThanOrEqualTo: "Größer als oder gleich",
    igx_grid_filter_lastMonth: "Letzter Monat",
    igx_grid_filter_lastYear: "Letztes Jahr",
    igx_grid_filter_lessThan: "Weniger als",
    igx_grid_filter_lessThanOrEqualTo: "Weniger als oder gleich",
    igx_grid_filter_nextMonth: "Nächster Monat",
    igx_grid_filter_nextYear: "Nächstes Jahr",
    igx_grid_filter_notEmpty: "Nicht leer",
    igx_grid_filter_notNull: "Nicht Null",
    igx_grid_filter_null: "Null",
    igx_grid_filter_operator_and: "Und",
    igx_grid_filter_operator_or: "Oder",
    igx_grid_filter_row_boolean_placeholder: "Alle",
    igx_grid_filter_row_close: "Schließen",
    igx_grid_filter_row_date_placeholder: "Datum auswählen",
    igx_grid_filter_row_placeholder: "Filter-Wert hinzufügen",
    igx_grid_filter_row_reset: "Zurücksetzen",
    igx_grid_filter_startsWith: "Beginnt mit",
    igx_grid_filter_thisMonth: "Dieser Monat",
    igx_grid_filter_thisYear: "Dieses Jahr",
    igx_grid_filter_today: "Heute",
    igx_grid_filter_true: "Wahr",
    igx_grid_filter_yesterday: "Gestern",
    igx_grid_groupByArea_message: "Ziehen Sie eine Spalten-Beschriftung und legen Sie sie hier ab, um nach dieser Spalte zu gruppieren.",
    igx_grid_hiding_check_all_label: "Alles einblenden",
    igx_grid_hiding_uncheck_all_label: "Alles ausblenden",
    igx_grid_pinned_row_indicator: "Fixiert",
    igx_grid_pinning_check_all_label: "Alles fixieren",
    igx_grid_pinning_uncheck_all_label: "Fixierung für alle aufheben",
    igx_grid_snackbar_addrow_actiontext: "Anzeigen",
    igx_grid_snackbar_addrow_label: "Zeile hinzugefügt",
    igx_grid_summary_average: "Durchschnitt",
    igx_grid_summary_count: "Anzahl",
    igx_grid_summary_earliest: "Frühste(r)",
    igx_grid_summary_latest: "Späteste(r)",
    igx_grid_summary_max: "Max",
    igx_grid_summary_min: "Min",
    igx_grid_summary_sum: "Summe",
    igx_grid_toolbar_actions_filter_prompt: 'Filter Spalten-Liste...',
    igx_grid_toolbar_pinning_button_tooltip: 'Öffne Menü, um Spalten zu fixieren',
    igx_grid_toolbar_hiding_button_tooltip: 'Spalten ein-/ ausblenden',
    igx_grid_toolbar_pinning_title: 'Fixierte Spalten',
    igx_grid_toolbar_hiding_title: 'Sichtbare Spalten',
    igx_grid_toolbar_advanced_filtering_button_tooltip: 'Öffne Dialog zur erweiterten Filterung',
    igx_grid_toolbar_advanced_filtering_button_label: 'Erweiterte Filterung',
    igx_grid_toolbar_exporter_button_tooltip: 'Öffne Menü, um zu exportieren',
    igx_grid_toolbar_exporter_button_label: 'Exportiere',
    igx_grid_toolbar_exporter_excel_entry_text: 'Exportiere als Excel',
    igx_grid_toolbar_exporter_csv_entry_text: 'Exportiere als CSV',
    igx_time_picker_cancel: "Abbrechen",
    igx_time_picker_ok: "OK",
    igx_grid_row_edit_btn_done: 'Speichern',
    igx_grid_row_edit_btn_cancel: 'Abbrechen',
  };

  // Paginator Component
  export const infragisticsPaginatorResourceStringsGerman: IPaginatorResourceStrings = {
    igx_paginator_label: "Einträge pro Seite:",
    igx_paginator_first_page_button_text: "Erste Seite",
    igx_paginator_last_page_button_text: "Letzte Seite",
    igx_paginator_next_page_button_text: "Nächste Seite",
    igx_paginator_previous_page_button_text: "Vorherige Seite",
    igx_paginator_pager_text: "von"
  };
