import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrganisationService } from '../../service/sales-organisation.service';
import { KalkulationService } from 'src/app/benutzer-verwaltung/data-transfer-center/services/kalkulation.service';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConsultantSegmentService } from '../../service/consultant-segment.service';
import { DecimalPipe } from '@angular/common'
import { numberFormat } from 'highcharts';

@Component({
  selector: 'app-add-consultant',
  templateUrl: './add-consultant.component.html',
  styleUrls: ['./add-consultant.component.scss']
})
export class AddConsultantComponent implements OnInit {

  spinnerFlag = true;
  checkboxChanged: boolean;
  consultantForm: FormGroup;
  consultantId: any;
  isEdit: any;
  consultantObject = new Consultant();
  consultantIfEdit: any;
  parentProfitCenterList: any;
  consultantSegmentList: any;
  deadline: any;
  organisationalLengthErr = false;

  
  organisationStructureHierarchyId: any;
  parentProfitCenterId: number;

  constructor(private route: ActivatedRoute,
    private salesOrganisationService: SalesOrganisationService,
    private consultantSegmentService: ConsultantSegmentService,
    private kalkulationService: KalkulationService,
    private dialog: MatDialog,
    private commonUtils: CommonUtilsService,
    private router: Router,
    private decimalPipe: DecimalPipe) {
    this.consultantId = this.route.snapshot.paramMap.get('id');
    this.isEdit = (this.route.snapshot.paramMap.get('edit') === 'true');
    this.initForm();
    this.organisationStructureHierarchyId = this.route.snapshot.paramMap.get('orgaStructId');
    this.deadline = this.route.snapshot.paramMap.get('deadline');

    if (this.isEdit) {
      this.consultantId = this.route.snapshot.paramMap.get('id');
      this.getConsultantById();
    } else {
      this.parentProfitCenterId = +this.route.snapshot.paramMap.get('id');
      //this.getNewDeadline();
      this.getParentProfitCenters();
      this.getConsultantSegments();
    }
  }

  ngOnInit() {
    // console.log(this.deadline);
    // console.log(this.consultantId);
    // console.log(this.parentProfitCenterId);
    // console.log(this.organisationStructureHierarchyId);

  }

  initForm() {
    this.consultantForm = new FormGroup({
      name: new FormControl('', Validators.compose([
        Validators.required])),
      shortDescription: new FormControl(''),
      parentProfitCenter: new FormControl('', Validators.required),
      consultantClientId: new FormControl('', Validators.required),
      consultantSegment: new FormControl(''),
      capacity: new FormControl(''),
    });

    this.consultantForm.valueChanges.subscribe( form => {
      if (form.capacity && /[0-9]+$/.test(form.capacity.toString())) {
        this.consultantForm.patchValue({
          capacity: this.decimalPipe.transform(+form.capacity.toString().replace(',', '.'), "1.0-14", "de-DE")
        }, {emitEvent: false});
      }
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.consultantForm.controls[controlName].hasError(errorName);
  }

  getConsultantById() {
    this.salesOrganisationService.getConsultantById(this.consultantId).subscribe((res: any) => {
      this.consultantIfEdit = res.Result;
      if (this.isEdit) {
        this.setInitialObject();
      }
      //this.getNewDeadline();
      this.getParentProfitCenters();
      this.getConsultantSegments();
    });
  }

  // getNewDeadline() {
  //   this.kalkulationService.getNewestDeadline().subscribe((res: any) => {
  //     this.deadline = res.Result.deadlineDate;
  //   });
  // }

  getParentProfitCenters() {
    this.salesOrganisationService.getConsultantStructureByHierarchyId(this.organisationStructureHierarchyId).subscribe((res: any) => {
      this.parentProfitCenterList = res.Result;

      this.parentProfitCenterList.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      if (this.isEdit == false) {
        this.consultantObject.profitCenterId = this.parentProfitCenterId;
        this.consultantForm.controls['parentProfitCenter'].setValue(this.consultantObject.profitCenterId);
      }
      this.spinnerFlag = false;
    });
  }

  getConsultantSegments() {
    this.consultantSegmentService.getAllConsultantSegmentsByHierarchyId(this.organisationStructureHierarchyId).subscribe((res: any) => {
      this.consultantSegmentList = res.Result;
      this.consultantSegmentList.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      this.consultantSegmentList.unshift({id: null, name: '-'});

      this.spinnerFlag = false;
    });
  }

  setInitialObject() {
    
    this.consultantForm.controls['parentProfitCenter'].setValue(this.consultantIfEdit.profitCenterId);
    this.consultantForm.controls['consultantSegment'].setValue(this.consultantIfEdit.consultantSegment?.id);
    if (this.isEdit) {
      // set form
      this.consultantForm.controls['name'].setValue(this.consultantIfEdit.name);
      this.consultantForm.controls['shortDescription'].setValue(this.consultantIfEdit.description);
      this.consultantForm.controls['consultantClientId'].setValue(this.consultantIfEdit.consultantClientId);
      this.consultantForm.controls['capacity'].setValue(this.consultantIfEdit.capacity);

      this.consultantObject.consultantClientId = this.consultantIfEdit.consultantClientId;
      this.consultantObject.deadline = this.consultantIfEdit.deadline;
      this.consultantObject.description = this.consultantIfEdit.description;
      this.consultantObject.genoUserId = this.consultantIfEdit.genoUserId;
      this.consultantObject.name = this.consultantIfEdit.name;
      this.consultantObject.profitCenterId = this.consultantIfEdit.profitCenterId;
      this.consultantObject.validFrom = this.consultantIfEdit.validFrom;
      this.consultantObject.consultantSegmentId = this.consultantIfEdit.consultantSegment?.id;
      this.consultantObject.capacity = this.consultantIfEdit.capacity;
      this.consultantObject.organisationStructureHierarchyId = this.organisationStructureHierarchyId;

      if (this.consultantIfEdit.customersCount > 0) {
        this.consultantForm.controls['consultantClientId'].disable();
      }
    }
  }
  addConsultant() {
    this.consultantObject.name = this.consultantForm.value.name;
    this.consultantObject.description = this.consultantForm.value.shortDescription;
    this.consultantObject.consultantClientId = this.consultantForm.value.consultantClientId;
    this.consultantObject.profitCenterId = this.consultantForm.value.parentProfitCenter;
    this.consultantObject.consultantSegmentId = this.consultantForm.value.consultantSegment;
    this.consultantObject.capacity = +this.consultantForm.value.capacity.toString().replace(',', '.');
    this.consultantObject.organisationStructureHierarchyId = this.organisationStructureHierarchyId;
    this.consultantObject.deadline = this.deadline;

    if (this.consultantObject.capacity < 0 || this.consultantObject.capacity > 1) {
      this.commonUtils.errorSnackBar('error', 'Der Wert für Vertriebskapazität muss zwischen 0 und 1 liegen.', 3000);
      return;
    }

    if (this.isEdit) {
      this.salesOrganisationService.editConsultant(this.consultantId, this.consultantObject).subscribe(res => {
        this.router.navigateByUrl('/bv-home/sales-organization-list/true?consultantOrganisation=true');
      });
    } else {
      this.salesOrganisationService.addConsultant(this.consultantObject).subscribe(res => {
        this.router.navigateByUrl('/bv-home/sales-organization-list/true?consultantOrganisation=true');
      }, err => {
        this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage, 3000);
      });
    }
  }
  clearForm() {
    if (!this.consultantForm.dirty) {
      this.router.navigateByUrl('/bv-home/sales-organization-list/true?consultantOrganisation=true');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/sales-organization-list/true?consultantOrganisation=true');
        }
      });
    }
  }

  organisationalIdInputChange() {
    if (this.consultantForm.value.organisationalUnitId > 99999999999) {
      this.organisationalLengthErr = true;
    } else {
      this.organisationalLengthErr = false;
    }
  }

  disableSave() {
    if (this.consultantForm.valid && (this.consultantForm.dirty || this.checkboxChanged) && (!this.organisationalLengthErr) ) {
      return false;
    }
    return true;
  }

  onCheckboxChecked(event) {
    this.checkboxChanged = true;
  }
}


export class Consultant {
  'deadline': string = null;
  'genoUserId': string = null;
  'name': string = null;
  'description': string = null;
  'validFrom': string = null;
  'profitCenterId': any = null;
  'consultantClientId': string = null;
  'organisationalUnitId': number = null;
  'consultantSegmentId': number = null;
  'capacity': number = 0.0;
  'organisationStructureHierarchyId': number;
}
