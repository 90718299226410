import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-key-date-dialog',
  templateUrl: './delete-key-date-dialog.component.html',
  styleUrls: ['./delete-key-date-dialog.component.scss']
})
export class DeleteKeyDateDialogComponent implements OnInit {
  calculation;
  constructor(public matDialog: MatDialogRef<DeleteKeyDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.calculation = data.calculation;
  }

  ngOnInit() {
  }

  closeDialog(isdeleted) {
    this.matDialog.close(isdeleted);
  }
}
