import { Injectable } from '@angular/core';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimGroupService {
  createHeaderObject() {
    const headers: any = {
      'customerId': this.userSessionService.getCustomerAndUserId().customerId
    };

    return { headers: new HttpHeaders(headers) };
  }
  constructor(private userSessionService: UserSessionService,
    private commonUtils: CommonUtilsService,
    private http : HttpClient) { }

  getClaimGroups(){
    const url = this.commonUtils.getAPIUrl(`ClaimGroup`);
    return this.http.get(url, this.createHeaderObject());
  }
}
