<div class="p-l-1vw p-t-1vw p-r-1vw">
    <mat-grid-list cols="2" rowHeight="70px">
      <mat-grid-tile flex-start flex-align-top class="grid-spacing">
        <h1 class="page-title">
          Beratersegment > {{ isEdit ? ' Bearbeiten' :'Neues Beratersegment'}}
        </h1>
      </mat-grid-tile>
      <mat-grid-tile flex-end flex-align-top class="grid-spacing">
      </mat-grid-tile>
    </mat-grid-list>
  
    <mat-card class="m-t-50 m-b-50 p-t-30">
      <mat-card-content > 
         
            <form class="w-100" novalidate [formGroup]="consultantSegmentForm">
              <div class="form-field">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'name' | translate}}</mat-label>
                  <input matInput placeholder="Name" name="des" formControlName="name">
                  <mat-error *ngIf="hasError('name', 'required')">name {{'isRequired' | translate}}</mat-error>
  
                </mat-form-field>
              </div>

            </form> 
              <div class=" w-100 card-shadow">
                <div class="table-container">
                  <div class="div-spinner" *ngIf="spinnerFlag">
                    <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
                  </div>
                  <table mat-table [dataSource]="dataSource" align="center" matSort class="w-100" style="max-height: 300px">
        
                    <ng-container matColumnDef="consultant">
                        <th width="60" mat-header-cell *matHeaderCellDef mat-sort-header> {{'assignConsultantToConsultantSegment' | translate}} </th>
                        <td mat-cell *matCellDef="let consultantSegment" >
                            <mat-checkbox [(ngModel)]="consultantSegment.isChecked"
                            (ngModelChange)="checkChanged(consultantSegment)">
                            {{consultantSegment.name}}
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="consultantsCurrentConsultantSegment">
                      <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'consultantsCurrentConsultantSegment' | translate}} </th>
                      <td mat-cell *matCellDef="let consultantSegment"> {{consultantSegment.consultantSegment ? consultantSegment.consultantSegment.name : '-'}}</td>
                    </ng-container>
                   
                    
                    
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                  </table>
                </div>
                <mat-paginator class="m-t-5" [length]='totalCount' showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 30]">
                </mat-paginator>
        
            </div>
              
      </mat-card-content>
      <mat-card-actions class="m-b-5">
        <mat-grid-list cols="12" rowHeight="45px">
          <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
            <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
            <button mat-raised-button class="custom-button m-l-20" (click)="addUpdateConsultantSegment()"
              [disabled]="disableSave()">{{'save' | translate}}</button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-actions>
    </mat-card>
  </div>