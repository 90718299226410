import { MUKU_SCOPE } from 'src/app/app.config';


export const environmentProd = {

  production: true,

  dev: false,

  originUrl: 'https://frontend.vr-musterkundenansatz.de',

  identityUrl: 'https://login.microsoftonline.com/40a83bd0-2969-4a4d-8f68-6f3c7da61e4e/v2.0',

  client_id: 'Musterkunde.Clients.Angular',


  scope: MUKU_SCOPE,

  version: '2.0',

  service_id: 2,

  // service endpoints

  musterkundeDevEndpoint: 'https://api.vr-musterkundenansatz.de',

  datenImportendpoint: 'https://datentransfer.vr-musterkundenansatz.de',

  devAuthManagementEndpoint: 'https://api.vr-musterkundenansatz.de',

  datenExportEndpoint: 'https://export.vr-musterkundenansatz.de'

};
