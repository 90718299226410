import { Component, OnInit, Input, Host } from '@angular/core';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { BvHomeComponent } from '../../bv-common/bv-home/bv-home.component';

@Component({
  selector: 'app-marketing-fabrik-main-page',
  templateUrl: './marketing-fabrik-main-page.component.html',
  styleUrls: ['./marketing-fabrik-main-page.component.scss']
})
export class MarketingFabrikMainPageComponent implements OnInit {

  constructor(private commonUtils: CommonUtilsService) { }

  @Host() bvhome: BvHomeComponent;
  ngOnInit() {
  }
  hover(id) {
    document.getElementById(id).style.fill = '#eb690b';
  }
  hoverOut(id) {
    document.getElementById(id).style.fill = '#f2f2f2';
  }
  navigateToActivityManager() {
    this.commonUtils.setSvgNavigation('ActivityManager');
    // this.router.navigateByUrl('bv-home/vr-activity-mngr-mainpage');
  }
  navigateToVrMusterkunde() {
    this.commonUtils.setSvgNavigation('vrMusterkunde');

  }
}
