
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environmentDev } from './environments/environment.dev';
import { environmentProd } from './environments/environment.prod';

import 'hammerjs';



let environmentSettings;
const domain = window.location.hostname;
switch (domain) {
    //    production
    case 'musterkundefrontend.azurewebsites.net':
        environmentProd.originUrl = 'https://musterkundefrontend.azurewebsites.net';
        environmentSettings = environmentProd;
        break;
    case 'musterkunde.emnicon.de':
        environmentProd.originUrl = 'https://musterkunde.emnicon.de';
        environmentSettings = environmentProd;
        break;
    case 'staging-musterkunde.emnicon.de':
        environmentProd.originUrl = 'https://staging-musterkunde.emnicon.de';
        environmentSettings = environmentProd;
        break;
    // development
    case 'localhost':
        environmentSettings = environmentDev;
        break;
    // default
    default:
        //environmentProd.originUrl = 'https://' + domain;
        environmentSettings = environmentProd;
        break;
}

if (environmentSettings.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
