export class TreeNode {
    constructor(name, id, children = []) {
      this.name = name;
      this.id = id;
      this.children = children;
    }
    name: string;
    id: number;
    children: TreeNode[] = [];
    onSelectedNode?: TreeNode;
    public static toNodeArray(objArr: any[]): TreeNode[] {
      let nodes: TreeNode[] = [];
      objArr.forEach((obj) => {
        nodes.push(new TreeNode(obj.name, obj.id, obj.children));
      });
      return nodes;
    }
  }
  