<div>
  <div mat-dialog-title>
    <h3 class="fw-500 m-t-0">{{'cancelImportConfirmTitle' | translate}}</h3>
  </div>
  <div class="calculation-list">
    <p>
      {{'cancelImportConfirmLine1' | translate}}
    </p>
    <p>
      {{'cancelImportConfirmLine2' | translate}}
    </p>
    <p>
      {{'cancelImportConfirmLine3' | translate}}
    </p>
  </div>
  <div mat-dialog-actions align="center" class="p-10 m-t-20">
    <button mat-flat-button class="reverse-custom-button m-r-10" (click)="abort()">{{'stop' | translate}}</button>
    <button mat-raised-button class="custom-button" (click)="continue()"> {{'continue' | translate}}</button>
  </div>
</div>
