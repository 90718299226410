import { Component, OnInit, ViewChild } from '@angular/core';
import { ParameterMgmtService } from 'src/app/mandanten-verwaltung/services/parameter-mgmt.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-calculation-variables',
  templateUrl: './calculation-variables.component.html',
  styleUrls: ['./calculation-variables.component.scss']
})
export class CalculationVariablesComponent implements OnInit {

  displayedColumns: string[] = ['name', 'needTheme', 'needField', 'algorithm', 'personan', 'datatype', 'value'];
  totalData: any;
  dataSource = new MatTableDataSource();
  spinnerFlag: any = true;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  constructor(private parameterService: ParameterMgmtService) {
    this.parameterService.getParameterList().subscribe((response: any) => {
      this.spinnerFlag = false;
      this.totalData = response.Result.length;
   
      this.dataSource = new MatTableDataSource(response.Result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
  
      this.dataSource.filteredData.forEach((data: any) => {
        data.edit = true;
      });
    });
  }

  

  ngOnInit() {
  }

}
