import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCombinationsService } from '../../service/product-combinations.service';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { ProduktCombination } from '../../models/Product-combination';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-produktzuordnungen',
  templateUrl: './edit-produktzuordnungen.component.html',
  styleUrls: ['./edit-produktzuordnungen.component.scss']
})
export class EditProduktzuordnungenComponent implements OnInit {
  id: any;
  produktEditForm: FormGroup;
  editAssignmentForm: FormGroup;
  assignmentArray = new FormArray([]);
  needAndSolutionName = new FormControl('');
  count = 0;
  filteredNeedsAndSolutions: Observable<any[]>[] = [];
  needAndSolutionsArray: any[] = [];
  KreditTotal = 0;
  VermogenTotal = 0;
  ZahlungTotal = 0;
  assetValuesNull: any;
  exceeds100: any;
  noNeedIsSelected: any;
  buttonDisable = true;
  spinnerFlag = true;
  finalProduktCombinationArray: any;
  editProductCombinationObject = new ProduktCombination();

  editProductCombinationModel: any;
  constructor(private route: ActivatedRoute,
    private router: Router, private productCombinationService: ProductCombinationsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private commonUtils: CommonUtilsService,
    private translateService: TranslateService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(this.id, 10);
    this.editAssignmentForm = new FormGroup({
      'assignmentArray': this.assignmentArray
    });

    this.productCombinationService.getProduktCombinationById(this.id).subscribe((response: any) => {
      this.getProductCombinationNeedsAndSolutionDropdown();
      this.editProductCombinationModel = response;
      if (response.Result && response.Result.productCombinationNeedSolutionViewModels
        && response.Result.productCombinationNeedSolutionViewModels.length > 0) {
        response.Result.productCombinationNeedSolutionViewModels.forEach(x => {
          x.anteilKredit = +x.anteilKredit.replace(',', '.');
          x.anteilVermogen = +x.anteilVermogen.replace(',', '.');
          x.anteilZahlung = +x.anteilZahlung.replace(',', '.');
        });
      }
      this.initForm(response.Result);
      response.Result.productCombinationNeedSolutionViewModels.forEach(element => {
        if (element) {
          this.onAddItem(element);
        }
      });
    });
  }
  _filterGroup(value) {
    if (value) {
      return this.needAndSolutionsArray
        .map(group =>
          ({
            needThemeName: group.needThemeName, needFieldName: group.needFieldName,
            needSolutions: _filter(group.needSolutions, value)
          }))
        .filter(group => group.needSolutions.length > 0);
    }
    return this.needAndSolutionsArray;
  }



  getProductCombinationNeedsAndSolutionDropdown() {
    this.productCombinationService.getProduktCombinationNeedSolutionDropdown().subscribe((Response: any) => {
      this.needAndSolutionsArray = Response.Result;
      this.needAndSolutionsArray.forEach(solution => {
        solution.needFieldName = solution.needFieldName.trim();
        solution.needThemeName = solution.needThemeName.trim();
        solution.needSolutions.forEach(sol => {
          sol.needSolutionName = sol.needSolutionName.trim();
        });
      });
      this.spinnerFlag = false;
    });
  }
  ngOnInit() {
    this.initProductForm();
  }

  initProductForm() {
    this.produktEditForm = new FormGroup({
      Produktname: new FormControl(''),
      ISIN: new FormControl(''),
      ProduktId1: new FormControl(''),
      ProduktId2: new FormControl(''),
      providers: new FormControl('')

    });
  }
  initForm(data) {
    if (this.editProductCombinationModel.Result.clientId === 0) {
      this.produktEditForm = new FormGroup({
        Produktname: new FormControl({ value: data.name, disabled: true }),
        ISIN: new FormControl({ value: data.isin, disabled: true }),
        ProduktId1: new FormControl({ value: data.product01, disabled: true }),
        ProduktId2: new FormControl({ value: data.product02, disabled: true }),
        providers: new FormControl({ value: data.partner.name, disabled: true })
      });
    } else {
      this.produktEditForm = new FormGroup({
        Produktname: new FormControl(data.name),
        ISIN: new FormControl({ value: data.isin, disabled: true }),
        ProduktId1: new FormControl({ value: data.product01, disabled: true }),
        ProduktId2: new FormControl({ value: data.product02, disabled: true }),
        providers: new FormControl({ value: data.partner.name, disabled: true })
      });
    }
  }

  manageLosungControl(index: number) {

    const arrayControl = this.editAssignmentForm.get('assignmentArray') as FormArray;

    // To initially load the autocompelte list initialized the filteredNeedsAndSolutions.
    this.filteredNeedsAndSolutions[index] = of(this.needAndSolutionsArray
      .map(group => ({ needThemeName: group.needThemeName, needFieldName: group.needFieldName, needSolutions: group.needSolutions })));
    arrayControl.at(index).get('needAndSolution').valueChanges.subscribe(res => {
      this.filteredNeedsAndSolutions[index] = of(this._filterGroup(res));

    });
  }

  getOptionGroupName(group) {

    return group.needThemeName + ' >' + group.needFieldName;
  }

  onAddItem(value) {

    const controls = <FormArray>this.editAssignmentForm.controls['assignmentArray'];
    let formGroup;
    if (value) {

      // added Trim to trim the losung value
      value.losung = value.losung.trim();
      formGroup = this.fb.group({
        id: new FormControl(value.id),
        needAndSolution: new FormControl({ value: value.losung, disabled: this.editProductCombinationModel.Result.clientId === 0 }),
        anteilZahlung: new FormControl({ value: value.anteilZahlung, disabled: this.editProductCombinationModel.Result.clientId === 0 }),
        anteilVermogen: new FormControl({ value: value.anteilVermogen, disabled: this.editProductCombinationModel.Result.clientId === 0 }),
        anteilKredit: new FormControl({ value: value.anteilKredit, disabled: this.editProductCombinationModel.Result.clientId === 0 }),
        neccessaryOwnership: new FormControl({ value: this.getOwnershipValue(value.immobilienbesitz), disabled: this.editProductCombinationModel.Result.clientId === 0 }),
        needSolutionId: new FormControl(value.needSolutionId),
        errorZahlung: new FormControl(false),
        errorVermogen: new FormControl(false),
        errorKredit: new FormControl(false),
        noSelected: new FormControl(false)
      });

    } else {
      formGroup = this.fb.group({
        id: new FormControl(0),
        needAndSolution: new FormControl(''),
        anteilZahlung: new FormControl(0),
        anteilVermogen: new FormControl(0),
        anteilKredit: new FormControl(0),
        neccessaryOwnership: new FormControl(false),
        errorZahlung: new FormControl(false),
        errorVermogen: new FormControl(false),
        errorKredit: new FormControl(false),
        noSelected: new FormControl(false)
      });

    }
    controls.push(formGroup);
    this.manageLosungControl(controls.length - 1);
  }
  getControls() {
    return (<FormArray>this.editAssignmentForm.get('assignmentArray')).controls;
  }

  getOwnershipValue(value) {
    if (value == 1) {
      return true;
    } else {
      return false;
    }
  }


  inputChangeForNeed(event) {

    (<FormArray>this.editAssignmentForm.get('assignmentArray')).controls.forEach((element: any) => {
      if (element.controls['needAndSolution'].value != null || element.controls['needAndSolution'].value != '') {
        element.controls['noSelected'].setValue(false);
        this.noNeedIsSelected = false;
      }
    });
  }

  // validations
  checkTotalValues() {
    this.KreditTotal = 0;
    this.VermogenTotal = 0;
    this.ZahlungTotal = 0;
    this.assignmentArray.value.forEach(element => {
      this.KreditTotal = element.anteilKredit + this.KreditTotal;
      this.VermogenTotal = element.anteilVermogen + this.VermogenTotal;
      this.ZahlungTotal = element.anteilZahlung + this.ZahlungTotal;
    });
    if (this.KreditTotal > 100 || this.VermogenTotal > 100 || this.ZahlungTotal > 100) {
      this.exceeds100 = true;
    } else {
      this.exceeds100 = false;
    }
    this.resetPreviousErrorValue();

  }
  resetPreviousErrorValue() {
    (<FormArray>this.editAssignmentForm.get('assignmentArray')).controls.forEach((element: any) => {
      if (!this.noValue(element.controls['anteilZahlung'].value) ||
        !this.noValue(element.controls['anteilVermogen'].value) || !this.noValue(element.controls['anteilKredit'].value)) {
        element.controls['errorZahlung'].setValue(false);
        element.controls['errorVermogen'].setValue(false);
        element.controls['errorKredit'].setValue(false);
        this.assetValuesNull = false;
      }
    });

  }

  addNewSolution() {
    (<FormArray>this.editAssignmentForm.get('assignmentArray')).controls.forEach((element: any) => {
      if (element.controls['needAndSolution'].value == null || element.controls['needAndSolution'].value == '') {
        element.controls['noSelected'].setValue(true);
        this.noNeedIsSelected = true;
      } else if (this.noValue(element.controls['anteilZahlung'].value)
        && this.noValue(element.controls['anteilVermogen'].value) && this.noValue(element.controls['anteilKredit'].value)) {
        element.controls['errorZahlung'].setValue(true);
        element.controls['errorVermogen'].setValue(true);
        element.controls['errorKredit'].setValue(true);
        this.assetValuesNull = true;
      } 
    });

    if (!this.noNeedIsSelected && !this.assetValuesNull && !this.exceeds100) {
      const productCombinationArray: any[] = [];
      this.assignmentArray.value.forEach(element => {
        productCombinationArray.push(this.setProductCombinition(element));
      });
      this.editProductCombinationObject.productName = this.produktEditForm.value.Produktname;
      this.editProductCombinationObject.productCombinationNeedSolutions = productCombinationArray;
      this.productCombinationService.editProduktCombination(this.id, this.editProductCombinationObject).subscribe((response: any) => {
        this.router.navigateByUrl('bv-home/product-classification/true');
        this.commonUtils.successSnackBar(this.translateService.instant('produktCombinationsAdded'));
      }, (err) => {
        this.commonUtils.errorSnackBar('error', null);
      });
    }

  }

  deleteProductCombination(index) {
    this.noNeedIsSelected = this.assetValuesNull = this.exceeds100 = false;
    (<FormArray>this.editAssignmentForm.get('assignmentArray')).removeAt(index);
  }

  disableSaveButton() {
    // if(this.exceeds100)
    return this.exceeds100;
  }

  ifNoSelected(needSelect) {
    this.assignmentArray.value.forEach(element => {
      if (element.needAndSolution == null || element.needAndSolution == '') {
        return true;
      }
    });
  }
  noValue(value) {
    if (value === null || value === undefined || value === '') {
      return true;
    }
  }
  setProductCombinition(value) {
    const productKombination = new produktCombination();
    productKombination.id = value.id;
    productKombination.needSolutionId = this.getSolutionId(value);
    productKombination.productCombinationId = this.id;
    productKombination.percentDebit = value.anteilKredit || 0;
    productKombination.percentCashflow = value.anteilZahlung || 0;
    productKombination.percentCredit = value.anteilVermogen || 0;
    productKombination.estateStatus = value.neccessaryOwnership  ? 1 : 0;
    return productKombination;
  }


  getSolutionId(value) {
    let needSolutionValue;
    if (value.needAndSolution) {
      needSolutionValue = value.needAndSolution.trim();
    }
    let sendId: any;
    this.needAndSolutionsArray.forEach(element => {
      element.needSolutions.forEach(solution => {
        const solutionName = solution.needSolutionName.trim();

        if (needSolutionValue == solutionName) {
          sendId = solution.needSolutionId;
        }
      });
    });

    return sendId || value.needSolutionId;
  }

  navigateBackToProduktList() {
    if (this.editProductCombinationModel.Result.clientId === 0) {
      this.router.navigateByUrl('bv-home/product-classification/true');
    }
    const change = this.checkIfAnyChangeInData();
    if (change == 0) {
      this.router.navigateByUrl('bv-home/product-classification/true');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/product-classification/true');
        }
      });
    }
  }

  checkIfAnyChangeInData() {

    let changeCount: any = 0;
    const initialArray = this.editProductCombinationModel.Result.productCombinationNeedSolutionViewModels;
    const productCombinationArray: any[] = [];
    this.assignmentArray.value.forEach(element => {
      productCombinationArray.push(this.setProductCombinition(element));
    });

    if (productCombinationArray.length !== initialArray.length) {
      return 1;
    }
    productCombinationArray.forEach(combination => {
      if (combination.id) {

        initialArray.forEach(element => {
          if (element.id == combination.id) {
            combination.percentDebit !== element.anteilKredit ? changeCount++ : null;
            combination.percentCashflow !== element.anteilZahlung ? changeCount++ : null;
            combination.percentCredit !== element.anteilVermogen ? changeCount++ : null;
          }
        });

      } else {
        // newly added
        changeCount++;
      }
    });
    return changeCount;

  }
}
export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter((item: any) => item.needSolutionName.toLowerCase().includes(filterValue));
};

export class produktCombination {
  'id': number;
  'productCombinationId': number;
  'needSolutionId': number;
  'percentCashflow': number;
  'percentCredit': number;
  'percentDebit': number;
  'estateStatus': number;
}
