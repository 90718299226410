import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PotentialAnalyseService } from 'src/app/benutzer-verwaltung/v-musterkunde/services/potential-analyse.service';
import { PROFITCENTER_TYPES } from 'src/app/app.config';
import { DeleteConsultantDialogComponent } from '../delete-consultant-dialog/delete-consultant-dialog.component';
import { Router } from '@angular/router';
import { SalesOrganisationService } from '../../service/sales-organisation.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { KalkulationService } from 'src/app/benutzer-verwaltung/data-transfer-center/services/kalkulation.service';
import { DeleteConsultantOrganizationDialogComponent } from '../delete-consultant-organization-dialog/delete-consultant-organization-dialog.component';
import { DeadlineHierarchiesService } from 'src/app/shared/services/deadline-hierarchies.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Component({
  selector: 'app-consultant-orgnaization-tab',
  templateUrl: './consultant-orgnaization-tab.component.html',
  styleUrls: ['./consultant-orgnaization-tab.component.scss']
})
export class ConsultantOrgnaizationTabComponent implements OnInit {
  @Output() spinnerEvent = new EventEmitter<boolean>();
  isBusy: boolean = true;
  beraterOrganisation: any;
  expansionState = [];
  elementToBeRemoved = [];
  deadlines = [];
  selectedDeadline: any;
  hierarchies = [];
  selectedHierarchy: any;

  constructor(
    private potentialAnalysisService: PotentialAnalyseService,
    private router: Router,
    private salesOrganisationService: SalesOrganisationService,
    private dialog: MatDialog,
    private commonUtils: CommonUtilsService,
    private kalkulationService: KalkulationService,
    private matDialog: MatDialog,
    private deadlineHierarchiesService: DeadlineHierarchiesService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.expansionState = this.commonUtils.getVetribsOrganisationStoreState();
    this.getAllDeadlines();
    //this.getProfitCenter();
  }

  getAllDeadlines() {
    this.kalkulationService.getAllDeadlines().subscribe((res: any) => {
      if (res.Result) {
        this.deadlines = res.Result;
        if (this.deadlines.length > 0) {
          this.deadlines.sort((one, two) => (new Date(one.deadlineDate).getTime() > new Date(two.deadlineDate).getTime() ? -1 : 1));
          this.selectedDeadline = this.deadlineHierarchiesService.getSelectedDeadline(this.deadlines);
          this.getHierarchiesByDeadline(this.selectedDeadline);
        } else {
          this.sendMessage();
        }
      }
    });
  }

  getHierarchiesByDeadline(deadline) {
    this.kalkulationService.getHierarchiesByDeadline(deadline.deadlineDate).subscribe((res: any) => {
      if (res.Result) {
        this.hierarchies = res.Result;
        if (this.hierarchies.length > 0) {
          this.selectedHierarchy = this.deadlineHierarchiesService.getSelectedHierarchy(this.hierarchies);
          this.getProfitCenterByHierarchyId(this.selectedHierarchy.id);
        } else {
          this.sendMessage();
        }
      }
    });
  }

  
  getProfitCenter() {
    this.potentialAnalysisService.getProfitCenter().subscribe((response: any) => {
      this.beraterOrganisation = response.Result;
      this.sendMessage();
      this.arrangeConsultantInAlphabeticalOrder(this.beraterOrganisation);
      this.setExpansionState(this.beraterOrganisation);
      this.expansionState.forEach(states => {
        this.setPreviousStateOfVetribsOrganisation(this.beraterOrganisation, states)
      });
      this.expansionState = [];
    });
  }

  getProfitCenterByHierarchyId(orgaStructHierarchyId) {
    this.potentialAnalysisService.getProfitCenterByHierarchyId(orgaStructHierarchyId).subscribe((response: any) => {
      this.beraterOrganisation = response.Result;
      this.sendMessage();
      this.arrangeConsultantInAlphabeticalOrder(this.beraterOrganisation);
      this.setExpansionState(this.beraterOrganisation);
      this.expansionState.forEach(states => {
        this.setPreviousStateOfVetribsOrganisation(this.beraterOrganisation, states)
      });
      this.expansionState = [];
    },
    (error: any) => {
      this.sendMessage();
    });
  }

  sendMessage() {
    this.isBusy = false;
    this.spinnerEvent.emit(false)
  }

  arrangeConsultantInAlphabeticalOrder(list) {
    for (const currentNode of list) {
      currentNode.consultants.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      if (currentNode.childProfitCenter.length > 0) {
        this.arrangeConsultantInAlphabeticalOrder(currentNode.childProfitCenter);

      }
    }
  }

  setExpansionState(beraterList) {
    beraterList.forEach((list) => {
      list.expanded = false;
      if (list.childProfitCenter) {
        this.setExpansionState(list.childProfitCenter);
      }
    })
  }

  setPreviousStateOfVetribsOrganisation(list, states) {
    list.forEach(element => {
      if (element.id == states.id) {
        element.expanded = states.state
      }
      if (element.childProfitCenter) {
        this.setPreviousStateOfVetribsOrganisation(element.childProfitCenter, states)
      }
    });
  }

  getType(id) {
    if (id == undefined || id == null) return "";
    const type = PROFITCENTER_TYPES.filter(p => p.id == id)[0];
    return type ? `(${type.name})` : null;
  }

  // addProfitCenter(id) {
  //   this.router.navigateByUrl(`bv-home/add-profitcenter/false/${id}`);
  // }
  // editProfitCenter(id) {
  //   this.router.navigateByUrl(`bv-home/edit-profitcenter/true/${id}`);
  // }

  // addConsultants(id) {
  //   this.router.navigateByUrl(`bv-home/add-consultant/false/${id}`);
  // }
  // editConsultants(id) {
  //   this.router.navigateByUrl(`bv-home/add-consultant/true/${id}`);
  // }

  addProfitCenter(id) {
    this.router.navigateByUrl(`bv-home/add-profitcenter/false/${id};orgaStructId=${this.selectedHierarchy.id};deadline=${this.selectedDeadline.deadlineDate}`);
  }
  editProfitCenter(id) {
    this.router.navigateByUrl(`bv-home/edit-profitcenter/true/${id};orgaStructId=${this.selectedHierarchy.id};deadline=${this.selectedDeadline.deadlineDate}`);
  }

  addConsultants(id) {
    this.router.navigateByUrl(`bv-home/add-consultant/false/${id};orgaStructId=${this.selectedHierarchy.id};deadline=${this.selectedDeadline.deadlineDate}`);
  }
  editConsultants(id) {
    this.router.navigateByUrl(`bv-home/add-consultant/true/${id};orgaStructId=${this.selectedHierarchy.id};deadline=${this.selectedDeadline.deadlineDate}`);
  }

  deleteConsultants(consultant) {

    const dialogRef = this.dialog.open(DeleteConsultantDialogComponent, {
      width: '350px',
      data: {
        object: consultant
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.salesOrganisationService.deleteConsultantsById(consultant.id).subscribe((res => {
          this.findConsultantAndRemove(consultant.id, this.beraterOrganisation);
        }),err => {
          this.commonUtils.errorSnackBar('error', 'Damit der Berater gelöscht werden kann, dürfen keine Kunden mehr zum Berater existieren.');
        });
      }
    });


  }

  deleteProfitcenter(data) {
    data.name = data.description;
    const dialogRef = this.dialog.open(DeleteConsultantDialogComponent, {
      width: '350px',
      data: {
        object: data
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.findProfitCenterAndRemoveIfNoChild(data.id, this.beraterOrganisation);

      }
    });
  }

  findConsultantAndRemove(id, list) {
    for (const element of list) {
      if (element.childProfitCenter.length > 0) {
        const temp = element.consultants.filter(c => c.id != id);
        element.consultants = temp;
        this.findConsultantAndRemove(id, element.childProfitCenter);
      } else {
        const temp = element.consultants.filter(c => c.id != id);
        element.consultants = temp;
      }
    }
  }

  findProfitCenterAndRemoveIfNoChild(id, list) {
    for (const element of list) {
      if (element.childProfitCenter.length > 0) {
        // delete if no child
        element.childProfitCenter.forEach(c => {
          if (c.id === id) {
            if (c.childProfitCenter.length > 0 || c.consultants.length > 0) {
              // error
              this.commonUtils.errorSnackBar('error', 'Damit das Profitcenter gelöscht werden kann, müssen alle untergeordneten Knoten entfernt werden.');
            } else {
              this.salesOrganisationService.deleteProfitcenter(id, false).subscribe(res => {
                const temp = element.childProfitCenter.filter(c => c.id != id);
                element.childProfitCenter = temp;
              });
            }
          }
        });
        this.findProfitCenterAndRemoveIfNoChild(id, element.childProfitCenter);
      }
    }
  }

  storeState(data) {
    data.expanded = !data.expanded;
    this.storeExpansionStateBeforeNavigation(this.beraterOrganisation);
    this.deleteClosedExpansionState();
    this.elementToBeRemoved = [];

    this.commonUtils.setVetribsOrganisationState(this.expansionState);

  }

  storeExpansionStateBeforeNavigation(beraterList) {
    beraterList.forEach(list => {
      if (!this.expansionState.find(state => state.id === list.id)) {
        let expandedState = new ExpandedState();
        expandedState.id = list.id;
        expandedState.state = list.expanded;
        this.expansionState.push(expandedState);
      } else {
        this.expansionState.find(element => element.id == list.id).state = list.expanded;

      }

      if (list.childProfitCenter.length != 0) {
        this.storeExpansionStateBeforeNavigation(list.childProfitCenter);
      }
    })

  }

  deleteClosedExpansionState() {

    for (let i = 0; i < this.expansionState.length; i++) {
      let obj = this.expansionState[i];
      if (this.elementToBeRemoved.indexOf(obj.id) !== -1) {
        this.elementToBeRemoved.splice(i, 1);
        i--;
      }
    }

  }


  onDeadlineSelectionChanged(deadline) {
    this.isBusy = true;
    this.spinnerEvent.emit(true);
    this.beraterOrganisation = [];
    this.hierarchies = [];
    this.deadlineHierarchiesService.setSelectedDeadline(this.selectedDeadline);
    this.getHierarchiesByDeadline(deadline);
  }

  onHierarchySelectionChanged(hierarchy) {
    this.isBusy = true;
    this.spinnerEvent.emit(true);
    this.beraterOrganisation = [];
    this.deadlineHierarchiesService.setSelectedHierarchy(hierarchy);
    this.getProfitCenterByHierarchyId(hierarchy.id);
  }

  duplicateOrgaStruct() {
    this.router.navigateByUrl(`bv-home/duplicate-consultant-organization/${this.selectedDeadline.deadlineDate};orgaStructId=${this.selectedHierarchy.id}`);
  }

  editHierarchyName(id) {
    this.router.navigateByUrl(`bv-home/edit-hierarchy/${id}`);
  }

  deleteOrgaStruct(data) {
    data.name = data.description;
    const dialogRef = this.matDialog.open(DeleteConsultantOrganizationDialogComponent, {
      width: '350px',
      data: {
        object: data
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.salesOrganisationService.deleteOrganizationStructureHierarchy(data.id).subscribe((res => {
          this.beraterOrganisation = [];
          this.hierarchies = [];
          this.sessionStorageService.remove(this.deadlineHierarchiesService.selectedHierarchyKey);
          this.sessionStorageService.remove(this.deadlineHierarchiesService.selectedDeadlineKey);
          this.getAllDeadlines();
        }),err => {
          this.commonUtils.errorSnackBar('error', 'Ein Fehler ist beim Löschen der Hierachie aufgetreten.');
        });
      }
    });
  }
}

export class ExpandedState {
  id: any;
  state: any;
}
