import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { CommonUtilsService } from '../../../shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class AssetClassesService {
  environment: any;
  endpoint: any;
  clientId: any;

  createHeaderObject() {
    const headerObj = this.commonUtils.getHeaderObject();
    return headerObj;
  }
  constructor(private http: HttpClient,
              private commonUtils: CommonUtilsService,
              private configurationservice: ConfigurationService,
              private userSessionService: UserSessionService) {
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/AssetClasses`;
    this.clientId = userSessionService.getClientId().toString();
  }


  getAssetClasses() {
    const url = this.endpoint;
    return this.http.get(url);
  }
  getAssetClassesById(id) {
    const url = `${this.endpoint}/${id}`;
    return this.http.get(url);
  }
  editAssetClasses(id, data) {
    const url = `${this.endpoint}/${id}`;
    return this.http.put(url, data);
  }
  resetAssetClasses(id) {
    const url = `${this.endpoint}/Reset/${id}`;
    return this.http.get(url);
  }
}
