import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SALES_ORGANIZATION_TYPE } from 'src/app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { SALES_ORGANIZATION } from 'src/app/tests/sales-organization-list';

@Component({
  selector: 'app-vertriebs-organization-delete',
  templateUrl: './vertriebs-organization-delete.component.html',
  styleUrls: ['./vertriebs-organization-delete.component.scss']
})
export class VertriebsOrganizationDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VertriebsOrganizationDeleteComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  type: any;
  label: any;
  listSubNode: any[] = [];
  nodeName: any;
  officeId: any;
  bankId: any;
  regionId: any;
  telimarktId: any;
  competenceId: any;
  teamId: any;
  salesOrganisationType = SALES_ORGANIZATION_TYPE;
  salesOrganisation: any;
  consultantClientId: any;
  officeName: any;
  consultantName: any;
  ngOnInit() {
    this.regionId = this.data.regionId;
    this.type = this.data.type;
    this.telimarktId = this.data.telimarktId;
    this.competenceId = this.data.competenceId;
    this.teamId = this.data.teamId;
    this.bankId = this.data.bankId;
    this.salesOrganisation = this.data.object;
    this.officeName = this.data.officeName;
    this.consultantName = this.data.consultantName;
    this.getNodeName();
  }

  getRegionNode() {
    this.salesOrganisation.forEach(organisation => {
      if (this.bankId == organisation.id) {
        organisation.creditInstitutionRegions.forEach(region => {
          if (region.id == this.regionId) {
            this.nodeName = this.translate.instant('region') + ' "' + region.name + '"';
            region.creditInstitutionDepartments.forEach(department => {
              this.listSubNode.push('Zweigstelle ' + '"' + department.name + '"');
            });
          }
        });
      }
    });
  }

  getTeliMarket() {
    this.salesOrganisation.forEach(organisation => {
      if (this.bankId == organisation.id) {
        organisation.creditInstitutionPartMarkets.forEach(partMarket => {
          if (partMarket.id == this.telimarktId) {
            this.nodeName = 'Teilmarkt' + ' "' + partMarket.name + '"';
            partMarket.creditInstitutionRegions.forEach(region => {
              this.listSubNode.push('Kompetenzcenter ' + '"' + region.name + '"');

            });
          }
        });
      }
    });
  }

  getKreditInstituteNode() {
    this.salesOrganisation.forEach(organisation => {
      if (this.bankId == organisation.id) {
        this.nodeName = this.translate.instant('creditInstitute') + ' "' + organisation.name + '"';
        organisation.creditInstitutionRegions.forEach(region => {
          this.listSubNode.push('Region ' + '"' + region.name + '"');

        });
      }
    });
  }

  getCompetenceNode() {
    this.salesOrganisation.forEach(element => {
      if (element.id == this.bankId) {
        element.creditInstitutionPartMarkets.forEach(partMarket => {
          if (partMarket.id == this.telimarktId) {
            partMarket.creditInstitutionRegions.forEach(competence => {
              if (competence.id == this.competenceId) {
                this.nodeName = this.translate.instant('Kompetenzcenter') + ' "' + competence.name + '"';
              }
              competence.creditInstitutionDepartments.forEach(team => {
                this.listSubNode.push('Team ' + '"' + team.name + '"');

              });
            });
          }
        });
      }
    });
  }

  getTeamNode() {
    this.salesOrganisation.forEach(element => {
      if (element.id == this.bankId) {
        element.creditInstitutionPartMarkets.forEach(partMarket => {
          if (partMarket.id == this.telimarktId) {
            partMarket.creditInstitutionRegions.forEach(competence => {
              if (competence.id == this.competenceId) {
                competence.creditInstitutionDepartments.forEach(team => {
                  if (team.id == this.teamId) {
                    this.nodeName = 'Team' + ' "' + team.name + '"';
                  }
                  team.consultants.forEach(consultant => {
                    this.listSubNode.push('Berater ' + '"' + consultant.name + '"');
                  });
                });
              }
            });
          }
        });
      }
    });
  }

  getOfficeName() {
    this.nodeName = this.translate.instant('office') + ' "' + this.officeName + '"';

  }
  getConsultantName() {
    this.nodeName = this.translate.instant('consultant') + ' "' + this.consultantName + '"';

  }
  getNodeName() {
    if (this.data.type == SALES_ORGANIZATION_TYPE.BANK.value) {
      this.getKreditInstituteNode();
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.REGION.value) {
      this.getRegionNode();
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.OFFICE.value) {
      this.getOfficeName();
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.TELIMARKT.value) {
      this.getTeliMarket();
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.KOMPETENZZENTER.value) {
      this.getCompetenceNode();
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.TEAM.value) {
      this.getTeamNode();
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.BERATER.value) {
      this.getConsultantName();
    }
  }



  getNodeName1() {
    const saleOrganisation = SALES_ORGANIZATION;
    if (this.data.type == SALES_ORGANIZATION_TYPE.BANK.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          this.nodeName = this.translate.instant('creditInstitute') + ' "' + bank.bankName + '"';
          bank.region.forEach(region => {
            this.listSubNode.push('Region ' + '"' + region.regionName + '"');
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.REGION.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          bank.region.forEach(region => {
            if (region.id == this.regionId) {
              this.nodeName = this.translate.instant('region') + ' "' + region.regionName + '"';
              region.office.forEach(office => {
                this.listSubNode.push('Zweigstelle ' + '"' + office.officeName + '"');
              });
            }
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.OFFICE.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          bank.region.forEach(region => {
            if (region.id == this.regionId) {
              region.office.forEach(office => {
                if (this.officeId == office.id) {
                  this.nodeName = this.translate.instant('office') + ' "' + office.officeName + '"';
                }
              });
            }
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.CONSULTANT_BANK.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          this.nodeName = this.translate.instant('creditInstitute') + ' "' + bank.bankName + '"';
          bank.partMarket.forEach(partMrkt => {
            this.listSubNode.push('Teilmarkt ' + '"' + partMrkt.partMarketName + '"');
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.TELIMARKT.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          bank.partMarket.forEach(partMrkt => {
            if (partMrkt.id == this.telimarktId) {
              this.nodeName = 'Teilmarkt' + ' "' + partMrkt.partMarketName + '"';
              partMrkt.competenceCenter.forEach(competence => {
                this.listSubNode.push('Kompetenzcenter ' + '"' + competence.competenceName + '"');

              });
            }
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.KOMPETENZZENTER.value) {

      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          bank.partMarket.forEach(partMarket => {
            if (partMarket.id == this.telimarktId) {
              partMarket.competenceCenter.forEach(competence => {
                if (competence.id == this.competenceId) {
                  this.nodeName = 'Kompetenzcenter' + ' "' + competence.competenceName + '"';
                  competence.team.forEach(team => {
                    this.listSubNode.push('Team ' + '"' + team.teamName + '"');
                  });
                }
              });
            }
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.TEAM.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          bank.partMarket.forEach(partMarket => {
            if (partMarket.id == this.telimarktId) {
              partMarket.competenceCenter.forEach(competence => {
                if (competence.id == this.competenceId) {
                  competence.team.forEach(team => {
                    if (team.id == this.teamId) {
                      this.nodeName = 'Team' + ' "' + team.teamName + '"';
                      team.consultant.forEach(consultant => {
                        this.listSubNode.push('Berater ' + '"' + consultant.consultantName + '"');
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    } else if (this.data.type == SALES_ORGANIZATION_TYPE.BERATER.value) {
      saleOrganisation.forEach(bank => {
        if (bank.id == this.bankId) {
          bank.partMarket.forEach(partMarket => {
            if (partMarket.id == this.telimarktId) {
              partMarket.competenceCenter.forEach(competence => {
                if (competence.id == this.competenceId) {
                  competence.team.forEach(team => {
                    if (team.id == this.teamId) {
                      team.consultant.forEach(consultant => {
                        if (this.consultantClientId == consultant.id) {
                          this.nodeName = 'Berater' + ' "' + consultant.consultantName + '"';
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
  delete() {
    this.dialogRef.close(true);
  }
}
