import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KalkulationService } from 'src/app/benutzer-verwaltung/data-transfer-center/services/kalkulation.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { DeadlineHierarchiesService } from 'src/app/shared/services/deadline-hierarchies.service';
import { ConsultantSegmentService } from '../../service/consultant-segment.service';
import { DeleteConsultantSegmentDialogComponent } from '../delete-consultant-segment-dialog/delete-consultant-segment-dialog.component';

@Component({
  selector: 'app-consultant-segments-list',
  templateUrl: './consultant-segments-list.component.html',
  styleUrls: ['./consultant-segments-list.component.scss']
})
export class ConsultantSegmentsListComponent implements OnInit {
  
  displayedColumns: string[] = ['consultantSegment', ' '];
  dataSource = new MatTableDataSource();
  spinnerFlag = true;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  consultantSegments: any[] = [];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  totalCount: any;

  deadlines = [];
  selectedDeadline: any;
  hierarchies = [];
  selectedHierarchy: any;
  
  constructor(
    private router: Router,
    private consultantSegmentService: ConsultantSegmentService,
    private commonUtils: CommonUtilsService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private kalkulationService: KalkulationService,
    private deadlineHierarchiesService: DeadlineHierarchiesService
    ) { }

  ngOnInit(): void {
    this.spinnerFlag = true;
    this.getAllDeadlines();
    
  }

  getAllDeadlines() {
    this.kalkulationService.getAllDeadlines().subscribe((res: any) => {
      if (res.Result) {
        this.deadlines = res.Result;
        if (this.deadlines.length > 0) {
          this.deadlines.sort((one, two) => (new Date(one.deadlineDate).getTime() > new Date(two.deadlineDate).getTime() ? -1 : 1));
          this.selectedDeadline = this.deadlineHierarchiesService.getSelectedDeadline(this.deadlines);
          this.getHierarchiesByDeadline(this.selectedDeadline);
        } else {
          this.spinnerFlag = false;
        }
      }
    });
  }

  getHierarchiesByDeadline(deadline) {
    this.kalkulationService.getHierarchiesByDeadline(deadline.deadlineDate).subscribe((res: any) => {
      if (res.Result) {
        this.hierarchies = res.Result;
        if (this.hierarchies.length > 0) {
          this.selectedHierarchy = this.deadlineHierarchiesService.getSelectedHierarchy(this.hierarchies);
          this.getConsultantSegmentsByHierarchyId(this.selectedHierarchy.id);
        } else {
          this.spinnerFlag = false;
        }
      }
    });
  }

  getConsultantSegmentsByHierarchyId(orgaStructHierarchyId) {
    this.consultantSegmentService.getAllConsultantSegmentsByHierarchyId(orgaStructHierarchyId).subscribe((Response: any) => {
      this.consultantSegments = Response.Result;
      this.dataSource = new MatTableDataSource(this.consultantSegments);
      this.totalCount = this.consultantSegments.length;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinnerFlag = false;

    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });
  }

  navigateToAddConsultantSegment() {
    this.router.navigate(['bv-home/add-consultant-segments',this.selectedHierarchy.id, this.selectedDeadline.deadlineDate]);
  }

  navigateToEditConsultantSegment(id) {
    this.router.navigate(['bv-home/edit-consultant-segments', id, this.selectedHierarchy.id, this.selectedDeadline.deadlineDate]);

  }

  

  deleteConsultantSegment(id, place) {
    this.consultantSegmentService.getCanDeleteConsultantSegment(id).subscribe((response: any) => {
      if (response.Result == "false") {
        this.commonUtils.errorSnackBar('error', this.translateService.instant('consultantSegmentNoDeleteHasAssignedConsultants'));
      } else {
        const dialogRef = this.matDialog.open(DeleteConsultantSegmentDialogComponent, {
          width: '400px',
          data: { place: place }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.consultantSegmentService.deleteConsultantSegment(id).subscribe((response: any) => {
              const temp = this.dataSource.filteredData.filter((data: any) => data.id != id);
              this.dataSource = new MatTableDataSource(temp);
              this.commonUtils.successSnackBar(this.translateService.instant('consultantSegmentDeleted'));
            }, (err) => {
              this.commonUtils.errorSnackBar('error', err.message);
            });
          }
        });
      }
      
    }, (err) => {
      this.commonUtils.errorSnackBar('error', err.message);
    });
  }

  onDeadlineSelectionChanged(deadline) {
    this.spinnerFlag = true;
    this.consultantSegments = [];
    this.dataSource = new MatTableDataSource();
    this.hierarchies = [];
    this.deadlineHierarchiesService.setSelectedDeadline(this.selectedDeadline);
    this.getHierarchiesByDeadline(deadline);
  }

  onHierarchySelectionChanged(hierarchy) {
    this.spinnerFlag = true;
    this.consultantSegments = [];
    this.dataSource = new MatTableDataSource();
    this.deadlineHierarchiesService.setSelectedHierarchy(hierarchy);
    this.getConsultantSegmentsByHierarchyId(hierarchy.id);
  }

}
