import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { UserProfileService } from 'src/app/user-profile/services/user-profile.service';
import { BV_SIDEBAR_STATUS, PREVIOUS_ROUTE, SCOPE_TABS } from 'src/app/app.config';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { PotentialAnalyseService } from '../../v-musterkunde/services/potential-analyse.service';
import { DataImportService } from '../../data-transfer-center/services/data-import.service';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';
import { BenutzerService } from '../../einstellugen/service/benutzer.service';

@Component({
  selector: 'app-bv-home',
  templateUrl: './bv-home.component.html',
  styleUrls: ['./bv-home.component.scss'],
})
export class BvHomeComponent implements OnInit {
  selectBenutzer: boolean;
  selectServiceZugriff: boolean;
  selectDataImport: boolean;
  selectEinstellungen: boolean;
  selectBankActivities: boolean;
  selectDataTransfer: boolean;
  selectKalkulation: boolean;
  selectbussinessAreas: boolean;
  selectSalesOrganization: boolean;
  selectConsultantStructure: boolean;
  selectConsultantSegments: boolean;
  sidebarToggle = false;
  selectConsultatNeedAndSolution: boolean;
  selectMusterkundeCustomerGroup: boolean;
  client_permissions: any[] = [];
  selectAssetClasses: boolean;
  selectBookongForm: boolean;
  selectProductClassification: boolean;
  selectVrMusterkunde: boolean;
  selectPopentialAnaylis: boolean;
  selectParameter: boolean;
  selectCustomerScaffolding: boolean;
  selectQualityReport: boolean;
  selectVrActivityMngr: boolean;
  selectMarketingFabrik: boolean;
  noCalculation = true;
  selectDataexport: boolean;
  selectIdaBerichte: boolean;
  selectDokumentation: boolean;
  currentHost;
  // navParams
  navigationExtras: NavigationExtras;

  // interval
  intervalEvent: any;
  subject: any;
  SCOPE_TABS = SCOPE_TABS;
  selectCalculationVariables: boolean;
  selectBankBills: boolean;
  selectManageBanks: boolean;
  userDashboardProfile: any;
  isVrcpAdmin: boolean = false;
  userRoles: any[] = [];
  accesibleTabs: any[] = [];

  constructor(public router: Router,
              private userProfileService: UserProfileService,
              private commonUtils: CommonUtilsService,
              private benutzerService: BenutzerService,
              public potentialanalysisService: PotentialAnalyseService,
              private dataImportService: DataImportService,
              private localStorageService: LocalStorageService) {

    // set initial sidebar status
    BV_SIDEBAR_STATUS.status = this.sidebarToggle;

    // navigate by svg
    if (this.hasNewPermission('VR Musterkundenansatz')) {
      this.subject = this.commonUtils.getSvgNavigation().subscribe(Result => {
        if (Result === 'ActivityManager') {
          this.navigateToActivityManager();
        } else if (Result === 'MarketingFabrik') {
          this.navigateToMarketingFabrik();
        } else if (Result === 'vrMusterkunde') {
          this.showVrMusterkunde();
        }
      });
    }
  }

  async ngOnInit() {
    this.currentHost = window.location.hostname;
    await this.getUserRoles();
  }

  async getUserRoles() {
      this.userDashboardProfile = await this.benutzerService.getDashboardProfile()
      this.userRoles = this.userDashboardProfile.ClaimGroups;
      this.localStorageService.set("userRoles", this.userRoles)
      if (this.userRoles) {
        this.isVrcpAdmin = this.userRoles.filter(group => group === "VRCP-Admin").length > 0;
        if(!this.isVrcpAdmin) {
          this.userRoles.forEach(role => {
            if (this.SCOPE_TABS[role]) {
              this.accesibleTabs = [...this.accesibleTabs, ...this.SCOPE_TABS[role]]
            }
          })
          this.accesibleTabs = [...new Set([...this.accesibleTabs, ...this.SCOPE_TABS["CommonRoutes"]])];
        }
        if (this.userRoles.length == 1 && this.userRoles[0] == "Revisor") {
          this.showDataTransfer();
          this.navigateToDateExport();
        } else if (this.accesibleTabs.indexOf('VR Musterkundenansatz') == -1 && !(this.accesibleTabs.length == 0)) {
          this.navigateToActivityManager();
        } else if (this.accesibleTabs.length == 0 && !this.isVrcpAdmin) {
          this.router.navigate([`unathorized`]);
        } else {
          this.dataImportService.checkClientIdExistIfNotCreate().subscribe();
          if (this.hasNewPermission('VR Musterkundenansatz')) {
            // if previous route does not exist

            if (!PREVIOUS_ROUTE.allowPReviousRoutes) {
              this.getCalculationBeforeRedirect();
            } else {
              /**  if previous route exist first store calulation result and
               * check if the routes are of potentialanalysis then check the calculation result and
               * redirect according to the result
              */
              await this.storeCalculationResult();

              if (this.localStorageService.get('currentRoute').indexOf('bv-home/v-musterkunde') !== -1 ||
                this.localStorageService.get('currentRoute').indexOf('bv-home/potential-analysis-mainpage') !== -1) {
                if (this.noCalculation) {
                  this.router.navigateByUrl('bv-home/v-musterkunde');
                } else {
                  this.router.navigateByUrl('bv-home/potential-analysis-mainpage');

                }
              }
            }
          }
        }
      }
  }

  async storeCalculationResult() {
    this.potentialanalysisService.getCalculationDates().subscribe(async (response: any) => {
      await response;
      if (response.Result.length > 0) {
        this.noCalculation = false;
      } else {
        this.noCalculation = true;
      }

    });
  }

  getCalculationBeforeRedirect() {
    this.potentialanalysisService.getCalculationDates().subscribe((response: any) => {
      if (response.Result.length > 0) {
        this.navigatePotentialAnalysis();
        this.noCalculation = false;
      } else {
        this.noCalculation = true;
        this.router.navigateByUrl('bv-home/v-musterkunde');
      }
    }, (err) => {
      this.router.navigateByUrl('bv-home/v-musterkunde');

    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 1200) {
      this.sidebarToggle = true;
      BV_SIDEBAR_STATUS.status = this.sidebarToggle;
    }
  }

  navigateToUserList() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectBenutzer = true;
      //this.router.navigateByUrl('bv-home/benutzerlist');
      switch (this.currentHost) {
        case "https://dev-frontend.vr-musterkundenansatz.de":
        case "localhost":
          window.open('https://dev-ws.vr-consultingpartner.de/Administration/User', '_blank');
          break;
         default:
           window.open('https://ws.vr-consultingpartner.de/Administration/User', '_blank');
           break;
      }

    }
  }

  navigateToKalkulationVariables() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectCalculationVariables = true;

      this.router.navigateByUrl('bv-home/calculation-variable');
    }
  }
  navigateToServiceZugriff() {
    if (!this.sidebarToggle) {
      this.selectBenutzer = false;
      this.selectServiceZugriff = true;
      this.selectMarketingFabrik = false;
      this.selectCalculationVariables = false;

      this.router.navigateByUrl('bv-home/service-zugriff');
    }

  }

  navigateToDateImport() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectDataImport = true;

      this.router.navigateByUrl('bv-home/dataimport-list');
    }
  }


  navigateToDateExport() {
    if (!this.sidebarToggle) {

      this.unselectAllMenuItems();
      this.selectDataexport = true;

      this.router.navigateByUrl('bv-home/data-export-list');
    }
  }

  showEinstellugen() {
    if (!this.sidebarToggle) {
      this.selectEinstellungen = !this.selectEinstellungen;
      this.selectBenutzer = false;
      this.selectCalculationVariables = false;
      this.selectBankBills = false;
      if (this.selectEinstellungen) {
        this.selectDataTransfer = false;
        this.selectVrMusterkunde = false;
        this.selectVrActivityMngr = false;
        this.selectMarketingFabrik = false;
        // this.selectParameter = false;

      }
    }
  }

  showVrMusterkunde() {
    if (!this.sidebarToggle) {
      this.selectVrMusterkunde = !this.selectVrMusterkunde;
      if (this.selectVrMusterkunde) {
        this.selectDataTransfer = false;
        this.selectEinstellungen = false;
        this.selectParameter = false;
        this.selectVrActivityMngr = false;
        this.selectMarketingFabrik = false;
        if (this.noCalculation) {
          this.router.navigateByUrl('bv-home/v-musterkunde');
        } else {
          this.navigatePotentialAnalysis();
        }

      }
    }
  }
  showDataTransfer() {
    if (!this.sidebarToggle) {
      this.selectDataTransfer = !this.selectDataTransfer;
      if (this.selectDataTransfer) {
        this.selectEinstellungen = false;
        this.selectVrMusterkunde = false;
        this.selectParameter = false;
        this.selectVrActivityMngr = false;
        this.selectMarketingFabrik = false;

      }
    }
  }
  navigateToKalkulation() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectKalkulation = true;

      this.router.navigateByUrl('bv-home/kalkulation-list');
    }
  }
  navigateTobBussinssArea() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectbussinessAreas = true;

      this.router.navigateByUrl('bv-home/bussiness-areas');
    }
  }
  navigateToSalesOrganization() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectSalesOrganization = true;

      // navigation params added for default selection
      this.navigationExtras = {
        queryParams: {
          'consultantOrganisation': true
        }
      };
      this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);
    }
  }
  navigateToConsultantStructure() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectConsultantStructure = true;

      this.router.navigateByUrl('bv-home/consultant-structure-grid');
    }
  }
  navigateToConsultantSegments() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectConsultantSegments = true;

      this.router.navigateByUrl('bv-home/consultant-segments-list');
    }
  }
  navigateToConsultantNeedsAndSolution() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectConsultatNeedAndSolution = true;

      this.router.navigateByUrl('bv-home/consultation-needs-solution');
    }
  }

  navigateToModalCustomerGroup() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectMusterkundeCustomerGroup = true;

      this.router.navigateByUrl('bv-home/musterkunde-customer-group');
    }
  }

  navigateToAssetClasses() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectAssetClasses = true;

      this.router.navigateByUrl('bv-home/asset-classes');
    }

  }

  showMandantenverwaltung() {
    //switch (this.currentHost) {
      // case "qs-musterkunde.emnicon.de/":
      //   window.open('https://qs-musterkunde-mandantenverwaltung.emnicon.de/', '_blank');
      //   break;
      // case "localhost":
      //   window.open('https://qs-musterkunde-mandantenverwaltung.emnicon.de/', '_blank');
      //   break;
      // case 'vr-musterkundenansatz.de':
      //   window.open('https://musterkunde-mandantenverwaltung.emnicon.de/', '_blank');
      //   break;
      // case 'staging-musterkunde.emnicon.de':
      //   window.open('https://staging-musterkunde-mandantenverwaltung.emnicon.de/', '_blank');
      //   break;
      // default:
      //   window.open('https://musterkunde-mandantenverwaltung.emnicon.de/', '_blank');
      //   break;
      //  default:
      //    window.open('http://vrcp-srv-web03.northeurope.cloudapp.azure.com', '_blank');
      //    break;
    //}

    switch (this.currentHost) {
      case "dev-frontend.vr-musterkundenansatz.de":
      case "localhost":
        window.open('https://dev-ws.vr-consultingpartner.de/Administration/Bank', '_blank');
        break;
       default:
         window.open('https://ws.vr-consultingpartner.de/Administration/Bank', '_blank');
         break;
    }
  }

  clickHomeButton(){
    switch (this.currentHost) {
      case "dev-frontend.vr-musterkundenansatz.de":
      case "localhost":
        window.open('https://dev-ws.vr-consultingpartner.de');
        break;
      default:
        window.open('https://ws.vr-consultingpartner.de');
        break;
    }
  }

  navigateToBookingForm() {
    if (!this.sidebarToggle) {
      this.unselectAllMenuItems();
      this.selectBookongForm = true;

      this.router.navigateByUrl('bv-home/booking-forms');
    }
  }

  navigateToProductClassification() {
    if (!this.sidebarToggle) {

      this.selectbussinessAreas = false;
      this.selectSalesOrganization = false;
      this.selectConsultantSegments = false;
      this.selectConsultatNeedAndSolution = false;
      this.selectMusterkundeCustomerGroup = false;
      this.selectAssetClasses = false;
      this.selectProductClassification = true;
      this.router.navigateByUrl('bv-home/product-classification/false');
    }
  }


  navigatePotentialAnalysis() {
    if (!this.sidebarToggle) {
      this.unselectMusterkundeMenuItems();
      this.unselectDatentransfercenterMenuItems();
      this.unselectParameterSubMenuItems();
      this.unselectEinstellungenSubMenuItems();

      // Can be used to close Sections
      // this.selectParameter = false;
      // this.selectEinstellungen = false;

      this.selectPopentialAnaylis = true;

      this.router.navigateByUrl('bv-home/potential-analysis-mainpage');
    }
  }
  navigateToCustomerScaffolding() {
    if (!this.sidebarToggle) {
      this.unselectMusterkundeMenuItems();
      this.unselectDatentransfercenterMenuItems();
      this.unselectParameterSubMenuItems();
      this.unselectEinstellungenSubMenuItems();

      // Can be used to close Sections
      // this.selectParameter = false;
      // this.selectEinstellungen = false;

      this.selectCustomerScaffolding = true;


      this.navigationExtras = {
        queryParams: {
          'type': 'customerScaffolding'
        }
      };
      this.router.navigate(['bv-home/potential-analysis-mainpage'], this.navigationExtras);
    }
  }

  navigateToQualityReport() {
    if (!this.sidebarToggle) {
      this.unselectMusterkundeMenuItems();
      this.unselectDatentransfercenterMenuItems();
      this.unselectParameterSubMenuItems();
      this.unselectEinstellungenSubMenuItems();

      // Can be used to close Sections
      // this.selectParameter = false;
      // this.selectEinstellungen = false;

      this.selectQualityReport = true;

      this.navigationExtras = {
        queryParams: {
          'type': 'qualityReport'
        }
      };
      this.router.navigate(['bv-home/potential-analysis-mainpage'], this.navigationExtras);
    }
  }

  navigateToActivityManager() {
    if (!this.sidebarToggle) {
      this.selectVrActivityMngr = !this.selectVrActivityMngr;
      if (this.selectVrActivityMngr) {
        this.selectEinstellungen = false;
        this.selectVrMusterkunde = false;
        this.selectParameter = false;
        this.selectMarketingFabrik = false;
        this.router.navigate(['bv-home/vr-activity-mngr-mainpage'], this.navigationExtras);
      }
    }
  }

  navigateToMarketingFabrik() {
    if (!this.sidebarToggle) {
      this.selectMarketingFabrik = !this.selectMarketingFabrik;
      if (this.selectMarketingFabrik) {
        this.selectEinstellungen = false;
        this.selectVrMusterkunde = false;
        this.selectParameter = false;
        this.selectVrActivityMngr = false;
        this.router.navigate(['bv-home/marketing-fabrik-mainpage'], this.navigationExtras);
      }
    }
  }

  navigateToBankActivities() {
    if (!this.sidebarToggle) {
      this.unselectMusterkundeMenuItems();
      this.unselectDatentransfercenterMenuItems();
      this.unselectParameterSubMenuItems();
      this.unselectEinstellungenSubMenuItems();

      this.selectBankActivities = true;

      this.router.navigate(['bv-home/bank-activities']);
    }
  }
  

  toggleSidebar() {
    this.sidebarToggle = !this.sidebarToggle;
    this.commonUtils.sendToggleStatus(this.sidebarToggle);
    BV_SIDEBAR_STATUS.status = this.sidebarToggle;
  }
  getToggleStatus() {

    return this.sidebarToggle;
  }

  hasNewPermission(path: string) {
    if(!this.isVrcpAdmin) {
      return this.accesibleTabs.includes(path);
    } else {
      return true;
    }
  }

  navigateToHome() {
    this.router.navigate([`home`]);
  }

  // parameter
  showParameter() {
    if (!this.sidebarToggle) {

      this.selectParameter = !this.selectParameter;
      if (this.selectParameter) {
        this.selectDataTransfer = false;
        this.selectVrMusterkunde = false;
        // this.selectEinstellungen = false;
        this.selectVrActivityMngr = false;
        this.selectMarketingFabrik = false;
      }
    }
  }

  unselectMusterkundeMenuItems() {
    this.selectPopentialAnaylis = false;
    this.selectCustomerScaffolding = false;
    this.selectQualityReport = false;
    this.selectBankActivities = false;
  }

  unselectDatentransfercenterMenuItems() {
    this.selectDataImport = false;
    this.selectKalkulation = false;
    this.selectDataexport = false;
    this.selectIdaBerichte = false;
    this.selectDokumentation = false;
  }

  unselectParameterSubMenuItems() {
    this.selectAssetClasses = false;
    this.selectConsultatNeedAndSolution = false;
    this.selectMusterkundeCustomerGroup = false;
    this.selectbussinessAreas = false;
    this.selectProductClassification = false;
    this.selectSalesOrganization = false;
    this.selectConsultantStructure = false;
    this.selectConsultantSegments = false;
  }

  unselectEinstellungenSubMenuItems() {
    this.selectBenutzer = false;
    this.selectBookongForm = false;
    this.selectCalculationVariables = false;
  }

  unselectAllMenuItems() {
    this.unselectMusterkundeMenuItems();
    this.unselectDatentransfercenterMenuItems();
    this.unselectParameterSubMenuItems();
    this.unselectEinstellungenSubMenuItems();
  }

}
