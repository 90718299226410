export class Benutzer {
    Id?: number;
    Email?: string;
    UserName?: string;
    Address?: string;
    City?: string;
    DateOfBirth?: string;
    FirstName?: any;
    LastName?: any;
    ZipCode?: string;
    PhoneNumber?: any;
    IsDeleted?: boolean;
    hasLoginDate?: boolean
    LastLoginDate?: string;
    LockoutEnabled?: boolean;
    EmailConfirmed?: boolean;
    UserClaims?: any[];
    Status?: any;
    Theme?: any;
    IsAdministrator?: boolean;
    Language?: any;
    ExpectedClaims?: any[] = [];
    ClientId?: string;
    roles?: any;
    GroupIds?: any[];
    AdditionalClaims?: [];
    ClaimGroups?:[]

}
