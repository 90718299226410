import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataImportService } from '../../services/data-import.service';
import { Router } from '@angular/router';
import { isThisTypeNode } from 'typescript';
import { OrgaStructImportMode } from 'src/app/shared/enums/orga-struct-import-mode.enum';

@Component({
  selector: 'app-encryption-key-dialog-box',
  templateUrl: './encryption-key-dialog-box.component.html',
  styleUrls: ['./encryption-key-dialog-box.component.scss']
})
export class EncryptionKeyDialogBoxComponent implements OnInit {

  constructor(private translate: TranslateService,
    private dataImportService: DataImportService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<EncryptionKeyDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router) { }

  hide = true;
  isKeyChecked = false;
  passwordForm: FormGroup;
  message: any;
  success: boolean;
  fileNames: any;
  keyNull = false;

  uploadedDeadline: string;
  orgaStructImportmode: OrgaStructImportMode;
  deadlineToCopyFrom: string;
  hierarchyIdsToCopy: string;

  // Button Options
  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: this.translate.instant('continue'),
    spinnerSize: 19,
    raised: true,
    stroked: true,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: true,
    mode: 'indeterminate',
  };

  ngOnInit() {
    this.fileNames = this.data.fileNameArray;
    this.uploadedDeadline = this.data.uploadedDeadline;
    this.orgaStructImportmode = this.data.orgaStructImportmode;
    this.deadlineToCopyFrom = this.data.deadlineToCopyFrom;
    this.hierarchyIdsToCopy = this.data.hierarchyIdsToCopy;

    this.passwordForm = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?['#*+~!§$%&\/\\()=?`´.,\-_<>|^°]).{8,50}$/),
        Validators.minLength(8)]))
    });
  }

  encryptionKeyInputChange() {
    if (this.passwordForm !== undefined) {
      if (this.passwordForm.value.password) {
        this.btnOpts.disabled = false;
      } else {
        this.btnOpts.disabled = true;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.passwordForm.controls[controlName].hasError(errorName);
  }

  checkHashValue() {
    if (!this.keyNull && this.passwordForm.valid && !this.btnOpts.disabled && !this.btnOpts.active) {
      this.btnOpts.active = true;

      this.dataImportService.validateTransferToDB(this.fileNames, this.passwordForm.value.password, null).subscribe((response) => {
        this.dataImportService.transferFilesToDbWithMode(this.fileNames, this.passwordForm.value.password, this.orgaStructImportmode, this.uploadedDeadline, this.deadlineToCopyFrom, this.hierarchyIdsToCopy).subscribe();
        this.success = true;
        this.btnOpts.active = false;
        this.btnOpts.disabled = true;
        this.message = this.translateService.instant('transferToDbSuccess');

        setTimeout(() => {
          this.router.navigateByUrl(`bv-home/dataimport-list`);
          this.dialogRef.close();
        }, 1000);
      }, (err) => {
        this.success = false;
        this.btnOpts.active = false;
        this.message = this.translateService.instant('transferToDbFailure');

      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
