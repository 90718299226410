<div>
  <h4 class="m-t-0" mat-dialog-title>{{'setEncryptionCode' | translate}}</h4>
  <p>{{'addNewEncryptionKeyNote' | translate}}</p>
  <div>


    <!-- <mat-progress-spinner [diameter]="25" [strokeWidth]="2"></mat-progress-spinner> -->
  </div>

  <form class="w-100 key-dialoge" [formGroup]="addEncryptionKeyForm" novalidate>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{'encryptionKey' | translate}}</mat-label>
      <input matInput placeholder="" formControlName="password" name="password" value=""
        (input)="encryptionKeyInputChange()" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

      <mat-error *ngIf="hasError('password', 'required')">{{'password' | translate}} {{'isRequired' | translate}}
      </mat-error>
      <mat-hint *ngIf="!hasError('password', 'pattern') && !hasError('password', 'required')" class="success">
        <div>{{'min8Characters' | translate}}<i class="material-icons">check</i></div>
        <div>{{'atleastOneDigit' | translate}}<i class="material-icons" style="height:10px; width:10px;">check</i></div>
        <div>{{'upperCase' | translate}} <i class="material-icons">check</i></div>
        <div>{{'lowerCaseLetters' | translate}} <i class="material-icons">check</i></div>
        <div>{{'specialCharacter' | translate}} <i class="material-icons">check</i></div>
      </mat-hint>
      <mat-hint [ngClass]="{'success' : success}">{{message}}</mat-hint>

      <mat-error *ngIf="hasError('password', 'pattern')">
        <div>{{'min8Characters' | translate}}</div>
        <div>{{'atleastOneDigit' | translate}}</div>
        <div>{{'upperCase' | translate}}</div>
        <div>{{'lowerCaseLetters' | translate}}</div>
        <div>{{'specialCharacter' | translate}}</div>
      </mat-error>

    </mat-form-field>


    <mat-form-field class="full-width" style="height: 100px!important;" appearance="outline">
      <mat-label>{{'repeatEncryptionCode' | translate}}</mat-label>
      <input matInput placeholder="" formControlName="confirmPassword" name="confirmPassword" value=""
        (input)="encryptionKeyInputChange()" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="hasError('confirmPassword','confirmPasswordValidation')">Eingabe nicht identisch </mat-error>
      <mat-hint
        *ngIf="!hasError('confirmPassword','confirmPasswordValidation') && !hasError('confirmPassword', 'required')">
        <div class="success">{{'inputIdentical' | translate}} <i class="material-icons">check</i></div>
      </mat-hint>
    </mat-form-field>


    <!-- </div>
  <div mat-dialog-actions > -->
    <div mat-dialog-actions align="end">
      <button mat-raised-button color="basic" (click)="closeDialog()">{{'stop' | translate}}</button>
      <mat-spinner-button class="m-l-10" (click)="addNewEncryptionKey()" [options]="btnOpts"></mat-spinner-button>
    </div>
  </form>
</div>
