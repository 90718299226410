import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AssetClassesService } from '../../service/asset-classes.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
@Component({
  selector: 'app-assetklassen-list',
  templateUrl: './assetklassen-list.component.html',
  styleUrls: ['./assetklassen-list.component.scss']
})
export class AssetklassenListComponent implements OnInit {
  assetClassesList: any[] = [];
  assetClasses: any;
  displayedColumns: string[] = ['name', 'Liquidität', 'Geldwerte', 'Substanzwerte', 'Sachwerte', 'Alternativ', ' '];
  dataSource: any;
  spinnerFlag = true;
  @ViewChild(MatSort, {static: false}) sort: MatSort;


  constructor(private router: Router,
    private assetClassService: AssetClassesService, private commonUtils: CommonUtilsService) {
    this.getAssetClasses();

  }
  checkIfValueModified() {
    this.assetClassesList.forEach(assetClass => {
      assetClass.first = assetClass.riskCategory.liquidityPercentage != assetClass.liquidityPercentage;
      assetClass.fifth = assetClass.riskCategory.alternativeAssetsPercentage != assetClass.shareAlternatives;
      assetClass.fourth = assetClass.riskCategory.materialAssetsPercentage != assetClass.shareAssets;
      assetClass.second = assetClass.riskCategory.monetaryAssetsPercentage != assetClass.shareMonetaryValue;
      assetClass.third = assetClass.riskCategory.substanceAssetsPercentage != assetClass.proportionSubstanceValues;
    });
  }

  getAssetClasses() {
    this.assetClassService.getAssetClasses().subscribe((response: any) => {
      this.spinnerFlag = false;
      this.assetClassesList = response.Result;
      this.assetClassesList.forEach(element => {
        element.first = false;
        element.second = false;
        element.third = false;
        element.fourth = false;
        element.fifth = false;

        element.name = element.riskCategory.description;
      });
      this.assetClassesList.sort((a, b) => +(a.name > b.name) || -(a.name < b.name));
      this.checkIfValueModified();
      this.dataSource = new MatTableDataSource(response.Result);
      this.dataSource.sort = this.sort;
    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });
  }
  ngOnInit() {
  }

  editAssetClass(id) {
    this.router.navigateByUrl(`bv-home/asset-classes-edit/${id}`);
  }

  showRefresh(assetClass) {
    if (assetClass.first || assetClass.second || assetClass.third || assetClass.forth || assetClass.fifth) {
      return true;
    }
  }

  resetValueToNationalValue(id) {
    this.assetClassService.resetAssetClasses(id).subscribe((response: any) => {
      this.getAssetClasses();
          });
  }

}
