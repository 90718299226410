import { Injectable, Injector } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { MUKU_SCOPE, PREVIOUS_ROUTE, SUPER_ADMIN } from 'src/app/app.config';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedUserDataService } from './shared-user-data.service';
import { UserProfileService } from 'src/app/user-profile/services/user-profile.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class InitAuthService {
  constructor(
    private oauthService: OAuthService,
    private injector: Injector,
    private userProfileService: UserProfileService,
    private commonUtils: CommonUtilsService,
    private localStorageService: LocalStorageService,
    private userDataService: SharedUserDataService,
    private userSessionService: UserSessionService,
    private configurationService: ConfigurationService
  ) {}

  async initAuth(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      const environment = this.configurationService.getEnvironmentVariables();
      const authConfig: AuthConfig = {
        issuer: environment.identityUrl,
        redirectUri: window.location.origin + "/callback",
        // requestAccessToken: true,
        clientId: "547114e3-666e-4730-8129-ae8ce8e37c6c",
        //responseType: "code",
        responseType: "id_token token",
        scope: 'openid api://8db25aa9-7cfe-4643-8867-561be8fd23a9/Musterkunde.Calculation.API' +
          ' api://8db25aa9-7cfe-4643-8867-561be8fd23a9/Musterkunde.Configuration.API' +
          ' api://8db25aa9-7cfe-4643-8867-561be8fd23a9/Musterkunde.DataCenter.API', //api://8db25aa9-7cfe-4643-8867-561be8fd23a9/Musterkunde.Calculation.API api://8db25aa9-7cfe-4643-8867-561be8fd23a9/Musterkunde.Configuration.API
        //tokenEndpoint: "https://login.microsoftonline.com/40a83bd0-2969-4a4d-8f68-6f3c7da61e4e/oauth2/v2.0/token",
        showDebugInformation: true,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        requestAccessToken: true,

        userinfoEndpoint: environment.musterkundeDevEndpoint + "/api/userinfo",
      };
      this.oauthService.events
        .pipe(filter((e: any) => e.type === 'invalid_nonce_in_state'))
        .subscribe(async () => {
          if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            // await this.oauthService.loadUserProfile(); // for getting user
            const user = await this.userSessionService.getUserInfoFromServer();
            await this.userDataService.changeMessage(this.userSessionService.getUserProfileDetails());
            const postloginRoute = await this.getPostLoginRoute();
            this.injector
              .get(Router)
              .navigateByUrl(user ? postloginRoute : 'unathorized') // remove login hash fragments from url as we are using
              .then(() => resolveFn()); // callback only once login state is resolved
          } else {
            this.oauthService.initImplicitFlow();
          }
        });
      this.oauthService.configure(authConfig);
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin()
        .then(async (isLoggedIn: boolean) => {
          if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            // await this.oauthService.loadUserProfile(); // for getting user
            const user = await this.userSessionService.getUserInfoFromServer();
            await this.userDataService.changeMessage(this.userSessionService.getUserProfileDetails());
            const postloginRoute = await this.getPostLoginRoute();
            this.injector
              .get(Router)
              .navigateByUrl(user ? postloginRoute : 'unathorized') // remove login hash fragments from url as we are using
              .then(() => resolveFn()); // callback only once login state is resolved
          } else {
            this.oauthService.initImplicitFlow();
          }
        });
    });

  }

  async getPostLoginRoute() {
    /** if the previous route is null or undefined */
    if (this.localStorageService.get('currentRoute') === null || this.localStorageService.get('currentRoute') === undefined) {
      return 'base';
    } else {
      const client_permission = this.userProfileService.getClientPermission();
      if (client_permission != undefined) {
        if (this.localStorageService.get('currentRoute').indexOf('bv-home') != -1) {
          PREVIOUS_ROUTE.allowPReviousRoutes = true;
          return this.localStorageService.get('currentRoute');
        } else {
          return 'bv-home';
        }
      } else {
        return 'base';
      }
    }
  }

  isSuperAdmin(roles: any[]) {
    return roles.indexOf(SUPER_ADMIN) > -1;
  }
}

