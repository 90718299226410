<mat-grid-list cols="12" rowHeight="400px" class="m-t-50 m-b-50">
  <mat-grid-tile flex-start flex-align-top [colspan]="9" class="grid-spacing">
    <div class=" w-100 card-shadow">
      <table mat-table [dataSource]="dataSource" class="custom-table fixed-table  w-100 m-b-0">
        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef=" ">
          <th width="180" mat-header-cell *matHeaderCellDef> </th>
          <td class="fw-500" mat-cell *matCellDef="let liscense">
            <mat-checkbox class="custom-checkbox">{{liscense.name}} </mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
  </mat-grid-tile>
</mat-grid-list>