import { Component, OnInit } from '@angular/core';
import { HighchartService } from '../../v-musterkunde/services/highchart.service';
import { Router } from '@angular/router';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Component({
  selector: 'app-vr-activity-mngr-mainpage',
  templateUrl: './vr-activity-mngr-mainpage.component.html',
  styleUrls: ['./vr-activity-mngr-mainpage.component.scss']
})
export class VrActivityMngrMainpageComponent implements OnInit {

  constructor(private commonUtils: CommonUtilsService) {
  }
  ngOnInit() {

  }

  hover(id) {
    document.getElementById(id).style.fill = '#eb690b';
  }
  hoverOut(id) {
    document.getElementById(id).style.fill = '#f2f2f2';
  }
  navigateToMarketingFabric() {
    this.commonUtils.setSvgNavigation('MarketingFabrik');
    // this.router.navigateByUrl('bv-home/marketing-fabrik-mainpage')
  }
  navigateToVrMusterkunde() {
    this.commonUtils.setSvgNavigation('vrMusterkunde');

  }
}
