<div class="p-l-1vw p-t-1vw p-r-1vw">
    <mat-grid-list cols="2" rowHeight="70px">
      <mat-grid-tile flex-start flex-align-top class="grid-spacing">
        <h1 class="page-title">
          Beraterstruktur > {{ isEdit ? ' Hierarchie bearbeiten' : ['duplicate' | translate ] }}
        </h1>
      </mat-grid-tile>
      <mat-grid-tile flex-end flex-align-top class="grid-spacing">
      </mat-grid-tile>
    </mat-grid-list>
  
  
    <div *ngIf="spinnerFlag" class="progress-spinner spin-position">
      <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
    </div>
    <mat-card class="m-t-50 m-b-50 p-t-30" *ngIf="!spinnerFlag">
      <mat-card-content>
        <mat-grid-list cols="12" rowHeight="100px">
          <mat-grid-tile flex-start flex-align-top [colspan]="7">
  
            <form class="w-100" novalidate [formGroup]="hierarchyDuplicationForm">
              <!-- <div class="form-field">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'Id' | translate}}</mat-label>
                  <input matInput placeholder="Id" name="des" formControlName="hierarchyId" type="number">
                  <mat-error *ngIf="hasError('hierarchyId', 'required')">Id {{'isRequired' | translate}}</mat-error>
  
                </mat-form-field>
              </div> -->
  
              <div class="form-field">
                <mat-form-field class="full-width mt-20" appearance="outline">
                  <mat-label>{{'hierarchy' | translate}}-{{'name' | translate}}</mat-label>
                  <input matInput placeholder="{{'hierarchy' | translate}}-{{'name' | translate}}" name="shortDes" formControlName="hierarchyDescription">
                  <mat-error *ngIf="hasError('hierarchyDescription', 'required')">{{'hierarchy' | translate}}-{{'name' | translate}} {{'isRequired' | translate}}</mat-error>

                </mat-form-field>
              </div>
            </form>
            
  
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
      <mat-card-actions class="m-b-5">
        <mat-grid-list cols="12" rowHeight="45px">
          <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
            <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
            <button mat-raised-button class="custom-button m-l-20" (click)="save()"
              [disabled]="disableSave()">{{'save' | translate}}</button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-actions>
    </mat-card>
  </div>