import { Component, OnInit, ViewChild, Input, ViewRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ParameterMgmtService } from '../../services/parameter-mgmt.service';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';


@Component({
  selector: 'app-parameter-mgmt-list',
  templateUrl: './parameter-mgmt-list.component.html',
  styleUrls: ['./parameter-mgmt-list.component.scss']
})
export class ParameterMgmtListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'needTheme', 'needField', 'algorithm', 'personan', 'datatype', 'value'];
  totalData;
  dataSource = new MatTableDataSource();
  spinnerFlag: any = true;
  hideButtons;
  userRoles: any[] = [];
  isVrcpAdmin: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input('type') type: any;
  tableColspan = 9;
  btnColspan = 3;
  constructor(private parameterMgmtService: ParameterMgmtService, private localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.getParameterList();
    if (this.type === 'bv-musterkunde' || this.type == 'bv-activity-mngr') {
      this.hideButtons = true;

    }
    if (this.type) {
      this.btnColspan = 0;
      this.tableColspan = 11;
    }
    this.userRoles = this.localStorageService.get('userRoles');
    if (this.userRoles && this.userRoles.length) {
      this.isVrcpAdmin = this.userRoles.filter(group => group === "VRCP-Admin").length > 0;
    }
    
  }

  getParameterList() {
    this.parameterMgmtService.getParameterList().subscribe((response: any) => {
      this.spinnerFlag = false;
      this.totalData = response.Result.length;
      if (this.type == 'bv-musterkunde') {

        let temp = response.Result.filter((data: any) => !(data.algorithm.includes('Geschäftsintensität') || data.algorithm.includes('Bedarfsscore')));
        response.Result = temp;
        this.totalData = temp.length;
      } else if (this.type == 'bv-activity-mngr') {
        let temp = response.Result.filter((data: any) => (data.algorithm.includes('Geschäftsintensität') || data.algorithm.includes('Bedarfsscore')));
        response.Result = temp;
        this.totalData = temp.length;
      }
      this.dataSource = new MatTableDataSource(response.Result);

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.filteredData.forEach((data: any) => {
        data.edit = true;
      });



    });
  }

  getGermanDecimal(number) {
    return number.toLocaleString('de-DE', { minimumFractionDigits: 2 });
  }

  editInitiated(element) {
    this.dataSource.filteredData.forEach((data: any) => {
      if (element.id === data.id) {
        if(element.dataType.includes('decimal')) {
          data.edit = false;
          data.editValue = data.value;
          data.previousValue = data.value;
        } else {
          data.edit = false;
          data.editValue = Math.floor(this.convertGermanToDecimal(data.value)).toString();
          data.previousValue = Math.floor(this.convertGermanToDecimal(data.value)).toString();
        }
      }
    });
  }

  editWert(id) {
    this.dataSource.filteredData.forEach((data: any) => {
      if (data.id == id) {
        if (data.editValue) {
          const convertedValue = this.convertGermanToDecimal(data.editValue)
          this.parameterMgmtService
            .updateParameter(data.id, convertedValue)
            .subscribe((res: any) => {
              data.value = data.editValue;
              data.edit = true;
            });
        }
      }
    });
  }

  convertGermanToDecimal(value: string) {
    const returnVal = +value.replace(',', '.')
    return returnVal;
  }
  clearEditWert(name) {
    this.dataSource.filteredData.forEach((data: any) => {
      if (data.name === name) {
        data.edit = true;
        data.editValue = data.previousValue;
      }
    });
  }




  validateWertValue(datatype, inputValue) {
    if (datatype !== undefined && inputValue !== undefined) {
      let inputValueBeforePoint, inputValueAfterPoint;

      /* find digits in input value after
      and before decimal point. */

      if (inputValue.toString().includes(',')) {
        inputValueBeforePoint = inputValue.substring(0, inputValue.lastIndexOf(','));
        inputValueAfterPoint = inputValue.substring(inputValue.lastIndexOf(',') + 1);
      } else {
        inputValueBeforePoint = inputValue;
        inputValueAfterPoint = '';
      }
      

      /** check datatype */
      if (datatype.includes('decimal')) {

        const valueAfterDecimalPoint = +datatype.substring(
          datatype.lastIndexOf(',') + 1,
          datatype.lastIndexOf(')')
        );
        if(inputValue.split(',').length > 2 || inputValue.split('.').length > 2) {
          return false;
        }
        // before decimal point
        const valueBeforeDecimal = +(datatype.substring(
          datatype.lastIndexOf('(') + 1,
          datatype.lastIndexOf(',')));

        /** validate input for decimal datatype */
        if (inputValueBeforePoint.length <= valueBeforeDecimal && inputValueAfterPoint.length <= valueAfterDecimalPoint) {
          return true;
        } else {
          return false;
        }
      } else if (datatype.includes('integer')) {

        const totalDigits = datatype.substring(datatype.lastIndexOf('(') + 1,
          datatype.lastIndexOf(')'));

        if (inputValue.includes(',')) {
          return false;
        } else {
          if (inputValue.length <= totalDigits) {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      return false;
    }
    
  }
}
