import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-service-zugriff',
  templateUrl: './service-zugriff.component.html',
  styleUrls: ['./service-zugriff.component.scss']
})
export class ServiceZugriffComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  SERVICE_ZUGRIFF = [
    { FirstName: 'Max', LastName: 'Muster', Organisation: 'VR Consulting Partner', accessRights: true },
    { FirstName: 'Müller', LastName: 'Thomas', Organisation: 'VR Consulting Partner', accessRights: false },
    { FirstName: 'Schulze', LastName: 'Annemarie', Organisation: 'VR Consulting Partner', accessRights: true },
    { FirstName: 'Winter', LastName: 'Reinhold', Organisation: 'VR Consulting Partner', accessRights: false },
  ];
  role = 'Alle';
  dataSource: any;
  displayedColumns: string[] = ['FirstName', 'Organisation'];


  constructor() {
    this.dataSource = new MatTableDataSource(this.SERVICE_ZUGRIFF);
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  filterData(event) {
    if (event.value == 'Mit Zugriffsberechtigung') {
      this.dataSource = new MatTableDataSource(this.SERVICE_ZUGRIFF);
      const temp = this.dataSource.filteredData.filter(data => data.accessRights);
      this.dataSource = new MatTableDataSource(temp);
    } else if (event.value == 'Ohne Zugriffsberechtigung') {
      this.dataSource = new MatTableDataSource(this.SERVICE_ZUGRIFF);
      const temp = this.dataSource.filteredData.filter(data => !data.accessRights);
      this.dataSource = new MatTableDataSource(temp);
    } else {
      this.dataSource = new MatTableDataSource(this.SERVICE_ZUGRIFF);

    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
