import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'alterChartTableData'
})
export class AlterChartTableDataPipe implements PipeTransform {


  constructor(private decimalPipe: DecimalPipe) {

  }
  transform(value: any, addPercentage: boolean): any {
    if (value === 0) {
      if (addPercentage) {
        return '0,0%';
      } else {
        return 0;
      }
    }
    if (addPercentage) {
      value = value.toFixed(1);
      value = value.replace('.', ',');
      return `${value}%`;
    } else {
      value = parseInt(value, 10);
      value = this.decimalPipe.transform(value, '1.0-0', 'de-DE');
      return value;
    }
  }

}
