import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelCustomerGroupService } from '../../service/model-customer-group.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';


@Component({
  selector: 'app-add-tailbedarf',
  templateUrl: './add-tailbedarf.component.html',
  styleUrls: ['./add-tailbedarf.component.scss']
})
export class AddTailbedarfComponent implements OnInit {

  customerNeeds: any[] = [];
  elementNeedToBeRemoved: any[] = [];
  hideContent = false;
  clientId: any;
  bankIndividualNeeds: any;


  constructor(private dialogRef: MatDialogRef<AddTailbedarfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private musterkundegruppenService: ModelCustomerGroupService,
    private commonUtilsService: CommonUtilsService,
    private userSessionService: UserSessionService) {
    this.bankIndividualNeeds = this.data.bankIndividualNeeds;
    this.clientId = this.userSessionService.getClientId();
  }


  ngOnInit() {
    this.getListOfCustomerNeedsAndSolution();

  }


  getListOfCustomerNeedsAndSolution() {
    this.musterkundegruppenService.getCustomerNeedsSolutionList().subscribe((Response: any) => {
      this.customerNeeds = Response.Result;
      this.removeAlreadyAddedElements();
      setTimeout(() => {
        this.hideContent = true;
      }, 90);
    });
  }

  removeAlreadyAddedElements() {
    this.bankIndividualNeeds.forEach(bankIndividualNeed => {
      this.customerNeeds.forEach(element => {
        element.needFields.forEach(needFields => {
          const temp = needFields.needFieldPartCategories.filter(obj => obj.needFieldPartId != bankIndividualNeed.needFieldPartId);
          needFields.needFieldPartCategories = temp;
        });
      });
    });
  }


  closeDialog() {
    this.dialogRef.close();
  }
  addPartialNeeds(id) {
    const customerNeed = new CustomerNeeds();
    customerNeed.clientId = this.clientId;
    customerNeed.modelCustomerGroupId = this.data.customerId;
    customerNeed.needFieldPartId = id;
    this.musterkundegruppenService.addNewNeedSolution(customerNeed).subscribe((Response: any) => {
      this.dialogRef.close(this.data.customerId);
    });

  }
  setNeedData(needANdSoliution, needs, partialNeed) {
    const customerNeed: any = {
      id: needANdSoliution.id,
      indentityNo: needANdSoliution.indentityNo,
      themeColor: needANdSoliution.themeColor,
      themeName: needANdSoliution.themeName,
      need: needs.need,
      partialNeedName: partialNeed.partialNeedName,
      solution: partialNeed.solution,
      existentialStatus: false,
      cashFlowStatus: false
    };

    return customerNeed;
  }

  getLeftBorderColor(themeName) {
    const color = this.commonUtilsService.getThemeColorByThemeName(themeName);
    return {
      'border-left': `8px solid ${color}`
    };
  }
}


export class CustomerNeeds {
  id: any = 0;
  modelCustomerGroupId: any;
  needFieldPartId: any;
  needFieldId: any = null;
  isExistence: any = false;
  isCashFlowNeutral: any = false;
  rang: any = 0;
  clientId: any = 0;

}
