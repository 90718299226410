import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BenutzerService } from '../../service/benutzer.service';
import { Benutzer } from '../../models/benutzer.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivateDeactivateUserConfirmDialogComponent } from '../activate-deactivate-user-confirm-dialog/activate-deactivate-user-confirm-dialog.component';
import { CommonUtilsService } from '../../../../shared/services/common-utils.service';
import { ROLES_SCOPES } from 'src/app/app.config';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { forkJoin } from 'rxjs';
import { ClaimGroupService } from '../../service/claim-group.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-benutzer-edit',
  templateUrl: './benutzer-edit.component.html',
  styleUrls: ['./benutzer-edit.component.scss'],
})
export class BenutzerEditComponent implements OnInit {

  claims: any[] = [];
  roles;
  roleScope = ROLES_SCOPES;
  id: any;
  benutzer = new Benutzer();
  wasAdministrator = false;
  userForm: FormGroup;
  roleChanged = false;
  spinnerFlag;

  constructor(
    private route: ActivatedRoute,
    private userService: BenutzerService,
    private dialog: MatDialog,
    private router: Router,
    private commonUtils: CommonUtilsService,
    private translateService: TranslateService,
    private claimGroupService: ClaimGroupService,
  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.initForm();
    this.loadUserData();
  }

  loadUserData() {
    forkJoin([
      this.getUserById(),
      this.getClaimGroups(),
    ]).subscribe((response: any) => {
      // response 1 (user by id)
      this.benutzer = response[0].Result[0];
      response[0].Result.forEach(element => {
        if (element.Id == this.id) {
          this.benutzer = element;
          this.setForm();
        }
      });

      // response 2 (claim groups)
      this.roles = response[1].Result;
      this.roles.forEach((role: any) => {
        if (response[0].Result[0].ClaimGroups.find(element => element.Id === role.Id)) {
          if (role.InternalName === 'user_admin') {
            this.wasAdministrator = true;
          }
          role.checked = true;
        } else {
          role.checked = false;
        }
      });
      this.roles.sort((a, b) => {
        if (a.Name > b.Name) {
          return 1;
        }
        if (a.Name < b.Name) {
          return -1;
        }
        return 0;
      });
      this.spinnerFlag = false;
    });
  }

  initForm() {
    this.userForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      userName: new FormControl('', Validators.required),
    });
  }

  setForm() {
    this.userForm.controls['firstName'].setValue(this.benutzer.FirstName);
    this.userForm.controls['lastName'].setValue(this.benutzer.LastName);
    this.userForm.controls['email'].setValue(this.benutzer.Email);
    this.userForm.controls['userName'].setValue(this.benutzer.UserName ? this.benutzer.UserName : this.benutzer.Email);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  };

  getUserById() {
    this.spinnerFlag = true;
    const ids: any[] = [];
    ids.push(this.id);
    return this.userService.getUserById(ids);
  }

  getClaimGroups() {
    return this.claimGroupService.getClaimGroups();
  }

  getUserRole(userclaims: any[]) {
    userclaims.forEach(claim => {
      this.claims.push(claim.ClaimValue);
    });
    const roles = this.commonUtils.getClaimsAndReturnRoles(this.claims);

    this.roles.forEach(role => {
      roles.forEach(innerRole => {
        if (role.roleName == innerRole) {
          role.select = true;
        }
      });
    });
  }

  lockOrUnlockUser() {
    const dialogRef = this.dialog.open(ActivateDeactivateUserConfirmDialogComponent, {
      width: '400px',
      data: {
        isUserActive: this.benutzer.IsDeleted,
      },
    });
    dialogRef.afterClosed().subscribe(activateOrDeactivateUser => {
      if (activateOrDeactivateUser) {
        this.benutzer.Language = 'DE';
        this.benutzer.Theme = 'light-background-theme';
        // Set the isDeleted flag to true/false according to current activate/deactivate state of user.
        this.benutzer.IsDeleted = !this.benutzer.IsDeleted;
        this.spinnerFlag = true;
        this.userService.updateUser(this.benutzer.Id, this.benutzer).subscribe(() => {
          this.loadUserData();
        });
      }
    });
  }

  navigateToUserList() {
    this.router.navigateByUrl('bv-home/benutzerlist');
  }

  disableForm() {
    if ((!this.userForm.dirty || !this.userForm.valid) && !this.roleChanged) {
      return true;
    }
  }

  roleCheckedEvent() {
    this.roleChanged = true;
  }

  updateUser() {
    const selectedRole: any = [];
    let IsAdministrator = false;
    this.roles.forEach((role: any) => {
      if (role.checked) {
        if (role.InternalName === 'user_admin') {
          IsAdministrator = true;
        }
        selectedRole.push(role.Id);
      }
    });
    this.benutzer.Theme = 'light-background-theme';
    this.benutzer.Language = 'DE';
    this.benutzer.FirstName = this.userForm.value.firstName;
    this.benutzer.LastName = this.userForm.value.lastName;
    this.benutzer.UserName = this.userForm.value.userName;
    this.benutzer.Email = this.userForm.value.email;
    this.benutzer.GroupIds = selectedRole;
    if (IsAdministrator) {
      this.benutzer.IsAdministrator = true;
    } else {
      this.benutzer.IsAdministrator = false;
    }
    this.spinnerFlag = true;
    if (this.wasAdministrator && !IsAdministrator) {
      this.userService.deleteCustomerAdmin(this.id).subscribe(() => {
        this.userService.updateUser(this.id, this.benutzer).subscribe(() => {
          this.spinnerFlag = false;
          this.router.navigateByUrl('bv-home/benutzerlist');
        }, () => {
          this.spinnerFlag = false;
        });
      }, () => {
        this.spinnerFlag = false;
      });
    } else if (!this.wasAdministrator && IsAdministrator) {
      if (!this.benutzer.IsDeleted) {
        this.userService.updateUser(this.id, this.benutzer).subscribe(() => {
          this.userService.createCustomerAdmin(this.id).subscribe(() => {
            this.spinnerFlag = false;
            this.router.navigateByUrl('bv-home/benutzerlist');
          }, () => {
            this.spinnerFlag = false;
          });
        }, () => {
          this.spinnerFlag = false;
        });
      } else {
        const inactiveUserMsg = this.translateService.instant('reactivateBeforeAdmin');
        this.commonUtils.errorSnackBar('error', inactiveUserMsg);
        this.spinnerFlag = false;
      }
    } else {
      this.userService.updateUser(this.id, this.benutzer).subscribe(() => {
        this.spinnerFlag = false;
        this.router.navigateByUrl('bv-home/benutzerlist');
      });
    }
  }

  clearUserForm() {
    if (!this.userForm.dirty) {
      this.router.navigateByUrl('/bv-home/benutzerlist');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/benutzerlist');
        }
      });
    }
  }
}


export class Claims {
  claims: string[] = [];
}
