<div *ngIf="responseKundengerustSuccess || multiPdfProcessingRunning">
  <div *ngIf="!hideSelectionContent">
    <h2 style="font-weight: 500;">PDF-Report herunterladen</h2>

    <mat-radio-group aria-label="Select an option" [(ngModel)]="pdfFormat">
      <mat-radio-button value="simplePDF">Einzelner Report mit aktuellen Einstellungen</mat-radio-button> <br><br>
      <mat-radio-button value="multiplePDF" [disabled]="totalReports == 0">Für jeden Berater{{totalReports > 0 ? " (" + totalReports + ")" : " (0)"}} ein Report mit aktuellen Einstellungen</mat-radio-button> <br><br>
      <!-- <mat-radio-button value="zipPDF">ZIP-Archiv mit Reports für alle <br>
        Profitcenter mit den aktuellen Einstellungen</mat-radio-button> <br><br> -->
    </mat-radio-group>

    <a href="javascript:void(0)" (click)="showOptions = true" *ngIf="!showOptions">PDF-Report anpassen</a><br>

    <div *ngIf="showOptions">
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page1">Potentialübersicht</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page4">Potentiale Liquidität</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page5">Potentiale Absicherung</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page6">Potentiale Vermögen</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page7">Qualitätsanalyse Vermögen</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page8">Potentiale Vorsorge</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page9">Potentiale Immobilien</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page10">Kundengerüst</mat-checkbox><br>
      <mat-checkbox class="custom-checkbox" [(ngModel)]="page16">Filterung</mat-checkbox><br>
    </div>
    <br>
    <!-- <div class="PDF-spinner m-b-10" *ngIf="disabled">
      <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
    </div> -->
  </div>

  <div *ngIf="spinnerFlag || multiProcessingRunning">
    <div class="PDF-spinner">
      <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
    </div>
    <br>
    <p *ngIf="pdfFormat == 'simplePDF'" style="text-align: center;">Report wird erstellt.<br>
      Ihr Download startet automatisch.</p>

    <p *ngIf="(pdfFormat == 'zipPDF' || pdfFormat == 'multiplePDF') && currentReport == 0" style="text-align: center;">Daten werden vorbereitet.<br>
        Ihr Download startet automatisch.</p>
    <p *ngIf="(pdfFormat == 'zipPDF' || pdfFormat == 'multiplePDF') && currentReport > 0 && !processingDownload" style="text-align: center;">Report {{currentReport}} von {{totalReports}} wird erstellt.<br>
      Ihr Download startet automatisch.</p>
    <p *ngIf="pdfFormat == 'multiplePDF' && currentReport > 0 && processingDownload" style="text-align: center;">Erstellung Report {{currentReport}} von {{totalReports}} abgeschlossen.<br>
      Ihr Download startet automatisch.</p>
        
  </div>

  <button mat-raised-button class="m-l-20 f-right custom-button" *ngIf="!spinnerFlag && pdfFormat == 'simplePDF'"
    (click)="processPdfFlag = true; processPDF()">{{'download' | translate}}
  </button>
  <button mat-raised-button class="m-l-20 f-right custom-button" *ngIf="!spinnerFlag && pdfFormat == 'multiplePDF'"
    (click)="processPdfFlag = true; startPDFConsultantProcess()">{{'download' | translate}}
  </button>
  <!-- <button mat-raised-button class="m-l-20 f-right custom-button" *ngIf="!spinnerFlag && pdfFormat == 'zipPDF'"
    (click)="processPdfFlag = true; startPDFConsultantProcess()">{{'download' | translate}}
  </button> -->
  <button mat-raised-button color="basic" class="m-l-10  f-right reverse-custom-button"
    (click)="closeDialog()">{{'stop' | translate}}</button>
  <div *ngIf="responseKundengerustSuccess">
    <br><br>
    <div style="overflow: hidden; height: 0;">
      <div id="pdfHTML2">

        <!-- Component to load the Potential analysis list pages -->
        <app-potentialanalyse-pdf-generate
          [consultantInfo]="data.consultantInfo"
          [consultantNeedSolutionList]="data.consultantNeedSolutionList"
          [profitCenterFilter]="data.profitCenterFilter" 
          [kalkulationHeader]="kalkulationHeader"
          (onInitDoneEvent)="setHtmlRenderingFinishedForConsultant($event)">
        </app-potentialanalyse-pdf-generate>

        <!-- Component to load the potentialanalysis vermogen report Page -->
        <app-potentialanalysis-vermogen-report class="vermogenReport" [dataForPDF]="true" (onInitDoneEvent)="setHtmlRenderingFinishedForVermogen($event)">
        </app-potentialanalysis-vermogen-report>
        <div *ngIf="showTables">
          <!-- Component to load the potentialanalysis kundengerust list Tables content -->
          <app-potentialanalysis-kundengerust-list class="framework1" [dataSource]="framework1" [dataForPDF]="true" (onInitDoneEvent)="setHtmlRenderingFinishedForFramework1($event)">
          </app-potentialanalysis-kundengerust-list>
          <app-potentialanalysis-kundengerust-list class="framework2" [dataSource]="framework2" [dataForPDF]="true"(onInitDoneEvent)="setHtmlRenderingFinishedForFramework2($event)">
          </app-potentialanalysis-kundengerust-list>
          <app-potentialanalysis-kundengerust-list class="framework3" [dataSource]="framework3" [dataForPDF]="true"(onInitDoneEvent)="setHtmlRenderingFinishedForFramework3($event)">
          </app-potentialanalysis-kundengerust-list>
          <app-potentialanalysis-kundengerust-list class="framework4" [dataSource]="framework4" [dataForPDF]="true"(onInitDoneEvent)="setHtmlRenderingFinishedForFramework4($event)">
          </app-potentialanalysis-kundengerust-list>
          <app-potentialanalysis-kundengerust-list class="framework5" [dataSource]="framework5" [dataForPDF]="true"(onInitDoneEvent)="setHtmlRenderingFinishedForFramework5($event)">
          </app-potentialanalysis-kundengerust-list>
          <app-potentialanalysis-kundengerust-list class="framework6" [dataSource]="framework6" [dataForPDF]="true"(onInitDoneEvent)="setHtmlRenderingFinishedForFramework6($event)">
          </app-potentialanalysis-kundengerust-list>
        </div>
        <div class="headerKundengerustTable">
          <h1 class="main-title">
            Kundengerüst
          </h1>
          <h3 class="mainSub-title">
            {{data.profitCenterFilter}} <br>
            {{kalkulationHeader}}
          </h3>
        </div>
        <div class="KundengruppePDF">
          <div class="KundengerüstTable1Div">
            <b class="kundengruppeTable1Title" style="margin-left: 33px;font-weight: 500;"></b>
          </div>
          <div class="KundengerüstTable2Div">
            <b class="kundengruppeTable2Title" style="margin-left: 33px;font-weight: 500;"></b>
          </div>
          <div class="KundengerüstTable3Div">
            <b class="kundengruppeTable3Title" style="margin-left: 33px;font-weight: 500;"></b>
          </div>
          <div class="KundengerüstTable4Div">
            <b class="kundengruppeTable4Title" style="margin-left: 33px;font-weight: 500;"></b>
          </div>
          <div class="KundengerüstTable5Div">
            <b class="kundengruppeTable5Title" style="margin-left: 33px;font-weight: 500;"></b>
          </div>
          <div class="KundengerüstTable6Div" style="width: 850px;">
            <b class="kundengruppeTable6Title" style="margin-left: 33px;font-weight: 500;"></b>
          </div>
        </div>
      </div>

      <!-- Page to be rendered at the end of pdf containing list of filters applied. -->
      <div class="selectedFilterScreen">
        <h1 class="main-title">
          Filterung
        </h1>
        <h3 class="mainSub-title">
          {{data.profitCenterFilter}} <br>
          {{kalkulationHeader}}
        </h3>

        <div style="padding: 2px;margin-left:35px;margin-top: 2px;"
          *ngFor="let filter of data.potentialAnalysisFilters">
          <div *ngIf="filter.values && filter.values.length !== 0" class="m-b-10"
            [ngClass]="{'display-none' : filter.values.length == 0}">
            <b *ngIf="filter.values.length != 0">{{filter.name}}</b>
            <div *ngFor="let value of filter.values" class="font-size-14">
              {{value.name}}
            </div>
          </div>
        </div>
        <div *ngFor="let filter of selectedAdditionalFilters" style="padding: 2px;margin-left:35px;margin-top: 2px;">
          <!-- 1 -->
          <div
            *ngIf="((filter.matchValue == 'oldestPersonInHousehold') || (filter.matchValue == 'customerSegmentBank') || (filter.matchValue == 'noOfPersonInHouse') || (filter.matchValue == 'noOfAdultsInHouse') || (filter.matchValue == 'noOfMinorsInHouse') || (filter.matchValue == 'purchasingPower' ) || (filter.matchValue == 'datesPast') || (filter.matchValue == 'datesFuture')) && filter.select">
            <div class="m-b-5">
              <div class="selected-filter-label">{{filter.name}}</div>
              <div  style="font-size: 14px; font-weight :100">
                <div>Min: {{filter.minValue}}</div>
                <div>Max: {{filter.maxValue}}</div>
              </div>
            </div>
          </div>


          <!-- 2 -->
          <div *ngIf="(filter.matchValue == 'ratingHousehold') && filter.select">
            <div *ngIf="filter.values" class="m-b-5">
              <div class="selected-filter-label" *ngIf="filter.values.length != 0">{{filter.name}}</div>
              <div *ngFor="let value of filter.values" style="font-size: 14px; font-weight :100">
                {{value}}
              </div>
            </div>
          </div>

          <!-- 3 -->
          <div
            *ngIf="((filter.matchValue == 'customerSegmentVRCP') || (filter.matchValue == 'customerSegmentBVR')) && filter.select">
            <div *ngIf="filter.values" class="m-b-5">
              <div class="selected-filter-label" *ngIf="filter.values.length != 0">{{filter.name}}</div>
              <div *ngFor="let value of filter.values" style="font-size: 14px; font-weight :100">
                {{value.shl_name}}
              </div>
            </div>
          </div>


        </div>


      </div>
    </div>
  </div>
</div>
<div class="spinner-div" *ngIf="!responseKundengerustSuccess && !multiPdfProcessingRunning">
  <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
</div>