import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { RightSidebarService } from '../../services/right-sidebar.service';
import {
  ADDITIONAL_FILTER,
  AllDataLoadDetail,
  BV_SIDEBAR_STATUS,
  CONSULTANT_IDS,
  DEPARTMENT_NUMBERS,
  KUNDERGUST,
  POTENTIAL_FILTERS,
  PROFIT_CENTER_FILTER,
  SELECTED_ADDITIONAL_FILTER,
  VERMOGEN_FILTER,
  VERMOGEN_REPORT_DATA,
} from '../../../../app.config';
import { PotentialAnalysis } from '../../models/potentialanalysis';
import { PotentialAnalyseService } from '../../services/potential-analyse.service';
import { CommonUtilsService } from '../../../../shared/services/common-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PotentialanalysisVermogenReportComponent } from '../potentialanalysis-vermogen-report/potentialanalysis-vermogen-report.component';
import { ConfirmPotentialFiltersDialogComponent } from '../confirm-potential-filters-dialog/confirm-potential-filters-dialog.component';
import { PdfGenerationPopUpComponent } from '../pdf-generation-pop-up/pdf-generation-pop-up.component';
import { PotentialanalysisKundengerustListComponent } from '../potentialanalysis-kundengerust-list/potentialanalysis-kundengerust-list.component';
import { PotentialanalyseFilterBarComponent } from '../potentialanalyse-filter-bar/potentialanalyse-filter-bar.component';
import { interval } from 'rxjs';
import { DatePipe } from '@angular/common';
import 'rxjs/add/operator/take';
import { ConsultantSegmentService } from 'src/app/benutzer-verwaltung/parameter/service/consultant-segment.service';

@Component({
  selector: 'app-potential-analysis-mainpage',
  templateUrl: './potential-analysis-mainpage.component.html',
  styleUrls: ['./potential-analysis-mainpage.component.scss'],
})
export class PotentialAnalysisMainpageComponent implements OnInit, OnDestroy {
  @ViewChild('rightSidenav', {static: false}) public sidenav: MatSidenav;

  sideToggle: any;
  consultantNeedSolutionList: any;
  kalkulationList: any;
  kalkulation: any;
  kalkulationValue: any;
  potentialAnalysisFilters = POTENTIAL_FILTERS;
  profitCenterFilter = PROFIT_CENTER_FILTER;
  sideBarType: any;
  spinnerFlag: any = false;
  errorMessage: any;
  success = false;
  mainSpinner: any = true;
  selectedCalculationId: any;
  selectedCalculationDeadline: any;
  selectedHierachy: any;
  vermogenName = 'Vermögen';
  // upper info text data
  houseHoldCount: any;
  privatePersonCount: any;
  disableDataLoad = false;
  commonSpinner = AllDataLoadDetail.loaded;
  frameworkCount = 0;

  // show different components
  showPotentialMainPage = true;
  showPotentailVermogen = false;
  showPotentailKundengerust = false;
  openedFromSideMenu = false;
  @ViewChild(PotentialanalysisVermogenReportComponent, {static: false}) chartComponent: PotentialanalysisVermogenReportComponent;
  @ViewChild(PotentialanalysisKundengerustListComponent, {static: false}) kundengerustComponent: PotentialanalysisKundengerustListComponent;
  @ViewChild(PotentialanalyseFilterBarComponent, {static: false}) filterBarComponent: PotentialanalyseFilterBarComponent;
  potentialAnalysisObject = new PotentialAnalysis();
  intervalEvent: any;

  selectedAdditionalFilters = SELECTED_ADDITIONAL_FILTER;

  // guid
  guid: any;

  canProcessGetAllData2 = false;

  constructor(
    private sidenavService: RightSidebarService,
    private potentialAnalysisService: PotentialAnalyseService,
    private commonUtils: CommonUtilsService,
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private consultantSegmentService: ConsultantSegmentService
  ) {
    this.sideToggle = false;
    this.getCalculationDates();
    this.getQueryParam();
  }

  ngOnInit() {
  }

  checkFilterValueDisplay(filterId) {
    return ![6, 2, 5].includes(filterId);
  }

  exportCsv() {
    const potentialAnalyse = this.commonUtils.setPotentialAnalysisWithFilters(this.selectedCalculationId);
    if (this.intervalEvent) {
      this.intervalEvent.unsubscribe();
      this.intervalEvent = false;
    }

    this.potentialAnalysisService.getCsvExport(potentialAnalyse).subscribe((response: any) => {
      let dataType = response.type;
      let date = new Date();
      const datepipe: DatePipe = new DatePipe('de-DE');
      let formattedDate = datepipe.transform(date, 'yyyy-MM-dd');
      let filename = formattedDate + " Potenzialanalyse Export.csv";
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
        downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

  async getAllData(element: any = null) {

    if (element) {
      CONSULTANT_IDS.ids = [ element ];
    }

    if (this.chartComponent) {
      this.chartComponent.spinnerFlag = false;
    }
    this.commonSpinner = AllDataLoadDetail.loaded = false;
    const potentialAnalyse = this.commonUtils.setPotentialAnalysisWithFilters(this.selectedCalculationId);
    if (this.intervalEvent) {
      this.intervalEvent.unsubscribe();
      this.intervalEvent = false;
    }
    await this.potentialAnalysisService.applyFilters(potentialAnalyse).subscribe((response: any) => {
      this.guid = response.Result;

      // Potentialanalysis data
      this.getPotentialAnalyseData(response.Result);

    }, () => {
      this.onError();

    });
  }

  async getAllData2(element: any = null): Promise<any> {

    return new Promise<any>(async (resolve, reject) => {

      if (element) {
        CONSULTANT_IDS.ids = [ element ];
      }
      
      if (this.chartComponent) {
        this.chartComponent.spinnerFlag = false;
      }
      this.commonSpinner = AllDataLoadDetail.loaded = false;
      const potentialAnalyse = this.commonUtils.setPotentialAnalysisWithFilters(this.selectedCalculationId);
      if (this.intervalEvent) {
        this.intervalEvent.unsubscribe();
        this.intervalEvent = false;
      }
      await this.potentialAnalysisService.applyFilters(potentialAnalyse).subscribe(async (response: any) => {
        this.guid = response.Result;
  
        // Potentialanalysis data
        await this.getPotentialAnalyseDataAsync(response.Result).then((result) => {
          //console.log('getPotentialAnalyseDataAsync', result);
          resolve(true);
        });

        
  
      }, () => {
        reject(false);
  
      });

    });
    
  }

  setSelectedHierachy(event) {
    this.selectedHierachy = event;
  }

  onError() {
    this.spinnerFlag = false;
    this.success = false;
    this.disableDataLoad = false;
    this.errorMessage = this.translateService.instant('calculationNotSelectedError');
    this.commonUtils.errorSnackBar('error', '');
  }

  getKundergust() {
    KUNDERGUST.forEach(kundergust => {
      this.getCustomerFramework(kundergust.frameworkNo, this.guid);
    });
  }

  async getKundergustAsync() {
    return new Promise(async (resolve, reject) => {
      for await (const kundergust of KUNDERGUST) {
        await this.getCustomerFrameworkAsync(kundergust.frameworkNo, this.guid);
      }
      resolve(true);
    });
  }

  getVermogen() {
    this.potentialAnalysisService.getQualityReportData(this.guid, CONSULTANT_IDS.ids, DEPARTMENT_NUMBERS.numbers).subscribe((reportData: any) => {
      switch (reportData.StatusCode) {
        case 200:
          if (this.intervalEvent) {
            this.intervalEvent.unsubscribe();
            this.intervalEvent = false;
          }

          VERMOGEN_REPORT_DATA.data = reportData.Result;
          this.getKundergust();
          break;
        case 206:
          if (!this.intervalEvent) {
            this.intervalEvent = interval(10000).subscribe(() => {
              this.getVermogen();
            });
          }
          break;
        case 404:
          if (this.intervalEvent) {
            this.intervalEvent.unsubscribe();
            this.intervalEvent = false;
          }
          this.getVermogen();
          break;
      }
    }, () => {
    });
  }

  async getVermogenAsync() {
    return new Promise(async (resolve, reject) => {
      await this.potentialAnalysisService.getQualityReportData(this.guid, CONSULTANT_IDS.ids, DEPARTMENT_NUMBERS.numbers).subscribe(async (reportData: any) => {
        switch (reportData.StatusCode) {
          case 200:
            if (this.intervalEvent) {
              this.intervalEvent.unsubscribe();
              this.intervalEvent = false;
            }
  
            VERMOGEN_REPORT_DATA.data = reportData.Result;
            await this.getKundergustAsync().then(() => {
              resolve(true);
            });
            break;
          case 206:
            if (!this.intervalEvent) {
              this.intervalEvent = await interval(10000).subscribe(async () => {
                await this.getVermogenAsync();
              });
            }
            break;
          case 404:
            if (this.intervalEvent) {
              this.intervalEvent.unsubscribe();
              this.intervalEvent = false;
            }
            await this.getVermogenAsync();
            break;
        }
      }, () => {

      });
    });
  }

  async getCustomerFrameworkAsync(currentFrameworkNumber, guidOfAppliedFilter) {
    return new Promise(async (resolve, reject) => {
      await this.potentialAnalysisService[`getCustomerFramework0${currentFrameworkNumber}`](guidOfAppliedFilter, CONSULTANT_IDS.ids, DEPARTMENT_NUMBERS.numbers).subscribe(async (response: any) => {
        switch (response.StatusCode) {
          case 200:
            if (this.intervalEvent) {
              this.intervalEvent.unsubscribe();
              this.intervalEvent = false;
            }
            this.disableDataLoad = false;
            KUNDERGUST.forEach(obj => {
              if (obj.frameworkNo === currentFrameworkNumber) {
                obj.data = response.Result.rows;
                if (this.frameworkCount == 5) {
                  this.commonSpinner = AllDataLoadDetail.loaded = true;
                  this.disableDataLoad = false;
                  if (this.chartComponent) {
                    this.chartComponent.spinnerFlag = true;
                    this.chartComponent.initVermogen();
                  }
                  if (this.kundengerustComponent) {
                    this.kundengerustComponent.dimensionenOrKennzahlChanged();
                  }
                } else {
                  this.frameworkCount++;
                }
  
              }
            });
            resolve(true);
            break;
          case 206:
            if (!this.intervalEvent) {
              this.intervalEvent = interval(10000).subscribe(async () => {
                await this.getCustomerFrameworkAsync(currentFrameworkNumber, guidOfAppliedFilter);
              });
            }
            break;
          case 404:
            if (this.intervalEvent) {
              this.intervalEvent.unsubscribe();
              this.intervalEvent = false;
            }
            await this.getCustomerFrameworkAsync(currentFrameworkNumber, guidOfAppliedFilter);
            break;
        }
  
      }, () => {
        this.spinnerFlag = false;
      });
    })
  }

  getCustomerFramework(currentFrameworkNumber, guidOfAppliedFilter) {
    this.potentialAnalysisService[`getCustomerFramework0${currentFrameworkNumber}`](guidOfAppliedFilter, CONSULTANT_IDS.ids, DEPARTMENT_NUMBERS.numbers).subscribe((response: any) => {
      switch (response.StatusCode) {
        case 200:
          if (this.intervalEvent) {
            this.intervalEvent.unsubscribe();
            this.intervalEvent = false;
          }
          this.disableDataLoad = false;
          KUNDERGUST.forEach(obj => {
            if (obj.frameworkNo === currentFrameworkNumber) {
              obj.data = response.Result.rows;
              if (this.frameworkCount == 5) {
                this.commonSpinner = AllDataLoadDetail.loaded = true;
                this.disableDataLoad = false;
                if (this.chartComponent) {
                  this.chartComponent.spinnerFlag = true;
                  this.chartComponent.initVermogen();
                }
                if (this.kundengerustComponent) {
                  this.kundengerustComponent.dimensionenOrKennzahlChanged();
                }
              } else {
                this.frameworkCount++;
              }

            }
          });
          break;
        case 206:
          if (!this.intervalEvent) {
            this.intervalEvent = interval(10000).subscribe(() => {
              this.getCustomerFramework(currentFrameworkNumber, guidOfAppliedFilter);
            });
          }
          break;
        case 404:
          if (this.intervalEvent) {
            this.intervalEvent.unsubscribe();
            this.intervalEvent = false;
          }
          this.getCustomerFramework(currentFrameworkNumber, guidOfAppliedFilter);
          break;
      }

    }, () => {
      this.spinnerFlag = false;
    });
  }

  

  getQueryParam() {
    this.activatedRoute.queryParams.subscribe(params => {
      const queryParam = params['type'];
      if (queryParam == 'customerScaffolding') {
        this.showPotentailVermogen = false;
        this.showPotentailKundengerust = true;
        this.success = true;
        this.openedFromSideMenu = true;
      } else if (queryParam == 'qualityReport') {
        this.showPotentailVermogen = true;
        this.showPotentailKundengerust = false;
        this.success = true;
        this.openedFromSideMenu = true;
      } else {
        this.showPotentailVermogen = false;
        this.showPotentailKundengerust = false;
        this.openedFromSideMenu = false;
      }
    });
  }

  getValueRangeText(value) {
    let min, max;
    min = this.getDecimalPoint(value.minValue);
    max = this.getDecimalPoint(value.maxValue);
    if (value.minValue == 0) {
      if (value.maxValue == 500000) {
        return 'ab ' + min + ' EUR bis ' + max + ' EUR';
      } else {
        return 'bis ' + max + ' EUR';
      }
    } else {
      if (value.maxValue == 500000) {
        return 'ab ' + min + ' EUR';
      } else {
        return 'ab ' + min + ' EUR bis ' + max + ' EUR';
      }
    }
  }

  filterRightNav() {
    this.sideBarType = 2;
    this.sidenavService.toggle();
    this.sideToggle = !this.sideToggle;
    this.commonUtils.sendToggleStatus(this.sideToggle);
  }

  profitCenterRightNav() {
    this.sideBarType = 1;
    this.sidenavService.toggle();
    this.sideToggle = !this.sideToggle;
    this.commonUtils.sendToggleStatus(this.sideToggle);

    this.filterBarComponent.consultantId = [];
  }

  getLeftBorder(themeColor) {
    return {
      'border-left': `8px solid ${themeColor}`,
    };
  }

  getThemeColor(themeColor) {
    return {
      'color': themeColor,
    };
  }

  getCalculationDates() {
    this.potentialAnalysisService.getCalculationDates().subscribe((response: any) => {
      this.kalkulationList = response.Result;
      if (this.kalkulationList && this.kalkulationList.length > 0) {
        this.kalkulationValue = this.selectedCalculationId = this.kalkulationList[0].value.toString();
        this.selectedCalculationDeadline = this.kalkulationList[0].deadlineDate;
      }
      this.mainSpinner = false;
    });

  }

  changeCalculation(event) {
    this.selectedCalculationId = +event.value;
    this.selectedCalculationDeadline = this.kalkulationList.find(element => element.value == +event.value).deadlineDate;
    // this.commonSpinner = false;
    // this.getPotentialAnalysis();

    CONSULTANT_IDS.ids = [];
    DEPARTMENT_NUMBERS.numbers = [];

    if (this.showPotentailVermogen) {
      // call child component method
      this.chartComponent.parentChartGeneration(this.selectedCalculationId);
    } else if (this.showPotentailKundengerust) {
      this.kundengerustComponent.parentChartGeneration(this.selectedCalculationId);
    }
  }

  showresetFilter() {
    let count = 0;
    this.potentialAnalysisFilters.forEach(filter => {
      if (filter.select) {
        count++;
      }
    });
    this.selectedAdditionalFilters.forEach(() => {
      count++;
    });
    return ((count > 0) || (this.selectedAdditionalFilters.length > 0));
  }

  // close sidebar event
  closeSidebar(event) {
    this.sidenavService.toggle();
    /*
    Add new filters
    or modify filters
   */
    if (event == 'saveFilters' || event == 'profitCenter') {
      this.getAllData();
    }

    this.sideToggle = !this.sideToggle;
    this.commonUtils.sendToggleStatus(this.sideToggle);
  }


  resetFilters() {
    const dialogRef = this.dialog.open(ConfirmPotentialFiltersDialogComponent, {
      width: '400px',
      data: {
        resetFilters: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.potentialAnalysisFilters.forEach(filter => {
          filter.values = [];
          filter.select = false;
        });
        this.commonUtils.resetVermogenFilter();
        this.filterBarComponent.vermogen = [];
        this.resetAdditionalFilters();
        this.getAllData();
      }
    });
    VERMOGEN_FILTER.forEach(filter => {
      filter.isSlider = true;
      filter.minValue = filter.defaultMinValue;
      filter.maxValue = filter.defaultMaxValue;
    });
  }


  // resetAdditionalFilters
  resetAdditionalFilters() {
    SELECTED_ADDITIONAL_FILTER.length = 0;
    ADDITIONAL_FILTER.forEach(filter => {
      if ((filter.matchValue == 'oldestPersonInHousehold') || (filter.matchValue == 'noOfPersonInHouse') || (filter.matchValue == 'noOfAdultsInHouse')
        || (filter.matchValue == 'noOfMinorsInHouse') || (filter.matchValue == 'purchasingPower')) {
        filter.minValue = filter.defaultMinValue;
        filter.maxValue = filter.defaultMaxValue;
      } else if (filter.matchValue == 'ratingHousehold') {
        filter.values = [];
      } else if (filter.matchValue == 'privateLegalForms') {
        // pending from backend
      } else if (filter.matchValue == 'customerSegmentBank') {
        //  pending from backend
      } else if (filter.matchValue == 'customerSegmentBVR') {
        //  pending from backend

      } else if (filter.matchValue == 'customerSegmentVRCP') {
        //  pending from backend

      } else if ((filter.matchValue == 'datesPast') || filter.matchValue == 'datesFuture') {
        filter.minValue = null;
        filter.maxValue = null;

      }
    });

  }

  getPotentialAnalysis() {
    this.spinnerFlag = !this.showPotentailVermogen && !this.showPotentailKundengerust;
    const potentialAnalyse = this.commonUtils.setPotentialAnalysisWithFilters(this.selectedCalculationId);
    if (this.intervalEvent) {
      this.intervalEvent.unsubscribe();
      this.intervalEvent = false;
    }
    this.potentialAnalysisService.applyFilters(potentialAnalyse).subscribe((response: any) => {
      this.getPotentialAnalyseData(response.Result);
      this.guid = response.Result;
    }, () => {
      this.onError();
    });
  }

  async getPotentialAnalysisAsync() {
    this.spinnerFlag = !this.showPotentailVermogen && !this.showPotentailKundengerust;
    const potentialAnalyse = this.commonUtils.setPotentialAnalysisWithFilters(this.selectedCalculationId);
    if (this.intervalEvent) {
      this.intervalEvent.unsubscribe();
      this.intervalEvent = false;
    }
    await this.potentialAnalysisService.applyFilters(potentialAnalyse).subscribe(async (response: any) => {
      await this.getPotentialAnalyseDataAsync(response.Result);
      this.guid = response.Result;
    }, () => {
      this.onError();
    });
  }

  getPotentialAnalyseData(guidOfAppliedFilter) {

    // Abruf der Poentialanalyse unnter Angabe der Consultant-Ids
    this.potentialAnalysisService.getPotentialAnalyse(guidOfAppliedFilter, CONSULTANT_IDS.ids, DEPARTMENT_NUMBERS.numbers).subscribe((response: any) => {
      switch (response.StatusCode) {
        case 200:
          if (this.intervalEvent) {
            this.intervalEvent.unsubscribe();
            this.intervalEvent = false;
          }
          this.processPotentialAnalyseData(response);
          // vermogen report
          this.getVermogen();
          break;
        case 206:
          if (!this.intervalEvent) {
            this.intervalEvent = interval(10000).subscribe(() => {
              this.getPotentialAnalyseData(guidOfAppliedFilter);
            });
          }
          break;
        case 404:
          if (this.intervalEvent) {
            this.intervalEvent.unsubscribe();
            this.intervalEvent = false;
          }
          this.getPotentialAnalysis();
          break;
      }
    }, () => {
      this.disableDataLoad = false;
      this.spinnerFlag = false;
      this.success = false;
      this.errorMessage = this.translateService.instant('calculationNotSelectedError');
      this.commonUtils.errorSnackBar('error', '');
    });
  }

  async getPotentialAnalyseDataAsync(guidOfAppliedFilter): Promise<any> {

    return new Promise<any>(async (resolve, reject) => {
      // Abruf der Poentialanalyse unnter Angabe der Consultant-Ids
      await this.potentialAnalysisService.getPotentialAnalyse(guidOfAppliedFilter, CONSULTANT_IDS.ids, DEPARTMENT_NUMBERS.numbers).subscribe(async (response: any) => {
        switch (response.StatusCode) {
          case 200:
            if (this.intervalEvent) {
              this.intervalEvent.unsubscribe();
              this.intervalEvent = false;
            }
            this.processPotentialAnalyseData(response);
            // vermogen report
            await this.getVermogenAsync().then(() => {
              resolve(true);
            });
            break;
          case 206:
            if (!this.intervalEvent) {
              this.intervalEvent = interval(10000).subscribe(async () => {
                await this.getPotentialAnalyseDataAsync(guidOfAppliedFilter);
              });
            }
            break;
          case 404:
            if (this.intervalEvent) {
              this.intervalEvent.unsubscribe();
              this.intervalEvent = false;
            }
            await this.getPotentialAnalysisAsync().then(()=> {
              resolve(true);
            });
            break;
        }
      }, () => {
        this.disableDataLoad = false;
        this.spinnerFlag = false;
        this.success = false;
        this.errorMessage = this.translateService.instant('calculationNotSelectedError');
        this.commonUtils.errorSnackBar('error', '');
      });
    });
  }

  processPotentialAnalyseData(response) {
    this.spinnerFlag = false;
    this.success = true;
    this.errorMessage = null;
    response.Result.needThemes.forEach(theme => {
      this.commonUtils.getThemeColor(theme);

      theme.needFields.forEach(needField => {
        if (needField.cashFlowTypeId == 4835) {
          needField.cashFlowType = 'Anzahl';
        } else if (needField.cashFlowTypeId == 4836) {
          needField.cashFlowType = 'Bestand in EUR';
        } else if (needField.cashFlowTypeId == 4838) {
          needField.cashFlowType = 'Zahlungen in EUR';
        } else if (needField.cashFlowTypeId == 4837) {
          needField.cashFlowType = 'Umsatz in EUR';
        }

        // manually removed needFieldPart id 5191
        if (needField.id == 5) {
          needField.needFieldParts = needField.needFieldParts.filter(element => element.id !== 5191);
        }
      });
    });
    this.consultantNeedSolutionList = response.Result.needThemes;
    this.houseHoldCount = this.getDecimalPoint(response.Result.householdsCount);
    this.privatePersonCount = this.getDecimalPoint(response.Result.privatePersonsCount);
  }

  getDateFormat(data: string) {
    data = data.toString();
    return data.replace('.', '-');
  }

  // #region [rgba(255,0,0,0.05)]

  dialogRef2: any; 
  generatePDF() {
    this.canProcessGetAllData2 = true;
     
    this.dialogRef2 = this.dialog.open(PdfGenerationPopUpComponent, {
      width: '580px',
      disableClose: true,
      data: {
        selectedCalculationId: this.selectedCalculationId,
        consultantNeedSolutionList: this.consultantNeedSolutionList,
        potentialAnalysisFilters: this.potentialAnalysisFilters,
        profitCenterFilter: this.profitCenterFilter.selectedProfitCenterFilter,
        kalkulation: this.kalkulationList.find(kalkulation => kalkulation.value == this.selectedCalculationId),
      },
    });
    this.dialogRef2.componentInstance.startPDFConsultantProcessEvent.subscribe(this.PDFConsultantProcess.bind(this))
    this.dialogRef2.afterClosed().subscribe((orgConsultantIds) => {
      //console.log('dialog closed', orgConsultantIds);
      this.canProcessGetAllData2 = false;
      if (orgConsultantIds) {
        CONSULTANT_IDS.ids = orgConsultantIds;
        this.getAllData();
      }
    });
  }

  async PDFConsultantProcess(consultantIdsToProcess){
    this.consultantSegmentService.getAllConsultantsByHierarchie(this.selectedHierachy.id).subscribe(async (response: any) => {

      // Lookup for ids
      let consultants = response.Result;
      
      // loop throuph every consultant and process PDF
      for await (const consultantId of consultantIdsToProcess) {
        if (this.canProcessGetAllData2 == false) {
          break;
        }
        
        await this.getAllData2(consultantId).then(async () => {
          let data = {
            consultantInfo: consultants.find(x => x.id == consultantId),
            selectedCalculationId: this.selectedCalculationId,
            consultantNeedSolutionList: this.consultantNeedSolutionList,
            potentialAnalysisFilters: this.potentialAnalysisFilters,
            profitCenterFilter: this.profitCenterFilter.selectedProfitCenterFilter,
            kalkulation: this.kalkulationList.find(kalkulation => kalkulation.value == this.selectedCalculationId),
          }

          await this.dialogRef2.componentInstance.processSinglePDF(consultantId, data).then(result => {
            //console.log('processSinglePDF done', result);
          });

        }).catch(error => {
          console.error('Error in PDFConsultantProcess', error);
        });
      }
    });
  }




  // # endregion

  // #region [rgba(0,255,0,0.1)] --- Original

  // generatePDF() {
  //   const dialogRef = this.dialog.open(PdfGenerationPopUpComponent, {
  //     width: '580px',
  //     data: {
  //       selectedCalculationId: this.selectedCalculationId,
  //       consultantNeedSolutionList: this.consultantNeedSolutionList,
  //       potentialAnalysisFilters: this.potentialAnalysisFilters,
  //       profitCenterFilter: this.profitCenterFilter.selectedProfitCenterFilter,
  //       kalkulation: this.kalkulationList.find(kalkulation => kalkulation.value == this.selectedCalculationId),
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe(() => {
  //   });
  // }

  // #endregion

  navigateToChart() {
    this.router.navigateByUrl(`bv-home/potential-analysis-vermogen`);
  }

  isVermogen(element) {
    return element.includes('Vermögen');
  }

  showVermogenChartPage() {
    this.showPotentailVermogen = true;
    this.showPotentailKundengerust = false;
  }

  showPotentailKundengerustPage() {
    this.showPotentailKundengerust = true;
    this.showPotentailVermogen = false;
  }

  closeReport() {
    this.showPotentailVermogen = false;
    this.showPotentailKundengerust = false;
  }


  // set sidebar toggle status
  getToggleStatus() {
    return this.commonUtils.getToggleStatus().subscribe((Response) => {
      return Response;
    });
  }

  getDecimalPoint(number: number) {
    return number.toLocaleString('de-DE');
  }


  ngOnDestroy() {
    VERMOGEN_REPORT_DATA.data = null;
    AllDataLoadDetail.loaded = true;
    if (this.chartComponent) {
      this.chartComponent.spinnerFlag = false;
    }
  }

  getLeftsideBarStatus() {
    return BV_SIDEBAR_STATUS.status;
  }
}

