import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { TranslateService } from '@ngx-translate/core';
import { DataImportService } from '../../services/data-import.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrgaStructImportMode } from 'src/app/shared/enums/orga-struct-import-mode.enum';

@Component({
  selector: 'app-add-new-encryption-key',
  templateUrl: './add-new-encryption-key.component.html',
  styleUrls: ['./add-new-encryption-key.component.scss']
})
export class AddNewEncryptionKeyComponent implements OnInit {

  addEncryptionKeyForm: FormGroup;
  dataPresent = false;
  oneSmallCase: any;
  oneUpperCase: any;
  oneNumber: any;
  passwordLength: any;
  hide = true;
  fileNames: any;
  success: any;
  message: any;


  orgaStructImportmode: OrgaStructImportMode;
  uploadedDeadline: string;
  deadlineToCopyFrom: string;
  hierarchyIdsToCopy: string;

  constructor(private translate: TranslateService,
    private dataImportService: DataImportService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AddNewEncryptionKeyComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fileNames = this.data.fileNameArray;
  }
  // Button Options
  btnOpts: MatProgressButtonOptions = {
    active: false,
    text: this.translate.instant('establish'),
    spinnerSize: 19,
    raised: true,
    stroked: true,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: true,
    mode: 'indeterminate',
  };


  initForm() {
    this.addEncryptionKeyForm = new FormGroup({
      password: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?['#*+~!§$%&\/\\()=?`´.,\-_<>|^°]).{8,50}$/),
      ])),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required, this.confirmPasswordValidation.bind(this)]))
    });
  }

  encryptionKeyInputChange() {
    if (this.addEncryptionKeyForm !== undefined) {
      if (this.addEncryptionKeyForm.value.password && this.addEncryptionKeyForm.value.confirmPassword) {
        this.btnOpts.disabled = false;
      } else {
        this.btnOpts.disabled = true;
      }
    }
  }

  matchPassword() {
    if (this.addEncryptionKeyForm.value.password && this.addEncryptionKeyForm.value.confirmPassword) {
      return this.addEncryptionKeyForm.value.password !== this.addEncryptionKeyForm.value.confirmPassword;
    } else {
      return false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEncryptionKeyForm.controls[controlName].hasError(errorName);
  }
  passwordDoNotMatch() {
    if (this.addEncryptionKeyForm.value.password != null && this.addEncryptionKeyForm.value.confirmPassword != null) {
      this.dataPresent = true;
      return this.addEncryptionKeyForm.value.password === this.addEncryptionKeyForm.value.confirmPassword;
    }
  }

  ngOnInit() {
    this.uploadedDeadline = this.data.uploadedDeadline;
    this.orgaStructImportmode = this.data.orgaStructImportmode;
    this.deadlineToCopyFrom = this.data.deadlineToCopyFrom;
    this.hierarchyIdsToCopy = this.data.hierarchyIdsToCopy;
    this.initForm();
  }
  private confirmPasswordValidation(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.addEncryptionKeyForm !== undefined) {

      if (control.value !== this.addEncryptionKeyForm.value.password) {
        return { 'confirmPasswordValidation': true };
      }
      return null;
    }
  }
  addNewEncryptionKey() {
    if (this.addEncryptionKeyForm.valid) {
      this.btnOpts.active = true;

      this.dataImportService.validateTransferToDB(this.fileNames, this.addEncryptionKeyForm.value.password,
        this.addEncryptionKeyForm.value.confirmPassword)
      .subscribe((response) => {
        //this.dataImportService.transferFilesToDb(this.fileNames, this.addEncryptionKeyForm.value.password).subscribe();
        this.dataImportService.transferFilesToDbWithMode(this.fileNames, this.addEncryptionKeyForm.value.password, this.orgaStructImportmode, this.uploadedDeadline, this.deadlineToCopyFrom, this.hierarchyIdsToCopy).subscribe();
        this.success = true;
        this.btnOpts.active = false;
        this.btnOpts.disabled = true;
        this.message = this.translateService.instant('transferToDbSuccess');

        setTimeout(() => {
          this.router.navigateByUrl(`bv-home/dataimport-list`);
          this.dialogRef.close();
        }, 1000);
      }, (err) => {
        this.btnOpts.active = false;
        this.btnOpts.disabled = true;

        this.router.navigateByUrl(`bv-home/dataimport-list`);
        this.dialogRef.close();
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
