import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultantSegmentService {
  private endpoint;
  private environment;
  private endpointConsultant: any;
  private clientId: any;

  //#region Constructor
  constructor(
    private http: HttpClient,
    private commonUtils: CommonUtilsService,
    private configurationservice: ConfigurationService,
    private userSessionService: UserSessionService
  ) { 
    this.environment = configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/ConsultantSegments`;
    this.endpointConsultant = `${this.environment.musterkundeDevEndpoint}/api/Consultants`;
    this.clientId = userSessionService.getClientId().toString();
  }
  //#endregion


  //#region Methods
  getClientIdHeader() {
    const headers: any = {
      'clientId': this.clientId
    };

    return { headers: new HttpHeaders(headers) };
  }

  getAllConsultantSegments() {
    const url = this.endpoint;
    return this.http.get(url, this.getClientIdHeader());
  }

  getAllConsultantSegmentsByHierarchyId(orgaStructHierarchyId) {
    const url = `${this.endpoint}/consultantSegmentsByHierarchy/?orgaStructHierarchyId=${orgaStructHierarchyId}`;
    return this.http.get(url, this.getClientIdHeader());
  }

  saveConsultantSegment(data) {
    const url = this.endpoint;
    return this.http.post(url, data, this.getClientIdHeader());
  }

  getConsultantSegmentById(id) {
    const url = `${this.endpoint}/${id}`;
    return this.http.get(url, this.getClientIdHeader());
  }

  getCanDeleteConsultantSegment(id) {
    const url = `${this.endpoint}/can-delete/${id}`;
    return this.http.get(url, this.getClientIdHeader());
  }
  deleteConsultantSegment(id) {
    const url = `${this.endpoint}/${id}`;
    return this.http.delete(url, this.getClientIdHeader());
  }

  updateConsultantSegment(id, data) {
    const url = `${this.endpoint}/${id}`;
    return this.http.put(url, data, this.getClientIdHeader());
  }

  getAllConsultants() {
    const url = this.endpointConsultant;
    return this.http.get(url, this.getClientIdHeader());
  }

  getAllConsultantsByHierarchie(orgaStructHierarchyId) {
    const url = `${this.endpointConsultant}/consultantsByHierarchy/?orgaStructHierarchyId=${orgaStructHierarchyId}`;
    return this.http.get(url, this.getClientIdHeader());
  }


  //#endregion


}
