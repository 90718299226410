import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environmentProd} from '../../environments/environment.prod';
import {environmentDev} from '../../environments/environment.dev';
import {environmentQS} from '../../environments/environment.qs';
import {environmentCrossDomain} from '../../environments/crossdomainenv.qs';
import {staging} from '../../environments/environment.staging';

@Injectable()
export class ConfigurationService {

  constructor(private http: HttpClient) {
  }

  getEnvironmentVariables() {
    let environmentSettings;
    const domain = window.location.hostname;
    switch (domain) {

      //@TODO: rework that old emnicon stuff ...

      //    production
      case 'frontend.vr-musterkundenansatz.de':
        environmentSettings = environmentProd;
        break;
      case 'dev-frontend.vr-musterkundenansatz.de':
        environmentSettings = staging;
        break;
      case 'qs-musterkunde.emnicon.de':
        environmentSettings = environmentQS;
        break;
      case 'musterkunde.weberfolg-tools.de':
        environmentSettings = environmentCrossDomain;
        break;
      // development
      case 'localhost':
        environmentSettings = environmentDev;
        break;
      // default
      default:
        environmentSettings = environmentProd;
        break;
    }
    return environmentSettings;
  }


}
