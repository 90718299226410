import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-kalkulation-dialog',
  templateUrl: './delete-kalkulation-dialog.component.html',
  styleUrls: ['./delete-kalkulation-dialog.component.scss']
})
export class DeleteKalkulationDialogComponent implements OnInit {
  calculation;
  type;
  parent;
  constructor(public matDialog: MatDialogRef<DeleteKalkulationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.calculation = data.calculation;
    this.parent = data.parent
    this.type = data.type;
  }

  ngOnInit() {
  }

  closeDialog(isDeleted) {
    this.matDialog.close(isDeleted);
  }

}
