<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="50px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'consultantNeedsAndSolution' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="12" rowHeight="700px" class="m-t-50 m-b-50">

    <mat-grid-tile flex-start flex-align-top [colspan]="12">
        <div class="div-spinner" *ngIf="spinnerFlag">
            <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
          </div>
      <div class="w-100"  *ngIf="!spinnerFlag">
        <div>
          <mat-label class="m-b-10" class="info-text d-flex nowrap p-30">
            <i class="material-icons">info</i>
            <span class="m-l-10">{{'consultantNeedsAndSolutionInfo' | translate}}
            </span>
          </mat-label>
        </div>
        <div class="w-100 m-t-20 m-b-10">

          <mat-accordion multi="true" class="custom example-headers-align mat-elevation-z8 w-100">
            <mat-expansion-panel [ngStyle]="getLeftBorder(needAndSolution.themeColor)"

              *ngFor="let needAndSolution of consultantNeedSolutionList">
              <mat-expansion-panel-header>
                <mat-panel-title>
                   {{needAndSolution.description}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <!-- sub expansion panel -->
              <span class="list-label">{{'requirements' | translate}}</span>
              <span class="list-label-2">
                {{'solutions' | translate}}
              </span>
              <mat-expansion-panel hideToggle #expId *ngFor="let needs of needAndSolution.needFields">
                <mat-expansion-panel-header class="pt-10">
                  <mat-panel-title>
                    <i class="material-icons" *ngIf="!expId.expanded">
                      keyboard_arrow_right
                    </i>
                    <i class="material-icons" *ngIf="expId.expanded">
                      keyboard_arrow_down
                    </i>
                    <span>
                      <div class="p-t-5">
                        {{needs.name}}</div>
                    </span>
                  </mat-panel-title>
                  <mat-panel-description>

                  </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-list>
                  <mat-list-item class="expansion-inner-list" *ngFor="let partialNeeds of needs.needFieldParts">
                    <span class="expantion-list-left">
                      {{partialNeeds.name}}
                    </span>
                    <span class="m-l-20" class="inner-needs">
                      <div *ngFor="let solution of partialNeeds.needSolutions; let i = index">{{solution.name}}
                      </div>
                    </span>
                  </mat-list-item>
                </mat-list>
              </mat-expansion-panel>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>


    </mat-grid-tile>
  </mat-grid-list>
</div>
