import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService,
    private userSessionService: UserSessionService) { }

  createHeaderObject() {
    const headers: any = {
      'customerId': this.userSessionService.getCustomerAndUserId().customerId
    };
    return { headers: new HttpHeaders(headers) };
  }


  getAllInvitation(pageIndex, pagesize, payload) {
    const url = this.commonUtils.getAPIUrl(`Invitation/Search?pageIndex=${pageIndex}&pageSize=${pagesize}`);
    return this.http.post(url, payload,this.createHeaderObject());
  }

  getInvitationByEmail(email) {
    const url = this.commonUtils.getAPIUrl(`Invitation/${email}`);
    return this.http.get(url, this.createHeaderObject())
  }

  sendInvitation(invitationId){
    const url = this.commonUtils.getAPIUrl(`Invitation/ResendInvitation/${invitationId}`);
    return this.http.post(url,{},this.createHeaderObject());
  }
}
