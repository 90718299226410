<div class="note" *ngIf="openedFromSideMenu && !selectedCalculationId">
  {{'calculationNotSelectedError' | translate}}
</div>
<div class="div-spinner" *ngIf="!spinnerFlag">
  <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
</div>


<div *ngIf="spinnerFlag" class="card-shadow"
  style="border-radius: 2px;background: white;border-left: 6px solid;border-left-color: #679344;padding-left: 10px;border-radius: 2px;padding-bottom: 30px;  padding: 10px ">
  <h2 class="headerColor" style="margin-top:5px;font-weight: normal;">{{'assetStructureReport' | translate}}
    <i *ngIf="!openedFromSideMenu" class="material-icons"
      style="float: right;color: black;cursor: pointer;font-size: 30px;margin-right: 5px;" (click)="closeChart()">
      close
    </i></h2>

  <div class="reportForPdf" *ngIf="dataSource">
    <div>
      <div class="chart-left" md-col-6 [chart]="vermogensverteilungAktuellChart"></div>
      <div class="chart-right" md-col-6 [chart]="vermogensverteilungNachMusterallokationChart"></div>
    </div>



    <table class="vermogensverteilungTable" [ngClass]="{'w-100' : !dataForPDF , 'table-pdf' : dataForPDF}">
      <thead>
        <tr>
          <th colspan="1"></th>
          <th colspan="2"></th>
          <th colspan="3">{{'assets' | translate}}</th>
          <th colspan="2"></th>
          <!-- <th colspan="3" class=" darkBorderHeader">{{'assets' | translate}} {{'saveUp' | translate}}</th> -->
        </tr>
        <tr>
          <th colspan="1" class=" ">{{'designation' | translate}}</th>
          <th colspan="2">{{'dateInventory' | translate}}</th>
          <th colspan="3">{{'redeploymentNeeds' | translate}}</th>
          <th colspan="2">{{'potentialStock' | translate}}</th>
          <!-- <th colspan="1" class="darkBorderHeader">Zuwachs</th> -->
          <!-- <th colspan="2">{{'potentialStock' | translate}} 2</th> -->
        </tr>
        <tr>
          <th class=" "></th>
          <th class="topBorder">{{'absolute' | translate}}</th>
          <th class="topBorder">{{'percentage' | translate}}</th>
          <th class="topBorder">{{'invest' | translate}}</th>
          <th class="topBorder">{{'salesVolume' | translate}}</th>
          <th class="topBorder">{{'optimise' | translate}}</th>
          <th class="topBorder">{{'absolute' | translate}}</th>
          <th class="topBorder">{{'percentage' | translate}}</th>
          <!-- <th class="topBorder darkBorderHeader">{{'fromSavings' | translate}}</th> -->
          <!-- <th class="topBorder">{{'absolute' | translate}}</th> -->
          <!-- <th class="topBorder ">{{'delta' | translate}}</th> -->
        </tr>
      </thead>
      <tbody class="colorTableRow">
        <tr *ngFor="let report of dataSource; let i = index">
          <td align="center">
            <p>
              <div class="square" *ngIf="report.color" [ngStyle]="{'background-color': report.color}">
              </div>
              <p> {{report.title}}</p>
              <p>
          </td>
          <td align="center">
            <p>{{report.stichtagsbestand.absolut | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.stichtagsbestand.prozentual | alterChartTableData: true}}</p>
          </td>
          <td align="center">
            <p>{{report.umschichtungsbedarf.anlegen | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.umschichtungsbedarf.verkaufvolumen | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.umschichtungsbedarf.optimieren | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.potentialbestand1.absolut | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.potentialbestand1.prozentual | alterChartTableData: true}}</p>
          </td>
          <!-- <td align="center">
            <p>{{report.zuwachs.ausSparleistungen | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.potentialbestand2.absolut | alterChartTableData}}</p>
          </td>
          <td align="center">
            <p>{{report.potentialbestand2.delta | alterChartTableData : true}}</p>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
