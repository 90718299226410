export class AssetClass {

    id: number;
    riskCategoryId: number;
    riskCategory: any;
    deadline: string;
    clientId: number;
    liquidityPercentage: number;
    proportionSubstanceValues: number;
    shareAlternatives: number;
    shareMonetaryValue: number;
    shareAssets: number;
}