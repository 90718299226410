import { Component, OnInit } from '@angular/core';
import { Benutzer } from '../../models/benutzer.model';
import { BenutzerService } from '../../service/benutzer.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { ROLES_SCOPES } from 'src/app/app.config';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { TranslateService } from '@ngx-translate/core';
import { ClaimGroupService } from '../../service/claim-group.service';
import { hasWhitespaceValidator } from '../../../../shared/validator/whitespace-validator';

@Component({
  selector: 'app-benutzer-add',
  templateUrl: './benutzer-add.component.html',
  styleUrls: ['./benutzer-add.component.scss']
})
export class BenutzerAddComponent implements OnInit {
  addressAdmin = 'Herr';
  hide = true;
  userForm: FormGroup;
  // roles = Roles;
  claims = ROLES_SCOPES;
  roles = [];
  spinnerFlag;
  constructor(private benutzerService: BenutzerService,
    private router: Router,
    private dialog: MatDialog,
    private commonUtils: CommonUtilsService,
    private userSessionService: UserSessionService,
    private translateService: TranslateService,
    private claimGroupService: ClaimGroupService) {
    this.getClaimGroup();
  }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.userForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      userName: new FormControl('', [Validators.required, hasWhitespaceValidator()]),
    });
  }


  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }


  getClaimGroup() {
    this.spinnerFlag = true;
    this.claimGroupService.getClaimGroups().subscribe((Res: any) => {
      this.roles = Res.Result.sort((a, b) => a.Name > b.Name ? 1 : -1);
      this.spinnerFlag = false;
    })
  }
  saveUser() {
    let userObj = this.getUserObject();
    this.benutzerService.saveUser(userObj).subscribe(async (response: any) => {
      this.router.navigateByUrl('/bv-home/benutzerlist');
      this.commonUtils.successSnackBar(this.translateService.instant('invitationMailSentMsg'));
    }, (err) => {
      this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage);
    });
  }

  getUserObject() {
    let benutzer = new Benutzer();
    let IsAdministrator = false;
    let selectedRole = [];
    this.roles.forEach(role => {
      if (role.select) {
        if (role.InternalName === "user_admin") {
          IsAdministrator = true;
        }
        selectedRole.push(role.Id);
      }
    });
    benutzer.FirstName = this.userForm.value['firstName'];
    benutzer.LastName = this.userForm.value['lastName'];
    benutzer.Email = this.userForm.value['email'];
    benutzer.UserName = this.userForm.value['userName'];
    benutzer.GroupIds = selectedRole;
    if (IsAdministrator) {
      benutzer.IsAdministrator = true;
    } else {
      benutzer.IsAdministrator = false;
    }
    benutzer.ClientId = this.userSessionService.getOAuthClientId();
    return benutzer;
  }

  clearUserForm() {
    if (!this.userForm.dirty) {
      this.router.navigateByUrl('/bv-home/benutzerlist');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/benutzerlist');
        }
      });

    }
  }

  disableForm() {
    let roleSelected = false;
    this.roles.forEach(role => {
      if (role.select) {
        roleSelected = true;
      }
    });
    if (this.userForm.valid) {
      return !roleSelected;
    }
    return true;
  }
}

