export class Consultant {
    'id': number;
    'consultantClientId': number;
    'clientId': number;
    'description': string;
    'creditInstitutionId': any;
    'genoUserId': any;
    'name': string;
    'creditInstitutionPartMarketId': any;
    'consultantCompetenceCenterId': any;
    'consultantTeamId': any;
    'deadline': any;
}
