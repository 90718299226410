<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'marketingFabrik' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>
</div>
<div class="activity-icon">

</div>
