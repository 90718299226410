import { Component, OnInit } from '@angular/core';
import { DeleteMandantDialogComponent } from '../delete-mandant-dialog/delete-mandant-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MandantenService } from '../../services/mandanten.service';
import { Mandanten } from '../../models/mandanten';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Component({
  selector: 'app-manage-mandanten-base-data',
  templateUrl: './manage-mandanten-base-data.component.html',
  styleUrls: ['./manage-mandanten-base-data.component.scss']
})
export class ManageMandantenBaseDataComponent implements OnInit {

  id: any;
  name: any;
  address: any;
  postCode: any;
  mandaten = new Mandanten();
  oldMandaten = new Mandanten();
  userId: any;
  oldUserName: any;
  oldAddress: any;
  oldPostCode: any;
  editName = true;
  editAddress = true;
  editZipcode = true;
  editedName: any;


  constructor(private dialog: MatDialog,
    private route: ActivatedRoute,
    private mandatenService: MandantenService,
    private userSessionService: UserSessionService,
    private router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.mandatenService.getCustomerById(this.id).subscribe((Response: any) => {
      this.oldUserName = Response.Result.Name;
      this.oldAddress = Response.Result.Address;
      this.oldPostCode = Response.Result.ZipCode;
      this.initValue(Response.Result);
      this.userId = this.userSessionService.getCustomerAndUserId().userId;

    });
  }

  initValue(data) {
    this.mandaten.Name = data.Name;
    this.mandaten.Address = data.Address;
    this.mandaten.ZipCode = data.ZipCode;
  }

  ngOnInit() {
    this.editedName = this.name;

  }

  editUserName() {
    this.editName = !this.editName;
    if (this.oldUserName !== this.mandaten.Name) {
      this.mandatenService.updateMandaten(this.id, this.mandaten, this.userId).subscribe((response) => {
      });
    }
  }
  openUserNameEditBox() {
    this.oldUserName = this.mandaten.Name;
    this.editName = !this.editName;
  }


  clearUserName() {
    this.mandaten.Name = this.oldUserName;
    this.editName = !this.editName;
  }


  openAddressEditBox() {
    this.oldAddress = this.mandaten.Address;
    this.oldPostCode = this.mandaten.ZipCode;
    this.editAddress = !this.editAddress;
  }

  clearAddress() {
    this.mandaten.Address = this.oldAddress;
    this.mandaten.ZipCode = this.oldPostCode;
    this.editAddress = !this.editAddress;
  }

  updateAddress() {
    this.editAddress = !this.editAddress;

    if (this.mandaten.Address !== this.oldAddress || this.mandaten.ZipCode !== this.oldPostCode) {
      this.mandatenService.updateMandaten(this.id, this.mandaten, this.userId).subscribe((response) => {
      });
    }
  }

  deleteMandantDialog() {
    const dialogRef = this.dialog.open(DeleteMandantDialogComponent, {
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // delete
      }
    });
  }

  //  back button
  navigateBackToMandatenList() {
    this.router.navigateByUrl(`home/mandantenlist`);
  }
}
