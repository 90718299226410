<div>
    <h4>Buchungsart bearbeiten</h4>
    <div *ngIf="!spinnerFlag">
        <div class="field">
            <div md-col-4>
                <mat-label>Buchungsart</mat-label>
            </div>
            <div md-col-8>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput placeholder="" disabled [(ngModel)]="bookingTypeName" name="bookingTypeName"
                        type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="field">
            <div md-col-4>
                <mat-label>Bezeichnung:</mat-label>
            </div>
            <div md-col-8>
                <mat-form-field class="full-width" appearance="outline">
                    <input matInput placeholder="" [(ngModel)]="bookingTypeDescription" name="bookingTypeDescription"
                        type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="field">
            <div md-col-4>
                <mat-label>Buchungsgruppe</mat-label>
            </div>
            <div md-col-8>
                <mat-form-field class="full-width" appearance="outline">
                    <!-- <input matInput placeholder="" [(ngModel)]="bookingTypeGroup" name="bookingTypeGroup" type="text"> -->
                    <mat-select [(ngModel)]="bookingTypeId" name="bookingTypeGroup">
                        <mat-option *ngFor="let type of typeGroups" [value]="type.id">{{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-actions align="end" class="p-10 m-t-20">
            <button mat-raised-button color="basic" (click)="abort()" class="m-r-10">Abbrechen</button>
            <button mat-raised-button color="primary" (click)="save()">Speichern</button>
        </div>
    </div>
    <div *ngIf="spinnerFlag">
        <mat-grid-list cols="12" rowHeight="250px" class="m-t-50 m-b-50">
            <div class="div-spinner">
                <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
            </div>
        </mat-grid-list>
    </div>
</div>