<mat-accordion multi="true" class="custom example-headers-align mat-elevation-z8 w-100">
  <div *ngIf="spinnerFlag">
    <div class="div-spinner">
      <mat-spinner class="custom-field display-top" mode="indeterminate"></mat-spinner>
    </div>
  </div>
  <mat-expansion-panel hideToggle *ngFor="let data of parentDepartmentStructure" #expId ngDefaultControl
                       [(ngModel)]="data.expanded" [expanded]="data.expanded">
    <mat-expansion-panel-header class="pt-10" (click)="storeState(data)">
      <mat-panel-title>
        <i class="material-icons" *ngIf="!expId.expanded">
          keyboard_arrow_right
        </i>
        <i class="material-icons" *ngIf="expId.expanded">
          keyboard_arrow_down
        </i>
        <span> {{data.descriptionDepartment}}
          <div class="p-t-5 grey-text fw-300">
            {{getType(data.profitCenterRegionStructureId)}}
          </div>
        </span>
      </mat-panel-title>
      <mat-panel-description>
        <span class="action-icons" style="">
          <i class="material-icons" (click)="addEditBranchNode(false, true, data.id); $event.stopPropagation();">add</i>
          <i class="material-icons"
             (click)="addEditBranchNode(true, false, data.id); $event.stopPropagation();">edit</i>
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <hr>
    <div *ngFor="let childProfitCenter1 of data.childProfitCenter">
      <mat-expansion-panel #expId2
                           ngDefaultControl
                           class="inner-expand consultant-expand"
                           [(ngModel)]="childProfitCenter1.expanded"
                           [expanded]="childProfitCenter1.expanded"
                           *ngIf="childProfitCenter1.profitCenterRegionStructureId == 2">
        <!-- Regions of main profit center -->
        <mat-expansion-panel-header (click)="storeState(childProfitCenter1)">
          <mat-panel-title>
            <i class="material-icons" *ngIf="!childProfitCenter1.expanded">
              keyboard_arrow_right
            </i>
            <i class="material-icons" *ngIf="childProfitCenter1.expanded">
              keyboard_arrow_down
            </i>
            <span> {{childProfitCenter1.descriptionDepartment}}
              <div class="p-t-5 grey-text fw-300">
                {{getType(childProfitCenter1.profitCenterRegionStructureId)}}</div>
              </span>
          </mat-panel-title>
          <mat-panel-description>
            <span class="action-icons">
              <span>
                <i class="material-icons"
                   (click)="addEditBranchNode(true, false, childProfitCenter1.id); $event.stopPropagation();">edit</i>
              </span>
            </span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Departments -->
        <mat-list *ngFor="let childProfitCenter2 of childProfitCenter1.childProfitCenter" class="consultant-inner-list">
          <mat-list-item class="expansion-inner-list">
            <span>
              {{childProfitCenter2.descriptionDepartment}}
              <div class="p-t-5 grey-text fw-300">
                {{getType(childProfitCenter2.profitCenterRegionStructureId)}}
              </div>
              <span class="action-icons"
                    style="text-align: right; position:absolute; right:0; margin-right: -30px;">
                <span class="expansion-inner-icon">
                  <i class="material-icons"
                     (click)="addEditBranchNode(true, false, childProfitCenter2.id); $event.stopPropagation();">edit</i>
                </span>
              </span>
            </span>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </div>
    <!-- Departments of main profit center -->
    <div *ngFor="let childProfitCenter1 of data.childProfitCenter">
      <mat-list class="consultant-inner-list" *ngIf="childProfitCenter1.profitCenterRegionStructureId == 3">
        <mat-list-item class="expansion-inner-list">
          <span>
            {{childProfitCenter1.descriptionDepartment}}
            <div class="p-t-5 grey-text fw-300">
                {{getType(childProfitCenter1.profitCenterRegionStructureId)}}
            </div>
            <span class="action-icons"
                  style="text-align: right; position:absolute; right:0; margin-right: -10px;">
              <span class="expansion-inner-icon">
                <i class="material-icons"
                   (click)="addEditBranchNode(true, false, childProfitCenter1.id); $event.stopPropagation();">edit</i>
              </span>
            </span>
          </span>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-expansion-panel>
</mat-accordion>
