<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="60px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'productClassification' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>

</div>

<div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap fxLayoutGap="0.5%"
  fxLayoutAlign="center">
  <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
    <div *ngIf="spinnerFlag" class="progress-spinner spin-position">
      <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
    </div>
    <div class="w-100 m-b-50 card-shadow">
      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" multi matSort matSortDisableClear="true" (matSortChange)="sortData($event)" matSortActive="Name"
          matSortDirection="asc" multiTemplateDataRows class="mat-elevation-z8 productlist-table">

          <ng-container matColumnDef="arrow">
            <th width="50" mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <i class="material-icons arrow-right">
                keyboard_arrow_right
              </i>
              <i class="material-icons arrow-down">
                keyboard_arrow_down
              </i>
            </td>
          </ng-container>
          <ng-container matColumnDef="Name">
            <th width="180" mat-header-cell *matHeaderCellDef mat-sort-header> {{'productName' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.name ? element.name : '-'}} </td>
          </ng-container>
          <ng-container matColumnDef="Product01">
            <th width="80" mat-header-cell *matHeaderCellDef mat-sort-header> {{'prodId1' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.product01}} </td>
          </ng-container>
          <ng-container matColumnDef="Product02">
            <th width="220" mat-header-cell *matHeaderCellDef mat-sort-header> {{'prodId2' | translate}} </th>
            <td mat-cell class="text-wrap" *matCellDef="let element"> {{element.product02}} </td>
          </ng-container>
          <ng-container matColumnDef="ISIN">
            <th width="110" mat-header-cell *matHeaderCellDef mat-sort-header> {{'isinNo' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.isin}} </td>
          </ng-container>
          <ng-container matColumnDef="Partner.Name">
            <th width="150" mat-header-cell *matHeaderCellDef mat-sort-header> {{'providers' | translate}}
            </th>
            <td mat-cell class="text-wrap" *matCellDef="let element">
              <div *ngIf="element.partner.name">{{element.partner.name}}</div>
              <div *ngIf="!element.partner.name">-</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Zuordnungen">
            <th width="100" mat-header-cell *matHeaderCellDef mat-sort-header> {{'assignmentNumber' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" class="p-15"> {{element.zuordnungen}} </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element.id == expandedElement ? 'expanded' : 'collapsed'">

                <div class="example-element-description">
                  <table class="inner-table">
                    <tr style="border-bottom: 1px solid ">
                      <th>{{'solution' | translate}}</th>
                      <th>{{'proportionOf' | translate}} {{'payment' | translate}}</th>
                      <th>{{'proportionOf' | translate}} {{'Vermögen' | translate}}</th>
                      <th>{{'proportionOf' | translate}} {{'Kredit' | translate}}</th>
                      <th>Immobilienbesitz notwendig</th>
                    </tr>

                    <tr *ngFor="let solutions of element.productCombinationNeedSolutionViewModels">
                      <td>{{solutions.losung}}</td>
                      <td>{{solutions.anteilZahlung}} %</td>
                      <td>{{solutions.anteilVermogen}} %</td>
                      <td>{{solutions.anteilKredit}} %</td>
                      <td>
                        <div *ngIf="solutions.immobilienbesitz !== 1"> nein</div>
                        <div *ngIf="solutions.immobilienbesitz === 1"> ja </div>
                      </td>
                    </tr>
                  </table>

                  <span>
                    <!-- <button (click)="edit(element.id)" mat-button>edit button</button> -->
                    <button mat-button class="custom-button m-r-5" (click)="editProdukt(element.id)">{{'editAssignment' | translate}}</button>
                    <button mat-button class="custom-button" (click)="resetStandardData(element.id)" *ngIf="element.isModified">{{'resetToDefaultSolutionValues' | translate}}</button>
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element.id ? null : element.id">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
      </div>
      <mat-paginator class="m-t-5" [length]='totalCount' showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 30, 50]"
        (page)="paginationEvent($event)" #paginator>
      </mat-paginator>
    </div>
  </div>
  <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="">
    <div class="d-flex w-100 fd-col" class="sidebar-form" fxLayout="column" fxLayout.md="row" fxLayout.sm="row" fxLayoutWrap
      fxLayoutGap="0.5%" fxLayoutAlign="flex-start">



        <mat-form-field>
          <mat-select matNativeControl [(value)]="productProviderFilter" (selectionChange)="filterData($event)" required>
            <mat-option [value]="filter.id" *ngFor="let filter of productProviders">{{filter.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select matNativeControl [(value)]="productStatusFilter" (selectionChange)="filterData($event)" required>
            <mat-option [value]="filter.id" *ngFor="let filter of productStatuses">{{filter.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div >
          <mat-form-field class="w-100">
            <input matInput [placeholder]="'productAssignmentsSearchLabel' | translate"  
                [(ngModel)]="searchString" 
                name="searchString" 
                (keyup.enter)="searchData()" 
                (keyup.esc)="searchData(true)">
            <button *ngIf="searchString" matSuffix mat-icon-button aria-label="Clear" (click)="searchData(true)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <button mat-button class="custom-button duplicate-button" (click)="searchData()" [disabled]="spinnerFlag">
          {{'search' | translate}}
        </button>

    </div>
  </div>
</div>