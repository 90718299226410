import { MUKU_SCOPE } from 'src/app/app.config';


export const environmentCrossDomain = {

  production: true,
  dev: false,
  originUrl: 'http://vrcp-srv-web03.northeurope.cloudapp.azure.com',
  identityUrl: 'http://vrcp-srv-web03.northeurope.cloudapp.azure.com',
  client_id: 'Musterkunde.IntegrationTest.Team',
  scope: MUKU_SCOPE,
  version: '0.1',
  service_id: 2,

  // service endpoints

  musterkundeDevEndpoint: 'http://vrcp-srv-web03.northeurope.cloudapp.azure.com',

  datenImportendpoint: 'http://vrcp-srv-web03.northeurope.cloudapp.azure.com',

  devAuthManagementEndpoint: 'http://vrcp-srv-web03.northeurope.cloudapp.azure.com'

};
