import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
	providedIn: 'root'
})
export class RightSidebarService {
	filterObject: any[] = [];
	private sidenav: MatSidenav;
	toggleRightSide = false;



	public setSidenav(sidenav: MatSidenav) {
		this.sidenav = sidenav;
	}

	public open() {
		this.toggleRightSide = !this.toggleRightSide;

		return this.sidenav.open();
	}
	getSideToggleParam() {
		return this.toggleRightSide;
	}

	public close() {
		this.toggleRightSide = !this.toggleRightSide;
		return this.sidenav.close();
	}

	public toggle(): void {
		this.toggleRightSide = !this.toggleRightSide;
		this.sidenav.toggle();
	}

	getFilterObject() {
		return this.filterObject;
	}
	setFilterObject(object) {
		this.filterObject = object;
	}
}
