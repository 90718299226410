<ul class="header-list">
  <li>{{userDetails.CustomerModel.Name}}</li>
    <li>
        <button mat-button [matMenuTriggerFor]="menu">{{userDetails.FirstName}} {{userDetails.LastName}}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" style="margin: 0 !important">
            <button mat-menu-item (click)="logout()">{{'logout' | translate}}</button>
        </mat-menu>
    </li>
</ul>
