<!-- sidebar -->
<app-potentialanalyse-filter-bar *ngIf="selectedCalculationDeadline"
                                 (closeEvent)="closeSidebar($event)"
                                 (selectedHierarchyEvent)="setSelectedHierachy($event)"
                                 [sideBarType]="sideBarType"
                                 [houseHoldCount]="houseHoldCount"
                                 [privatePersonCount]="privatePersonCount"
                                 [selectedDeadline]="selectedCalculationDeadline"
                                 class="right-sidebar"
                                 [ngClass]="{'right-side-toggle' : sideToggle, 'left-bar' : getLeftsideBarStatus()}">
</app-potentialanalyse-filter-bar>
​
<!--main content-->
​ ​
<!-- Page Heading -->
<div [ngClass]="{'overlay' : sideToggle , 'overlay-left' : getToggleStatus()}"></div>
<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'potentialAnalysis' | translate}}
      </h1>
    </mat-grid-tile>
    ​
  </mat-grid-list>
  <!-- End: Page Heading -->
  <!-- New Mailn content -->
  <mat-grid-list cols="12" rowHeight="700px" class="m-t-50 m-b-50" *ngIf="mainSpinner">
    <div class="div-spinner">
      <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
    </div>
  </mat-grid-list>
</div>
<div *ngIf="!mainSpinner">
  <!-- main page -->
  <div class="container maincontent-wrapper"
       fxLayout="row"
       fxLayout.md="column"
       fxLayout.sm="column"
       fxLayoutWrap
       fxLayoutGap="0.5%"
       fxLayoutAlign="center">
    ​
    <!-- left -->
    <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">
      <div class="grid-spacin" >
        <div class="div-spinner" *ngIf="!commonSpinner">
          <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
        </div>
        ​
        <div *ngIf="errorMessage" class="error-msg">
          {{errorMessage}}
        </div>
        ​
        <div class=" w-100 m-b-10" *ngIf="success">
          <div class=" p-b-15 ">{{'analysisBasedOn' | translate}} {{houseHoldCount}} {{'keepHouse' | translate}}
            / {{privatePersonCount}} {{'individuals'
              | translate}}
          </div>
          <mat-accordion *ngIf="!showPotentailVermogen && !showPotentailKundengerust"
                         multi="true"
                         class="custom example-headers-align mat-elevation-z8 w-100">
            <mat-expansion-panel class="analysis-tab border-wrapper"
                                 [ngStyle]="getLeftBorder(needAndSolution.themeColor)"
                                 *ngFor="let needAndSolution of consultantNeedSolutionList">
              <mat-expansion-panel-header class="analysis-title">
                <mat-panel-title>
                  <span class="title" [ngStyle]="getThemeColor(needAndSolution.themeColor)">
                    {{needAndSolution.description}}
                  </span>
                  <span class="progressbar-left grey-text">{{'actualValue' | translate}}</span>
                  <span class=" progressbar-right grey-text">{{'potential' | translate}}</span>
                  <span class=" point grey-text"> {{'creation' | translate}}</span>
                </mat-panel-title>
                ​
              </mat-expansion-panel-header>
              ​
              <mat-expansion-panel hideToggle #expId *ngFor="let needs of needAndSolution.needFields">
                <mat-expansion-panel-header class="pt-10 analysis-content">
                  <mat-panel-title>
                    <i class="material-icons" *ngIf="!expId.expanded">
                      keyboard_arrow_right
                    </i>
                    <i class="material-icons" *ngIf="expId.expanded">
                      keyboard_arrow_down
                    </i>
                    <div class="inner-tab">
                      <span class="title">
                        <span class="fw-500"> {{needs.name}}</span> ({{needs.cashFlowType}})
                      </span>
                      <span class="progress-bar">
                        <mat-progress-bar mode="determinate" [value]="needs.inventionValue"></mat-progress-bar>
                        <span class="left-digit">{{getDecimalPoint(needs.actualValue)}}</span>
                        <span class="right-digit">{{getDecimalPoint(needs.potencialValue)}}</span>
                      </span>
                      <span class=" point ">{{needs.inventionValue | alterChartTableData : true}}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                ​
                <mat-list class="tab-list">
                  <mat-list-item class="expansion-inner-list p-10"
                                 *ngFor="let solution of needs.needFieldParts; let i = index">
                    <span class="title" matTooltip="{{solution.category}}">{{solution.category}}</span>
                    <span class="solution">{{solution.name}}</span>
                    <span class="progress-bar">
                      <mat-progress-bar mode="determinate" [value]="solution.inventionValue"></mat-progress-bar>
                      <span class="left-digit">{{getDecimalPoint(solution.actualValue)}}</span>
                      <span class="right-digit">{{getDecimalPoint(solution.potencialValue)}}</span>
                    </span>
                    <span class="point">{{solution.inventionValue | alterChartTableData : true}}</span>
                    ​
                  </mat-list-item>
                  <!-- </div> -->
                </mat-list>
              </mat-expansion-panel>
            </mat-expansion-panel>
          </mat-accordion>
          <!-- chart -->
          <div class="w-100" *ngIf="showPotentailVermogen">
            <app-potentialanalysis-vermogen-report [openedFromSideMenu]="openedFromSideMenu"
                                                   [selectedCalculationId]="selectedCalculationId"
                                                   (closeReport)="closeReport()"
                                                   [dataForPDF]="false">
            </app-potentialanalysis-vermogen-report>
          </div>
          ​
          <!-- Kundengerust -->
          <div class="w-100" *ngIf="showPotentailKundengerust">
            <app-potentialanalysis-kundengerust-list [openedFromSideMenu]="openedFromSideMenu"
                                                     [selectedCalculationId]="selectedCalculationId"
                                                     (closeReport)="closeReport()">
            </app-potentialanalysis-kundengerust-list>
          </div>
          ​ ​
        </div>
      </div>
    </div>
    <!-- right -->
    <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="">

      ​ ​

      <!-- calculation -->
      <div class="d-flex w-100 fd-col sidebar-form"
           fxLayout="column"
           fxLayout.md="row wrap"
           fxLayout.sm="row wrap"
           fxLayoutGap="0.5%"
           fxLayoutAlign.md="flex-start  "
           fxLayoutAlign="flex-start">
        ​
        <div fxFlex="35%" class="">

          <div class="sidebar-form">
            <button mat-raised-button
                    class="custom-button m-b-20 h-55"
                    id="pdf-button"
                    [disabled]="!consultantNeedSolutionList"
                    (click)="generatePDF()">
              <i class="material-icons m-r-20">
                cloud_download
              </i>
              {{'downloadPDF' | translate}}</button>
            <button mat-raised-button
                    class="custom-button m-b-20 h-55"
                    id="csv-button"
                    [disabled]="!consultantNeedSolutionList"
                    (click)="exportCsv()">
              <i class="material-icons m-r-20">
                cloud_download
              </i>
              {{'downloadCSV' | translate}}</button>
          </div>

          <div class="" style=" display: flex;flex-direction: column;">
            <mat-label class="main-label">Kalkulation</mat-label>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'calculation' | translate}} </mat-label>
              <mat-select [(ngModel)]="kalkulationValue"
                          (selectionChange)="changeCalculation($event)"
                          [disabled]="!commonSpinner">
                <mat-option [value]="kalkulation.value" *ngFor="let kalkulation of kalkulationList">
                  {{kalkulation.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          ​
          <!-- profit center -->
          <div style=" display: flex;flex-direction: column;">
            <mat-label class="main-label">Profit-Center</mat-label>
            <mat-label *ngIf="profitCenterFilter.selectedProfitCenterFilter">
              {{profitCenterFilter.selectedProfitCenterFilter}}</mat-label>
            <button mat-raised-button
                    class="custom-button m-t-5 btn-border"
                    [disabled]="!commonSpinner"
                    (click)="profitCenterRightNav()">
              {{'changeProfitCenter' | translate}}
            </button>
            ​ ​
          </div>
          <!-- load data -->
          <button mat-raised-button
                  class="custom-button h-55 m-b-20"
                  (click)="getAllData()"
                  [disabled]="(disableDataLoad || !commonSpinner)">
            {{'loadData' | translate}}</button>
        </div>

        <div fxFlex="64%" class=" ">
          <!-- filter -->
          <div style=" display: flex;flex-direction: column;">
            <mat-label class="main-label">{{'filteration' | translate}}</mat-label>
            <div *ngIf="!showresetFilter()" class="no-filter-label">{{'noFilterActive' | translate}}</div>
            <div *ngIf="showresetFilter()">
              <div class="filter-list"
                   [ngClass]="{'display-none' : filter.values.length == 0}"
                   *ngFor="let filter of potentialAnalysisFilters">
                <div *ngIf="filter.values" class="m-b-5">
                  <div class="selected-filter-label" *ngIf="filter.values.length != 0">{{filter.name}}</div>
                  <div *ngFor="let value of filter.values" class="font-size-14 fw-100">
                    <span *ngIf="filter.id == 6">{{value.description}}</span>
                    <span *ngIf="(filter.id == 5)">{{value.type}}</span>
                    <span *ngIf="(filter.id == 2)">{{value.name}} {{getValueRangeText(value)}}</span>
                    <span *ngIf="checkFilterValueDisplay(filter.id)">{{value.name}}</span>
                  </div>
                </div>
              </div>
            </div>

            ​
            <!-- additional filter selected -->
            <div *ngFor="let filter of selectedAdditionalFilters">
              <!-- 1 -->
              <div *ngIf="((filter.matchValue == 'oldestPersonInHousehold') || (filter.matchValue == 'customerSegmentBank') || (filter.matchValue == 'noOfPersonInHouse') || (filter.matchValue == 'noOfAdultsInHouse') || (filter.matchValue == 'noOfMinorsInHouse') || (filter.matchValue == 'purchasingPower' ) || (filter.matchValue == 'datesPast') || (filter.matchValue == 'datesFuture')) && filter.select">
                <div class="m-b-5 float-left">
                  <div class="selected-filter-label">{{filter.name}}</div>
                  <div class="font-size-14 fw-100">
                    <div>Min: {{filter.minValue}}</div>
                    <div>Max: {{filter.maxValue}}</div>
                  </div>
                </div>
              </div>
              ​ ​
              <!-- 2 -->
              <div *ngIf="(filter.matchValue == 'ratingHousehold') && filter.select">
                <div *ngIf="filter.values" class="m-b-5 float-left">
                  <div class="selected-filter-label" *ngIf="filter.values.length != 0">{{filter.name}}</div>
                  <div *ngFor="let value of filter.values" class="font-size-14 fw-100">
                    {{value}}
                  </div>
                </div>
              </div>
              ​
              <!-- 3 -->
              <div *ngIf="((filter.matchValue == 'customerSegmentVRCP') || (filter.matchValue == 'customerSegmentBVR')) && filter.select">
                <div *ngIf="filter.values" class="m-b-5 float-left">
                  <div class="selected-filter-label" *ngIf="filter.values.length != 0">{{filter.name}}</div>
                  <div *ngFor="let value of filter.values" class="font-size-14 fw-100">
                    {{value.shl_name}}
                  </div>
                </div>
              </div>
              ​ ​
            </div>
            ​ ​
            <a *ngIf="showresetFilter()" (click)="resetFilters()">{{'resetAllFilters' | translate}}
            </a>
            ​ ​
            <button mat-raised-button
                    class="custom-button m-t-5 m-b-5 btn-border"
                    [disabled]="!commonSpinner"
                    (click)="filterRightNav()">
              {{'editFiltering'| translate}}
            </button>
            ​ ​ ​ ​
          </div>
        </div>
      </div>
    </div>
    <!-- End: New Main Content -->
  </div>
</div>
