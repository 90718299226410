import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SALES_ORGANIZATION_TYPE } from 'src/app/app.config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-sales-organisation-second-part',
  templateUrl: './delete-sales-organisation-second-part.component.html',
  styleUrls: ['./delete-sales-organisation-second-part.component.scss']
})
export class DeleteSalesOrganisationSecondPartComponent implements OnInit {

  object: any;
  type: any;
  nodeName: any;
  listChildNode: any[] = [];
  salesOrganisationType = SALES_ORGANIZATION_TYPE;
  constructor(public dialogRef: MatDialogRef<DeleteSalesOrganisationSecondPartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService) {
    this.object = data.object;
    this.type = data.type;

  }

  ngOnInit() {
    if (this.type == SALES_ORGANIZATION_TYPE.BERATER.value) {
      this.setConsultantNode(this.object);
    } else if (this.type == SALES_ORGANIZATION_TYPE.TEAM.value) {
      this.setDepartmentNode(this.object);
    } else if (this.type == SALES_ORGANIZATION_TYPE.KOMPETENZZENTER.value) {
      this.setCompetence(this.object);
    } else if (this.type == SALES_ORGANIZATION_TYPE.TELIMARKT.value) {
      this.setTelimarket(this.object);
    } else if (this.type == SALES_ORGANIZATION_TYPE.BANK.value) {
      this.setKreditInstitute(this.object);
    }
  }


  deleteConsultant(consultants, type) {
    if (consultants && consultants.length > 0) {
      consultants.forEach(c => {
        this.listChildNode.push(type + ' ' + '"' + c.name + '"');
      });
    }
  }

  setConsultantNode(consultant) {
    this.nodeName = this.translate.instant('consultant') + ' "' + consultant.name + '"';
  }

  setDepartmentNode(department) {
    this.nodeName = 'Team' + ' "' + department.name + '"';
    this.deleteConsultant(department.consultants, 'Berater');
  }
  deleteDepartment(department, type) {
    if (department && department.length > 0) {
      department.forEach(c => {
        this.listChildNode.push(type + ' ' + '"' + c.name + '"');
        this.deleteConsultant(c.consultants, 'Berater');
      });
    }
  }
  setCompetence(competence) {
    this.nodeName = this.translate.instant('competence') + ' "' + competence.name + '"';
    this.deleteDepartment(competence.consultantTeams, 'Team');
    this.deleteConsultant(competence.consultants, 'Berater');

  }
  deleteCompetence(competence, type) {
    if (competence && competence.length > 0) {
      competence.forEach(c => {
        this.listChildNode.push(type + ' ' + '"' + c.name + '"');
        this.deleteDepartment(competence.consultantTeams, 'Team');
        this.deleteConsultant(c.consultants, 'Berater');

      });
    }
  }

  setTelimarket(telimarkt) {
    this.nodeName = 'Teilmarkt' + ' "' + telimarkt.name + '"';
    this.deleteCompetence(telimarkt.consultantCompetenceCenters, 'Kompetenzzenter');
    this.deleteDepartment(telimarkt.consultantTeams, 'Team');
    this.deleteConsultant(telimarkt.consultants, 'Berater');

  }

  deleteTelimarket(telimarkt, type) {
    if (telimarkt && telimarkt.length > 0) {
      telimarkt.forEach(c => {
        this.listChildNode.push(type + ' ' + '"' + c.name + '"');
        this.deleteCompetence(telimarkt.consultantCompetenceCenters, 'Kompetenzzenter');
        this.deleteDepartment(telimarkt.consultantTeams, 'Team');
        this.deleteConsultant(c.consultants, 'Berater');

      });
    }
  }

  setKreditInstitute(kreditInstitute) {
    this.nodeName = this.translate.instant('creditInstitute') + ' "' + kreditInstitute.name + '"';
    this.deleteTelimarket(kreditInstitute.creditInstitutionPartMarkets, 'Teilmarkt');
    this.deleteCompetence(kreditInstitute.consultantCompetenceCenters, 'Kompetenzzenter');
    this.deleteDepartment(kreditInstitute.consultantTeams, 'Team');
    this.deleteConsultant(kreditInstitute.consultants, 'Berater');
  }

  closeDialog() {
    this.dialogRef.close();
  }
  delete() {
    this.dialogRef.close(true);
  }
}
