import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BookingtypesService } from '../../service/bookingtypes.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { MatDialog } from '@angular/material/dialog';
import { BuchungsartenEditModalComponent } from '../buchungsarten-edit/buchungsarten-edit.component';
import { BenutzerService } from 'src/app/benutzer-verwaltung/einstellugen/service/benutzer.service';

@Component({
  selector: 'app-buchungsarten-list',
  templateUrl: './buchungsarten-list.component.html',
  styleUrls: ['./buchungsarten-list.component.scss']
})
export class BuchungsartenListComponent implements OnInit {

  totalCount: any;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'description', 'Buchungsgruppe', ' '];
  bookingTypes: any[] = [];
  spinnerFlag = true;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isVRCPAdmin: boolean;

  constructor(private bookingCategoryService: BookingtypesService, private commonUtils: CommonUtilsService, private dialog: MatDialog, private benutzerService: BenutzerService) {
    this.getBookingTypes();
    this.getUserProfile();
  }

  ngOnInit() { }

  getUserProfile() {
    this.benutzerService.getDashboardProfile().then((data: any) => {
       const vrcpAdmin = data.Result.ClaimGroups.filter(group => group.InternalName === "VRCP-Admin");
       this.isVRCPAdmin = (vrcpAdmin.length > 0);
    })
  }

  editBooking(booking) {
    const dialogRef = this.dialog.open(BuchungsartenEditModalComponent, {
      width: '600px',
      data: {
        bookingTypeGroup: booking.bookingTypeGroup,
        description: booking.description,
        name: booking.name
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const finalBooking = {
          id: booking.id,
          name: booking.name,
          description: result.bookingTypeDescription,
          value: booking.value,
          bookingTypeGroupId: result.bookingTypeGroup.id,
          bookingTypeGroup: result.bookingTypeGroup
        }
        this.bookingCategoryService.editBookingType(finalBooking).subscribe((response: any) => {
          this.bookingTypes.forEach(type => {
            if (type.id == response.Result.id) {
              type.description = response.Result.description;
              type.bookingTypeGroupId = response.Result.bookingTypeGroupId;
              type.bookingTypeGroup = response.Result.bookingTypeGroup;
            }
          }, err => { });
        })
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }
  changePage() {
    this.dataSource.paginator = this.paginator;
  }

  getBookingTypes() {
    this.bookingCategoryService.getBookingTypes().subscribe((response: any) => {

      this.bookingTypes = response.Result;
      this.dataSource = new MatTableDataSource(response.Result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.totalCount = response.Result.length;
      this.spinnerFlag = false;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item : any, property) => {
        switch (property) {
          case 'Buchungsgruppe': return item.bookingTypeGroup.name;
          default: return item[property];
        }
      };

    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });
  }

}
