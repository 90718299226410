import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LocalStorageService } from '../shared/services/localStorage.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  client_permissions;
  constructor(
    private router: Router, private oauthService: OAuthService,
    private authService: AuthService,
    private localStorageService: LocalStorageService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.oauthService.getIdToken();
    //  If token does not exist or it is expired, redirect to login page
    const iTokenexpired = this.authService.isTokenExpired();
    if (!token || iTokenexpired) {
      //this.router.navigate(['']);
      location.reload();
      return false;
    }
    let allow = false
    const userRoles: any[] = this.localStorageService.get("userRoles");
    if (!route.data.allowedScopes || userRoles.includes("VRCP-Admin")) {
      allow = true;
    } else {
      for(let role of userRoles) {
        if (route.data.allowedScopes.includes(role)) {
          allow = true;
          break;
        }
      }
    }
    if(!allow) {
      this.router.navigateByUrl('');
    }
    return allow;
  }
}
