<div class="root-container">

  <div class="title-container m-t-20 p-l-1vw p-t-1vw p-r-1vw">
    <mat-grid-list cols="2" rowHeight="50px">
      <mat-grid-tile flex-start flex-align-top class="grid-spacing">
        <h1 class="page-title">
          {{('consultantStructureGrid') | translate}}
        </h1>
      </mat-grid-tile>
      <mat-grid-tile flex-end flex-align-top class="grid-spacing">

      </mat-grid-tile>
    </mat-grid-list>
  </div>


  <div class="main-container">

    <!-- Deadline and hiera selection -->
    <mat-card class="header-card m-t-20 m-b-0 m-l-20 m-r-20 p-t-10 p-b-0" >
        <mat-card-content>
            <div class="d-flex flex-row" >
                <mat-form-field class="m-b-5"  appearance="outline">
                    <mat-label>{{'deadline' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedDeadline" (ngModelChange)="onDeadlineSelectionChanged($event)" [disabled]="deadlines.length == 0 || isBusy">
                      <mat-option *ngFor="let deadline of deadlines" [value]="deadline">{{deadline.deadlineDate | date}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="m-l-10 m-b-5" appearance="outline">
                    <mat-label>{{'hierarchy' | translate}}</mat-label>
                    <mat-select [(ngModel)]="selectedHierarchy" (ngModelChange)="onHierarchySelectionChanged($event)" [disabled]="hierarchies.length == 0 || isBusy">
                      <mat-option *ngFor="let hierarchy of hierarchies" [value]="hierarchy">{{hierarchy.hierarchyDescription}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <button mat-button class="custom-button duplicate-button" (click)="duplicateOrgaStruct()" [disabled]="hierarchies.length == 0 || !selectedHierarchy || isBusy">
                    {{'duplicate' | translate}}
                  </button>
                  <button mat-stroked-button class="duplicate-button" (click)="editHierarchyName(selectedHierarchy.id)" [disabled]="hierarchies.length == 0 || !selectedHierarchy || isBusy">
                    Umbenennen
                  </button>
                  <button mat-stroked-button color="warn" class="duplicate-button" (click)="deleteOrgaStruct(selectedHierarchy)" [disabled]="hierarchies.length == 0 || !selectedHierarchy || isBusy">
                    Löschen
                  </button>
            </div>

        </mat-card-content>

      </mat-card>


      <!-- Data-Table -->
      <mat-card class="grid-card m-t-20 m-b-20 m-l-20 m-r-20 p-t-0 p-b-0 p-l-0 p-r-0" >

        <igx-grid #grid1
        id="grid1"
        height="100%"
        width="100%"
        [autoGenerate]="false"
        [data]="data"
        [primaryKey]="'id'"
        [allowFiltering]="true"
        [filterMode]="'excelStyleFilter'"
        [filterStrategy]="filterStrategy"
        [rowEditable]="data.length > 0"
        [showToolbar]="true"

        (cellEditEnter)="onCellEditEnter($event)"
        (cellEdit)="onCellEdit($event)"
        (rowEdit)="onRowEdit($event)"
        (rowEditEnter)="onRowEditEnter($event)"
        (rowEditExit)="onRowEditExit($event)"
        >
          <!-- <igx-grid-toolbar>
            <igx-grid-toolbar-actions>

            </igx-grid-toolbar-actions>
          </igx-grid-toolbar> -->

            <!--Toolbar-->
            <igx-grid-toolbar>
              <igx-grid-toolbar-title *ngIf="selectedDeadline && selectedHierarchy">{{(selectedDeadline.deadlineDate | date)}} - {{selectedHierarchy.hierarchyDescription}}</igx-grid-toolbar-title>

              <!--
                  Everything between the toolbar tags except the default toolbar components/directives
                  will be projected as custom content.
               -->
              <!-- <button mat-button class="custom-button toolbar-button"  [disabled]="!selectedHierarchy || data.length == 0 || isBusy">
                <igx-icon class="toolbar-button-icon" fontSet="material">add</igx-icon>
                Neues Profitcenter
              </button> -->

              <!-- <button mat-button class="custom-button toolbar-button" (click)="navigateToAddConsultantSegment()"  [disabled]="!selectedHierarchy || data.length == 0 || isBusy">
                <igx-icon class="toolbar-button-icon" fontSet="material">add</igx-icon>
                Neues Beratersegment
              </button> -->

              <!-- Consultant Segment Managment Dropdown -->
              <button mat-button class="custom-button toolbar-button" [igxToggleAction]="dropdown" [igxDropDownItemNavigation]="dropdown" [overlaySettings]="overlaySettings" [disabled]="!selectedHierarchy || data.length == 0 || isBusy">
                <igx-icon class="toolbar-button-icon" fontSet="material">add</igx-icon>
                Beratersegmente verwalten
              </button>

              <igx-drop-down #dropdown [width]="'540px'">
                <h4 class="dropdown-header">Beratersegmente ({{ consultantSegments.length }})</h4>

                <div class="drop-down__scroll-container consultantsegments-table-container" [ngClass]="{'blocked': consultantSegmentLoading}">
                  <table mat-table [dataSource]="dataSource" matSort class="w-100">

                    <!-- Data-Columns -->
                    <ng-container matColumnDef="consultantSegment">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'consultantSegment' | translate}} </th>
                      <td mat-cell *matCellDef="let consultantSegment" >
                        <div *ngIf="!consultantSegment.isEdit"> {{consultantSegment.name}}</div>
                        <div *ngIf="consultantSegment.isEdit">


                            <!-- <mat-form-field class="example-full-width" appearance="fill">
                              <mat-label></mat-label>
                              <input matInput placeholder="" value="{{consultantSegment.name}}">
                            </mat-form-field> -->
                            <igx-input-group type="line" class="example-full-width"   >
                              <input #taskEditText igxInput name="fullName1" type="text" [(ngModel)]="consultantSegment.editValue"  />
                              {{taskEditText.focus()}}
                          </igx-input-group>

                        </div>
                      </td>
                    </ng-container>

                    <!-- Edit Delete Buttons -->
                    <ng-container matColumnDef="consultantSegmentOptions">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let consultantSegment" >
                        <!-- <button mat-icon-button (click)="editConsultantSegmentClick(consultantSegment.id)">
                          <i class="fs-16 material-icons"> edit </i>
                        </button>
                        <button mat-icon-button (click)="deleteConsultantSegmentClick(consultantSegment.id, consultantSegment.name)">
                          <i class="fs-16 material-icons"> delete </i>
                        </button> -->

                        <div *ngIf="!consultantSegment.isEdit">
                          <button mat-stroked-button class="m-r-10" matTooltip="Umbenennen" matTooltipPosition="left" (click)="editConsultantSegmentClick(consultantSegment.id)">
                            <mat-icon>edit</mat-icon>
                          </button>
                          <button mat-stroked-button matTooltip="Löschen" matTooltipPosition="right" *ngIf="canDeleteConsultantSegment(consultantSegment.id)" (click)="deleteConsultantSegmentClick(consultantSegment)">
                            <mat-icon class="primary-fg">delete</mat-icon>
                          </button>
                        </div>

                        <div *ngIf="consultantSegment.isEdit">
                          <button mat-stroked-button class="m-r-10" matTooltip="Abbrechen" matTooltipPosition="left" (click)="editConsultantSegmentClick(consultantSegment.id)">
                            <mat-icon>cancel</mat-icon>
                          </button>
                          <button mat-button class="custom-button"  matTooltip="Speichern" matTooltipPosition="right" (click)="saveEditedConsultantSegmentClick(consultantSegment)" [disabled]="consultantSegment.editValue == ''">
                            <mat-icon>save</mat-icon>
                          </button>
                        </div>

                      </td>
                    </ng-container>

                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                  </table>

                </div>
                <div class="consultantsegments-search-container">
                  <h4 class="dropdown-header">Neues Beratersegment hinzufügen</h4>
                  <div>
                    <igx-input-group type="line" class="consultantsegments-search-input">
                      <!-- <input #input1 igxInput placeholder="Beratersegment-Bezeichnung"/> -->
                      <input #input1 igxInput name="consultantsegmentname" type="text" [(ngModel)]="newConsultantSegmentName" />
                      <label igxLabel for="consultantsegmentname">Beratersegment-Bezeichnung</label>
                      <!-- <igx-prefix>
                          <igx-icon>add</igx-icon>
                      </igx-prefix> -->
                      <igx-suffix *ngIf="newConsultantSegmentName.length > 0" (click)="newConsultantSegmentName = ''">
                          <igx-icon>clear</igx-icon>
                      </igx-suffix>
                      <igx-suffix>
                        <button mat-button class="custom-button toolbar-button" style="margin-right: -125px;" [disabled]="consultantSegmentLoading || newConsultantSegmentName == ''" (click)="addConsultantSegmentClick(newConsultantSegmentName)">
                          Hinzufügen
                        </button>
                      </igx-suffix>
                    </igx-input-group>
                  </div>

                </div>
              </igx-drop-down>

              <!-- <button igxButton="flat"  >
                <igx-icon fontSet="material">clear</igx-icon>
                Clear Sort
            </button> -->

              <igx-grid-toolbar-actions>
                <igx-grid-toolbar-hiding></igx-grid-toolbar-hiding>
              </igx-grid-toolbar-actions>
          </igx-grid-toolbar>

          <!-- <ng-template igxExcelStyleHeaderIcon>
            <igx-icon fontSet="material">lens</igx-icon>
          </ng-template> -->

          <igx-grid-excel-style-filtering >
              <igx-excel-style-column-operations>
                  <igx-excel-style-header
                      [showPinning]="false"
                      [showHiding]="true"
                  >
                  </igx-excel-style-header>

                  <igx-excel-style-sorting></igx-excel-style-sorting>
              </igx-excel-style-column-operations>

              <!-- <igx-excel-style-filter-operations>
                  <igx-excel-style-search></igx-excel-style-search>
              </igx-excel-style-filter-operations> -->
          </igx-grid-excel-style-filtering>


          <ng-template igxRowEditText let-rowChangesCount>
            {{ 'igx-grid-changes' | translate }}: {{rowChangesCount}}
          </ng-template>



            <!-- <igx-column field="id" [sortable]="true" header="Id" [filterable]="false"></igx-column> -->
            <!-- <igx-column field="dataId" [sortable]="true" header="Schlüssel" width="240px" [filterable]="false" [editable]="false" [hidden]="true"></igx-column> -->
            <igx-column field="consultantClientId" [sortable]="true" header="Beraternummer" width="240px" [filterable]="true" [editable]="false"></igx-column>
            <igx-column field="name" [sortable]="true" header="Name" minWidth="240px" [filterable]="true" [editable]="true"></igx-column>
            <!-- <igx-column field="consultantsCount" [sortable]="true" header="Berateranzahl" [filterable]="false"></igx-column> -->
            <igx-column field="parentProfitCenterId" [sortable]="true" header="Übergeordnete Organisationseinheit" minWidth="240px" [filterable]="true" [formatter]="formatParent" [editable]="true">
              <ng-template igxCellEditor let-cell="cell" let-value>
                <igx-select class="cell-select" [(ngModel)]="cell.editValue" [igxFocus]="true">
                    <igx-select-item *ngFor="let ppc of profitCenters" [value]="ppc.dataId">
                        {{ ppc.name }}
                    </igx-select-item>
                </igx-select>
              </ng-template>
            </igx-column>

            <igx-column field="category" header="Kategorie" width="240px" [editable]="true" [filterable]="true" [formatter]="formatCategory">
              <ng-template igxCellEditor let-cell="cell" let-value>
                <igx-select class="cell-select" [(ngModel)]="cell.editValue" [igxFocus]="true">
                    <igx-select-item *ngFor="let class of categoriesToSelect" [value]="class.id">
                        {{ class.name }}
                    </igx-select-item>
                </igx-select>
              </ng-template>
            </igx-column>

            <igx-column field="consultantSegment" [sortable]="true" header="Beratersegment" minWidth="240px" [filterable]="true" [formatter]="formatConsultantSegment" [editable]="true">
              <ng-template igxCellEditor let-cell="cell" let-value>
                <igx-select class="cell-select" [(ngModel)]="cell.editValue" [igxFocus]="true">
                    <igx-select-item *ngFor="let cs of consultantSegments" [value]="cs.id">
                        {{ cs.name }}
                    </igx-select-item>
                </igx-select>
              </ng-template>
            </igx-column>

            <igx-column field="capacity" [sortable]="true" header="Vertriebskapazität" width="240px" [filterable]="true" [editable]="true" [dataType]="'number'"></igx-column>
            <!-- <igx-column field="Val" header="Track progress" [filterable]="false">
                <ng-template igxCell let-value>
                    <igx-linear-bar [stripped]="false" [value]="value" [max]="100"></igx-linear-bar>
                </ng-template>
            </igx-column> -->


          <!-- Add/Delete Buttons In Columns -->
          <igx-column field="id" header=" " width="190px" [filterable]="false" [editable]="false" [disableHiding]="true">
            <ng-template igxCell let-cell="cell">

              <!-- Add ProfitCenter -->
              <button mat-stroked-button class="m-r-10" matTooltip="Neues Profitcenter" matTooltipPosition="left" *ngIf="canAddProfitCenter(cell)" [ngClass]="{'blocked': isInEditMode}" [igxToggleAction]="dropdown2" [igxDropDownItemNavigation]="dropdown2" [overlaySettings]="overlaySettings">
                <mat-icon>add</mat-icon>
              </button>

              <igx-drop-down #dropdown2 [width]="'540px'" (opened)="handleOpened($event, cell)" (opening)="handleOpening($event, cell)" (closed)="handleClosed()">
                <div class="add-profitcenter-container">
                  <h4 class="dropdown-header">Neues Profitcenter hinzufügen</h4>
                  <h5 class="dropdown-sub-header">(wird dem Profitcenter "<span style="font-style: italic; ">{{formatParent(newProfitCenterModel.parentProfitCenterId)}}</span>" untergeordnet)</h5>

                  <div class="add-profitcenter-inputs-container">

                    <igx-select class="add-profitcenter-input" [igxFocus]="true" [(ngModel)]="newProfitCenterModel.profitCenterConsultantStructureId">
                      <label igxLabel>Kategorie</label>
                      <igx-select-item *ngFor="let class of categoriesToSelect" [value]="class.id">
                          {{ class.name }}
                      </igx-select-item>
                    </igx-select>

                    <igx-input-group type="line" class="add-profitcenter-input">
                      <input #input1 igxInput name="profitcentername" type="text" [(ngModel)]="newProfitCenterModel.description"/>
                      <label igxLabel for="profitcentername">Profitcenter-Name</label>
                      <igx-suffix *ngIf="newProfitCenterModel.description.length > 0" (click)="newProfitCenterModel.description = ''">
                          <igx-icon>clear</igx-icon>
                      </igx-suffix>
                    </igx-input-group>

                    <div class="text-right">
                      <button mat-button class="custom-button toolbar-button" style="margin-bottom: 15px; margin-top: 10px;" [disabled]="newProfitCenterModel.description == ''" (click)="addProfitCenter(newProfitCenterModel)">
                        Hinzufügen
                      </button>
                    </div>
                    
                  </div>

                </div>
              </igx-drop-down>

              <!-- Delete ProfitCenter -->
              <button mat-stroked-button class="m-r-10" matTooltip="Löschen" matTooltipPosition="right" *ngIf="canDeleteProfitCenter(cell)" [ngClass]="{'blocked': isInEditMode || rowOptionsDisabled}" (click)="deleteProfitCenterClick(cell)">
                <mat-icon class="primary-fg">delete</mat-icon>
              </button>


              <!-- Children count of ProfitCenter -->
              <!-- <button mat-flat-button class="m-r-10" [igxTooltipTarget]="tooltipChildren" *ngIf="!canDeleteProfitCenter(cell) && canAddProfitCenter(cell)">
                <div>{{getChildrenCount(cell)}}</div>
              </button>
              <span #tooltipChildren="tooltip" igxTooltip>Untergeordnete Organisationseinheiten</span> -->


            </ng-template>
          </igx-column>

            <igx-paginator [perPage]="1000" [selectOptions]="[15, 50, 100, 500, 1000, 5000]">
            </igx-paginator>

        </igx-grid>
      </mat-card>


      <!-- Footer for additional data  -->
      <mat-card class="footer-card m-t-0 m-b-20 m-l-20 m-r-20 p-t-0 p-b-0" hidden >
        <mat-card-content>
            <div >


            </div>
        </mat-card-content>
      </mat-card>

    </div>

</div>



