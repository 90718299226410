import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class  ProductCombinationsService {
  private endpoint;
  private environment;
  private productNeedsAndSolutionEndpoint;
  private clientId;

  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService, private configurationservice: ConfigurationService, private userSessionService: UserSessionService) {
    this.clientId = this.userSessionService.getClientId().toString();
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/ProductCombinations`;
    this.productNeedsAndSolutionEndpoint = `${this.environment.musterkundeDevEndpoint}/api/ProductCombinationNeedSolutions`;
  }

  getClientIdHeader(filterValues) {
    const headers: any = {
      'productStatus': filterValues.productStatus.toString(),
      'searchString': filterValues.searchString,
      'productProvider': filterValues.productProvider.toString()
    };

    return { headers: new HttpHeaders(headers) };
  }

  getProductCombinations(filterValues) {
    const url = this.endpoint;
    let isAscending = filterValues.orderBy === 'asc';
    return this.http.get(url + `?OrderByAscending=${isAscending}&OrderBy=${filterValues.activeColumn}&PageIndex=${filterValues.index}&PageSize=${filterValues.size}`, this.getClientIdHeader(filterValues));
  }

  getProduktCombinationById(id) {
    const url = `${this.endpoint}/${id}`;
    return this.http.get(url);
  }
  getProduktCombinationNeedSolutionDropdown() {
    const url = `${this.productNeedsAndSolutionEndpoint}/dropdown`;
    return this.http.get(url);
  }
  editProduktCombination(id, data) {
    const url = `${this.productNeedsAndSolutionEndpoint}/edit/${id}`;
    return this.http.post(url, data);
  }
}
