import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { RightSidebarService } from '../../services/right-sidebar.service';
import { ADDITIONAL_FILTER, CONSULTANT_IDS, DEPARTMENT_NUMBERS, POTENTIAL_FILTERS, PROFIT_CENTER_FILTER, PROPERTY_STATUS, SELECTED_ADDITIONAL_FILTER, VERMOGEN_FILTER } from '../../../../app.config';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Options } from 'ng5-slider';
import { ConfirmPotentialFiltersDialogComponent } from '../confirm-potential-filters-dialog/confirm-potential-filters-dialog.component';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ModelCustomerGroupService } from 'src/app/benutzer-verwaltung/parameter/service/model-customer-group.service';
import { BussinessAreasService } from 'src/app/benutzer-verwaltung/parameter/service/bussiness-areas.service';
import { PotentialAnalyseService } from '../../services/potential-analyse.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TreeNode } from '../../models/treenode';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KalkulationService } from 'src/app/benutzer-verwaltung/data-transfer-center/services/kalkulation.service';
import { DeadlineHierarchiesService } from 'src/app/shared/services/deadline-hierarchies.service';

@Component({
  selector: 'app-potentialanalyse-filter-bar',
  templateUrl: './potentialanalyse-filter-bar.component.html',
  styleUrls: ['./potentialanalyse-filter-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PotentialanalyseFilterBarComponent implements OnInit, OnDestroy {

  @Output() closeEvent = new EventEmitter<string>();
  @Output() selectedHierarchyEvent = new EventEmitter<any>();
  @Input('sideBarType') sideBarType: any;
  @Input('houseHoldCount') houseHoldCount: any;
  @Input('privatePersonCount') privatePersonCount: any;
  @Input('selectedDeadline') selectedDeadline: any;

  @ViewChild('rightSidenav', {static: true}) public sidenav: MatSidenav;
  globalPotentialAnalysisFilters = POTENTIAL_FILTERS;
  potentialAnalysisFilters = POTENTIAL_FILTERS;

  musterkundeGruppen: any;
  riskClassesList: any;
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  selectedNode: number;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  vermogen: any[] = [];
  vermogenList = VERMOGEN_FILTER;
  selectable = true;
  removable = true;
  // slider
  minValue = 99;
  maxValue = 1000;
  showSlider = false;
  options: Options = {
    floor: 99,
    ceil: 1000,
    step: 1,
    minRange: 10,
    noSwitching: true,
    // maxRange:10
  };

  // slide toggle
  excludeMusterkundeGruppenInCalculation = false;

  areaStatusList: any;

  propertyStatusList = PROPERTY_STATUS;

  profitCenterFilter = PROFIT_CENTER_FILTER;

  accountUsageList: any;

  filtersSelectedByUser: any[];

  nodes: Node[] = [];

  spinnerFlag = false;
  branchDataSource = [];
  consultDataSource = [];
  salesConsultOrganization: any[] = [];
  salesBranchOrganization: any[] = [];
  currentSelectedProfileCenterNode: string;
  consultantTreeDataSource: any[] = [];
  branchTreeDataSource: any[] = [];

  // additional filters
  additionalFilters = ADDITIONAL_FILTER;
  additionalFilter: any;
  globalSelectedAdditionalFilterList = SELECTED_ADDITIONAL_FILTER;
  selectedAdditionalFilterList = SELECTED_ADDITIONAL_FILTER;
  ratingsList: any;
  organisation = 'consultantOrg';

  consultantId: any[] = [];
  departmentNumbers: any[] = [];
  previouslySelectedNode: number;
  previousOrganization: string;
  previousSelectedDeadline: any = undefined;

  hierarchies = [];
  selectedHierarchy: any;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private rightSidebarService: RightSidebarService,
    private dialog: MatDialog,
    private commonUtilsService: CommonUtilsService,
    private modelCustomerGroupService: ModelCustomerGroupService,
    private bussinessAreaService: BussinessAreasService,
    private potentialAnalysisService: PotentialAnalyseService,
    private kalkulationService: KalkulationService,
    private deadlineHierarchiesService: DeadlineHierarchiesService
  ) {
  }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  ngOnInit() {
    this.rightSidebarService.setSidenav(this.sidenav);

    this.getData();
    
    this.potentialAnalysisFilters.forEach(filter => {
      if (filter.values == []) {
        this.resetAllFilters();
      }
    });

    this.commonUtilsService.getToggleStatus()
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        if (value) {
          this.potentialAnalysisFilters = POTENTIAL_FILTERS.map(filter => ({...filter}));
          this.selectedAdditionalFilterList = SELECTED_ADDITIONAL_FILTER.map(filter => ({...filter}));

          if (this.previousSelectedDeadline != this.selectedDeadline) {
            this.salesConsultOrganization = [];
            this.clearProfitCenterFilter();
          } else {
            this.getHierarchiesByDeadline(this.selectedDeadline);
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData() {
    this.getModelCustomerList();
    this.getBussinessAreas();
    this.getRiskClassesList();
    this.getContractStatuses();
    this.getRating();
    //this.getSalesOrganisation('consultantOrg');
    
    this.getHierarchiesByDeadline(this.selectedDeadline);
  }

  toggleMusterkundeGroupIdNegation() {
    this.excludeMusterkundeGruppenInCalculation = !this.excludeMusterkundeGruppenInCalculation;
    this.potentialAnalysisFilters[0].excludeMusterkundeGruppenInCalculation = this.excludeMusterkundeGruppenInCalculation;
  }

  filterCheckedEvent() {
    this.potentialAnalysisFilters.forEach(filter => {
      if (filter.id == 1) {
        if (!filter.select) {
          filter.values = [];
        }
      }
      if (filter.id == 2) {
        if (!filter.select) {
          this.vermogen = [];
          filter.values = [];
          this.showSlider = false;
        }
      }
      if (filter.id == 3) {
        if (!filter.select) {
          filter.values = [];
        }
      }
      if (filter.id == 4) {
        if (!filter.select) {
          filter.values = [];
        }
      }
      if (filter.id == 5) {
        if (!filter.select) {
          filter.values = [];
        }
      }
      if (filter.id == 6) {
        if (!filter.select) {
          filter.values = [];
        }
      }
    });
  }

  // model customer list
  getModelCustomerList() {
    this.modelCustomerGroupService.getModelCustomerGroup(1).subscribe((response: any) => {
      this.musterkundeGruppen = response.Result;
      //console.log("musterkundeGruppen", this.musterkundeGruppen);
    });
  }

  getContractStatuses() {
    this.potentialAnalysisService.getContractStatuses().subscribe((Response: any) => {
      this.accountUsageList = Response.Result;
      //console.log("accountUsageList", this.accountUsageList);
    });
  }

  getRiskClassesList() {
    this.potentialAnalysisService.getRiskCategories().subscribe((response: any) => {
      this.riskClassesList = response.Result.map(riskClass => {
        // riskClass.displayName = this.translateService.instant('riskCategory') + ' ' + riskClass.name.trim();
        //console.log("riskClassesList", this.riskClassesList);

        return riskClass;
      });
    });
  }

  // get bussiness area
  getBussinessAreas() {
    this.bussinessAreaService.getCategories().subscribe((response: any) => {
      this.areaStatusList = response.Result;
      //console.log("areaStatusList", this.areaStatusList);
    });
  }

  removeSelectedElement(filterId, id) {
    this.potentialAnalysisFilters.forEach(filter => {
      if (filter.id == filterId) {
        filter.values = filter.values.filter(group => group.id != id);
      }
    });
  }

  // vermogen
  selectVermogen(event) {
    const removedValues: any[] = [];
    this.potentialAnalysisFilters[1].values.forEach(value => {
      if (!containsObject(value, event.value)) {
        removedValues.push(value.id);
      }
    });

    // remove removed values from dropdown
    removedValues.forEach(id => {
      const temp = this.potentialAnalysisFilters[1].values.filter(value => value.id != id);
      this.vermogenList.forEach(vermogen => {
        if (vermogen.id == id) {
          vermogen.minValue = vermogen.defaultMinValue;
          vermogen.maxValue = vermogen.defaultMaxValue;
        }
      });
      this.potentialAnalysisFilters[1].values = temp;
    });

    // add values in potentialanalysis filter object
    event.value.forEach(value => {
      if (!containsObject(value, this.potentialAnalysisFilters[1].values)) {
        this.potentialAnalysisFilters[1].values.push(value);
      }
    });

    this.options = Object.assign({}, {
      floor: event.value.minValue,
      ceil: event.value.maxValue,
      step: event.value.step,
      minRange: 100,
      noSwitching: true,
      translate: (value: number): string => {
        return value.toLocaleString('de');
      },
    });
    this.maxValue = event.value.maxValue;
    this.minValue = event.value.minValue;
    if (event.value) {
      this.showSlider = true;
    }
  }

  // areaStatus
  addFilters() {
    this.potentialAnalysisFilters.forEach(filter => {
      if (filter.select) {
        if (filter.id === 2) {
          filter.values.forEach(val => {
            if (typeof (val.minValue) == 'string') {
              val.minValue = val.minValue.replace(/\./g, '');
            }
            if (typeof (val.maxValue) == 'string') {
              val.maxValue = val.maxValue.replace(/\./g, '');
            }
          });

        }
      }
    });
    this.globalPotentialAnalysisFilters.forEach((filter, index) => {
      Object.assign(filter, this.potentialAnalysisFilters[index]);
    });
    this.globalSelectedAdditionalFilterList.forEach((filter, index) => {
      Object.assign(filter, this.selectedAdditionalFilterList[index]);
    });
    this.closeEvent.next('saveFilters');
  }

  close(resetFilters = true) {
    if (this.showResetFilter()) {
      const dialogRef = this.dialog.open(ConfirmPotentialFiltersDialogComponent, {
        width: '400px',
        data: {
          name: name,
          resetFilters: false,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (resetFilters) {
            this.resetAllFilters();
          }
          this.closeSideBar();
        }
      });
    } else {
      this.closeSideBar();
    }
  }

  closeSideBar() {
    this.selectedNode = this.previouslySelectedNode;
    this.organisation = this.previousOrganization;
    this.getSalesOrganisation(this.previousOrganization, false);
    this.closeEvent.next();
  }

  resetProfitcenterFilter() {
    CONSULTANT_IDS.ids = [];
    DEPARTMENT_NUMBERS.numbers = [];
    PROFIT_CENTER_FILTER.values.name = null;
    PROFIT_CENTER_FILTER.values.id = null;
    PROFIT_CENTER_FILTER.selectedProfitCenterFilter = null;
  }

  resetAllFilters() {
    const dialogRef = this.dialog.open(ConfirmPotentialFiltersDialogComponent, {
      width: '400px',
      data: {
        resetFilters: true,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.vermogen = [];
        this.potentialAnalysisFilters.forEach(filter => {
          filter.select = false;
          filter.values = [];
          this.selectedAdditionalFilterList = [];
        });

        this.commonUtilsService.resetVermogenFilter();
        this.resetAdditionalFilters();
      }
    });
    VERMOGEN_FILTER.forEach(filter => {
      filter.isSlider = true;
      filter.minValue = filter.defaultMinValue;
      filter.maxValue = filter.defaultMaxValue;
    });
  }

  getType() {
    return this.sideBarType;
  }

  showResetFilter() {
    let count = 0;
    this.potentialAnalysisFilters.forEach(filter => {
      if (filter.select) {
        count++;
      }
    });

    this.selectedAdditionalFilterList.forEach(filter => {
      count++;
    });
    return count > 0;
  }

  // profit filter functions
  clearProfitFilter() {
    this.closeSideBar();
  }

  // Get sales organisation tree nodes.
  getSalesOrganisation(organisation: any, forceReload: boolean) {
    this.spinnerFlag = true;
    this.dataSource.data = [];
    if (organisation === 'branchOrg') {
      if (this.salesBranchOrganization.length > 0) {
        this.dataSource.data = this.branchTreeDataSource;
        this.spinnerFlag = false;
        if (this.selectedNode) {
          this.onChange({value: this.selectedNode});
        }
        return;
      }
      this.potentialAnalysisService.getDepartmentStructures().subscribe((response: any) => {
        this.spinnerFlag = false;
        this.salesBranchOrganization = response.Result;
        this.buildBranchDataTreeNode(this.salesBranchOrganization);
        this.dataSource.data = this.branchTreeDataSource;
        if (this.selectedNode) {
          this.onChange({value: this.selectedNode});
        }
      }, () => {
        this.spinnerFlag = false;
        this.commonUtilsService.errorSnackBar('error', '');
      });
    } else {
      if (this.salesConsultOrganization.length > 0 && !forceReload) {
        this.dataSource.data = this.consultantTreeDataSource;
        this.spinnerFlag = false;
        if (this.selectedNode) {
          this.onChange({value: this.selectedNode});
        }
        return;
      }
      this.potentialAnalysisService.getProfitCenterByHierarchyId(this.selectedHierarchy.id).subscribe((response: any) => {
        this.spinnerFlag = false;
        this.consultantTreeDataSource = [];
        this.salesConsultOrganization = response.Result;
        this.buildConculatantDataTreeNode(this.salesConsultOrganization);
        this.buildTree(this.salesConsultOrganization);
        this.dataSource.data = this.consultantTreeDataSource;
      }, (error) => {
        this.spinnerFlag = false;
        this.commonUtilsService.errorSnackBar('error', '');
      });
    }
  }

  buildConculatantDataTreeNode(list, isChildren = false): TreeNode[] {
    const nodeList: TreeNode[] = [];
    for (const currentNode of list) {
      const newNode = new TreeNode(currentNode.description, currentNode.id, []);
      if (currentNode.childProfitCenter.length === 0) {
        const arr = this.buildConsultantsTreeNode(currentNode);
        arr.forEach(data => {
          newNode.children.push(data);
        });
      } else {
        currentNode.childProfitCenter.forEach(childNode => {
          const childTreeNode = new TreeNode(childNode.description, childNode.id, []);
          if (!(childNode.childProfitCenter.length == 0)) {
            const data = this.buildConculatantDataTreeNode(childNode.childProfitCenter, true);
            data.forEach(node => {
              childTreeNode.children.push(node);
            });
          }
          const arr2 = this.buildConsultantsTreeNode(childNode);
          arr2.forEach(data => {
            childTreeNode.children.push(data);
          });
          newNode.children.push(childTreeNode);
        });
        const arr = this.buildConsultantsTreeNode(currentNode);
        arr.forEach(data => {
          newNode.children.push(data);
        });
      }
      nodeList.push(newNode);
    }
    if (!isChildren) {
      nodeList.forEach(node => {
        this.consultantTreeDataSource.push(node);
      });
      return nodeList;
    } else {
      return nodeList;
    }
  }

  buildConsultantsTreeNode(node) {
    node.consultants.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    const arr: TreeNode[] = [];
    node.consultants.forEach(c => {
      if (c.profitCenterId) {
        c.parentProfitCenterId = c.profitCenterId;
      }
      const data = new TreeNode(c.name, c.id, []);

      arr.push(data);
    });
    return arr;
  }

  buildBranchDataTreeNode(list, isChildren = false): TreeNode[] {
    const nodeList: TreeNode[] = [];
    for (const currentNode of list) {
      const newNode = new TreeNode(currentNode.descriptionDepartment, currentNode.id, []);
      currentNode.childProfitCenter.forEach(childNode => {
        const childTreeNode = new TreeNode(childNode.descriptionDepartment, childNode.id, []);
        if (!(childNode.childProfitCenter.length == 0)) {
          const data = this.buildBranchDataTreeNode(childNode.childProfitCenter, true);
          data.forEach(node => {
            childTreeNode.children.push(node);
          });
        }
        newNode.children.push(childTreeNode);
      });
      nodeList.push(newNode);
    }
    if (!isChildren) {
      nodeList.forEach(node => {
        this.branchTreeDataSource.push(node);
      });
      return nodeList;
    } else {
      return nodeList;
    }
  }

  onChange(event) {
    this.consultantId = [];
    this.departmentNumbers = [];
    // On change set newly selected filter object.
    if (this.organisation === 'consultantOrg') {
      this.currentSelectedProfileCenterNode = event.value;
      let temp: any;
      temp = this.consultDataSource.filter(b => event.value == b.id);
      if (temp[0] != undefined && temp[0].consultantClientId) { // = Consultant
        this.consultantId.push(temp[0].id);
      } else { // = ProfitCenter
        this.findConsultantID(temp);
      }
    } else {
      this.findSelectedNode(this.salesBranchOrganization, event.value);
    }

  }

  findSelectedNode(list, id) {
    for (const currentNode of list) {
      if (currentNode.id == id) {
        this.findSelectedDepartmentNumbers(currentNode);
        return;
      }
      this.findSelectedNode(currentNode.childProfitCenter, id);
    }
  }

  findSelectedDepartmentNumbers(node) {
    if (node.profitCenterRegionStructureId == 3) {
      this.departmentNumbers.push(+node.departmentNumber);
    } else {
      node.childProfitCenter.forEach(child => {
        if (child.profitCenterRegionStructureId == 3) {
          this.departmentNumbers.push(+child.departmentNumber);
        } else {
          this.findSelectedDepartmentNumbers(child);
        }
      });
    }
  }

  findConsultantID(list) {
    for (const currentNode of list) {
      currentNode.consultants.forEach(c => {
        this.consultantId.push(c.id);
      });

      //this.consultantId.push(currentNode.id);
      this.findConsultantID(currentNode.childProfitCenter);
    }
  }

  addProfileFilters() {
    //console.log('Selected Consultant Ids', this.consultantId);
    DEPARTMENT_NUMBERS.numbers = this.departmentNumbers;
    CONSULTANT_IDS.ids = this.consultantId;
    this.previouslySelectedNode = this.selectedNode;
    this.previousOrganization = this.organisation;
    this.getSalesOrganisation(this.previousOrganization, false);
    this.closeEvent.next('profitCenter');
  }

  // getSelected filter Id
  getAddedFilters(selectedNode) {
    this.profitCenterFilter.values.id = selectedNode.id;

    if (selectedNode.currentNode == 'head_') {
      this.profitCenterFilter.values.name = 'head_';
    } else if (selectedNode.currentNode == 'region_') {
      this.profitCenterFilter.values.name = 'region_';
    } else if (selectedNode.currentNode == 'dep_') {
      this.profitCenterFilter.values.name = 'dep_';
    } else if (selectedNode.currentNode == 'market_') {
      this.profitCenterFilter.values.name = 'market_';
    } else if (selectedNode.currentNode == 'competenceCenters_') {
      this.profitCenterFilter.values.name = 'competenceCenters_';
    } else if (selectedNode.currentNode == 'team_') {
      this.profitCenterFilter.values.name = 'team_';
    } else if (selectedNode.currentNode == 'consultant_') {
      this.profitCenterFilter.values.name = 'consultant_';
    }

  }

  buildTree(list) {
    for (const currentNode of list) {
      if (currentNode.childProfitCenter.length === 0) {
        this.buildConsultantTree(currentNode);
        const data = Object.assign(currentNode, {
          node: new TreeNode(currentNode.description, currentNode.id, []),
          onSelectedNode: currentNode,
        });
        this.consultDataSource.push(data);
      } else {
        this.buildTree(currentNode.childProfitCenter);
        this.buildConsultantTree(currentNode);
        const data = Object.assign(currentNode, {
          node: new TreeNode(currentNode.description, currentNode.id, []),
          onSelectedNode: currentNode,
        });
        this.consultDataSource.push(data);
      }
    }
    return;
  }

  buildConsultantTree(currentNode) {
    // arrange in alphabetical order
    currentNode.consultants.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    currentNode.consultants.forEach(c => {
      if (c.profitCenterId) {
        c.parentProfitCenterId = c.profitCenterId;
      }
      const data = Object.assign(c, {
        node: new TreeNode(c.name, c.id),
        onSelectedNode: c,
      });
      this.consultDataSource.push(data);
    });
  }

  /* additional filters */
  selectAdditionalFilter(event) {
    let isAlreadyAdded = false;
    this.additionalFilter = {};
    this.additionalFilters.forEach(filter => {
      if (filter.id == event.id) {
        // filter.select = true;
        // filter.addInList = true;
        this.selectedAdditionalFilterList.forEach(element => {
          if (element.id == event.id) {
            isAlreadyAdded = true;

          }
        });
        if (!isAlreadyAdded) {
          this.selectedAdditionalFilterList.push(filter);
          filter.select = true;
          filter.addInList = true;
        }
      }
    });
  }

  additionalFilterCheckedEvent(event, filter) {
    if (!event.checked) {
      this.resetAdditionalFilters(filter);
    }
  }

  removeAdditionalFilter(filter) {
    this.selectedAdditionalFilterList = this.selectedAdditionalFilterList.filter(element => element.id != filter.id);
    this.resetAdditionalFilters(filter);
  }

  getRating() {
    this.potentialAnalysisService.getRatings().subscribe((response: any) => {
      this.ratingsList = response.Result;
      //console.log("ratingsList", this.ratingsList);
    });
  }

  removeInnerAdditionalChip(filterId, chipId) {
    this.selectedAdditionalFilterList.forEach(filter => {
      if (filter.id == filterId) {
        if (filter.matchValue == 'ratingHousehold') {
          filter.values = filter.values.filter(element => element != chipId);
        }
        if ((filter.matchValue == 'customerSegmentVRCP') || (filter.matchValue == 'customerSegmentBVR')) {
          filter.values = filter.values.filter(element => element.shl_num != chipId);
        }
      }
    });
  }

  pastDateInputEvent(event, selector, selectedFilter) {
    this.selectedAdditionalFilterList.forEach(filter => {
      if ((filter.matchValue == selectedFilter.matchValue)) {
        if (selector == 'min') {
          if (filter.maxValue != null) {
            filter.error = filter.maxValue < event.target.value;
          }
        } else {
          if (filter.minValue != null) {
            filter.error = filter.minValue > event.target.value;
          }
        }
      }
    });
  }

  resetAdditionalFilters(filterRemoved = null) {
    let count = 0;
    if (count == 0) {
      this.additionalFilters.forEach(filter => {
        if (filterRemoved != null) {
          filter = filterRemoved;
          count++;
        }
        if ((filter.matchValue == 'oldestPersonInHousehold') || (filter.matchValue == 'noOfPersonInHouse') || (filter.matchValue == 'noOfAdultsInHouse') ||
          (filter.matchValue == 'noOfMinorsInHouse') || filter.matchValue == 'purchasingPower') {
          filter.minValue = filter.defaultMinValue;
          filter.maxValue = filter.defaultMaxValue;
        } else if (filter.matchValue == 'ratingHousehold') {
          filter.values = [];
        } else if (filter.matchValue == 'privateLegalForms') {
          // pending from backend
        } else if (filter.matchValue == 'customerSegmentBank') {
          //  pending from backend
        } else if (filter.matchValue == 'customerSegmentBVR') {
          //  pending from backend
        } else if (filter.matchValue == 'customerSegmentVRCP') {
          //  pending from backend
        } else if ((filter.matchValue == 'datesPast') || (filter.matchValue == 'datesFuture')) {
          filter.minValue = null;
          filter.maxValue = null;
        }
      });
    }
  }

  directEntryVermogen(value) {
    this.potentialAnalysisFilters[1].values.forEach(element => {
      if (element.id == value.id) {
        element.error = false;
        element.isSlider = false;
        element.minValue = element.defaultMinValue.toLocaleString('de-DE');
        element.maxValue = element.directDefaultMaxValue.toLocaleString('de-DE');
      }
    });
  }

  sliderSelectionVermogen(value) {
    this.potentialAnalysisFilters[1].values.forEach(element => {
      if (element.id == value.id) {
        element.isSlider = true;
        element.minValue = element.defaultMinValue;
        element.maxValue = element.defaultMaxValue;
      }
    });
  }

  compareVermogenInputValues(event, selectedFilter, selector) {
    this.potentialAnalysisFilters[1].values.forEach(element => {
      if (element.name == selectedFilter.name) {
        let minValue = element.minValue.toString().replace(/\./g, '');
        let maxValue = element.maxValue.toString().replace(/\./g, '');
        minValue = parseInt(minValue, 10);
        maxValue = parseInt(maxValue, 10);

        if (selector == 'min') {
          // conversion to decimal
          element.minValue = element.minValue.toString();
          if (element.minValue.includes('.')) {
            element.minValue = element.minValue.replace(/\./g, '');
          }

          element.minValue = parseInt(element.minValue.toLocaleString('de-DE'), 10);
          element.minValue = element.minValue.toLocaleString('de-DE');
          // element.minValue = this.dp.transform(element.minValue, '1.0-0', 'de-DE');
          const targetMin = parseInt(event.target.value.toString().replace(/\./g, ''), 10);
          element.error = targetMin > maxValue || targetMin > element.defaultMaxValue || targetMin < element.defaultMinValue;
        } else {
          // conversion to decimal
          element.maxValue = element.maxValue.toString();
          if (element.maxValue.includes('.')) {
            element.maxValue = element.maxValue.replace(/\./g, '');
          }
          element.maxValue = parseInt(element.maxValue.toLocaleString('de-DE'), 10);
          element.maxValue = element.maxValue.toLocaleString('de-DE');
          const targetMin = parseInt(event.target.value.toString().replace(/\./g, ''), 10);

          element.error = targetMin < minValue || targetMin > element.directDefaultMaxValue || targetMin < element.defaultMinValue;
        }
      }
    });
  }

  clearProfitCenterFilter() {
    this.selectedNode = undefined;
    this.dataSource.data = [];
    this.departmentNumbers = [];
    this.consultantId = [];
    this.previousOrganization = undefined;
    this.previouslySelectedNode = undefined;
    //this.getSalesOrganisation(this.organisation, false);
    this.selectedHierarchy = undefined;
    this.getHierarchiesByDeadline(this.selectedDeadline);
    this.resetProfitcenterFilter();
  }

  selectRange(filter) {
    ADDITIONAL_FILTER.forEach(element => {
      if (element.id === filter.id) {
        element.options.selectOneElement = false;
        element.minValue = element.defaultMinValue;
        element.maxValue = element.defaultMaxValue;
      }
    });
  }

  selectOneElement(filter) {
    ADDITIONAL_FILTER.forEach(element => {
      if (element.id === filter.id) {
        element.options.selectOneElement = true;
        element.minValue = element.defaultMinValue;
        element.maxValue = 0;
      }
    });
  }

  getHierarchiesByDeadline(deadline) {
    this.kalkulationService.getHierarchiesByDeadline(deadline).subscribe((res: any) => {
      if (res.Result) {
        this.hierarchies = res.Result;
        if (this.hierarchies.length > 0) {

          if (!this.selectedHierarchy) {
            this.selectedHierarchy = this.hierarchies[0];
          } else {
            this.selectedHierarchy = this.hierarchies.find(x => x.id === this.selectedHierarchy.id);
          }
          this.selectedHierarchyEvent.next(this.selectedHierarchy);

          this.getSalesOrganisation(this.organisation, false);
          this.spinnerFlag = false;
          this.previousSelectedDeadline = deadline;

        } else {
          this.spinnerFlag = false;
        }
      }
    });
  }

  onHierarchySelectionChanged(hierarchy) {
    this.spinnerFlag = true;

    this.selectedNode = undefined;
    this.dataSource.data = [];
    this.departmentNumbers = [];
    this.consultantId = [];
    this.previousOrganization = undefined;
    this.previouslySelectedNode = undefined;

    this.resetProfitcenterFilter();

    this.deadlineHierarchiesService.setSelectedHierarchy(hierarchy);
    this.getSalesOrganisation(this.organisation, true);

  }

  onSalesOrganistationChanged(organisation) {
    //this.hierarchies = [];

    if (organisation == "branchOrg") {
      this.getSalesOrganisation(organisation, false);
    } else {
      this.getHierarchiesByDeadline(this.selectedDeadline);
    }
  }
}

function containsObject(obj, list) {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
}
