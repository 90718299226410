<div>
  <h4 class="m-t-0" mat-dialog-title>{{nodeName}} {{'delete' | translate}}?</h4>
  <p *ngIf="data.type != salesOrganisationType.BERATER.value && listChildNode.length > 0">
    {{'existingSubnodesAlsoDeletedSalesOrganization' | translate}}</p>

  <div class="m-b-20">
    <div class="div-list" *ngFor="let node of listChildNode">{{node}}</div>
  </div>
  <div mat-dialog-actions>
    <div mat-dialog-actions align="end">
      <button mat-raised-button color="basic" (click)="closeDialog()">{{'stop' | translate}}</button>
      <button mat-raised-button class="custom-button m-l-5" (click)="delete()">{{'deleteCap' | translate}}</button>
    </div>
  </div>