import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function hasWhitespaceValidator(): ValidatorFn {
  return (
    control: AbstractControl,
  ): (ValidationErrors | null) => {
    const {value}: AbstractControl = control;

    if (/\s+/.test(String(value))) {
      return {hasWhitespace: true};
    } else {
      return null;
    }
  };
}
