import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';

@Component({
  selector: 'app-vr-musterkunde',
  templateUrl: './vr-musterkunde.component.html',
  styleUrls: ['./vr-musterkunde.component.scss']
})
export class VrMusterkundeComponent implements OnInit {

  constructor(private commonUtils: CommonUtilsService) { }

  ngOnInit() {
  }

  hover(id) {
    document.getElementById(id).style.fill = '#eb690b';
  }
  hoverOut(id) {
    document.getElementById(id).style.fill = '#f2f2f2';
  }
  navigateToMarketingFabric() {
    this.commonUtils.setSvgNavigation('MarketingFabrik')
  }
  navigateToActivityManager() {
    this.commonUtils.setSvgNavigation('ActivityManager')
  }
}
