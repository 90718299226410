import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  userDataKey = `id_token_claims_obj`;
  constructor(private localStorageService: LocalStorageService) { }
  getUserProfileDetails() {
    const userData = this.localStorageService.get(this.userDataKey);
    if (userData) {
      //return JSON.parse(userData.User);
      return userData.User;
    }

  }
  getClientPermission() {
    const userData = this.localStorageService.get(this.userDataKey);
    return userData.client_permission;
  }
}
