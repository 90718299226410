import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { ROLES_SCOPES, POTENTIAL_FILTERS, PROFIT_CENTER_FILTER, SELECTED_ADDITIONAL_FILTER, VERMOGEN_FILTER } from 'src/app/app.config';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PotentialAnalysis } from 'src/app/benutzer-verwaltung/v-musterkunde/models/potentialanalysis';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {

  constructor(private userSessionService: UserSessionService,
    private configurationService: ConfigurationService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar) {
    this.getEnvironmentVariable();
  }
  API: any;

  potentialAnalysisFilters = POTENTIAL_FILTERS;
  profitCenterFilter = PROFIT_CENTER_FILTER;
  selectedAdditionalFilters = SELECTED_ADDITIONAL_FILTER;



  private sidebarToggle = new BehaviorSubject<any>(null);
  private svgNavigation = new Subject<any>();
  public getAPIUrl(url: string) {
    return this.API + '/' + url;
  }

  getEnvironmentVariable() {
    const environment = this.configurationService.getEnvironmentVariables();
    this.API = environment.devAuthManagementEndpoint;

  }
  getHeaderObject() {
    const headerObj = {
      headers: new HttpHeaders({
        'customerId': this.userSessionService.getCustomerAndUserId().customerId
      }),
    };
    return headerObj;
  }
  getHeaderByCustomerID(customerId) {
    const headerObj = {
      headers: new HttpHeaders({
        'customerId': customerId
      }),
    };
    return headerObj;
  }
  // function accepts user role and return claims
  getRolesAndReturnClaims(roles: string[]) {
    const claims: any[] = [];
    const roleScopes = ROLES_SCOPES;
    roles.forEach(role => {
      roleScopes.forEach(element => {
        if (element.role == role) {
          element.claims.forEach(claim => {
            if (claims.indexOf(claim) == -1) {
              claims.push(claim);
            }
          });
        }
      });
    });
    return claims;
  }

  // function accepts user claims and gives corresponding roles
  getClaimsAndReturnRoles(claims: any[]) {
    const roles: any[] = [];
    const roleScopes = ROLES_SCOPES;
    roleScopes.forEach(roleScope => {
      if (this.arrayContainsArray(claims, roleScope.claims)) {
        roles.push(roleScope.role);
      }
    });
    return roles;
  }
  checkId(id) {
    return id == 'null' ? null : +id;
  }


  getThemeColor(element) {
    if (element.name) {

      if (element.name.includes('Liquidität')) {
        element.themeColor = 'rgb(0,160,220)';
      }
      if (element.name.includes('Absichern')) {
        element.themeColor = 'rgb(255,102,0)';

      }
      if (element.name.includes('Vermögen')) {
        element.themeColor = 'rgb(102,148,58)';

      }
      if (element.name.includes('Vorsorge')) {
        element.themeColor = 'rgb(180,40,100)';

      }
      if (element.name.includes('Immobilie')) {
        element.themeColor = 'rgb(92,72,148)';

      }
    }
  }


  getThemeColorByThemeName(name) {
    if (name != undefined) {
      if (name.includes('Liquidität')) {
        return 'rgb(0,160,220)';
      } else if (name.includes('Absichern')) {
        return 'rgb(255,102,0)';

      } else if (name.includes('Vermögen')) {
        return 'rgb(102,148,58)';

      } else if (name.includes('Vorsorge')) {
        return 'rgb(180,40,100)';

      } else if (name.includes('Immobilie')) {
        return 'rgb(92,72,148)';
      }
    }
  }

  errorSnackBar(status, message, duration = 2000) {
    this.snackBar.open(message ? message : (status === 'error' ? this.translateService.instant('somethingWentWrong') : 'Successfull'), '', {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: status === 'success' ? 'valid-snackbar' : 'error-snackbar'
    });
  }
  setPotentialAnalysisWithFilters(selectedCalculationId) {
    const potentialanalysis = new PotentialAnalysis();
    potentialanalysis.modelCustomerGroupIdNegation = this.potentialAnalysisFilters[0].excludeMusterkundeGruppenInCalculation;
    // musterkundegruppen filters
    const musterkundeGruppenId: any[] = [];
    this.potentialAnalysisFilters[0].values.forEach(element => {
      musterkundeGruppenId.push(element.id);
    });

    // areastatus
    const areaStatusId: any[] = [];
    this.potentialAnalysisFilters[2].values.forEach(element => {
      areaStatusId.push(element.id);
    });


    // vermogen
    this.potentialAnalysisFilters[1].values.forEach(element => {
      element.maxValue = parseInt(element.maxValue.toString().replace('.', ''), 10);
      element.minValue = parseInt(element.minValue.toString().replace('.', ''), 10);
      if (element.name === 'Haushaltseinkommen') {
        potentialanalysis.householdIncomeFrom = element.minValue;
        potentialanalysis.householdIncomeTo = element.maxValue;
      } else if (element.name === 'Vermögen gesamt') {
        potentialanalysis.totalAssetFrom = element.minValue;
        potentialanalysis.totalAssetTo = element.maxValue;
      } else if (element.name === 'Vermögen liquide') {
        potentialanalysis.cashAssetFrom = element.minValue;
        potentialanalysis.cashAssetTo = element.maxValue;
      } else if (element.name === 'Kredite gesamt') {
        potentialanalysis.totalCreditFrom = element.minValue;
        potentialanalysis.totalCreditTo = element.maxValue;
      } else if (element.name === 'Kredite langfristig') {
        potentialanalysis.longTermCreditFrom = element.minValue;
        potentialanalysis.longTermCreditTo = element.maxValue;
      }
    });

    // Immobilestatus
    const immobilesStatus = this.potentialAnalysisFilters[3].values;
    if (immobilesStatus.length == 2) {
      potentialanalysis.ownershipRealEstate = null;
    } else if (immobilesStatus.length == 1) {
      potentialanalysis.ownershipRealEstate = immobilesStatus[0].name !== 'Kein Immobilieneigentum nachgewiesen';
    }


    // Riskclasses
    potentialanalysis.riskClasses = this.potentialAnalysisFilters[5].values.map(riskClass => riskClass.name);

    potentialanalysis.modelCustomerGroupIds = musterkundeGruppenId;
    potentialanalysis.areaStatuses = areaStatusId;
    potentialanalysis.calculationDeadlineId = +selectedCalculationId;

    // Kontonutzung
    potentialanalysis.contractStatuses = this.potentialAnalysisFilters[4].values.map(element => element.id);

    // Potentialanalysis filters

    if (this.profitCenterFilter.values.name == 'head_') {
      potentialanalysis.profitCenterCreditInstituteId = this.profitCenterFilter.values.id;
    } else if (this.profitCenterFilter.values.name == 'region_') {
      potentialanalysis.profitCenterRegionId = this.profitCenterFilter.values.id;

    } else if (this.profitCenterFilter.values.name == 'dep_') {
      potentialanalysis.profitCenterDepartmentId = this.profitCenterFilter.values.id;

    } else if (this.profitCenterFilter.values.name == 'market_') {
      potentialanalysis.profitCenterPartMarketId = this.profitCenterFilter.values.id;

    } else if (this.profitCenterFilter.values.name == 'competenceCenters_') {
      potentialanalysis.profitCenterCompotenceCenterId = this.profitCenterFilter.values.id;

    } else if (this.profitCenterFilter.values.name == 'team_') {
      potentialanalysis.profitCenterTeamId = this.profitCenterFilter.values.id;

    } else if (this.profitCenterFilter.values.name == 'consultant_') {
      potentialanalysis.profitCenterConsultantCreditInstituteId = this.profitCenterFilter.values.id;
    }
    this.setAdditionalFilters(potentialanalysis);
    return potentialanalysis;
  }

  setAdditionalFilters(potentialanalysis) {


    this.selectedAdditionalFilters.forEach(filter => {
      if (filter.options && (filter.maxValue === 0)) {
        filter.maxValue = filter.minValue
      }

      if (filter.matchValue == 'oldestPersonInHousehold') {
        potentialanalysis.householdOldestPersonFrom = filter.minValue;
        potentialanalysis.householdOldestPersonTo = filter.maxValue;
      } else if (filter.matchValue == 'ratingHousehold') {
        potentialanalysis.householdeRating = filter.values;
      } else if (filter.matchValue == 'noOfPersonInHouse') {
        potentialanalysis.householdNumberPersonFrom = filter.minValue;
        potentialanalysis.householdNumberPersonTo = filter.maxValue;
      } else if (filter.matchValue == 'noOfAdultsInHouse') {
        potentialanalysis.householdNumberAdultsFrom = filter.minValue;
        potentialanalysis.householdNumberAdultsTo = filter.maxValue;

      } else if (filter.matchValue == 'noOfMinorsInHouse') {
        potentialanalysis.householdNumberMinorsFrom = filter.minValue;
        potentialanalysis.householdNumberMinorsTo = filter.maxValue;

      } else if (filter.matchValue == 'privateLegalForms') {
        // pending from backend

      } else if (filter.matchValue == 'purchasingPower') {
        potentialanalysis.buyingPowerValueFrom = filter.minValue;
        potentialanalysis.buyingPowerValueTo = filter.maxValue;

      } else if (filter.matchValue == 'customerSegmentBank') {
        //  pending from backend
      } else if (filter.matchValue == 'customerSegmentBVR') {
        //  pending from backend

      } else if (filter.matchValue == 'customerSegmentVRCP') {
        //  pending from backend

      } else if (filter.matchValue == 'datesPast') {
        potentialanalysis.numberOfConsultationMeetingLast12MonthFrom = filter.minValue;
        potentialanalysis.numberOfConsultationMeetingLast12MonthTo = filter.maxValue;

      } else if (filter.matchValue == 'datesFuture') {
        potentialanalysis.numberOfConsultationMeetingPlannedLast12MonthFrom = filter.minValue;
        potentialanalysis.numberOfConsultationMeetingPlannedLast12MonthTo = filter.maxValue;
      }
    });
  }

  // potential-mainpage toggle status
  sendToggleStatus(message) {
    this.sidebarToggle.next(message);
  }

  clearToggleStatus() {
    this.sidebarToggle.next(null);
  }

  getToggleStatus(): Observable<any> {
    return this.sidebarToggle;
  }

  setSvgNavigation(value) {
    this.svgNavigation.next(value);
  }
  getSvgNavigation() {
    return this.svgNavigation.asObservable();
  }
  // reset vermogen filter
  resetVermogenFilter() {
    VERMOGEN_FILTER.forEach(vermogen => {
      vermogen.minValue = vermogen.defaultMinValue;
      vermogen.maxValue = vermogen.defaultMaxValue;
    });
  }

  successSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'success-snackbar'
    });
  }
  arrayContainsArray(superset, subset) {
    return subset.every(function (value) {
      return (superset.indexOf(value) >= 0);
    });
  }



  getBranchOrganisationStoreState() {
    return JSON.parse(localStorage.getItem('branch_state')) !== null ? JSON.parse(localStorage.getItem('branch_state')) : [];
  }

  setBranchOrganisationState(states) {
    localStorage.setItem('branch_state', JSON.stringify(states));
  }

  getVetribsOrganisationStoreState() {
    return JSON.parse(localStorage.getItem('vetribs_state')) !== null ? JSON.parse(localStorage.getItem('vetribs_state')) : [];
  }

  setVetribsOrganisationState(states) {
    localStorage.setItem('vetribs_state', JSON.stringify(states));
  }
}
