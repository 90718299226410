import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataImportService } from '../../services/data-import.service';


@Component({
  selector: 'app-dataexport-encryption-modal',
  templateUrl: './dataexport-encryption-modal.component.html',
  styleUrls: ['./dataexport-encryption-modal.component.scss']
})
export class DataexportEncryptionModalComponent implements OnInit {

  encryptionKey;
  hide = true;
  password;
  confirmPassword;
  lengthErr = false;
  noIntError = false;
  noCapsError = false;
  pwdValidate = false;
  mismatchPwdErr = false;
  validKey = false;
  apiKeyNotValid = false;
  constructor(public dialogRef: MatDialogRef<DataexportEncryptionModalComponent>,
    private dataImportService: DataImportService) { }

  ngOnInit() {
  }

  validatePassword() {
    if (this.password != null) {
      this.password.length < 6 ? this.lengthErr = true : this.lengthErr = false;

      // check if it contains number or not
      /\d/.test(this.password) ? this.noIntError = false : this.noIntError = true;

      // check if it contains capital alphabet or not
      /[A-Z]/.test(this.password) ? this.noCapsError = false : this.noCapsError = true;

      if (!this.noCapsError && !this.noIntError && !this.lengthErr && this.password) {
        this.pwdValidate = true;
      } else {
        this.pwdValidate = false;
      }

    }
  }
  export() {
    const exportKeys = new ExportKeys();
    exportKeys.key = this.encryptionKey;
    exportKeys.password = this.password;
    this.dialogRef.close(exportKeys);
  }
  closeDialog() {
    this.dialogRef.close();
  }

  disableExportButton() {
    if (this.password == null || this.confirmPassword == null) {
      return true;
    } else {
      if (this.lengthErr || this.noCapsError || this.noIntError || this.validKey  || this.apiKeyNotValid) {
        return true;
      } else {
        if (this.password === this.confirmPassword) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
  comparePassword() {
    if (this.password !== this.confirmPassword) {
      this.mismatchPwdErr = true;
    } else {
      this.mismatchPwdErr = false;
    }
  }

  validateKey() {
    if (this.encryptionKey) {
      const data = ['test'];
      this.dataImportService.validateTransferToDB(data, this.encryptionKey, this.encryptionKey).subscribe(response => {
        this.apiKeyNotValid = false;
      }, (err) => {
        this.apiKeyNotValid = true;
      });

      this.validKey = false;
    }
  }
}


export class ExportKeys {
  key: any;
  password: any;
}
