import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CommonUtilsService} from 'src/app/shared/services/common-utils.service';
import {ConfigurationService} from 'src/app/configration/configuration.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class BussinessAreasService {
  private endpoint;
  private environment;
  private categoryEndpoint: any;
  private clientId: any;

  constructor(private http: HttpClient,
              private commonUtils: CommonUtilsService,
              private configurationservice: ConfigurationService,
              private userSessionService: UserSessionService) {
    this.environment = configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/BusinessAreas`;
    this.categoryEndpoint = `${this.environment.musterkundeDevEndpoint}/api/BusinessAreaCategories`;
    this.clientId = userSessionService.getClientId().toString();
  }


  getAllBussinessAreas() {
    const url = this.endpoint;
    return this.http.get(url);
  }

  saveBussinessArea(data) {
    const url = this.endpoint;
    return this.http.post(url, data);
  }

  getBussinessAreasById(id) {
    const url = `${this.endpoint}/${id}`;
    return this.http.get(url);
  }

  deleteBussinessAreas(id) {
    const url = `${this.endpoint}/${id}`;
    return this.http.delete(url);
  }

  updateBussinessAreas(id, data) {
    const url = `${this.endpoint}/${id}`;
    return this.http.put(url, data);
  }
  getCategories() {
    const url = this.categoryEndpoint;
    return this.http.get(url);
  }
}
