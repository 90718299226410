<div>
    <div>
        <h3 class="fw-500"></h3>
    </div>
    <div class="calculation-list">
        <b>Details Stichtag {{deadline | dateFormat}}</b>
        <p>
            Der Stichtag wurde mit allen Importdaten und Kalkulationen am {{endDate | dateFormat}}
von ksakhiya gelöscht.
        </p>
    </div>
    <div mat-dialog-actions align="center" class="p-10 m-t-20">
        <button mat-raised-button color="basic" (click)="abort()" class="m-auto">{{'hide' | translate}}</button>
    </div>
</div>
