import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PotentialAnalyseService } from '../../services/potential-analyse.service';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';

@Component({
  selector: 'app-potentialanalyse-pdf-generate',
  templateUrl: './potentialanalyse-pdf-generate.component.html',
  styleUrls: ['./potentialanalyse-pdf-generate.component.scss']
})
export class PotentialanalysePdfGenerateComponent implements OnInit, AfterViewInit {

  @Input('consultantNeedSolutionList') customerNeeds: any;
  @Input('profitCenterFilter') profitCenterFilter: any;
  @Input('kalkulationHeader') kalkulationHeader: any;
  @Input('consultantInfo') consultantInfo: any;

  @Output() onInitDoneEvent: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router,
    private potentialanalysisService: PotentialAnalyseService,
    private localStorageService: LocalStorageService) {

  }
  
  ngOnInit() {
    // console.log('consultantInfo', this.consultantInfo);
    // console.log('customerNeeds', this.customerNeeds);
    // console.log('profitCenterFilter', this.profitCenterFilter);
    // console.log('profitCenterFilter', this.profitCenterFilter);
   }

   ngAfterViewInit(): void {
    this.onInitDoneEvent.emit(this.consultantInfo);
  }

  getLeftBorder(themeColor) {
    return {
      'border-left': `8px solid ${themeColor}`
    };
  }

  getThemeColor(themeColor) {
    return {
      'color': themeColor
    };
  }

  getDecimalPoint(number) {
    return number.toLocaleString('de-DE');
  }
}
