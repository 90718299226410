import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login/components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HeaderBarComponent } from './base/header-bar/header-bar.component';
import { FooterBarComponent } from './base/footer-bar/footer-bar.component';
import { MandantenService } from './mandanten-verwaltung/services/mandanten.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageMandantenBaseDataComponent } from './mandanten-verwaltung/components/manage-mandanten-base-data/manage-mandanten-base-data.component';
import { ManageMandantenLizenzenComponent } from './mandanten-verwaltung/components/manage-mandanten-lizenzen/manage-mandanten-lizenzen.component';
import { ManageMandantenAdministratorenComponent } from './mandanten-verwaltung/components/manage-mandanten-administratoren/manage-mandanten-administratoren.component';
import { ManageMandantenStatistikComponent } from './mandanten-verwaltung/components/manage-mandanten-statistik/manage-mandanten-statistik.component';
import { BenutzerListComponent } from './benutzer-verwaltung/einstellugen/components/benutzer-list/benutzer-list.component';
import { BvHomeComponent } from './benutzer-verwaltung/bv-common/bv-home/bv-home.component';
import { BenutzerAddComponent } from './benutzer-verwaltung/einstellugen/components/benutzer-add/benutzer-add.component';
import { BenutzerEditComponent } from './benutzer-verwaltung/einstellugen/components/benutzer-edit/benutzer-edit.component';
import { BvHeaderBarComponent } from './base/bv-header-bar/bv-header-bar.component';
import { customInterceptor } from './shared/services/custom-interceptor';
import { BenutzerService } from './benutzer-verwaltung/einstellugen/service/benutzer.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { CustomLoader } from './customLoader';
import { DeleteUserConfirmDialogComponent } from './benutzer-verwaltung/einstellugen/components/delete-user-confirm-dialog/delete-user-confirm-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ResetAdminPasswordComponent } from './mandanten-verwaltung/components/reset-admin-password/reset-admin-password.component';
import { DeleteAdminDialogComponent } from './mandanten-verwaltung/components/delete-admin-dialog/delete-admin-dialog.component';
import { DeleteMandantDialogComponent } from './mandanten-verwaltung/components/delete-mandant-dialog/delete-mandant-dialog.component';
import { ServiceZugriffComponent } from './benutzer-verwaltung/einstellugen/components/service-zugriff/service-zugriff.component';
import { DataImportListComponent } from './benutzer-verwaltung/data-transfer-center/components/data-import-list/data-import-list.component';
import { NewDataImportComponent } from './benutzer-verwaltung/data-transfer-center/components/new-data-import/new-data-import.component';
import { DataImportService } from './benutzer-verwaltung/data-transfer-center/services/data-import.service';
import { KalkulatiotenListComponent } from './benutzer-verwaltung/data-transfer-center/components/kalkulatioten-list/kalkulatioten-list.component';
import { NewKalkulatiotenComponent } from './benutzer-verwaltung/data-transfer-center/components/new-kalkulatioten/new-kalkulatioten.component';
import { EncryptionKeyDialogBoxComponent } from './benutzer-verwaltung/data-transfer-center/components/encryption-key-dialog-box/encryption-key-dialog-box.component';
import { CommonUtilsService } from './shared/services/common-utils.service';
import { FileSizePipe } from './shared/pipes/file-size.pipe';
import { GeschaftsgebieteListComponent } from './benutzer-verwaltung/parameter/components/geschaftsgebiete-list/geschaftsgebiete-list.component';
import { AddEditGeschaftsgebieteComponent } from './benutzer-verwaltung/parameter/components/add-edit-geschaftsgebiete/add-edit-geschaftsgebiete.component';
import { VertriebsOrganizationListComponent } from './benutzer-verwaltung/parameter/components/vertriebs-organization-list/vertriebs-organization-list.component';
import { VertriebsOrganizationCommentComponent } from './benutzer-verwaltung/parameter/components/vertriebs-organization-comment/vertriebs-organization-comment.component';
import { VertriebsOrganizationDeleteComponent } from './benutzer-verwaltung/parameter/components/vertriebs-organization-delete/vertriebs-organization-delete.component';
import { ConfirmationDialogBoxComponent } from './shared/components/confirmation-dialog-box/confirmation-dialog-box.component';

import { ConfigurationService } from './configration/configuration.service';
import { CallbackComponent } from './auth/callback/callback.component';
import { AuthService } from './auth/auth.service';
import { UnathorizedComponent } from './auth/unathorized/unathorized.component';
import { LocalStorageService } from './shared/services/localStorage.service';
import { UserSessionService } from './user-profile/services/user-session.service';
import { UserProfileService } from './user-profile/services/user-profile.service';
import { BaseComponent } from './base/base/base.component';
import { VertriebsAddConsultantComponent } from './benutzer-verwaltung/parameter/components/vertriebs-add-consultant/vertriebs-add-consultant.component';
import { VertriebsEditConsultantComponent } from './benutzer-verwaltung/parameter/components/vertriebs-edit-consultant/vertriebs-edit-consultant.component';
import { BeratungBedarfAndLosungComponent } from './benutzer-verwaltung/parameter/components/beratung-bedarf-and-losung/beratung-bedarf-and-losung.component';
import { MusterkundengruppenListComponent } from './benutzer-verwaltung/parameter/components/musterkundengruppen-list/musterkundengruppen-list.component';
import { AddTailbedarfComponent } from './benutzer-verwaltung/parameter/components/add-tailbedarf/add-tailbedarf.component';
import { AssetklassenListComponent } from './benutzer-verwaltung/parameter/components/assetklassen-list/assetklassen-list.component';
import { AssetklassenEditComponent } from './benutzer-verwaltung/parameter/components/assetklassen-edit/assetklassen-edit.component';
import { BuchungsartenListComponent } from './benutzer-verwaltung/parameter/components/buchungsarten-list/buchungsarten-list.component';
import { ProduktzuordnungenListComponent } from './benutzer-verwaltung/parameter/components/produktzuordnungen-list/produktzuordnungen-list.component';
import { DeleteBussinessAreasDialogboxComponent } from './benutzer-verwaltung/parameter/components/delete-bussiness-areas-dialogbox/delete-bussiness-areas-dialogbox.component';
import { DateFormatPipe } from './shared/pipes/date-format.pipe';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { ShowFileDetailsForCalculationComponent } from './benutzer-verwaltung/data-transfer-center/components/show-file-details-for-calculation/show-file-details-for-calculation.component';
import { EditProduktzuordnungenComponent } from './benutzer-verwaltung/parameter/components/edit-produktzuordnungen/edit-produktzuordnungen.component';
import { DeleteSalesOrganisationSecondPartComponent } from './benutzer-verwaltung/parameter/components/delete-sales-organisation-second-part/delete-sales-organisation-second-part.component';
import { AddNewEncryptionKeyComponent } from './benutzer-verwaltung/data-transfer-center/components/add-new-encryption-key/add-new-encryption-key.component';
import { PotentialAnalysisMainpageComponent } from './benutzer-verwaltung/v-musterkunde/components/potential-analysis-mainpage/potential-analysis-mainpage.component';
import { PotentialanalyseFilterBarComponent } from './benutzer-verwaltung/v-musterkunde/components/potentialanalyse-filter-bar/potentialanalyse-filter-bar.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ConfirmPotentialFiltersDialogComponent } from './benutzer-verwaltung/v-musterkunde/components/confirm-potential-filters-dialog/confirm-potential-filters-dialog.component';
import { PotentialanalysisVermogenReportComponent } from './benutzer-verwaltung/v-musterkunde/components/potentialanalysis-vermogen-report/potentialanalysis-vermogen-report.component';
import { ChartModule } from 'angular-highcharts';
import { PotentialanalysePdfGenerateComponent } from './benutzer-verwaltung/v-musterkunde/components/potentialanalyse-pdf-generate/potentialanalyse-pdf-generate.component';
import { ActivateDeactivateUserConfirmDialogComponent } from './benutzer-verwaltung/einstellugen/components/activate-deactivate-user-confirm-dialog/activate-deactivate-user-confirm-dialog.component';
import { KalkulationFilesDialogComponent } from './benutzer-verwaltung/data-transfer-center/components/kalkulation-files-dialog/kalkulation-files-dialog.component';
import { PotentialanalysisKundengerustListComponent } from './benutzer-verwaltung/v-musterkunde/components/potentialanalysis-kundengerust-list/potentialanalysis-kundengerust-list.component';
import { PdfGenerationPopUpComponent } from './benutzer-verwaltung/v-musterkunde/components/pdf-generation-pop-up/pdf-generation-pop-up.component';
import { ParameterMgmtListComponent } from './mandanten-verwaltung/components/parameter-mgmt-list/parameter-mgmt-list.component';
import { VrActivityMngrMainpageComponent } from './benutzer-verwaltung/vr-activity-manager/vr-activity-mngr-mainpage/vr-activity-mngr-mainpage.component';
import { MarketingFabrikMainPageComponent } from './benutzer-verwaltung/marketing-fabrik/marketing-fabrik-main-page/marketing-fabrik-main-page.component';
import { VrMusterkundeComponent } from './benutzer-verwaltung/v-musterkunde/components/vr-musterkunde/vr-musterkunde.component';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { AlterChartTableDataPipe } from '../app/alter-chart-table-data.pipe';
import { AddProfitCenterComponent } from './benutzer-verwaltung/add-profit-center/add-profit-center.component';
import { DataExportListComponent } from './benutzer-verwaltung/data-transfer-center/components/data-export-list/data-export-list.component';
import { DataexportEncryptionModalComponent } from './benutzer-verwaltung/data-transfer-center/components/dataexport-encryption-modal/dataexport-encryption-modal.component';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
import { AddConsultantComponent } from './benutzer-verwaltung/parameter/components/add-consultant/add-consultant.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CalculationVariablesComponent } from './benutzer-verwaltung/einstellugen/components/calculation-variables/calculation-variables.component';
import { CalculationVariableTabComponent } from './benutzer-verwaltung/einstellugen/components/calculation-variable-tab/calculation-variable-tab.component';
import { DeleteConsultantDialogComponent } from './benutzer-verwaltung/parameter/components/delete-consultant-dialog/delete-consultant-dialog.component';
import { BuchungsartenEditModalComponent } from './benutzer-verwaltung/parameter/components/buchungsarten-edit/buchungsarten-edit.component';
import { DeleteKalkulationDialogComponent } from './benutzer-verwaltung/data-transfer-center/components/delete-kalkulation-dialog/delete-kalkulation-dialog.component';
import { DeleteKeyDateDialogComponent } from './benutzer-verwaltung/data-transfer-center/components/delete-key-date-dialog/delete-key-date-dialog.component';
import { DeletedFileDetailDialogComponent } from './benutzer-verwaltung/data-transfer-center/components/deleted-file-dialog/deleted-file-dialog.component';
import { AllDeletdDialogComponent } from './benutzer-verwaltung/data-transfer-center/components/all-deletd-dialog/all-deletd-dialog.component';
import { InitAuthService } from './benutzer-verwaltung/einstellugen/service/init-auth.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ConsultantOrgnaizationTabComponent } from './benutzer-verwaltung/parameter/components/consultant-orgnaization-tab/consultant-orgnaization-tab.component';
import { BranchOrganisationTabComponent } from './benutzer-verwaltung/parameter/components/branch-organisation-tab/branch-organisation-tab.component';
import { AddEditZweigstellenComponent } from './benutzer-verwaltung/parameter/components/add-edit-zweigstellen/add-edit-zweigstellen.component';
import { DataConfirmCancelImportDialogComponent } from './benutzer-verwaltung/data-transfer-center/components/data-confirm-cancel-import-dialog/data-confirm-cancel-import-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteZweigstellenDialogComponent } from './benutzer-verwaltung/parameter/components/delete-zweigstellen-dialog/delete-zweigstellen-dialog.component';
import { ConsultantSegmentsListComponent } from './benutzer-verwaltung/parameter/components/consultant-segments-list/consultant-segments-list.component';
import { DeleteConsultantSegmentDialogComponent } from './benutzer-verwaltung/parameter/components/delete-consultant-segment-dialog/delete-consultant-segment-dialog.component';
import { AddEditConsultantSegmentComponent } from './benutzer-verwaltung/parameter/components/add-edit-consultant-segment/add-edit-consultant-segment.component';
import { DuplicateConsultantOrganizationComponent } from './benutzer-verwaltung/parameter/components/duplicate-consultant-organization/duplicate-consultant-organization.component';
import { DeleteConsultantOrganizationDialogComponent } from './benutzer-verwaltung/parameter/components/delete-consultant-organization-dialog/delete-consultant-organization-dialog.component';
import { IgxGridModule, IgxIconModule, IgxTooltipModule } from '@infragistics/igniteui-angular';
import { ConsultantStructureGridComponent } from './benutzer-verwaltung/parameter/components/consultant-structure-grid/consultant-structure-grid.component';
import { BankActivitiesComponent } from './administration/components/bank-activities/bank-activities.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function oAuthStorageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderBarComponent,
    FooterBarComponent,
    ManageMandantenBaseDataComponent,
    ManageMandantenLizenzenComponent,
    ManageMandantenAdministratorenComponent,
    ManageMandantenStatistikComponent,
    BenutzerListComponent,
    BvHomeComponent,
    BenutzerAddComponent,
    BenutzerEditComponent,
    BvHeaderBarComponent,
    DeleteUserConfirmDialogComponent,
    ResetAdminPasswordComponent,
    DeleteAdminDialogComponent,
    DeleteMandantDialogComponent,
    ServiceZugriffComponent,
    DataImportListComponent,
    NewDataImportComponent,
    KalkulatiotenListComponent,
    NewKalkulatiotenComponent,
    EncryptionKeyDialogBoxComponent,
    FileSizePipe,
    GeschaftsgebieteListComponent,
    AddEditGeschaftsgebieteComponent,
    VertriebsOrganizationListComponent,
    VertriebsOrganizationCommentComponent,
    VertriebsOrganizationDeleteComponent,
    ConfirmationDialogBoxComponent,
    CallbackComponent,
    UnathorizedComponent,
    BaseComponent,
    VertriebsAddConsultantComponent,
    VertriebsEditConsultantComponent,
    BeratungBedarfAndLosungComponent,
    MusterkundengruppenListComponent,
    AddTailbedarfComponent,
    AssetklassenListComponent,
    AssetklassenEditComponent,
    BuchungsartenListComponent,
    ProduktzuordnungenListComponent,
    DeleteBussinessAreasDialogboxComponent,
    DateFormatPipe,
    ShowFileDetailsForCalculationComponent,
    EditProduktzuordnungenComponent,
    DeleteSalesOrganisationSecondPartComponent,
    AddNewEncryptionKeyComponent,
    PotentialAnalysisMainpageComponent,
    PotentialanalyseFilterBarComponent,
    ConfirmPotentialFiltersDialogComponent,
    PotentialanalysisVermogenReportComponent,
    PotentialanalysePdfGenerateComponent,
    ActivateDeactivateUserConfirmDialogComponent,
    KalkulationFilesDialogComponent,
    PotentialanalysisKundengerustListComponent,
    PdfGenerationPopUpComponent,
    ParameterMgmtListComponent,
    VrActivityMngrMainpageComponent,
    MarketingFabrikMainPageComponent,
    VrMusterkundeComponent,
    AlterChartTableDataPipe,
    AddProfitCenterComponent,
    DataExportListComponent,
    DataexportEncryptionModalComponent,
    PageNotFoundComponent,
    AddConsultantComponent,
    CalculationVariablesComponent,
    CalculationVariableTabComponent,
    DeleteConsultantDialogComponent,
    DeleteKalkulationDialogComponent,
    DeleteKeyDateDialogComponent,
    DeletedFileDetailDialogComponent,
    AllDeletdDialogComponent,
    BuchungsartenEditModalComponent,
    ActivateDeactivateUserConfirmDialogComponent,
    DeleteUserConfirmDialogComponent,
    ResetAdminPasswordComponent,
    DeleteAdminDialogComponent,
    DeleteMandantDialogComponent,
    EncryptionKeyDialogBoxComponent,
    VertriebsOrganizationCommentComponent,
    VertriebsOrganizationDeleteComponent,
    ConfirmationDialogBoxComponent,
    AddTailbedarfComponent,
    DeleteBussinessAreasDialogboxComponent,
    ShowFileDetailsForCalculationComponent,
    DeleteSalesOrganisationSecondPartComponent,
    AddNewEncryptionKeyComponent,
    ConfirmPotentialFiltersDialogComponent,
    KalkulationFilesDialogComponent,
    PdfGenerationPopUpComponent,
    DataexportEncryptionModalComponent,
    DeleteConsultantDialogComponent,
    DeleteKalkulationDialogComponent,
    DeleteKeyDateDialogComponent,
    DeletedFileDetailDialogComponent,
    AllDeletdDialogComponent,
    ConsultantOrgnaizationTabComponent,
    BranchOrganisationTabComponent,
    AddEditZweigstellenComponent,
    DataConfirmCancelImportDialogComponent,
    DeleteZweigstellenDialogComponent,
    ConsultantSegmentsListComponent,
    DeleteConsultantSegmentDialogComponent,
    AddEditConsultantSegmentComponent,
    DuplicateConsultantOrganizationComponent,
    DeleteConsultantOrganizationDialogComponent,
    ConsultantStructureGridComponent,
    BankActivitiesComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (CustomLoader),
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MaterialModule,
    OAuthModule.forRoot(),
    Ng5SliderModule,
    ChartModule,
    NgxFileDropModule,
    MatDialogModule,
    IgxGridModule,
    IgxIconModule,
    IgxTooltipModule
  ],
  
  providers: [HttpClient,
    MandantenService,
    BenutzerService,
    DataImportService,
    {provide: MatPaginatorIntl, useClass: BenutzerListComponent},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customInterceptor,
      multi: true,
    },
    {provide: LOCALE_ID, useValue: 'de-DE'},
    CommonUtilsService,
    FileSizePipe,
    DatePipe,
    ConfigurationService,
    {provide: OAuthStorage, useFactory: oAuthStorageFactory},
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initAuthService: InitAuthService) => () => initAuthService.initAuth(),
      deps: [InitAuthService],
      multi: true,
    },
    LocalStorageService,
    UserSessionService,
    UserProfileService,
    DecimalPipe,
    AlterChartTableDataPipe,
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
}
