import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class SalesOrganisationService {
  endpointKreditInstitute: any;
  endpointconsultantStructure: any;
  environment: any;
  endpointRegion: any;
  endpointOffice: any;
  endpointPartMarket: any;
  endpointConsultant: any;
  endPointConsultantCompetenceCenters: any;
  endPointConsultantTeams: any;
  clientId: any;
  endPointProfitCenter: any;
  endPointHierarchies: any;


  constructor(private http: HttpClient,
    private configurationservice: ConfigurationService, private userSessionService: UserSessionService) {
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpointKreditInstitute = `${this.environment.musterkundeDevEndpoint}/api/ProfitCenter`;
    this.endpointconsultantStructure = `${this.environment.musterkundeDevEndpoint}/api/ProfitCenter/consultantStructure`;
    this.endpointRegion = `${this.environment.musterkundeDevEndpoint}/api/CreditInstitutionRegions`;
    this.endpointOffice = `${this.environment.musterkundeDevEndpoint}/api/CreditInstitutionDepartments`;
    this.endpointPartMarket = `${this.environment.musterkundeDevEndpoint}/api/CreditInstitutionPartMarkets`;
    this.endpointConsultant = `${this.environment.musterkundeDevEndpoint}/api/Consultants`;
    this.endPointConsultantCompetenceCenters = `${this.environment.musterkundeDevEndpoint}/api/ConsultantCompetenceCenters`;
    this.endPointConsultantTeams = `${this.environment.musterkundeDevEndpoint}/api/consultantTeams`;
    this.clientId = userSessionService.getClientId().toString();
    this.endPointProfitCenter = `${this.environment.musterkundeDevEndpoint}/api/ProfitCenter`;
    this.endPointHierarchies = `${this.environment.musterkundeDevEndpoint}/api/hierarchies`;
  }

  createHeader(value) {
    let data = 'false';
    if (value) {
      data = 'true';
    }
    const headerObj = {
      headers: new HttpHeaders({
        'isConsultantOrganisation': data,
        'mkclient_id': this.clientId
      }),
    };
    return headerObj;
  }

  profitCenterHeader() {
    const headerObj = {
      headers: new HttpHeaders({
        'client_id': this.clientId
      }),
    };
    return headerObj;
  }

  getClientIdHeader() {
    const headers: any = {
      'mkclient_id': this.clientId,
    };
    return { headers: new HttpHeaders(headers) };
  }

  getProfitCenterHeader() {
    return {
      headers: new HttpHeaders({
        'clientid': this.clientId
      })
    };
  }

  createApplicationTypeHeader() {
    const headerObj = {
      headers: new HttpHeaders({
        'dropdownType': 'CreditInstitutionPartMarket'
      }),
    };
    return headerObj;
  }

  getCreditInstitution(value) {
    const url = this.endpointKreditInstitute;
    return this.http.get(url, this.getProfitCenterHeader());
  }

  getConsultantStructure() {
    const url = this.endpointconsultantStructure;
    return this.http.get(url, this.getProfitCenterHeader());
  }
  getCreditInstitutionById(id) {
    const url = `${this.endpointKreditInstitute}/${id}`;
    return this.http.get(url);
  }
  addCreditInstitution(data) {
    data.clientId = this.clientId;
    const url = this.endpointKreditInstitute;
    return this.http.post(url, data);
  }
  addRegion(data) {
    const url = this.endpointRegion;
    return this.http.post(url, data);
  }

  getRegionById(id) {
    const url = `${this.endpointRegion}/${id}`;
    return this.http.get(url);
  }
  addOffice(data) {
    data.clientId = this.clientId;
    const url = this.endpointOffice;
    return this.http.post(url, data);
  }
  editKreditInstitute(id, data) {
    data.clientId = this.clientId;
    const url = `${this.endpointKreditInstitute}/${id}`;
    return this.http.put(url, data);

  }

  getParentCreditInstitution() {
    const url = `${this.endpointRegion}/parentnodes`;
    return this.http.get(url);
  }

  editRegion(id, data) {
    data.clientId = this.clientId;
    const url = `${this.endpointRegion}/${id}`;
    return this.http.put(url, data);
  }

  deleteRegion(id) {
    const url = `${this.endpointRegion}/${id}`;
    return this.http.delete(url);
  }
  deleteCreditInstitute(id) {
    const url = `${this.endpointKreditInstitute}/${id}`;
    return this.http.delete(url);
  }
  deleteOffice(id) {
    const url = `${this.endpointOffice}/${id}`;
    return this.http.delete(url);
  }

  editOffice(id, data) {
    data.clientId = this.clientId;
    const url = `${this.endpointOffice}/${id}`;
    return this.http.put(url, data);
  }
  getOfficeParentNode() {
    const url = `${this.endpointOffice}/parentnodes`;
    return this.http.get(url);
  }
  getOfficeById(id) {
    const url = `${this.endpointOffice}/${id}`;
    return this.http.get(url);
  }

  deleteBank(id) {
    const url = `${this.endpointKreditInstitute}/${id}`;
    return this.http.delete(url);
  } addPartMarket(data) {
    data.clientId = this.clientId;
    const url = this.endpointPartMarket;
    return this.http.post(url, data);
  }
  addConsultant(data) {
    const url = this.endpointConsultant;
    return this.http.post(url, data, this.getProfitCenterHeader());
  }
  getParentOptions(data) {
    data.clientId = this.clientId;
    const url = `${this.endpointKreditInstitute}/dropdown`;
    return this.http.post(url, data);
  }
  getTelimarketById(id) {
    const url = `${this.endpointPartMarket}/${id}`;
    return this.http.get(url);
  }
  deleteTeliMarket(id) {
    const url = `${this.endpointPartMarket}/${id}`;
    return this.http.delete(url);
  }
  deleteConsultant(id) {
    const url = `${this.endpointConsultant}/${id}`;
    return this.http.delete(url);
  }
  editPartMarket(id, data) {
    data.clientId = this.clientId;
    const url = `${this.endpointPartMarket}/${id}`;
    return this.http.put(url, data);
  }

  getChildProfitcentersById(id) {
    const url = `${this.endpointconsultantStructure}/${id}`;
    return this.http.get(url, this.getProfitCenterHeader());
  }

  getConsultantById(id) {
    const url = `${this.endpointConsultant}/${id}`;
    return this.http.get(url, this.getProfitCenterHeader());
  }
  editConsultant(id, data) {
    const url = `${this.endpointConsultant}/${id}`;
    return this.http.put(url, data, this.getProfitCenterHeader());
  }
  getAllConsultant() {
    const url = `${this.endpointConsultant}`;
    return this.http.get(url);
  }

  getConsultantCompetenceCenters() {
    const url = `${this.endPointConsultantCompetenceCenters}`;
    return this.http.get(url);
  }

  addConsultantCompetenceCenters(data) {
    data.clientId = this.clientId;
    const url = `${this.endPointConsultantCompetenceCenters}`;
    return this.http.post(url, data);
  }

  getConsultantCompetenceCentersById(id) {
    const url = `${this.endPointConsultantCompetenceCenters}/${id}`;
    return this.http.get(url);
  }

  editConsultantCompetenceCenter(id, data) {
    data.clientId = this.clientId;
    const url = `${this.endPointConsultantCompetenceCenters}/${id}`;
    return this.http.put(url, data);
  }

  deleteConsultantCompetenceCenter(id) {
    const url = `${this.endPointConsultantCompetenceCenters}/${id}`;
    return this.http.delete(url);
  }

  addConsultantTeams(data) {
    data.clientId = this.clientId;
    const url = this.endPointConsultantTeams;
    return this.http.post(url, data);
  }
  getConsultantTeamById(id) {
    const url = `${this.endPointConsultantTeams}/${id}`;
    return this.http.get(url);
  }

  editConsultantTeam(id, data) {
    data.clientId = this.clientId;
    const url = `${this.endPointConsultantTeams}/${id}`;
    return this.http.put(url, data);
  }

  deleteConsultantTeam(id) {
    const url = `${this.endPointConsultantTeams}/${id}`;
    return this.http.delete(url);
  }

  /** profitcenter API's */
  addProfitCenters(data) {
    const url = `${this.endPointProfitCenter}`;
    return this.http.post(url, data, this.getProfitCenterHeader());
  }

  getOrganizationStructureHierarchy(organisationStructureHierarchyId) {
    const url = `${this.endPointHierarchies}/${organisationStructureHierarchyId}`;
    return this.http.get(url, this.getClientIdHeader());
  }

  duplicateOrganizationStructure(data) {
    const url = `${this.endPointProfitCenter}/duplicateOrganizationStructure`;
    return this.http.post(url, data, this.getProfitCenterHeader());
  }

  editOrganizationStructureHierarchy(data) {
    const url = `${this.endPointHierarchies}`;
    return this.http.put(url, data, this.getClientIdHeader());
  }

  deleteOrganizationStructureHierarchy(organisationStructureHierarchyId) {
    const url = `${this.endPointHierarchies}/${organisationStructureHierarchyId}`;
    return this.http.delete(url, this.getClientIdHeader());
  }

  editProfitCenter(id, data) {
    const url = `${this.endPointProfitCenter}/${id}`;
    return this.http.put(url, data, this.getProfitCenterHeader());
  }

  getProfitCenterById(id) {
    const url = `${this.endPointProfitCenter}/${id}`;
    return this.http.get(url, this.getProfitCenterHeader());
  }

  getConsultantStructuresForProfitCenter() {
    const url = `${this.endPointProfitCenter}/consultantStructures`;
    return this.http.get(url);
  }
  getConsultantStructureById() {
    const url = `${this.endPointProfitCenter}/consultantStructuresByClient?clientId=${this.clientId}`;
    return this.http.get(url);
  }

  getConsultantStructureByHierarchyId(orgaStructHierarchyId) {
    const url = `${this.endPointProfitCenter}/consultantStructuresByHierarchyId?clientId=${this.clientId}&orgaStructHierarchyId=${orgaStructHierarchyId}`;
    return this.http.get(url);
  }

  deleteProfitcenter(id, deleteChild) {
    const url = `${this.endPointProfitCenter}/${id}?deleteChildren=${deleteChild}`;
    const headerObj = {
      headers: new HttpHeaders({
        'clientId': this.clientId
      }),
    };
    return this.http.delete(url, headerObj);
  }

  deleteConsultantsById(id) {
    const url = `${this.endpointConsultant}/Remove/${id}`;

    return this.http.delete(url);
  }
}
