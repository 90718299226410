import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogBoxComponent } from '../../../../shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { KalkulationService } from '../../services/kalkulation.service';
import { FILE_INTERFACE } from 'src/app/app.config';
import * as _ from 'lodash';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-new-kalkulatioten',
  templateUrl: './new-kalkulatioten.component.html',
  styleUrls: ['./new-kalkulatioten.component.scss']
})
export class NewKalkulatiotenComponent implements OnInit {

  id: any;
  kundenstammdatenCount = 0;
  regelzahlungCount = 0;
  bestandsdatenCount = 0;
  interfaceFiles = FILE_INTERFACE;
  calculationDeadlineFiles: any;
  deadlindeDate: any;
  date: any;
  defaultSelectedFileId: any[] = [];
  calculationDone = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private kalkulationService: KalkulationService,
    private commonUtils: CommonUtilsService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['id'] != undefined) {
        this.id = params['id'];
        this.deadlindeDate = params['deadlineDate'];
        this.getKalkulationById();
      }
    });
  }

  confirmDialogToGoBack() {
    const filesToCalculateDeadline = this.getFilesToCalculateDeadline();

    if (!this.arraysEqual(filesToCalculateDeadline, this.defaultSelectedFileId)) {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clearInterface();
          this.router.navigateByUrl('bv-home/kalkulation-list');
        }
      });
    } else {
      this.clearInterface();
      this.router.navigateByUrl('bv-home/kalkulation-list');
    }
  }

  getKalkulationById() {
    this.kalkulationService.getKalkulationFileByDeadLineId(this.id, 1).subscribe((response: any) => {
      this.calculationDeadlineFiles = response.Result[0].uploadedFiles;
      this.calculationDeadlineFiles.forEach(calculationFile => {
        if (calculationFile.transferType) {

          calculationFile.transferType.name = calculationFile.transferType.name.trim();
          if (calculationFile.transferType.name !== 'Dvo') {
          this.interfaceFiles.forEach(interfase => {
            if (calculationFile.transferType.name === interfase.name) {
              calculationFile.type = interfase.value;
              interfase.files.push(calculationFile);
            }
          });
        }
        }
      });
      this.selectDefaultFilesForDeadlineCalculation();
    }, (err) => {
    });
  }

  selectOrUnselectFile(selectedFile) {
    this.interfaceFiles.forEach(element => {
      if (element.files !== undefined) {
        element.files.forEach(file => {
          if (file.id == selectedFile.id) {
            file.selected = !file.selected;
          }
        });
      }
    });
    this.calculateIfMandatoryInterfaceSelected();
  }

  disableSaveButton() {
    if (!this.calculationDone) {
      if (this.kundenstammdatenCount <= 0 || this.bestandsdatenCount <= 0 || this.regelzahlungCount <= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  calculateIfMandatoryInterfaceSelected() {
    this.kundenstammdatenCount = 0;
    this.regelzahlungCount = 0;
    this.bestandsdatenCount = 0;
    this.interfaceFiles.forEach(element => {
      if (element.value === 'Kundenstammdaten') {
        element.files.forEach(file => {
          if (file.selected) {
            this.kundenstammdatenCount++;
          }
        });
      }
      if (element.value === 'Bestandsdaten') {
        element.files.forEach(file => {
          if (file.selected) {
            this.bestandsdatenCount++;
          }
        });
      }
      if (element.value == 'Regelzahlung') {
        element.files.forEach(file => {
          if (file.selected) {
            this.regelzahlungCount++;
          }
        });
      }
    });
  }

  selectDefaultFilesForDeadlineCalculation() {
    let file1Count = 0;
    let file2Count = 0;
    let file3Count = 0;
    this.interfaceFiles.forEach(interfase => {
      if (interfase.files != null) {
        interfase.files.forEach(file => {
          if (file.selected) {
            this.defaultSelectedFileId.push(file.id)
          }
          if (file.type === 'Kundenstammdaten') {
            if (file1Count === 0) {
              file.selected = true
            }
            file1Count++;
          } else if (file.type === 'Bestandsdaten') {
            if (file2Count) {
              file.selected = true;
            }
            file2Count++;
          } else if (file.type === 'Regelzahlung') {
            if (file3Count == 0) {
              file.selected = true;
            }
            file3Count++;
          }
        });
      }
    });
    
    file1Count > 0 ? this.kundenstammdatenCount++ : null;
    file2Count > 0 ? this.bestandsdatenCount++ : null;
    file3Count > 0 ? this.regelzahlungCount++ : null;
  }

  getFilesToCalculateDeadline() {

    const filesToCalculateDeadline = [];
    this.interfaceFiles.forEach(element => {
      if (element.files != undefined) {
        element.files.forEach(file => {
          if (file.selected) {
            filesToCalculateDeadline.push(file.id);
          }
        });
      }
    });
    return filesToCalculateDeadline;
  }
  calculateDeadline() {

    const filesToCalculateDeadline = this.getFilesToCalculateDeadline();
    this.calculationDone = true;
    this.kalkulationService.validatePushCalculation(this.deadlindeDate).subscribe(response => {
      this.kalkulationService.calculateDeadline(this.deadlindeDate, filesToCalculateDeadline).subscribe();
      setTimeout(() => {
        this.clearInterface();
        this.commonUtils.successSnackBar(this.translateService.instant('newCalculationAdded'));
        this.router.navigateByUrl('bv-home/kalkulation-list');
      }, 1000);
    }, (err) => {
      this.commonUtils.errorSnackBar('error', null);
    });
  }

  arraysEqual(_arr1, _arr2) {

    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
      return false;
    }

    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {

      if (arr1[i] !== arr2[i]) {
        return false;
      }

    }
    return true;

  }
  clearInterface() {
    this.interfaceFiles.forEach(element => {
      element.files = [];
    });
  }
}
