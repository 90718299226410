import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-mandant-dialog',
  templateUrl: './delete-mandant-dialog.component.html',
  styleUrls: ['./delete-mandant-dialog.component.scss']
})
export class DeleteMandantDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteMandantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  deleteMandaten() {
    this.dialogRef.close(true);
  }
}
