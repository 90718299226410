export class PotentialAnalysis {

  'calculationDeadlineId': number = null;
  'modelCustomerGroupIds': number[] = null;
  'householdIncomeFrom': any = null;
  'householdIncomeTo': any = null;
  'totalAssetFrom': any = null;
  'totalAssetTo': any = null;
  'cashAssetFrom': any = null;
  'cashAssetTo': any = null;
  'totalCreditFrom': any = null;
  'totalCreditTo': any = null;
  'longTermCreditFrom': any = null;
  'longTermCreditTo': any = null;
  'areaStatuses': number[] = null;
  'ownershipRealEstate': boolean = null;
  'riskClasses': number[] = null;
  'profitCenterDepartmentId' = null;
  'profitCenterRegionId' = null;
  'profitCenterCreditInstituteId' = null;
  'profitCenterTeamId' = null;
  'profitCenterConsultantCreditInstituteId' = null;
  'profitCenterPartMarketId' = null;
  'profitCenterConsultantId' = null;
  'profitCenterCompotenceCenterId' = null;
  'contractStatuses': number[] = null;

  // additional filter
  'householdOldestPersonFrom' = null;
  'householdOldestPersonTo' = null;
  'householdeRating' = null;
  'householdNumberPersonFrom' = null;
  'householdNumberPersonTo' = null;
  'householdNumberAdultsFrom' = null;
  'householdNumberAdultsTo' = null;
  'householdNumberMinorsFrom' = null;
  'householdNumberMinorsTo' = null;
  'buyingPowerValueFrom' = null;
  'buyingPowerValueTo' = null;
  'numberOfConsultationMeetingLast12MonthFrom' = null;
  'numberOfConsultationMeetingLast12MonthTo' = null;
  'numberOfConsultationMeetingPlannedLast12MonthFrom' = null;
  'numberOfConsultationMeetingPlannedLast12MonthTo' = null;
  'modelCustomerGroupIdNegation' =  null;

}
