<div>
    <div>
        <h3 class="fw-500"></h3>
    </div>
    <div class="calculation-list">
        <b>{{'details' | translate}} {{'calculation' | translate}} {{data.calculation.kalkulationNumber}} {{'deadline' | translate}} {{data.deadline | dateFormat}}</b>
        <p>
            {{data.calculation.description}}
        </p>
    </div>
    <div mat-dialog-actions align="center" class="p-10 m-t-20">
        <button mat-raised-button color="basic" (click)="abort()" class="m-auto">{{'hide' | translate}}</button>
    </div>
</div>