import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-mandanten-lizenzen',
  templateUrl: './manage-mandanten-lizenzen.component.html',
  styleUrls: ['./manage-mandanten-lizenzen.component.scss']
})
export class ManageMandantenLizenzenComponent implements OnInit {

  LISCENSE = [
    { 'name': 'VR Musterkunde' },
    { 'name': 'VR Aktivitätenmanager' },
    { 'name': 'Marketingfabrik' }
  ];
  displayedColumns = [' '];

  dataSource: any;
  constructor() {
    this.dataSource = new MatTableDataSource(this.LISCENSE);
  }

  ngOnInit() {
  }

}
