import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from '../../../shared/services/common-utils.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BenutzerService {
  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService,
    private userSessionService: UserSessionService) {
  }
  private createHeaderObject(includeDeletedUsers = null) {
    const headers: any = {
      'customerId': this.userSessionService.getCustomerAndUserId().customerId
    };
    if (includeDeletedUsers) {
      headers.includeDeletedUsers = includeDeletedUsers;
    }

    return { headers: new HttpHeaders(headers) };
  }

  saveUser(body) {
    const user = this.userSessionService.getUserProfileDetails();
    body.ProductId = user.AdminPrivileges[0].ProductId;
    const url = this.commonUtils.getAPIUrl('User');
    return this.http.post(url, body, this.createHeaderObject());
  }

  createCustomerAdmin(userId) {
    const url = this.commonUtils.getAPIUrl(`User/CreateCustomerAdministrator/${userId}`);
    return this.http.post(url, {}, this.createHeaderObject());
  }

  deleteCustomerAdmin(userId) {
    const url = this.commonUtils.getAPIUrl(`User/DeleteCustomerAdministrator/${userId}`);
    return this.http.delete(url, this.createHeaderObject());
  }

  getAllUsers(pageIndex, pagesize) {
    const url = this.commonUtils.getAPIUrl(`User?includeDeletedUsers=true&pageIndex=${pageIndex}&pageSize=${pagesize}`);
    return this.http.get(url, this.createHeaderObject('true'));
  }

  searchUsers(pageIndex, pageSize, payload) {
    const url = this.commonUtils.getAPIUrl(`User/Search?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    return this.http.post(url, payload, this.createHeaderObject())
  }

  getUserById(ids) {
    const url = this.commonUtils.getAPIUrl(`User/GetUsersById?includeDeletedUsers=true`);
    return this.http.post(url, ids, this.createHeaderObject());
  }

  updateUser(userId, user) {
    const url = this.commonUtils.getAPIUrl(`User/${userId}`);
    return this.http.put(url, user, this.createHeaderObject());
  }

  deleteUser(userId) {
    const url = this.commonUtils.getAPIUrl(`User/${userId}`);
    return this.http.delete(url, this.createHeaderObject());
  }

  updateEmail(body) {
    const url = this.commonUtils.getAPIUrl(`User/UpdateEmail`);
    return this.http.post(url, body, this.createHeaderObject());
  }

  updateUserName(body) {
    const url = this.commonUtils.getAPIUrl(`User/UpdateUserName`);
    return this.http.post(url, body, this.createHeaderObject());
  }

  saveUserClaims(userId, body) {
    const url = this.commonUtils.getAPIUrl(`claims/User/${userId}`);
    return this.http.put(url, body, this.createHeaderObject());

  }
  updateClaims(userId, serviceId, data) {
    const url = this.commonUtils.getAPIUrl(`Claims/User/${userId}/service/${serviceId}`);
    return this.http.put(url, data, this.createHeaderObject());
  }

  getBankBills(pageIndex, pagesize) {
    const url = this.commonUtils.getAPIUrl(`Customer?pageIndex=${pageIndex}&pageSize=${pagesize}`);
    return this.http.get(url);

  }

  async getDashboardProfile() {
    const headerObject = this.createHeaderObject();
    const url = this.commonUtils.getAPIUrl(`User/DashboardProfile`);
    return await this.userSessionService.getUserInfoFromServer();
  }

  activateUser(id) {
    const url = this.commonUtils.getAPIUrl(`User/Activate/${id}`);
    return this.http.post(url, {});

  }

}
