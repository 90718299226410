<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="50px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'sampleGroupCostomers' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="12" rowHeight="700px" class="m-t-50 m-b-50 mklist-wrapper">
    <mat-grid-tile flex-start flex-align-top [colspan]="4" class="grid-spacing overflow-hide">
      <div class="div-spinner" *ngIf="spinnerFlag">
        <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
      </div>
      <mat-tab-group class="tab-height tab-border overflow-hide" (selectedTabChange)="tabChanged($event)"
        *ngIf="!spinnerFlag">
        <!-- tab 1 -->
        <mat-tab label="Bankindividuell" class="inner-tab">
          <div class="inner-content">
            <mat-list role="list" class="outer-list-border">
              <mat-list-item (click)="selectListItemBankSpecificCustomer(customerGroup.id)"
                [ngClass]="{'list-selected' : customerGroup.select }" class="list-border" role="listitem"
                *ngFor="let customerGroup of bankSpecificCustomerGroupList">
                <div>

                  <span class="grey-text" class="tab-inner-content-main"></span>
                  <div class="tab-inner-content">
                    <span> {{customerGroup.name}}
                      <span *ngIf="customerGroup.ageGroup">|</span>
                      <!-- {{customerGroup.customerGroup}} -->
                    </span>

                    <div class="text-center">{{customerGroup.subData}} </div>
                  </div>
                </div>
              </mat-list-item>

            </mat-list>
          </div>


        </mat-tab>

        <!-- tab 2 -->
        <mat-tab label="BVR-Standard">
          <div class="inner-content">
            <mat-list role="list" class="outer-list-border">
              <mat-list-item (click)="selectListItemNationalCustomer(customerGroup.id)"
                [ngClass]="{'list-selected' : customerGroup.select }" class="list-border" role="listitem"
                *ngFor="let customerGroup of nationalCustomerGroupList">
                <div>
                  <span class="grey-text" class="tab-inner-content-main"></span>
                  <div class="tab-inner-content">
                    <span> {{customerGroup.name}}
                    </span>
                    <div class="text-center">{{customerGroup.subData}} </div>
                  </div>
                </div>
              </mat-list-item>

            </mat-list>
          </div>

        </mat-tab>

      </mat-tab-group>
    </mat-grid-tile>

    <mat-grid-tile flex-start flex-align-top [colspan]="8" class="grid-spacing">
      <div></div>
      <!-- side content 1 -->
      <!-- <div class="div-spinner" *ngIf="rightSpinFlag">
        <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
      </div> -->

      <div *ngIf="bankSpecificCustomerGroupIsSelected" class="side-main">
        <div>
          <button mat-raised-button class="custom-button add-button"
            [ngClass]="{'button-disabled' : isCustomerSelected() }" (click)="addPartialNeeds()">
            <i class="material-icons icon-add">
              add
            </i>
            {{'addPartialDemand' | translate}} </button>
        </div>
        <div class="side-content card-shadow">
          <div *ngIf="selectCustomerGroupText" class="no-need-data">
            {{'pleaseSelectSampleCustomerGroup' | translate}}
          </div>

          <div *ngIf="!selectCustomerGroupText" cdkDropList class="example-list"
            (cdkDropListDropped)="drop($event, customerGroup && customerGroup.id)">
            <div class="example-box  need-left-border" *ngFor="let needs of bankSpecificCustomerNeeds"
              [ngStyle]="getLeftBorder(needs.needThemeName)" cdkDrag>
              <div class="left-icon">
                <img src="../../../../../assets/images/braille-six-dots.svg">
              </div>
              <div class="need-data-align">
                <span class="grey-text"> {{needs.needThemeName}} > {{needs.needFieldName}}</span>
                <div>
                  <b>{{needs.needFieldPartName}}</b>
                </div>
              </div>
              <div class="f-left">
                <span class="p-r-15">
                  <span class="priotity-number">
                    {{needs.rang}} </span>
                  <img [matTooltip]="translateService.instant('cashFlowNeutral')"
                    [ngClass]="{'image-disabled': (!needs.isCashFlowNeutral)}"
                    src="../../../../../assets/images/Zahlungsstromneutral.svg" (click)="cashFlowStatusChange(needs)">
                  <img [matTooltip]="translateService.instant('existentially')"
                    [ngClass]="{'image-disabled': (!needs.isExistence)}"
                    src="../../../../../assets/images/Essenziell.svg" (click)="existentialStatusChange(needs)">
                </span>
                <i class="material-icons delete-icon" (click)="deleteNeed(needs)">
                  clear
                </i>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- side content 2 -->
      <div class="side-main" *ngIf="!bankSpecificCustomerGroupIsSelected">
        <div>
          <button mat-raised-button class="custom-button" class="add-button button-disabled"
            (click)="addPartialNeeds()">
            <i class="material-icons icon-add">
              add
            </i>
            {{'addPartialDemand' | translate}}</button>
        </div>
        <div class="side-content card-shadow">

          <div *ngIf="selectCustomerGroupText" class="no-need-data">
            {{'pleaseSelectSampleCustomerGroup' | translate}}
          </div>
          <div *ngIf="!selectCustomerGroupText" class="example-list">
            <div class="example-box  need-left-border" *ngFor="let needs of nationalCustomerNeeds"
              [ngStyle]="getLeftBorder(needs.needThemeName)">



              <div class="left-icon">
                <img src="../../../../../assets/images/braille-six-dots.svg">
              </div>
              <div class="need-data-align">
                <span class="grey-text"> {{needs.needThemeName}} > {{needs.needFieldName}}</span>
                <div>
                  <div class="fw-500">{{needs.needFieldPartName}}</div>
                </div>
              </div>
              <div class="f-left">
                <span style="font-size: 24px;padding:  2px;margin-bottom: 5px;position:  absolute;right: 130px;">
                  {{needs.rang}} </span>
                <span>
                  <img [matTooltip]="translateService.instant('cashFlowNeutral')"
                    [ngClass]="{'image-disabled': (!needs.isCashFlowNeutral)}"
                    src="../../../../../assets/images/Zahlungsstromneutral.svg">
                  <img [matTooltip]="translateService.instant('existentially')"
                    [ngClass]="{'image-disabled': (!needs.isExistence)}"
                    src="../../../../../assets/images/Essenziell.svg">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </mat-grid-tile>
  </mat-grid-list>
</div>
