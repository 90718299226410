import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-deleted-file-dialog",
  templateUrl: "./deleted-file-dialog.component.html",
  styleUrls: ["./deleted-file-dialog.component.scss"],
})
export class DeletedFileDetailDialogComponent implements OnInit {
  deadline: number;
  constructor(
    public dialogRef: MatDialogRef<DeletedFileDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.deadline = new Date(this.data.deadline).getDate()
  }
  
  abort() {
    this.dialogRef.close();
  }
}
