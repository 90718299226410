import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KalkulationService } from '../../services/kalkulation.service';
import { ShowFileDetailsForCalculationComponent } from '../show-file-details-for-calculation/show-file-details-for-calculation.component';
import { map } from 'rxjs/operators';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { KalkulationFilesDialogComponent } from '../kalkulation-files-dialog/kalkulation-files-dialog.component';
import { FILE_INTERFACE } from 'src/app/app.config';
import { DeleteKalkulationDialogComponent } from '../delete-kalkulation-dialog/delete-kalkulation-dialog.component';
import { DeleteKeyDateDialogComponent } from '../delete-key-date-dialog/delete-key-date-dialog.component';
import { DeletedFileDetailDialogComponent } from '../deleted-file-dialog/deleted-file-dialog.component';
import { AllDeletdDialogComponent } from '../all-deletd-dialog/all-deletd-dialog.component';
import { stringToBoolean } from '../../../../shared/util/string-to-boolean';

@Component({
  selector: 'app-kalkulatioten-list',
  templateUrl: './kalkulatioten-list.component.html',
  styleUrls: ['./kalkulatioten-list.component.scss'],
})
export class KalkulatiotenListComponent implements OnInit {
  panelOpenState = false;
  calculationListLength: any;
  kalkulationList: any[] = [];
  spinnerFlag = true;
  fileInterface = ['Customer', 'ProcessBalance', 'ProcessSavingsEur'];
  innerSpin = true;

  private readonly calculationStatusWasCanceled: string = 'Wurde abgebrochen.';
  private readonly calculationStatusWasDeleted: string = 'Wurde gelöscht.';
  private readonly calculationStatusIsCalculating: string = 'Wird berechnet...';
  private readonly calculationStatusIsDeleting: string = 'Wird gelöscht...';
  private readonly calculationStatusFailed: string = 'Kalkulation fehlgeschlagen';
  private readonly calculationDescriptionStart: string = 'Kallkulation wurde am';


  // kalkulationDeadlines: any[] = [];
  constructor(private router: Router,
              private kalkulationService: KalkulationService,
              private commonUtils: CommonUtilsService,
              private dialog: MatDialog) {
    this.getKalkulationList();
  }

  calculationList: any[] = [];

  ngOnInit() {

  }


  getKalkulationList() {
    this.kalkulationService.getDeadlines().pipe(
      map((data: any) =>
        data.Result.sort((a, b) => new Date(b.deadlineDate).getTime() - new Date(a.deadlineDate).getTime())),
    ).subscribe((response: any) => {
      this.spinnerFlag = false;
      this.kalkulationList = response;
      this.kalkulationList.forEach(element => {
        // button disable
        element.isExpanded = false;
        element.disable = false;
        element.kalkulationFiles = [];
        element.innerSpin = true;
        element.isDeleted = stringToBoolean(element.isDeleted);
        element.isComplete = stringToBoolean(element.isComplete);
      });
      this.calculationListLength = this.kalkulationList.length;
    });
  }

  checkAllDeleted(element): boolean {
    for (let i = 0; i < element.kalkulationDeadlines.length; i++) {
      if (!element.kalkulationDeadlines[i].isDeleted) {
        return false;
      }
    }
    return true;
  }

  isAnydeleting(element): boolean {
    for (let i = 0; i < element.kalkulationDeadlines.length; i++) {
      if (element.kalkulationDeadlines[i].isdeleting) {
        return true;
      }
    }
    return false;
  }

  navigateToNewCalulation(kalkulation) {
    FILE_INTERFACE.forEach(file => {
      file.files = [];
    });

    const navigationExtras: NavigationExtras = {
      queryParams: {
        'id': kalkulation.id,
        'deadlineDate': kalkulation.deadlineDate,
      },
    };
    this.router.navigate(['bv-home/new-kalkulation'], navigationExtras);
  }

  allDeletedDialog(calculation) {
    const dialogref = this.dialog.open(AllDeletdDialogComponent, {
      width: '500px',
      data: {calculation},
    });
    dialogref.afterClosed().subscribe(() => {
    });
  }

  calculationFilesDialogBox(deadline, calculation) {
    if (calculation.status === this.calculationStatusWasDeleted || calculation.status === this.calculationStatusWasCanceled) {
      const dialogref = this.dialog.open(DeletedFileDetailDialogComponent, {
        width: '500px',
        data: {calculation, deadline},
      });
      dialogref.afterClosed().subscribe(() => {
      });
    } else {
      const dialogRef = this.dialog.open(KalkulationFilesDialogComponent, {
        width: '700px',
        height: '500px',
        data: {
          files: calculation.calculationDeadlineFiles,
          kalkulationNo: calculation.kalkulationNumber,
          deadline: deadline,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    }
  }

  closeCalculation(calculation) {
    this.kalkulationList.forEach(calc => {
      if (calc.id === calculation.id) {
        calc.isExpanded = false;
      }
    });
  }

  openCalculation(calculation) {
    let canCalculationStart: any;
    this.kalkulationList.forEach(deadline => {
      if (deadline.id === calculation.id) {
        deadline.isExpanded = true;
        this.kalkulationService.canCalculationStarted(calculation.deadlineDate).subscribe((Response: any) => {
          if (stringToBoolean(Response.Result)) {
            deadline.disable = false;
            canCalculationStart = true;
          } else {
            deadline.disable = true;
            canCalculationStart = false;
          }
          this.getKalkulationFilesForValidation(calculation, canCalculationStart);
        }, () => {
          deadline.disable = true;
        });
      }
    });

    // get calculations
    this.getCalculationList(calculation);
  }


  // getKalkulationFileByDeadline Id --> for validation of 3 files
  getKalkulationFilesForValidation(calculation, canCalculationStart) {
    this.kalkulationService.getKalkulationFileByDeadLineId(calculation.id, 1).subscribe((response: any) => {
      this.kalkulationList.forEach(data => {
        if (data.id === calculation.id) {
          data.innerSpin = false;
        }
      });
      response.Result.forEach(element => {
        element.calculationDeadlines.forEach(data => {
          const calculationDeadlineFiles = data.calculationDeadlineFiles;
          this.kalkulationList.forEach(deadlineList => {
            if (deadlineList.id === calculation.id) {
              deadlineList.kalkulationFiles = calculationDeadlineFiles;
              if (canCalculationStart) {
                if (this.checkIfAllThreeTypesOfFilesPresent(calculationDeadlineFiles)) {
                  deadlineList.disable = false;
                }
              }
            }
          });

        });
      });
      this.innerSpin = false;
    }, () => {
      this.innerSpin = false;
      this.commonUtils.errorSnackBar('error', '');
    });
  }


  checkIfAllThreeTypesOfFilesPresent(calculationDeadlineFiles) {
    let customer = 0;
    let processBalance = 0;
    let processSavingsEur = 0;
    calculationDeadlineFiles.forEach(file => {
      if (file.uploadedFile.transferType) {
        file.uploadedFile.transferType.name = file.uploadedFile.transferType.name.trim();
        if (file.uploadedFile.transferType.name === 'Customer') {
          customer++;
        }
        if (file.uploadedFile.transferType.name === 'ProcessBalance') {
          processBalance++;
        }
        if (file.uploadedFile.transferType.name === 'ProcessSavingsEur') {
          processSavingsEur++;
        }
      }
    });
    return customer > 0 && processBalance > 0 && processSavingsEur > 0;

  }

  // get kalkulation list
  getCalculationList(calculation) {
    let kalkulationDeadlines: any;
    this.kalkulationService.getCalculationDeadlines(calculation.id)
      .subscribe((response: any) => {
        kalkulationDeadlines = response.Result;
        kalkulationDeadlines = this.sortCalculationDeadlineAsc(kalkulationDeadlines);
        let count = 0;
        kalkulationDeadlines.forEach(deadline => {
          count++;
          deadline.kalkulationNumber = count;
          deadline.status = (deadline.status || '').trim();
          if (stringToBoolean(deadline.isCalculationFinished)) {
            // failed
            deadline.status === this.calculationStatusFailed ? deadline.failed = true : deadline.failed = false;

            // success
            this.isEmpty(deadline.status) ? deadline.success = true : deadline.success = false;
            // is deleting
            if (deadline.status.includes(this.calculationStatusIsDeleting)) {
              deadline.failed = false;
              deadline.success = false;
              deadline.isdeleting = true;
              deadline.isDeleted = false;
              deadline.isCanceled = false;
              deadline.isCanceling = false;
            }
            else if (deadline.status.includes(this.calculationStatusWasDeleted)) {
              deadline.failed = false;
              deadline.success = false;
              deadline.isdeleting = false;
              deadline.isDeleted = true;
              deadline.isCanceled = false;
              deadline.isCanceling = false;
            }
            else if (deadline.status.includes(this.calculationStatusWasCanceled)) {
              deadline.failed = false;
              deadline.success = false;
              deadline.isdeleting = false;
              deadline.isDeleted = false;
              deadline.isCanceled = true;
              deadline.isCanceling = false;
            }

          } else {
            // progress
            deadline.status === this.calculationStatusIsCalculating ? deadline.progress = true : deadline.progress = false;
            // failed
            deadline.status === this.calculationStatusFailed ? deadline.failed = true : deadline.failed = false;
            if (deadline.status.includes(this.calculationStatusWasDeleted)) {
              deadline.failed = false;
              deadline.success = false;
              deadline.isdeleting = false;
              deadline.isDeleted = true;
              deadline.isCanceled = false;
              deadline.isCanceling = false;
            }
            else if (deadline.status.includes(this.calculationStatusIsDeleting)) {
              deadline.failed = false;
              deadline.success = false;
              deadline.isdeleting = false;
              deadline.isDeleted = false;
              deadline.isCanceled = false;
              deadline.isCanceling = true;
            }
            else if (deadline.status.includes(this.calculationStatusWasCanceled)) {
              deadline.failed = false;
              deadline.success = false;
              deadline.isdeleting = false;
              deadline.isDeleted = false;
              deadline.isCanceled = true;
              deadline.isCanceling = false;
            }
          }
        });
        kalkulationDeadlines = this.sortCalculationDeadlineDes(kalkulationDeadlines);
        this.kalkulationList.forEach(c => {
          if (c.id === calculation.id) {
            c.kalkulationDeadlines = kalkulationDeadlines;
            if (c.kalkulationDeadlines.length > 0) {
              c.isAllDeleted = this.checkAllDeleted(c);
              c.isAnyDeleting = this.isAnydeleting(c);
            }
          }
        });
        this.getProgressStatus();
        this.repeatGetCalculations();
      });

  }


  getProgressStatus() {
    let count = 0;
    this.kalkulationList.forEach(calculation => {
      if (calculation.kalkulationDeadlines) {
        calculation.kalkulationDeadlines.forEach(deadline => {
          if (deadline.progress) {
            if (count === 0) {
              setTimeout(() => {
                this.getCalculationList(calculation);
              }, 10000);
            }
            count++;
          }
        });
      }
    });
  }


  navigateToDataImportPage() {
    this.router.navigateByUrl('bv-home/dataimport-list');
  }


  showKalkulationFileDetails(files) {
    this.kalkulationList.forEach(element => {
      if (element.id === files.id) {
        const dialogRef = this.dialog.open(ShowFileDetailsForCalculationComponent, {
          width: '800px',
          data: {calculation: element},
        });
        dialogRef.afterClosed().subscribe(() => {
        });
      }
    });
  }

  sortCalculationDeadlineAsc(kalkulationDeadlines) {

    kalkulationDeadlines.sort(function (a, b) {
      a = new Date(a.calculationTimeStamp);
      b = new Date(b.calculationTimeStamp);
      return a < b ? -1 : a > b ? 1 : 0;
    });
    return kalkulationDeadlines;
  }

  sortCalculationDeadlineDes(kalkulationDeadlines) {
    kalkulationDeadlines.sort(function (a, b) {
      a = new Date(a.calculationTimeStamp);
      b = new Date(b.calculationTimeStamp);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    return kalkulationDeadlines;
  }

  isEmpty(value) {
    if (value === '' || value == null) {
      return true;
    } else {
      return false;
    }
  }

  deleteKalkulation(kalkulation, type, calculation) {

    const dialogRef = this.dialog.open(DeleteKalkulationDialogComponent, {
      width: '800px',
      data: {calculation: kalkulation, type: type, parent: calculation},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.kalkulationService
          .deleteKalulationDeadlines(kalkulation.id, type === 'stop')
          .subscribe(() => {
            this.kalkulationList.forEach((calc) => {
              if (calculation.id == calc.id) {
                calc.kalkulationDeadlines.forEach((deadline) => {
                  if (kalkulation.id == deadline.id) {
                    if (type === 'stop') {
                      deadline.isdeleting = false;
                      deadline.isCanceling = true;
                      deadline.success = false;
                      deadline.failed = false;
                      deadline.isDeleted = false;
                      deadline.isCanceled = false;
                      deadline.progress = false;
                      deadline.status = this.calculationStatusIsDeleting;
                    } else {
                      deadline.isdeleting = true;
                      deadline.isCanceling = false;
                      deadline.success = false;
                      deadline.failed = false;
                      deadline.isDeleted = false;
                      deadline.isCanceled = false;
                      deadline.progress = false;
                      deadline.status = this.calculationStatusIsDeleting;
                    }
                  }
                });
              }
            });
            if (!(type === 'stop')) {
              this.repeatGetCalculations();
            }
          });
      }
    });

  }

  repeatGetCalculations() {
    let count = 0;
    this.kalkulationList.forEach(calculation => {
      if (calculation.kalkulationDeadlines) {
        calculation.kalkulationDeadlines.forEach(deadline => {
          if (deadline.isdeleting || deadline.isCanceling) {
            if (count === 0) {
              setTimeout(() => {
                this.getCalculationList(calculation);
              }, 10000);
            }
            count++;
          }
        });
      }
    });
  }

  deleteKeyDate(kalkulation) {
    this.kalkulationList.forEach(calc => {
      if (calc.id === kalkulation.id) {
        kalkulation.isAnyDeleting = true;
      }
    });
    const dialogRef = this.dialog.open(DeleteKeyDateDialogComponent, {
      width: '800px',
      data: {calculation: kalkulation},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.kalkulationService.deleteDeadline(kalkulation.id).subscribe(() => {
          this.getKalkulationList();
        });
      }
    });
  }


}


