import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-unathorized',
  templateUrl: './unathorized.component.html',
  styleUrls: ['./unathorized.component.scss']
})
export class UnathorizedComponent implements OnInit {

  constructor(private router: Router,
              private platformLocation: PlatformLocation) { }

  ngOnInit() {
  }

  onLogoutClick() {
    localStorage.clear();
    location.href = 'https://login.microsoftonline.com/common/oauth2/logout';
  }
}
