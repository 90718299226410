<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        Beraterstruktur > {{ isEdit ? ' Bearbeiten' :'Neuer Berater'}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>


  <div *ngIf="spinnerFlag" class="progress-spinner spin-position">
    <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
  </div>
  <mat-card class="m-t-50 m-b-50 p-t-30" *ngIf="!spinnerFlag">
    <mat-card-content>
      <mat-grid-list cols="12" rowHeight="500px">
        <mat-grid-tile flex-start flex-align-top [colspan]="7">

          <form class="w-100" novalidate [formGroup]="consultantForm">
            <div class="form-field">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'name' | translate}}</mat-label>
                <input matInput placeholder="Name" name="des" formControlName="name">
                <mat-error *ngIf="hasError('name', 'required')">name {{'isRequired' | translate}}</mat-error>

              </mat-form-field>
            </div>

            <div class="form-field">
              <mat-form-field class="full-width mt-20" appearance="outline">
                <mat-label>{{'description' | translate}}</mat-label>
                <input matInput placeholder="Beschreibung" name="shortDes" formControlName="shortDescription">
              </mat-form-field>
            </div>
            <!-- categorie -->

            <!-- parent profit center-->
            <div class="form-field">

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'parentProfitCenter' | translate}}</mat-label>
                <mat-select formControlName="parentProfitCenter">
                  <mat-option *ngFor="let parent of parentProfitCenterList" [value]="parent.id">{{parent.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('parentProfitCenter', 'required')">Übergeordnetes Profitcenter
                  {{'isRequired' | translate}}</mat-error>
              </mat-form-field>
            </div>

            <div class="form-field">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Beraternummer</mat-label>
                <input matInput placeholder="Beraternummer" name="Beraternummer" formControlName="consultantClientId">
                <mat-error *ngIf="hasError('consultantClientId', 'required')">Beraternummer {{'isRequired' | translate}}
                </mat-error>

              </mat-form-field>
            </div>

            <!-- <div class="form-field">
              <div>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Organisationseinheit</mat-label>
                  <input matInput placeholder="Organisationseinheit" type="number"
                    (input)="organisationalIdInputChange()" name="Organisationseinheit"
                    formControlName="organisationalUnitId">
                  <mat-error class="m-t-20" *ngIf="hasError('organisationalUnitId', 'required')">Es muss ein Wert erfasst werden.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="error" *ngIf="organisationalLengthErr">Es werden nur Zahlen bis 99.999.999.999 erfasst werden.
              </div>
            </div> -->

            <!-- consultant-segment/type -->
            <div class="form-field">

              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'consultantSegment' | translate}}</mat-label>
                <mat-select formControlName="consultantSegment">
                  <mat-option *ngFor="let segment of consultantSegmentList" [value]="segment.id">{{segment.name}}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="hasError('consultantSegment', 'required')">{{'consultantSegment' | translate}} 
                  {{'isRequired' | translate}}</mat-error> -->
              </mat-form-field>
            </div>

            <!-- capacity -->
            <div class="form-field">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'capacity' | translate}}</mat-label>
                <input matInput placeholder="{{'capacity' | translate}}" name="capacity" formControlName="capacity">
                <!-- <mat-error *ngIf="hasError('capacity', 'required')">{{'capacity' | translate}} {{'isRequired' | translate}}
                </mat-error> -->
              </mat-form-field>
            </div>

          </form>
          

        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
    <mat-card-actions class="m-b-5">
      <mat-grid-list cols="12" rowHeight="45px">
        <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
          <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" (click)="addConsultant()"
            [disabled]="disableSave()">{{'save' | translate}}</button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-actions>
  </mat-card>
</div>