import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vertriebs-organization-list',
  templateUrl: './vertriebs-organization-list.component.html',
  styleUrls: ['./vertriebs-organization-list.component.scss'],
})
export class VertriebsOrganizationListComponent implements OnInit, OnDestroy {

  salesOrganization: any;
  branchOrganisation = false;
  consultantOrganisationList: any = [];
  consultantOrganisation = false;
  spinnerFlag = true;
  elementToBeRemoved = [];
  beraterOrganisation: any;
  expansionState = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonUtils: CommonUtilsService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        const consultantOrganisation = params['consultantOrganisation'];
        this.expansionState = this.commonUtils.getVetribsOrganisationStoreState();

        if (consultantOrganisation === 'true') {
          this.consultantOrganisation = true;
          this.branchOrganisation = false;
        } else {
          this.consultantOrganisation = false;
          this.branchOrganisation = true;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setSpinner($event) {
    this.spinnerFlag = $event;
  }

  switch(type) {
    this.spinnerFlag = true;
    if (type === 'branch') {
      this.consultantOrganisation = false;
      this.branchOrganisation = true;
    } else {
      this.consultantOrganisation = true;
      this.branchOrganisation = false;
    }
  }
}

