import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DuplicateOrganizationStructureStatus } from 'src/app/shared/enums/duplicate-organization-structure-status.enum';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { SalesOrganisationService } from '../../service/sales-organisation.service';

@Component({
  selector: 'app-duplicate-consultant-organization',
  templateUrl: './duplicate-consultant-organization.component.html',
  styleUrls: ['./duplicate-consultant-organization.component.scss']
})
export class DuplicateConsultantOrganizationComponent implements OnInit {

  spinnerFlag = true;
  hierarchyDuplicationForm: FormGroup;
  consultantSegmentId: any;
  organisationStructureHierarchyId: any;
  deadline: any;
  hierarchyDuplicationData: HierarchyDuplicationData;
  hierarchyEditData: HierarchyEditData;
  isEdit: boolean;
  hierarchyIfEdit: any;
  
  constructor(private route: ActivatedRoute,
    private commonUtils: CommonUtilsService,
    private router: Router,
    private salesOrganisationService: SalesOrganisationService,
    private translateService: TranslateService
    ) {
      this.hierarchyDuplicationData = new HierarchyDuplicationData();
      this.hierarchyDuplicationData.sourceOrganisationStructureHierarchyId = +this.route.snapshot.paramMap.get('orgaStructId');
      this.hierarchyDuplicationData.deadline = this.route.snapshot.paramMap.get('deadline');
      this.organisationStructureHierarchyId = +this.route.snapshot.paramMap.get('orgaStructHierarchyId');
      this.initForm();
    }


  ngOnInit(): void {
    this.spinnerFlag = false;

    if (this.organisationStructureHierarchyId) {
      this.isEdit = true; 
      this.getOrganisationStructureHierarchyById();
    }
  }

  initForm() {
    this.hierarchyDuplicationForm = new FormGroup({
      // hierarchyId: new FormControl('', Validators.compose([
      //   Validators.required])),
      hierarchyDescription: new FormControl('', Validators.compose([
          Validators.required])),
      
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.hierarchyDuplicationForm.controls[controlName].hasError(errorName);
  }
  
  disableSave() {
    if (this.hierarchyDuplicationForm.valid && (this.hierarchyDuplicationForm.dirty ) ) {
      return false;
    }
    return true;
  }

  clearForm() {
    this.router.navigateByUrl('/bv-home/sales-organization-list/true?consultantOrganisation=true');
  }

  save() {
    if (this.isEdit) {
      this.editOrgaStruct();
    } else {
      this.duplicateOrgaStruct();
    }
  }

  duplicateOrgaStruct() {
    this.spinnerFlag = true;
    
    this.hierarchyDuplicationData.newHierarchyDescription = this.hierarchyDuplicationForm.value.hierarchyDescription;

      this.salesOrganisationService.duplicateOrganizationStructure(this.hierarchyDuplicationData).subscribe((res: any) => {
        
        if (res.Result == DuplicateOrganizationStructureStatus.Success) {
          this.router.navigateByUrl('/bv-home/sales-organization-list/true?consultantOrganisation=true');
        } else if (res.Result == DuplicateOrganizationStructureStatus.ErrorHierarchyIdTaken) {
          this.commonUtils.errorSnackBar('error', this.translateService.instant('errorHierarchyIdTaken') , 3000);
        } else if (res.Result == DuplicateOrganizationStructureStatus.ErrorHierarchyDescriptionTaken) {
          this.commonUtils.errorSnackBar('error', this.translateService.instant('errorHierarchyDescriptionTaken'), 3000);
        } else if (res.Result == DuplicateOrganizationStructureStatus.Failed) {
          this.commonUtils.errorSnackBar('error', this.translateService.instant('errorDuplicateOrganisationStructureFailed'), 3000);
        }
        this.spinnerFlag = false;
      }, err => {
        this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage, 3000);
        this.spinnerFlag = false;
      });
  }

  editOrgaStruct() {
    this.spinnerFlag = true;

    this.hierarchyEditData.newHierarchyDescription = this.hierarchyDuplicationForm.value.hierarchyDescription;
    
      this.salesOrganisationService.editOrganizationStructureHierarchy(this.hierarchyEditData).subscribe((res: any) => {
        
        if (res.Result == DuplicateOrganizationStructureStatus.Success) {
          this.router.navigateByUrl('/bv-home/sales-organization-list/true?consultantOrganisation=true');
        } else if (res.Result == DuplicateOrganizationStructureStatus.ErrorHierarchyIdTaken) {
          this.commonUtils.errorSnackBar('error', this.translateService.instant('errorHierarchyIdTaken') , 3000);
        } else if (res.Result == DuplicateOrganizationStructureStatus.ErrorHierarchyDescriptionTaken) {
          this.commonUtils.errorSnackBar('error', this.translateService.instant('errorHierarchyDescriptionTaken'), 3000);
        } else if (res.Result == DuplicateOrganizationStructureStatus.Failed) {
          this.commonUtils.errorSnackBar('error', this.translateService.instant('errorDuplicateOrganisationStructureFailed'), 3000);
        }
        this.spinnerFlag = false;
      }, err => {
        this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage, 3000);
        this.spinnerFlag = false;
      });
  }

  getOrganisationStructureHierarchyById() {
    this.salesOrganisationService.getOrganizationStructureHierarchy(this.organisationStructureHierarchyId).subscribe((res: any) => {
      this.hierarchyIfEdit = res.Result;

      this.hierarchyEditData = new HierarchyEditData();
      this.hierarchyEditData.organisationStructureHierarchyId = this.organisationStructureHierarchyId;
      this.hierarchyEditData.deadline = this.hierarchyIfEdit.deadline;
      this.hierarchyEditData.newHierarchyDescription = this.hierarchyIfEdit.hierarchyDescription;

      if (this.isEdit) {
        this.setInitialObject();
      }
    });
  }

  setInitialObject() {
    if (this.isEdit) {
      // set form
      this.hierarchyDuplicationForm.controls['hierarchyDescription'].setValue(this.hierarchyIfEdit.hierarchyDescription);
    }
  }
  
}

export class HierarchyDuplicationData {
  'deadline': any = null;
  'sourceOrganisationStructureHierarchyId': number = null;
  // 'newHierarchyId': number = null;
  'newHierarchyDescription': string = null;

}

export class HierarchyEditData {
  'deadline': any = null;
  'organisationStructureHierarchyId': number = null;
  'newHierarchyDescription': string = null;

}
