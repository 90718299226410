<div>
  <h4 class="m-t-0" mat-dialog-title>{{'encryptionKey' | translate}} {{'enter' | translate}}</h4>
  <p>{{'encryptionKeyLabel' | translate}}</p>

  <div>


    <mat-progress-spinner [diameter]="25" [strokeWidth]="2"></mat-progress-spinner>
  </div>

  <form class="w-100" [formGroup]="passwordForm" novalidate>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label></mat-label>
      <input matInput placeholder="" formControlName="password" name="password" value=""
        (input)="encryptionKeyInputChange()" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="hasError('password', 'required')">{{'password' | translate}} {{'isRequired' | translate}}
      </mat-error>
      <!-- <mat-error *ngIf="hasError('password', 'minlength')">{{'minLenghth16NoteEncryptionKey' | translate}}</mat-error> -->
      <mat-error *ngIf="hasError('password', 'pattern')">{{'encryptionKeyPasswordValidation' | translate}}</mat-error>
      <mat-hint [ngClass]="{'success' : success , 'error' : !(success)}">{{message}}</mat-hint>
      <mat-hint *ngIf="keyNull"></mat-hint>
    </mat-form-field>


    <!-- </div>
<div mat-dialog-actions > -->
    <div mat-dialog-actions align="end" class="p-10 m-t-20">
      <button mat-raised-button color="basic" (click)="closeDialog()">{{'stop' | translate}}</button>
      <mat-spinner-button class="m-l-10" (click)="checkHashValue()" [options]="btnOpts"></mat-spinner-button>
    </div>
  </form>
</div>
