import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConnectedPositioningStrategy, FormattedValuesFilteringStrategy, HorizontalAlignment, IGridCellEventArgs, IGridEditEventArgs, IgxGridCell, IgxGridComponent, NoOpScrollStrategy, VerticalAlignment } from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import { constant, values } from 'lodash';
import { KalkulationService } from 'src/app/benutzer-verwaltung/data-transfer-center/services/kalkulation.service';
import { PotentialAnalyseService } from 'src/app/benutzer-verwaltung/v-musterkunde/services/potential-analyse.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { DeadlineHierarchiesService } from 'src/app/shared/services/deadline-hierarchies.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { ProfitCenter } from '../../models/profitcenter';
import { ConsultantSegmentService } from '../../service/consultant-segment.service';
import { ConsultantStructureGridService } from '../../service/consultant-structure-grid.service';
import { SalesOrganisationService } from '../../service/sales-organisation.service';
import { Consultant } from '../add-consultant/add-consultant.component';
import { DeleteConsultantDialogComponent } from '../delete-consultant-dialog/delete-consultant-dialog.component';
import { DeleteConsultantOrganizationDialogComponent } from '../delete-consultant-organization-dialog/delete-consultant-organization-dialog.component';
import { DeleteConsultantSegmentDialogComponent } from '../delete-consultant-segment-dialog/delete-consultant-segment-dialog.component';

@Component({
  selector: 'app-consultant-structure-grid',
  templateUrl: './consultant-structure-grid.component.html',
  styleUrls: ['./consultant-structure-grid.component.scss']
})
export class ConsultantStructureGridComponent implements OnInit {

  constructor(
    private consultantStructureGridService: ConsultantStructureGridService,
    private consultantSegmentService: ConsultantSegmentService,
    private router: Router,
    private salesOrganisationService: SalesOrganisationService,
    private commonUtils: CommonUtilsService,
    private matDialog: MatDialog,
    private kalkulationService: KalkulationService,
    private deadlineHierarchiesService: DeadlineHierarchiesService,
    private sessionStorageService: SessionStorageService,
    private translateService: TranslateService,
  ) { }


  @ViewChild('grid1', { read: IgxGridComponent, static: true })
    public grid1: IgxGridComponent;
    
  
  //@Output() spinnerEvent = new EventEmitter<boolean>();
  isBusy: boolean = true;
  isInEditMode: boolean = false;
  allowSave: boolean;
  beraterOrganisation: any;
  deadlines = [];
  selectedDeadline: any;
  hierarchies = [];
  selectedHierarchy: any;

  data = [];

  filterStrategy = new FormattedValuesFilteringStrategy();

  categories = [
    {id: 1, name:	'1 - Gesamtbank'},
    {id: 2, name:	'2 - Teilbank'},
    {id: 3, name:	'3 - Markt'},
    {id: 4, name:	'4 - Teilmarkt'},
    {id: 5, name:	'5 - Kompetenzzenter'},
    {id: 6, name:	'6 - Zweigstelle'},
    {id: 7, name:	'7 - Team'},
    {id: 8, name:	'8 - Berater'}
  ];

  categoriesToSelect = [
    {id: 2, name:	'2 - Teilbank'},
    {id: 3, name:	'3 - Markt'},
    {id: 4, name:	'4 - Teilmarkt'},
    {id: 5, name:	'5 - Kompetenzzenter'},
    {id: 6, name:	'6 - Zweigstelle'},
    {id: 7, name:	'7 - Team'}
  ];

    consultantSegments = [];
    profitCenters = [];

    newConsultantSegmentName = '';
    newProfitCenterModel = new ProfitCenter();

    consultantSegmentLoading: boolean = false;
    displayedColumns: string[] = ['consultantSegment', 'consultantSegmentOptions'];
    dataSource = new MatTableDataSource();

    rowOptionsDisabled: boolean = false;
    

  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
        horizontalDirection: HorizontalAlignment.Left,
        horizontalStartPoint: HorizontalAlignment.Right,
        verticalStartPoint: VerticalAlignment.Bottom
    }),
    scrollStrategy: new NoOpScrollStrategy()
  };
    

  ngOnInit(): void {
    this.getAllDeadlines();
  }

  

  // Formatting cell content
  formatParent = (value: any) => {
    let found = this.data.find(x => x.category != 8 && x.dataId == value);
    if (found) {
      return found.name;
    }
    return value; 
  }

  formatCategory = (value: number) => {
    let found = this.categories.find(x => x.id == value);
    if (found) {
      return found.name;
    }
    return value; 
  }

  formatConsultantSegment = (value: number) => {
    let found = this.consultantSegments.find(x => x.id == value);
    if (found) {
      return found.name;
    }
    return value;
  }


  // getting data
  getAllDeadlines() {
    this.kalkulationService.getAllDeadlines().subscribe((res: any) => {
      if (res.Result) {
        this.deadlines = res.Result;
        if (this.deadlines.length > 0) {
          this.deadlines.sort((one, two) => (new Date(one.deadlineDate).getTime() > new Date(two.deadlineDate).getTime() ? -1 : 1));
          this.selectedDeadline = this.deadlineHierarchiesService.getSelectedDeadline(this.deadlines);
          this.getHierarchiesByDeadline(this.selectedDeadline);
        } else {
          this.sendMessage();
        }
      }
    });
  }

  getHierarchiesByDeadline(deadline) {
    this.kalkulationService.getHierarchiesByDeadline(deadline.deadlineDate).subscribe((res: any) => {
      if (res.Result) {
        this.hierarchies = res.Result;
        if (this.hierarchies.length > 0) {
          this.selectedHierarchy = this.deadlineHierarchiesService.getSelectedHierarchy(this.hierarchies);
          this.getProfitCenterByHierarchyId(this.selectedHierarchy.id);
        } else {
          this.clearData();
          this.sendMessage();
        }
      }
    });
  }

  getConsultantSegmentsByHierarchyId(orgaStructHierarchyId) {
    this.consultantSegmentLoading = true;
    this.consultantSegments = [];

    this.consultantSegmentService.getAllConsultantSegmentsByHierarchyId(orgaStructHierarchyId).subscribe((response: any) => {
      response.Result.forEach(x => {
        this.consultantSegments.push({id: x.id, name: x.name, editValue: x.name, isEdit: false});
      });

      this.dataSource = new MatTableDataSource(this.consultantSegments);
      
    }, (error) => {

    }, () => {
      this.consultantSegmentLoading = false;
    });
    
  }

  clearData() {
    this.isInEditMode = false;
    this.rowOptionsDisabled = false;
    this.data = [];
    this.consultantSegments = [];
    this.profitCenters = [];
  }

  getProfitCenterByHierarchyId(orgaStructHierarchyId) {
    this.consultantStructureGridService.getGetConsultantStructureDataByHierarchyId(orgaStructHierarchyId).subscribe((response: any) => {
      this.beraterOrganisation = response.Result;
      
      this.clearData();

      this.beraterOrganisation.consultantSegments.forEach(x => {
        this.consultantSegments.push({id: x.id, name: x.name, editValue: x.name, isEdit: false});
      });

      this.dataSource = new MatTableDataSource(this.consultantSegments);

      let internalId = 0;

      this.beraterOrganisation.profitCenters.forEach(element => {
        let item: OrganizationItem = {
          id: internalId,
          dataId: element.id,
          category: element.profitCenterConsultantStructureId,
          capacity: null,
          name: element.description,
          consultantClientId: '',
          parentProfitCenterId: element.parentProfitCenterId,
          consultantsCount: element.consultants.length,
          consultantSegment: null
        } 
        this.data.push(item);
        this.profitCenters.push(item);
        internalId++;
      });

      this.beraterOrganisation.consultants.forEach(element => {
        let item: OrganizationItem = {
          id: internalId,
          dataId: element.id,
          category: 8,
          capacity: element.capacity,
          name: element.name,
          consultantClientId: element.consultantClientId,
          parentProfitCenterId: element.profitCenterId,
          consultantsCount: null,
          consultantSegment: element.consultantSegment?.id
        } 
        this.data.push(item);
        internalId++;
      });

      
      this.sendMessage();
      
     
    },
    (error: any) => {
      console.error(error);
      this.data = [];
      this.consultantSegments = [];
      this.sendMessage();
    });
  }

  sendMessage() {
    this.isBusy = false;
    //this.spinnerEvent.emit(false)
  }

  
  // #region [rgba(255,0,0,0.05)] -- Deadline and hierarchy control
  onDeadlineSelectionChanged(deadline) {
    this.clearData();
    this.isBusy = true;
    //this.spinnerEvent.emit(true);
    this.beraterOrganisation = [];
    this.hierarchies = [];
    this.deadlineHierarchiesService.setSelectedDeadline(this.selectedDeadline);
    this.getHierarchiesByDeadline(deadline);
  }

  onHierarchySelectionChanged(hierarchy) {
    this.clearData();
    this.isBusy = true;
    //this.spinnerEvent.emit(true);
    this.beraterOrganisation = [];
    this.deadlineHierarchiesService.setSelectedHierarchy(hierarchy);
    this.getProfitCenterByHierarchyId(hierarchy.id);
  }

  duplicateOrgaStruct() {
    this.router.navigateByUrl(`bv-home/duplicate-consultant-organization/${this.selectedDeadline.deadlineDate};orgaStructId=${this.selectedHierarchy.id}`);
  }

  editHierarchyName(id) {
    this.router.navigateByUrl(`bv-home/edit-hierarchy/${id}`);
  }

  deleteOrgaStruct(data) {
    data.name = data.description;
    const dialogRef = this.matDialog.open(DeleteConsultantOrganizationDialogComponent, {
      width: '350px',
      data: {
        object: data
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.salesOrganisationService.deleteOrganizationStructureHierarchy(data.id).subscribe((res => {
          this.beraterOrganisation = [];
          this.hierarchies = [];
          this.sessionStorageService.remove(this.deadlineHierarchiesService.selectedHierarchyKey);
          this.sessionStorageService.remove(this.deadlineHierarchiesService.selectedDeadlineKey);
          this.getAllDeadlines();
        }),err => {
          this.commonUtils.errorSnackBar('error', 'Ein Fehler ist beim Löschen der Hierachie aufgetreten.');
        });
      }
    });
  }
 // #endregion


 // #region [rgba(0,255,0,0.05)] -- Igx-Grid events
  onCellEditEnter(e: IGridEditEventArgs) {
    //console.log('onCellEditEnter', e);

    // prevent changing the parent of root-profitcenter which must be null
    if (e.column?.field === "parentProfitCenterId") {
      if (e.rowData.category === 1) {
        e.cancel = true;
        return;
      }
    }
    // prevent changing the category for root-profitcenter and consultants
    if (e.column?.field === "category") {
      if (e.rowData.category === 1 || e.rowData.category === 8) {
        e.cancel = true;
        return;
      }
    }

    // prevent changing the capacity for non-consultants
    if (e.column?.field === "capacity") {
      if (e.rowData.category !== 8) {
        e.cancel = true;
        return;
      }
    }

    // prevent changing the consultant-segment for non-consultants
    if (e.column?.field === 'consultantSegment') {
      if (e.rowData.category !== 8) {
        e.cancel = true;
        return;
      }
    }
  }

  onCellEdit(e: IGridEditEventArgs) {
    //console.log('onCellEdit', e);

    if (e.column?.field === 'capacity') {
      if (e.newValue) {
        if (e.newValue < 0 || e.newValue > 1) {
          this.commonUtils.errorSnackBar('error', 'Der Wert für Vertriebskapazität muss zwischen 0 und 1 liegen.', 3000);
          e.cancel = true;
          return;
        }
      }
    }
    
    if (e.column?.field === 'name') {
      if (e.oldValue && !e.newValue) {
        this.commonUtils.errorSnackBar('error', 'Es muss ein Name eingegeben werden.', 3000);
        e.cancel = true;
        return;
      }
    }
  }

  onRowEdit(e: any) {

    // prevent saving (submitting changes) when row changed (default behaviour)
    if (e.event.target.nodeName === 'IGX-GRID-CELL') {
      e.cancel = true;
      Promise.resolve().then(() => {
        //this.grid1.endEdit(false);
        e.owner.endEdit(false);
      });
    }

    if (e.cancel == true) {
      return;
    }

    // if (!this.allowSave) {
    //   e.cancel = true;
    //   e.owner.endEdit(false);
    //   return;
    // }

    // if (!this.validationService.isValid([e.newValue])) {
    //   e.cancel = true;
    //   return;
    // }

    //this.allowSave = false;

    if (e.newValue.category == 8) {
      this.editConsultant(e.newValue);
    } else {
      this.editProfitCenter(e.newValue);
    }
  }

  onRowEditEnter(e: any) {
    this.isInEditMode = true;
  }

  onRowEditExit(e: any) {
    this.isInEditMode = false;
  }

  // #endregion

  navigateToAddConsultantSegment() {
    this.router.navigate(['bv-home/add-consultant-segments',this.selectedHierarchy.id, this.selectedDeadline.deadlineDate]);
  }

  canAddProfitCenter(cell) {
    if (cell && cell.row && cell.row.data) {
      if (cell.row.data.category !== 8) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canDeleteProfitCenter(cell) {
    if (cell && cell.row && cell.row.data) {
      if (cell.row.data.category !== 1 && cell.row.data.category !== 8 && !this.hasProfitCenterChildren(cell.row.data.dataId)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  canDeleteConsultantSegment(consultantSegmentId) {
    if (!this.isConsultantSegmentAssigned(consultantSegmentId)) {
      return true;
    } else {
      return false;
    }
  }

  hasProfitCenterChildren(profitcenterId) {
    return this.data.some(x => x.parentProfitCenterId === profitcenterId);
  }

  getChildrenCount(cell) {
    if (cell && cell.row && cell.row.data) {
      return this.data.filter(x => x.parentProfitCenterId === cell.row.data.dataId).length;
    }
  }

  isConsultantSegmentAssigned(consultantSegmentId) {
    return this.data.some(x => x.consultantSegment === consultantSegmentId);
  }


  refreshData() {
    if (this.selectedHierarchy && this.selectedHierarchy.id > 0) {
      this.getProfitCenterByHierarchyId(this.selectedHierarchy.id);
    } else {
      this.getAllDeadlines();
    }
  }

  

  editProfitCenter(rowData: OrganizationItem) {
    let profitCenterToUpdateData = new ProfitCenter();
    profitCenterToUpdateData.description = rowData.name;
    profitCenterToUpdateData.parentProfitCenterId = rowData.parentProfitCenterId;
    profitCenterToUpdateData.profitCenterConsultantStructureId = rowData.category;
    
    this.salesOrganisationService.editProfitCenter(rowData.dataId, profitCenterToUpdateData).subscribe(res => {
        this.commonUtils.successSnackBar('Änderungen erfolgreich gespeichert.');
        this.refreshData();
      }, err => {
        console.error(err);
        this.commonUtils.errorSnackBar('error', 'Fehler beim Speichern', 3000);
        this.refreshData();
      });
  }

  editConsultant(rowData: OrganizationItem) {
    let consultToUpdateData = new Consultant();
    consultToUpdateData.deadline = this.selectedDeadline.deadlineDate; // TODO: remove
    consultToUpdateData.name = rowData.name;
    consultToUpdateData.consultantClientId = rowData.consultantClientId;
    consultToUpdateData.capacity = rowData.capacity;
    consultToUpdateData.consultantSegmentId = rowData.consultantSegment;
    consultToUpdateData.profitCenterId = rowData.parentProfitCenterId;
    
    this.salesOrganisationService.editConsultant(rowData.dataId, consultToUpdateData).subscribe(res => {
      this.commonUtils.successSnackBar('Änderungen erfolgreich gespeichert.');
    }, err => {
      console.error(err);
      this.commonUtils.errorSnackBar('error', 'Fehler beim Speichern.',  3000);
      this.refreshData();
    });
  }

  deleteProfitCenterClick(cell: IgxGridCell ) {

    if (!cell.row.index || !cell.row.data.dataId) {
      this.commonUtils.errorSnackBar('error', 'Fehler beim Löschen.', 3000);
      return;
    }

    let data = {name: cell.row.data.name};
    const dialogRef = this.matDialog.open(DeleteConsultantDialogComponent, {
      width: '420px',
      data: {
        object: data
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.salesOrganisationService.deleteProfitcenter(cell.row.data.dataId, false).subscribe(res => {
          this.grid1.deleteRow(cell.row.index);
          this.commonUtils.successSnackBar('Profitcenter erfolgreich gelöscht.');
        }, err => {
          console.error(err);
          this.commonUtils.errorSnackBar('error', 'Fehler beim Löschen.', 3000);
          this.refreshData();
        });
      }
    });

    
  }

  addProfitCenterClick(cell: IgxGridCell ) {
    if (this.isInEditMode) {
      return;
    }
    if (!cell.row.data.dataId) {
      this.commonUtils.errorSnackBar('error', 'Fehler beim Löschen.', 3000);
      return;
    }

    this.router.navigateByUrl(`bv-home/add-profitcenter/false/${cell.row.data.dataId};orgaStructId=${this.selectedHierarchy.id};deadline=${this.selectedDeadline.deadlineDate}`);
  }

  addConsultantSegmentClick(event) {
    //console.log('add clicked', event);

    // prevent saving when segment-name is already used
    if (this.consultantSegments.some(x => x.name === event)) {
      this.commonUtils.errorSnackBar('error', 'Ein Beratersegment mit dieser Bezeichnung exisitiert bereits.');
      return;
    }

    this.consultantSegmentLoading = true;
    let consultantSegmentObject = {name: event, deadline: this.selectedDeadline.deadlineDate, organisationStructureHierarchyId: this.selectedHierarchy.id };
    this.consultantSegmentService.saveConsultantSegment(consultantSegmentObject).subscribe(res => {
      this.commonUtils.successSnackBar('Neues Beratersegment erfolgreich hinzugefügt.');
      this.newConsultantSegmentName = '';
      this.getConsultantSegmentsByHierarchyId(this.selectedHierarchy.id);
    }, err => {
      this.commonUtils.errorSnackBar('error', err.error.ResponseException.ExceptionMessage, 3000);
      this.consultantSegmentLoading = false;
    });
  }

  editConsultantSegmentClick(event) {
    this.consultantSegments.forEach(x => {
      if (x.id != event) {
        x.isEdit = false
      }
    });
    let found = this.consultantSegments.find(x => x.id == event)
    if (found) {
      found.isEdit = !found.isEdit;
    }
  }

  deleteConsultantSegmentClick(consultantSegment) {
    //console.log('delete clicked', event);
    
    this.consultantSegmentService.getCanDeleteConsultantSegment(consultantSegment.id).subscribe((response: any) => {
      if (response.Result == "false") {
        this.commonUtils.errorSnackBar('error', this.translateService.instant('consultantSegmentNoDeleteHasAssignedConsultants'));
      } else {
        const dialogRef = this.matDialog.open(DeleteConsultantSegmentDialogComponent, {
          width: '400px',
          data: { name: consultantSegment.name }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.consultantSegmentService.deleteConsultantSegment(consultantSegment.id).subscribe((response: any) => {
              const temp = this.dataSource.filteredData.filter((data: any) => data.id != consultantSegment.id);
              this.dataSource = new MatTableDataSource(temp);
              this.commonUtils.successSnackBar(this.translateService.instant('consultantSegmentDeleted'));
            }, (err) => {
              this.commonUtils.errorSnackBar('error', err.message);
            });
          }
        });
      }
    }, (err) => {
      this.commonUtils.errorSnackBar('error', err.message);
    });
  }

  saveEditedConsultantSegmentClick(event) {
    //console.log('save clicked', event);

    // leave edit mode if no changes were made 
    if (event.name === event.editValue) {
      let found = this.consultantSegments.find(x => x.id == event.id)
      if (found) {
        found.isEdit = !found.isEdit;
      } 
      return;
    }

    // prevent saving when segment-name is already used
    if (this.consultantSegments.some(x => x.id != event.id && x.name === event.editValue)) {
      this.commonUtils.errorSnackBar('error', 'Ein Beratersegment mit dieser Bezeichnung exisitiert bereits.');
      return;
    }

    // save new consultant-segment
    this.consultantSegmentLoading = true;
    let consultantSegmentObject = {name: event.editValue };
    this.consultantSegmentService.updateConsultantSegment(event.id, consultantSegmentObject).subscribe(res => {
      
      this.commonUtils.successSnackBar('Beratersegment erfolgreich aktualisiert.');
      this.getConsultantSegmentsByHierarchyId(this.selectedHierarchy.id);
    }, error => {
      console.error(error);
      this.commonUtils.errorSnackBar('error', 'Fehler beim hinzufügen des Beratersegments.');
      this.consultantSegmentLoading = false;
    });

  }

  handleOpening(event, cell: IgxGridCell) {
    this.newProfitCenterModel.description = '';
    this.newProfitCenterModel.parentProfitCenterId = cell.row.data.dataId;
    this.newProfitCenterModel.organisationStructureHierarchyId = this.selectedHierarchy.id;
    this.newProfitCenterModel.deadline = this.selectedDeadline.deadlineDate;

    if (cell.row.data.category < 7) {
      this.newProfitCenterModel.profitCenterConsultantStructureId = cell.row.data.category + 1;
    } else {
      this.newProfitCenterModel.profitCenterConsultantStructureId = cell.row.data.category;
    }
  }

  handleOpened(event, cell: IgxGridCell) {
    // console.log('event',event);
    // console.log('cell',cell);

    this.rowOptionsDisabled = true;
  }

  handleClosed() {
    this.rowOptionsDisabled = false;
  }

  addProfitCenter(profitCenterModel) {
    this.salesOrganisationService.addProfitCenters(profitCenterModel).subscribe(res => {
      this.clearData();
      this.commonUtils.successSnackBar('Profitcenter erfolgreich hinzugefügt.');
      this.getProfitCenterByHierarchyId(this.selectedHierarchy.id);
    }, error => {
      console.error(error);
      this.commonUtils.errorSnackBar('error', 'Fehler beim hinzufügen des Profitcenters.');
    });
  }

}

export class OrganizationItem {
  id: number;
  dataId: number;
  consultantClientId: string;
  name: string;
  capacity: number;
  parentProfitCenterId: number;
  consultantsCount: number;
  consultantSegment: number;
  category: number;
  
}
 
