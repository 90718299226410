<div>
  <h4>Daten exportieren</h4>
  <div>

    <!-- encryption key -->
    <div class="field">
      <mat-label>{{'encryptionCodeForDepseudonymizingData' | translate}}</mat-label>
      <mat-form-field class="full-width" appearance="outline">
        <input matInput placeholder="" [(ngModel)]="encryptionKey" name="password" value="" (focusout)="validateKey()"
          [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </div>
    <div class="error" *ngIf="validKey"><span>{{'exportKeyValidation' | translate}} <i
          class="material-icons">clear</i></span>
    </div>
    <div class="error" *ngIf="apiKeyNotValid">{{'decryptionPasswordIsIncorrect' | translate}} <i
      class="material-icons">clear</i></div>
    <hr>


    <!-- password -->
    <div class="field">
      <mat-label>{{'passwordForEncryptingZipArchiveWithDpseudonymisedData' | translate}}</mat-label>
      <mat-form-field class="full-width" appearance="outline">
        <input matInput placeholder="" [(ngModel)]="password" name="password" value="" (focusout)="validatePassword()"
          [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <div>
        <div class="error" *ngIf="lengthErr"><span>Mindestlänge 6 Zeichen <i class="material-icons">clear</i></span>
        </div>
        <div class="error" *ngIf="noIntError">Mindestlänge 1 Ziffer<i class="material-icons">clear</i></div>
        <div class="error" *ngIf="noCapsError">Großbuchstaben <i class="material-icons">clear</i></div>
        <div class="success" *ngIf="pwdValidate">kleinbuchstaben <i class="material-icons">check</i></div>

      </div>
    </div>

    <!-- confirm password -->

    <div class="field">
      <mat-label>{{'repeatPassword' | translate}}</mat-label>
      <mat-form-field class="full-width" appearance="outline">
        <input matInput placeholder="" [(ngModel)]="confirmPassword" name="confirmPassword" value=""
          (input)="comparePassword()" [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      <div class="error" *ngIf="mismatchPwdErr">Eingaben identisch <i class="material-icons">clear</i></div>
    </div>
  </div>


  <div mat-dialog-actions align="end" class="p-10 m-t-20">
    <button mat-raised-button color="basic" class="m-r-10" (click)="closeDialog()">ABBRECHEN</button>
    <button mat-raised-button color="primary" (click)="export()" [disabled]="disableExportButton()">EXPORTIEREN</button>
  </div>
</div>