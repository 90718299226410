export class ProfitCenter {
    'organisationUnitId': any = 0;
    'description': any = '';
    'shortDescription': any;
    'parentProfitCenterId': any;
    'profitCenterConsultantStructureId': any;
    'profitCenterRegionStructureId': any;
    'deadline': any;
    'organisationStructureHierarchyId': number;
}
