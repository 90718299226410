import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CommonUtilsService} from '../../shared/services/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class MandantenService {
  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService) { }

  api = 'https://dev-pz-authmanagement-api.emnicon.de/api/v1.0/Customer';

  createHeaderObject() {
    const headerObj = this.commonUtils.getHeaderObject();
    return headerObj;
  }


  getAllCustomers() {
    const url = this.commonUtils.getAPIUrl('Customer');
    return this.http.get(url, this.createHeaderObject());
  }

  getCustomerById(customerId) {
    const url = this.commonUtils.getAPIUrl('Customer/GetCustomerByCustomerId');
    const headerObject = this.commonUtils.getHeaderByCustomerID(customerId);
    return this.http.get(url, headerObject);
  }

  updateMandaten(customerId, data, id) {
    const url = this.commonUtils.getAPIUrl(`Customer/UpdateCustomer/${id}`);
    const headerObject = this.commonUtils.getHeaderByCustomerID(customerId);
    return this.http.post(url, data, headerObject);
  }
  deleteMandaten() {
    // const url =
  }

  getAdministratons() {
    const url = this.commonUtils.getAPIUrl(`Invitation`);
    const headerObject = this.commonUtils.getHeaderObject();
    return this.http.get(url, headerObject);
  }
}
