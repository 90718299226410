import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { AddTailbedarfComponent } from '../add-tailbedarf/add-tailbedarf.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ModelCustomerGroupService } from '../../../parameter/service/model-customer-group.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Component({
  selector: 'app-musterkundengruppen-list',
  templateUrl: './musterkundengruppen-list.component.html',
  styleUrls: ['./musterkundengruppen-list.component.scss']
})
export class MusterkundengruppenListComponent implements OnInit {
  selectCustomerGroupText = true;
  customersNeeds: any[] = [];
  nationalCustomerNeeds: any[] = [];
  bankSpecificCustomerNeeds: any;
  bankSpecificCustomerGroupIsSelected = true;
  bankSpecificCustomerGroupList: any[] = [];
  nationalCustomerGroupList: any[] = [];
  selectedListItemBankSpecificCustomer;
  rightSpinFlag = true;
  clientId: any;

  // selected customerId
  customerId: any;
  spinnerFlag = true;
  constructor(public translateService: TranslateService,
    private dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    private musterkundeGruppenService: ModelCustomerGroupService,
    private commonUtilsService: CommonUtilsService,
    private userSessionService: UserSessionService) {
    this.clientId = userSessionService.getClientId().toString();

  }

  ngOnInit() {

    this.getBankSpecificCustomerGroup();
  }


  getNationalCustomerGroup() {
    this.musterkundeGruppenService.getModelCustomerGroup(0).subscribe((response: any) => {
      this.spinnerFlag = false;
      this.nationalCustomerGroupList = response.Result;
    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtilsService.errorSnackBar('error', '');
    });
  }

  getBankSpecificCustomerGroup() {
    // pass client Id
    this.musterkundeGruppenService.getModelCustomerGroup(1).subscribe((response: any) => {
      this.getNationalCustomerGroup();
      this.bankSpecificCustomerGroupList = response.Result;
    });
  }



  // nationalGroup
  selectListItemNationalCustomer(id) {
    this.musterkundeGruppenService.getModelCustomerNeedFieldPartsById(id).subscribe((response: any) => {
      this.selectCustomerGroupText = false;
      this.nationalCustomerNeeds = response.Result;
    });
    this.nationalCustomerGroupList.forEach(customer => {
      if (customer.id == id) {
        customer.select = true;
      } else {
        customer.select = false;
      }
    });
  }




  isCustomerSelected() {
    let count: any = 0;
    this.bankSpecificCustomerGroupList.forEach(customer => {
      if (customer.select) {
        count++;
      }
    });
    let returnValue = !(count > 0)
    return returnValue;
  }

  // bankspecific group
  selectListItemBankSpecificCustomer(id) {
    this.selectedListItemBankSpecificCustomer = id;
    this.selectCustomerGroupText = false;
    this.musterkundeGruppenService.getModelCustomerNeedFieldPartsById(id).subscribe((response: any) => {
      this.bankSpecificCustomerNeeds = response.Result;
    });
    this.bankSpecificCustomerGroupList.forEach(customer => {
      if (customer.id == id) {
        customer.select = true;
        this.customerId = customer;
      } else {
        customer.select = false;
      }
    });
  }



  drop(event: CdkDragDrop<string[]>, id) {
    moveItemInArray(this.bankSpecificCustomerNeeds, event.previousIndex, event.currentIndex);
    this.musterkundeGruppenService.updateRankOfCustomerNeeds(this.bankSpecificCustomerNeeds[event.currentIndex].id, event.currentIndex + 1)
      .subscribe((Response) => {
        this.selectListItemBankSpecificCustomer(this.selectedListItemBankSpecificCustomer);

      });
  }


  getLeftBorder(themeName) {
    const color = this.commonUtilsService.getThemeColorByThemeName(themeName);
    return {
      'border-left': `8px solid ${color}`
    };
  }

  existentialStatusChange(needs) {
    this.bankSpecificCustomerNeeds.forEach(need => {
      if (needs.id == need.id) {
        const existance = !needs.isExistence ? 1 : 0;
        this.musterkundeGruppenService.isExistanceCustomerNeed(needs.id, existance).subscribe((Response) => {
          needs.isExistence = !needs.isExistence;
        });
      }
    });
  }

  cashFlowStatusChange(needs) {
    this.bankSpecificCustomerNeeds.forEach(need => {
      if (needs.id == need.id) {
        const cashFlow = !needs.isCashFlowNeutral ? 1 : 0;
        this.musterkundeGruppenService.isCashFLowNeutralCustomerNeed(needs.id, cashFlow).subscribe((Response) => {
          needs.isCashFlowNeutral = !needs.isCashFlowNeutral;
        });
      }
    });
  }



  tabChanged(event) {
    this.selectCustomerGroupText = true;
    if (event.index == 0) {
      this.bankSpecificCustomerGroupIsSelected = true;
      this.nationalCustomerGroupList.forEach(customer => {
        customer.select = false;
        this.nationalCustomerNeeds = [];

      });
    } else {
      this.bankSpecificCustomerGroupIsSelected = false;
      this.bankSpecificCustomerGroupList.forEach(customer => {
        customer.select = false;
        this.bankSpecificCustomerNeeds = [];
        this.bankSpecificCustomerGroupIsSelected = false;
      });
    }
  }

  deleteNeed(need) {
    this.musterkundeGruppenService.deleteCustomerNeed(need.id).subscribe((response: any) => {
      this.selectListItemBankSpecificCustomer(this.selectedListItemBankSpecificCustomer);
      const successMessage = this.translateService.instant('customerNeedDeleted')
      this.commonUtilsService.successSnackBar(successMessage);
    }, (err) => {
      this.commonUtilsService.errorSnackBar('error', null);
    }
    );
  }

  addPartialNeeds() {
    const dialogRef = this.dialog.open(AddTailbedarfComponent, {
      width: '800px',
      height: '450px',
      data: { customerId: this.customerId.id, bankIndividualNeeds: this.bankSpecificCustomerNeeds }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.musterkundeGruppenService.getModelCustomerNeedFieldPartsById(result).subscribe((response: any) => {
          this.bankSpecificCustomerNeeds = response.Result;
        });
      }
    });
  }
}
