<div class="p-l-1vw p-t-1vw p-r-1vw " *ngIf="(assetClass.riskCategory != null) && (!spinnerFlag)">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        <div class="w-px-500">
          {{'assetClasses' | translate}} > {{'riskCategory' | translate}} {{assetClass.riskCategory.name}}
        </div>
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>

  </mat-grid-list>

  <mat-grid-list cols="12" rowHeight="450px" class="m-l-10">
    <mat-grid-tile flex-start flex-align-top colspan="10">
      <div class="card-shadow  outer-container">
        <div>
          <div>
            <p class="fw-500">{{'bankSpecificWeighting' | translate}}</p>
          </div>
          <div>
            <form class="w-100" novalidate [formGroup]="assetEditForm">
              <table class="asset-table">

                <!-- <span> -->
                <tr>
                  <td class="w-160">
                    <mat-form-field appearance="outline" class="form-field "
                      [ngClass]="{'error-outline' : isValidValue('liquiditat') }">
                      <mat-label [ngClass]="{'label-red' : isValidValue('liquiditat') }">{{'liquidity' | translate}}
                      </mat-label>
                      <input matInput placeholder="Liquidität" type="number" formControlName="liquiditat"
                        (input)="keyUpEvent()">
                    </mat-form-field>
                    <span>%</span>
                  </td>
                  <td class="w-160">
                    <mat-form-field appearance="outline" class="form-field "
                      [ngClass]="{'error-outline' : isValidValue('geldwerte') }">
                      <mat-label [ngClass]="{'label-red' : isValidValue('geldwerte') }">{{'monetaryValues' | translate}}
                      </mat-label>
                      <input matInput placeholder="Geldwerte" type="number" formControlName="geldwerte"
                        (input)="keyUpEvent()">
                    </mat-form-field>
                    <span>%</span>
                  </td>
                  <td class="w-160">
                    <mat-form-field appearance="outline" class="form-field "
                      [ngClass]="{'error-outline' : isValidValue('substanzwerte') }">
                      <mat-label [ngClass]="{'label-red' : isValidValue('substanzwerte') }">
                        {{'valueStocks' | translate}}
                      </mat-label>
                      <input matInput placeholder="Substanzwerte" type="number" formControlName="substanzwerte"
                        (input)="keyUpEvent()">
                    </mat-form-field>
                    <span>%</span>
                  </td>
                  <td class="w-160">
                    <mat-form-field appearance="outline" class="form-field "
                      [ngClass]="{'error-outline' : isValidValue('sachwerte') }">
                      <mat-label [ngClass]="{'label-red' : isValidValue('sachwerte') }">{{'nonCashAssets' | translate}}
                      </mat-label>
                      <input matInput placeholder="Sachwerte" type="number" formControlName="sachwerte"
                        (input)="keyUpEvent()">
                    </mat-form-field>
                    <span>%</span>
                  </td>
                  <td class="w-160">
                    <mat-form-field appearance="outline" class="form-field "
                      [ngClass]="{'error-outline' : isValidValue('alternativ') }">
                      <mat-label [ngClass]="{'label-red' : isValidValue('alternativ') }">{{'alternative' | translate}}
                      </mat-label>
                      <input matInput placeholder="Alternativ Anlagen" type="number" formControlName="alternativ"
                        (input)="keyUpEvent()">
                    </mat-form-field>
                    <span>%</span>
                  </td>
                  <td class="w-160">
                    <mat-form-field appearance="outline" class="form-field "
                      [ngClass]="{'error-outline' : getTotalValue()}">
                      <mat-label [ngClass]="{'label-red' : getTotalValue() }">{{'total' | translate}} </mat-label>
                      <input matInput placeholder="Gesamt" formControlName="gesamt" readonly="true">
                    </mat-form-field>
                    <span [ngClass]="{'label-red' : getTotalValue() }">%</span>
                  </td>
                </tr>

                <!-- </span> -->

              </table>
            </form>
          </div>
          <div>
            <a (click)="revertChanges()"> {{'resetToBVRProposal' | translate}}</a>
          </div>


          <div class="m-t-80">
            <p class="fw-500">{{'bvrProposalAsReference' | translate}}</p>
          </div>

          <div>
            <table>
              <tr>
                <td class="w-160">
                  <div> {{'liquidity' | translate}}
                    <div>{{assetClass.riskCategory.liquidityPercentage}} %</div>
                  </div>
                </td>
                <td class="w-160">
                  <div> {{'monetaryValues' | translate}}
                    <div>{{assetClass.riskCategory.monetaryAssetsPercentage}} %</div>
                  </div>
                </td>
                <td class="w-160">
                  <div> {{'valueStocks' | translate}}
                    <div>{{assetClass.riskCategory.substanceAssetsPercentage}} %</div>
                  </div>
                </td>
                <td class="w-160">
                  <div> {{'nonCashAssets' | translate}}
                    <div>{{assetClass.riskCategory.materialAssetsPercentage}} %</div>
                  </div>
                </td>
                <td class="w-160">
                  <div> {{'alternative' | translate}}
                    <div>{{assetClass.riskCategory.alternativeAssetsPercentage}} %</div>
                  </div>
                </td>

                <td class="w-160" class="fw-500">
                  <div> {{'total' | translate}}
                    <div> 100 %</div>
                  </div>
                </td>
            </table>
          </div>
        </div>
        <div class="m-t-80 m-b-20">
          <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" [disabled]="disableIfNoValueChanged()"
            (click)="saveAssetClasses()"> {{'save' | translate}}</button>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>



</div>
<div class="spinner" *ngIf="spinnerFlag">
  <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
</div>