<div class="p-l-1vw p-t-1vw p-r-1vw">


  <mat-grid-list cols="3" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top [colspan]="2" class="grid-spacing">
      <h1 class="page-title">
        {{'salesOrganization' | translate}} > {{'editConsultant' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top [colspan]="1" class="grid-spacing">
      
    </mat-grid-tile>
  </mat-grid-list>


  <mat-card class="m-t-50 m-b-50 p-t-30">
    <mat-card-content>
      <mat-grid-list cols="12" rowHeight="400px">
        <mat-grid-tile flex-start flex-align-top [colspan]="6">
          <form class="w-100" novalidate [formGroup]="consultantForm">
            <mat-grid-list cols="12" rowHeight="70px">
              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'consultantName' | translate}}</mat-label>
                  <input matInput placeholder="Name des Beraters" name="consultantName" formControlName="consultantName">

                  <mat-error *ngIf="hasError('consultantName', 'required')">{{'consultantName' | translate}} {{'isRequired' | translate}}</mat-error>
                  <mat-error *ngIf="hasError('consultantName', 'maxlength')">{{'maxLength100Msg' | translate}} </mat-error>
                </mat-form-field>
                <div class="field-range" *ngIf="consultantForm.value.consultantName">{{consultantForm.value.consultantName.length}}{{'100Range' | translate}}</div>
                <div class="field-range" *ngIf="!consultantForm.value.consultantName">{{'fieldLowerLimit' | translate}}{{'100Range' | translate}}</div>
              </mat-grid-tile>

              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'consultantNumber' | translate}}</mat-label>
                  <input matInput placeholder="Beraternummer" name="consultantNo" formControlName="consultantNo">
                  <mat-error *ngIf="hasError('consultantNo', 'required')">{{'consultantNumber' | translate}} {{'isRequired' | translate}}</mat-error>
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile class="text-area-spacing grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'comment' | translate}} ({{'optional' | translate}})</mat-label>
                  <textarea matInput placeholder="Beschreibung (optional)" rows="2" name="comment" formControlName="comment"></textarea>
                  <mat-error *ngIf="hasError('comment', 'maxlength')">{{'maxLength500Msg' | translate}} </mat-error>
                </mat-form-field>
                <div class="field-range" *ngIf="consultantForm.value.comment">{{consultantForm.value.comment.length}}{{'500Range' | translate}}</div>
                <div class="field-range" *ngIf="!consultantForm.value.comment">{{'fieldLowerLimit' | translate}}{{'500Range' | translate}}</div>
              </mat-grid-tile>

              <mat-grid-tile class="hint-align grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width mt-20" appearance="outline">
                  <mat-label>{{'parentNode' | translate }}</mat-label>
                  <mat-select formControlName="parentNode">
                    <mat-option *ngFor="let parent of parentNodeOptions" [value]="parent.value">{{parent.text}}</mat-option>
                  </mat-select>
                  <mat-hint>
                    {{'parentNodeNote' | translate}}
                  </mat-hint>
                </mat-form-field>
              </mat-grid-tile>
            </mat-grid-list>
          </form>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
    <mat-card-actions class="m-b-5">
      <mat-grid-list cols="12" rowHeight="45px">
        <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
          <button mat-raised-button color="basic" class="m-l-10" (click)="clearForm()">{{'stop' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" (click)="editConsultant()">{{'save' | translate}}</button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-actions>
  </mat-card>
</div>