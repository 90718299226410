<div>
  <h3 class="m-t-0" mat-dialog-title>{{'kalkulationDetailsLabel1' | translate}}
    {{data.calculation.deadlineDate  | dateFormat}} </h3>
  <div>
    <div class="calculation-list">
      <!-- type 1 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Kundenstammdaten')">
        {{'customerMasterData' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Kundenstammdaten'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- type 2 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Umsatzdaten')">
        {{'salesData' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Umsatzdaten'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- type 3 -->

      <mat-label class="fw-500" *ngIf="isInterfacePresent('Regelzahlung')">
        Regelzahlung
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Regelzahlung'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- type 4 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Bestandsdaten')">
        {{'inventoryData' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Bestandsdaten'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- type 5 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Wertpapierstammdaten')">
        {{'Wertpapierstammdaten' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Wertpapierstammdaten'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--type 6  -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Produktdaten')">
        {{'productData' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Produktdaten'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- type 7 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Beraterinformationen')">
        {{'consultantInformation' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Beraterinformationen'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- type 8 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Kundenkriterien')">
        {{'customerCriteria' | translate}}
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Kundenkriterien'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- type 9 -->
      <mat-label class="fw-500" *ngIf="isInterfacePresent('Fonds Zuordung')">
        Fonds Zuordung
      </mat-label>
      <div class="calculation-list--item" *ngFor="let file of files">
        <div *ngIf="file.uploadedFile.interfaceType  == 'Fonds Zuordung'">
          <h5 class="list-header"></h5>
          <div>
            <ul class="check-list">
              <li>
                <label>{{file.uploadedFile.fileName }} </label>
                <span class="sub-text">{{'importiert' | translate}} {{'atThe' | translate}}
                  {{file.uploadedFile.uploadTimeStamp}} {{'from' | translate}}
                  {{file.uploadedFile.userName}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div mat-dialog-actions class="m-t-20" align="end">
        <button mat-raised-button color="basic" (click)="closeDialog()">{{'hide' | translate}}</button>
      </div>
    </div>