import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-activate-deactivate-user-confirm-dialog',
  templateUrl: './activate-deactivate-user-confirm-dialog.component.html',
  styleUrls: ['./activate-deactivate-user-confirm-dialog.component.scss']
})
export class ActivateDeactivateUserConfirmDialogComponent implements OnInit {
  isUserActive;
  constructor(public dialogRef: MatDialogRef<ActivateDeactivateUserConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.isUserActive = !data.isUserActive;
  }

  ngOnInit() { }

  confirmAction(): void {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
