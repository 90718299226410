<!--main content-->
<!-- Page Heading -->
<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        {{'dataExport' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>
  <!-- End: Page Heading -->
  <!-- New Mailn content -->
  <mat-grid-list cols="12" rowHeight="700px" class="m-t-50 m-b-50">

    <mat-grid-tile flex-start flex-align-top [colspan]="10" class="grid-spacing">
      <!-- table starts -->
      <div class=" w-100 card-shadow m-b-10">

        <div class="table-container">

          <table mat-table [dataSource]="dataSource" matSort class="w-100  custom-table">


            <ng-container matColumnDef="topic">
              <th width="170" mat-header-cell *matHeaderCellDef mat-sort-header>{{'theme' | translate}} </th>
              <td mat-cell *matCellDef="let data"> {{data.topic}}

              </td>
            </ng-container>

            <ng-container matColumnDef="source">
              <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'source' | translate}} </th>
              <td mat-cell *matCellDef="let data"> {{data.source}}


              </td>
            </ng-container>

            <ng-container matColumnDef="username">
              <th width="190" mat-header-cell *matHeaderCellDef mat-sort-header> {{'analyst' | translate}}</th>
              <td mat-cell *matCellDef="let data"> {{data.username}}

              </td>
            </ng-container>

            <ng-container matColumnDef="dayTime">
              <th width="120" mat-header-cell *matHeaderCellDef mat-sort-header> {{'dateTime' | translate}} </th>
              <td mat-cell *matCellDef="let data">
                {{data.dayTime  | date : 'dd/MM/yyyy HH:mm:ss'}}
              </td>
            </ng-container>

            <ng-container matColumnDef=" ">
              <th width="150" mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let data">
                <div><i class="material-icons" *ngIf="data.state == 1" (click)="addEncryptionKey(data.id)">
                  vpn_key
                </i>
                  <i class="material-icons" *ngIf="data.state == 2">
                    hourglass_empty
                  </i>
                  <button mat-icon-button [disabled]="data.disabled"><i class="material-icons" *ngIf="data.state == 4"
                                                                        (click)="exportZip(data.id, data.topic)">
                    save_alt
                  </i></button>

                  <div class="mat-spinner-div" *ngIf="data.state == 3">
                    <mat-spinner mode="indeterminate" [diameter]="20"></mat-spinner>

                  </div>
                </div>

              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
          <mat-paginator class="m-t-5" [length]='totalLength' showFirstLastButtons [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 25, 30]">
          </mat-paginator>
        </div>

      </div>

    </mat-grid-tile>
    <mat-grid-tile flex-start flex-align-top [colspan]="2" class="grid-spacing">

    </mat-grid-tile>
  </mat-grid-list>
  <!-- End: New Main Content -->
</div>

<div class="spinner" *ngIf="spinnerFlag">
  <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
</div>
