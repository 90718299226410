<div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap
  fxLayoutGap="0.5%" fxLayoutAlign="center">
  <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">

    <div class=" w-100 card-shadow">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="FirstName">
            <th width="160" mat-header-cell *matHeaderCellDef mat-sort-header>{{'name/email' | translate}} </th>
            <td class="fw-500" mat-cell *matCellDef="let user"> {{user.LastName}}
              <span *ngIf="user.LastName && user.FirstName">, </span> {{user.FirstName}}
              <div class="p-t-5 grey-text fw-300">
                {{user.Email}}</div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="UserName">
            <th width="130" mat-header-cell *matHeaderCellDef mat-sort-header> {{'userName' | translate}} </th>
            <td mat-cell *matCellDef="let user"> {{user.UserName}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="Status">
            <th width="35" mat-header-cell *matHeaderCellDef> {{'status' | translate}} </th>
            <td mat-cell *matCellDef="let user">
              <div *ngIf="!user.IsDeleted"> {{'active' | translate}}</div>
              <div *ngIf="user.IsDeleted">{{'inactive' | translate}}</div>
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Rollen">
            <th width="110" mat-header-cell *matHeaderCellDef> {{'role' | translate}} </th>
            <td mat-cell *matCellDef="let user">
              <div class="role-div"><span *ngFor="let role of user.roles;let i = index">{{role}} </span></div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Letzte Aktivität">
            <th width="120" mat-header-cell *matHeaderCellDef> {{'lastActivity' | translate}} </th>
            <td mat-cell *matCellDef="let user"> {{user.LastLoginDate | dateFormat : 'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <ng-container matColumnDef=" ">
            <th width="80" mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let user" class="text-right">
              <button mat-icon-button (click)="navigateToEditAdministrator(user.Id)">
                <i class="fs-16 material-icons"> edit </i>
              </button>
              <button mat-icon-button (click)="deleteAdminDialog(user.Id, user.FirstName)">
                <i class="fs-16 material-icons"> delete </i>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
      <mat-paginator class="m-t-5" [length]='totalCount' [pageIndex]="pageIndex" [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 30]" (page)="paginationEvent($event)">
      </mat-paginator>
      <!-- <mat-card *ngIf="spinnerFlag" style="display: flex; justify-content: center; align-items: center">
        <mat-spinner class="custom-button" mode="indeterminate"></mat-spinner>
      </mat-card> -->

    </div>
  </div>
  <!-- right column -->

  <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="sidebar-btn">
    <div class="d-flex w-100 fd-col" class="sidebar-form" fxLayout="column" fxLayout.md="row" fxLayout.sm="row"
      fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="flex-start">
      <button mat-raised-button class="custom-button" (click)="navigateToAddAdministrator()">
        <i class="material-icons">
          add
        </i>
        {{'addUser' | translate}}</button>
    </div>
  </div>
</div>