import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/user-profile/services/user-profile.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  constructor(
    private oauthService: OAuthService,
    private router: Router) { }

  ngOnInit() {
    this.redirectToRoute();
  }
  redirectToRoute() {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      this.router.navigateByUrl('bv-home');
    }
  }
}
