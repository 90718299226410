import { Component, OnInit } from '@angular/core';
import { ConsultantNeedSolutionService } from '../../service/consultant-need-solution.service';
import { CommonUtilsService } from '../../../../shared/services/common-utils.service';
@Component({
  selector: 'app-beratung-bedarf-and-losung',
  templateUrl: './beratung-bedarf-and-losung.component.html',
  styleUrls: ['./beratung-bedarf-and-losung.component.scss']
})
export class BeratungBedarfAndLosungComponent implements OnInit {
  spinnerFlag = true;
  constructor(private consultantNeedsAndSolutionService: ConsultantNeedSolutionService,
    private commonUtilsService: CommonUtilsService) {
    this.consultantNeedsAndSolutionService.getNeedThemes().subscribe((response: any) => {
      this.spinnerFlag = false;
      this.consultantNeedSolutionList = response.Result;
      this.consultantNeedSolutionList.forEach(element => {
        element.name = element.name.trim();
        this.commonUtilsService.getThemeColor(element);
      });
      const temp = this.consultantNeedSolutionList.filter(consultant => consultant.name.includes('PK0'));
      this.consultantNeedSolutionList = temp;

    });
  }
  consultantNeedSolutionList: any[] = [];
  ngOnInit() {
  }

  getLeftBorder(themeColor) {
    return {
      'border-left': `8px solid ${themeColor}`
    };
  }
}
