import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PotentialAnalyseService } from "src/app/benutzer-verwaltung/v-musterkunde/services/potential-analyse.service";

@Component({
  selector: "app-delete-zweigstellen-dialog",
  templateUrl: "./delete-zweigstellen-dialog.component.html",
  styleUrls: ["./delete-zweigstellen-dialog.component.scss"],
})
export class DeleteZweigstellenDialogComponent implements OnInit {
  profitCenter: any;
  hasChildren: boolean;
  constructor(
    public dialogRef: MatDialogRef<DeleteZweigstellenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.profitCenter = this.data.profitCenter;
    this.hasChildren = this.profitCenter.childProfitCenter.length > 0;
  }

  closeDialog(flag: boolean) {
    this.dialogRef.close(flag);
  }
}
