import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/user-profile/services/user-profile.service';
import { LocalStorageService } from 'src/app/shared/services/localStorage.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
  userDetails: any;
  constructor(private router: Router,
    private oauthService: OAuthService, private userProfileService: UserProfileService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.userDetails = this.userProfileService.getUserProfileDetails();
  }
  logout() {
    this.oauthService.logOut();
    this.localStorageService.set('currentRoute', null);
    this.router.navigateByUrl(``);
  }
}
