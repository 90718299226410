import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataExportService } from '../../services/data-export.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataexportEncryptionModalComponent } from '../dataexport-encryption-modal/dataexport-encryption-modal.component';
import { interval } from 'rxjs';

@Component({
  selector: 'app-data-export-list',
  templateUrl: './data-export-list.component.html',
  styleUrls: ['./data-export-list.component.scss'],
})
export class DataExportListComponent implements OnInit, OnDestroy {
  dataExportList: any;
  displayedColumns: string[] = ['topic', 'source', 'username', 'dayTime', ' '];
  dataSource;
  spinnerFlag = true;
  totalLength;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  intervalEvent: any;

  constructor(private dataExportService: DataExportService,
              public dialog: MatDialog) {
    this.getDataExportList();
    this.intervalEvent = interval(10000).subscribe(x => {
      this.getDataExportList();
    });
  }

  ngOnInit() {
  }

  getDataExportList() {
    this.dataExportService.getAllExportEntries().subscribe((res: any) => {
      this.dataExportList = res.Result.map(entry => ({
        ...entry,
        dayTime: this.parseDate(entry.dayTime),
      }));
      this.spinnerFlag = false;
      this.totalLength = this.dataExportList.length;
      this.dataSource = new MatTableDataSource(this.dataExportList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  parseDate(date: string) {
    let dateToFormat = date;

    // add timezone information if it is missing in string, otherwise date will assume that we need to use our current timezone
    if (!dateToFormat.includes('+')) {
      dateToFormat = dateToFormat + '+00:00';
    }

    return new Date(dateToFormat);
  }

  addEncryptionKey(id) {
    const dialogRef = this.dialog.open(DataexportEncryptionModalComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataExportService.startDataExport(null, id, result.key, result.password).subscribe((res) => {
          this.getDataExportList();
        });
      }
    });
  }

  exportZip(id, name) {
    this.dataSource.filteredData.forEach(data => {
      if (data.id == id) {
        data.disabled = true;
      }
    });
    this.spinnerFlag = true;

    this.dataExportService.getExportZip(id).subscribe((res: Blob) => {
      this.downloadDocument(res, name);
      this.spinnerFlag = false;
      this.dataSource.filteredData.forEach(data => {
        if (data.id == id) {
          data.disabled = false;
        }
      });

    });
  }

  downloadDocument(blobContent, name) {
    const blob = new Blob([blobContent], {type: 'application/zip'});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.target = '_self';
    link.download = name + '.zip';
    link.click();
    document.body.removeChild(link);
  }


  ngOnDestroy() {
    if (this.intervalEvent != undefined) {
      this.intervalEvent.unsubscribe();
    }
  }
}



