import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
@Injectable({
  providedIn: 'root'
})
export class ConsultantNeedSolutionService {
  private endpoint;
  private environment;
  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService, private configurationservice: ConfigurationService) {
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/NeedThemes`;
  }
  getNeedThemes() {
    let url = this.endpoint;
    return this.http.get(url);
  }
}
