import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-potential-filters-dialog',
  templateUrl: './confirm-potential-filters-dialog.component.html',
  styleUrls: ['./confirm-potential-filters-dialog.component.scss']
})
export class ConfirmPotentialFiltersDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmPotentialFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }
  deleteFilters() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);

  }
}
