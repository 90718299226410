export const SALES_ORGANIZATION = [
    {
        id: 1,
        bankName: 'Vereinigte Volksbanken',
        bankcode: 'BLZ 123 456 00',
        comment: 'Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua',
        region: [
            {
                id: 1,
                regionName: 'Kreis Böblingen',
                number: null,
                comment: '',
                office: [
                    {
                        id: 1,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 34',
                        comment: ''
                    },
                    {
                        id: 2,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 35',
                        comment: 'Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua'
                    }
                ]
            },
            {
                id: 2,
                regionName: 'Kreis Calw',
                number: null,
                comment: '',
                office: [
                    {
                        id: 1,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 34',
                        comment: ''

                    },
                    {
                        id: 2,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 35',
                        comment: ''

                    }
                ]
            }
        ],
        partMarket: [
            {
                id: 1,
                partMarketName: 'Privatkunden',
                telemarketNo: null,
                comment: '',
                competenceCenter: [
                    {
                        id: 1,
                        competenceName: 'Immobilien',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        },
                        {
                            id: 2,
                            teamName: 'B-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''
                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    },
                    // 2nd row competence
                    {
                        id: 2,
                        competenceName: 'Investmentbanking',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    }
                ]
            },

            // 2nd row partMarket
            {
                id: 2,
                partMarketName: 'Privatkunden',
                telemarketNo: null,
                comment: '',
                competenceCenter: [
                    {
                        id: 1,
                        competenceName: 'Immobilien',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''
                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''
                                }
                            ]
                        }
                        ]
                    },
                    // 2nd row competence
                    {
                        id: 2,
                        competenceName: 'Investmentbanking',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    }
                ]
            },




        ]
    },

    // 2nd row
    {
        id: 2,
        bankName: 'Vereinigte Onlinebanken',
        bankcode: 'BLZ 123 456 00',
        comment: '',
        region: [
            {
                id: 1,
                regionName: 'Kreis Böblingen',
                number: null,
                comment: '',
                office: [
                    {
                        id: 1,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 34',
                        comment: ''
                    },
                    {
                        id: 2,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 35',
                        comment: ''

                    }
                ]
            },
            {
                id: 2,
                regionName: 'Kreis Calw',
                number: null,
                comment: '',
                office: [
                    {
                        id: 1,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 34',
                        comment: ''
                    },
                    {
                        id: 2,
                        officeName: 'Zweigstelle Musterstadt',
                        officeNumber: 'Zweigstellennummer 35',
                        comment: ''
                    }
                ]
            }
        ],
        partMarket: [
            {
                id: 1,
                partMarketName: 'Privatkunden',
                telemarketNo: null,
                comment: '',
                competenceCenter: [
                    {
                        id: 1,
                        competenceName: 'Immobilien',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    },
                    // 2nd row competence
                    {
                        id: 2,
                        competenceName: 'Investmentbanking',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    }
                ]
            },

            // 2nd row partMarket
            {
                id: 2,
                partMarketName: 'Privatkunden',
                telemarketNo: null,
                comment: '',
                competenceCenter: [
                    {
                        id: 1,
                        competenceName: 'Immobilien',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    },
                    // 2nd row competence
                    {
                        id: 2,
                        competenceName: 'Investmentbanking',
                        competenceNo: null,
                        comment: '',
                        team: [{
                            id: 1,
                            teamName: 'A-Team',
                            teamNo: null,
                            comment: '',
                            consultant: [
                                {
                                    id: 1,
                                    consultantName: 'Max Muster',
                                    consultantNumber: 'q1234567',
                                    comment: ''

                                },
                                {
                                    id: 2,
                                    consultantName: 'John Doe',
                                    consultantNumber: 'q1234568',
                                    comment: ''

                                }
                            ]
                        }
                        ]
                    }
                ]
            },
        ]
    }
];
