import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-data-confirm-cancel-import-dialog',
  templateUrl: './data-confirm-cancel-import-dialog.component.html',
  styleUrls: ['./data-confirm-cancel-import-dialog.component.scss'],
})
export class DataConfirmCancelImportDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DataConfirmCancelImportDialogComponent>,
  ) {
  }

  abort() {
    this.dialogRef.close(false);
  }

  continue() {
    this.dialogRef.close(true);
  }
}
