import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import * as JSZip from 'jszip';
import {
  saveAs
} from 'file-saver';

import {
  PotentialanalysisVermogenReportComponent
} from '../potentialanalysis-vermogen-report/potentialanalysis-vermogen-report.component';
import {
  PotentialAnalyseService
} from '../../services/potential-analyse.service';
import {
  UserProfileService
} from 'src/app/user-profile/services/user-profile.service';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { interval } from 'rxjs';
import { CONSULTANT_IDS, DEPARTMENT_NUMBERS, KUNDERGUST, SELECTED_ADDITIONAL_FILTER } from 'src/app/app.config';
import { PotentialanalysisKundengerustListComponent } from '../potentialanalysis-kundengerust-list/potentialanalysis-kundengerust-list.component';


@Component({
  selector: 'app-pdf-generation-pop-up',
  templateUrl: './pdf-generation-pop-up.component.html',
  styleUrls: ['./pdf-generation-pop-up.component.scss']
})
export class PdfGenerationPopUpComponent implements OnInit {

  @ViewChild(PotentialanalysisVermogenReportComponent, { static: false }) chartComponent: PotentialanalysisVermogenReportComponent;
  @ViewChild(PotentialanalysisKundengerustListComponent, {static: false}) kundengerustComponent: PotentialanalysisKundengerustListComponent;

  HTML_Width: any;
  HTML_Height: any;
  top_left_margin = 15;
  PDF_Width: any;
  PDF_Height: any;
  canvas_image_width: any;
  canvas_image_height: any;
  framework1: any;
  framework2: any;
  framework3: any;
  framework4: any;
  framework5: any;
  framework6: any;
  showKundengruppeTable: any;
  showVermogenTable: any;
  dataSource: any;
  kalkulationHeader: any;
  userDetails: any;
  spinnerFlag: any;
  pdfFormat: any = 'simplePDF';
  processPdfFlag: any;
  hideSelectionContent: boolean;
  pdf: any;
  customerNeedDetail: any;
  page1 = true;
  page4 = true;
  page5 = true;
  page6 = true;
  page7 = true;
  page8 = true;
  page9 = true;
  page10 = true;
  page16 = true;
  showOptions = false;
  responseKundengerustSuccess = false;
  selectedCalculationId: any;
  showTables = false;
  disabled = true;
  apiResultMap = [false, false, false, false, false, false];
  intervalEvent1: any;
  intervalEvent2: any;
  intervalEvent3: any;
  intervalEvent4: any;
  intervalEvent5: any;
  intervalEvent6: any;
  potentialAnalysis: any;
  selectedAdditionalFilters = SELECTED_ADDITIONAL_FILTER;
  constructor(public dialogRef: MatDialogRef<PdfGenerationPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonUtils: CommonUtilsService,
    private potentialAnalyseService: PotentialAnalyseService,
    private userProfileService: UserProfileService
  ) { this.getKundengerustLists(); }
  

  
  public startPDFConsultantProcessEvent: EventEmitter<any> = new EventEmitter();
  orgConsultantIds: any[];
  currentReport: number = 0;
  totalReports: number = 0;
  readyConsultantPdfs: any[] = [];
  downloadedConsultantPdfs: number = 0;
  html2canvasLogging: boolean = false;
  loadedConsultant = false;
  loadedFramework1 = false;
  loadedFramework2 = false;
  loadedFramework3 = false;
  loadedFramework4 = false;
  loadedFramework5 = false;
  loadedFramework6 = false;
  loadedVermogen = false;
  multiPdfProcessingRunning = false;
  processingDownload = false;

  currentProcessedConsultant: any;
   
  startPDFConsultantProcess() {
    this.spinnerFlag = true;
    this.hideSelectionContent = true;
    this.multiPdfProcessingRunning = true;
    this.startPDFConsultantProcessEvent.emit(this.orgConsultantIds);
  }

  ngOnInit() {
    if (CONSULTANT_IDS.ids && CONSULTANT_IDS.ids.length > 0) {
      this.totalReports = CONSULTANT_IDS.ids.length;
    }

    // Copy the original consultants ids
    this.orgConsultantIds = [...CONSULTANT_IDS.ids];

    this.selectedCalculationId = this.data.selectedCalculationId;
    this.userDetails = this.userProfileService.getUserProfileDetails();
    this.kalkulationHeader = `(Kalkulation per ${this.data.kalkulation.text.split(' ')[0]} / Kalkulation ${this.data.kalkulation.text.split(' ')[1][0]} von ${this.data.kalkulation.text.split(' ')[1][2]})`;
  }

  async processSinglePDF(info: any, newData: any) {
    return new Promise(async (resolve, reject) => {

      this.data = undefined;
      this.loadedConsultant = false;
      this.loadedFramework1 = false;
      this.loadedFramework2 = false;
      this.loadedFramework3 = false;
      this.loadedFramework4 = false;
      this.loadedFramework5 = false;
      this.loadedFramework6 = false;
      this.loadedVermogen = false;

      this.HTML_Width = undefined;
      this.HTML_Height = undefined;
      this.top_left_margin = 15;
      this.PDF_Width = undefined;
      this.PDF_Height = undefined;
      this.canvas_image_width = undefined;
      this.canvas_image_height = undefined;
      this.framework1 = undefined;
      this.framework2 = undefined;
      this.framework3 = undefined;
      this.framework4 = undefined;
      this.framework5 = undefined;
      this.framework6 = undefined;
      this.showKundengruppeTable = undefined;
      this.showVermogenTable = undefined;
      this.dataSource = undefined;
      this.kalkulationHeader = undefined;
      this.userDetails = undefined;


      //this.spinnerFlag = undefined;
      // this.pdfFormat = 'simplePDF';
      // this.processPdfFlag = undefined;
      // this.hideSelectionContent = undefined;


      this.pdf = undefined;
      this.customerNeedDetail = undefined;


      // this.page1 = true;
      // this.page4 = true;
      // this.page5 = true;
      // this.page6 = true;
      // this.page7 = true;
      // this.page8 = true;
      // this.page9 = true;
      // this.page10 = true;
      // this.page16 = true;


      this.showOptions = false;
      this.responseKundengerustSuccess = false;
      this.selectedCalculationId = undefined;
      this.showTables = false;
      this.disabled = true;
      this.apiResultMap = [false, false, false, false, false, false];
      this.intervalEvent1 = undefined;
      this.intervalEvent2 = undefined;
      this.intervalEvent3 = undefined;
      this.intervalEvent4 = undefined;
      this.intervalEvent5 = undefined;
      this.intervalEvent6 = undefined;
      this.potentialAnalysis = undefined;

      
      this.data = newData;
      
      this.selectedCalculationId = this.data.selectedCalculationId;
      this.userDetails = this.userProfileService.getUserProfileDetails();
      this.kalkulationHeader = `(Kalkulation per ${this.data.kalkulation.text.split(' ')[0]} / Kalkulation ${this.data.kalkulation.text.split(' ')[1][0]} von ${this.data.kalkulation.text.split(' ')[1][2]})`;

      this.getKundengerustLists();

      this.processPdfFlag = true;
      
      this.waitFor(_ => 
        // this.loadedConsultant === true 
        // && this.loadedFramework1 === true
        // && this.loadedFramework2 === true
        // && this.loadedFramework3 === true
        // && this.loadedFramework4 === true
        // && this.loadedFramework5 === true
        // && this.loadedFramework6 === true
        // && this.loadedVermogen === true
        // && 
        this.responseKundengerustSuccess === true)
        .then(async _ => {
          await this.processPDF().then(() => {
            resolve(true);
          });
        });
      
      
    });
  }

  waitFor(conditionFunction) {
    const poll = resolve => {
      if(conditionFunction()) resolve();
      else setTimeout(_ => poll(resolve), 500);
    }
    return new Promise(poll);
  }

  setHtmlRenderingFinishedForConsultant(consultantInfo){
    if (!consultantInfo) {
      return;
    }
    this.loadedConsultant = true;
    this.currentProcessedConsultant = consultantInfo;
  }
  setHtmlRenderingFinishedForFramework1(){
    this.loadedFramework1 = true;
  }
  setHtmlRenderingFinishedForFramework2(){
    this.loadedFramework2 = true;
  }
  setHtmlRenderingFinishedForFramework3(){
    this.loadedFramework3 = true;
  }
  setHtmlRenderingFinishedForFramework4(){
    this.loadedFramework4 = true;
  }
  setHtmlRenderingFinishedForFramework5(){
    this.loadedFramework5 = true;
  }
  setHtmlRenderingFinishedForFramework6(){
    this.loadedFramework6 = true;
  }
  setHtmlRenderingFinishedForVermogen(){
    this.loadedVermogen = true;
  }
  

  async processPDF() {
    if (!this.processPdfFlag) {
      return;
    }

    return new Promise(async (resolve, reject) =>
    {
      this.processingDownload = false;
      this.currentReport++;
      this.spinnerFlag = true;
      this.hideSelectionContent = true;
      this.pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'b3'
      });

      // Generate pages
      this.page1 && this.processPdfFlag && await this.page_1();
      this.page1 && this.processPdfFlag && await this.page_2();
      this.page1 && this.processPdfFlag && await this.page_3();
      this.customerNeedDetail = this.getCommonCustomerNeedDetail();
      this.page4 && this.processPdfFlag && await this.page_4();
      this.page5 && this.processPdfFlag && await this.page_5();
      this.page6 && this.processPdfFlag && await this.page_6();
      this.page7 && this.processPdfFlag && await this.page_7();
      this.page8 && this.processPdfFlag && await this.page_8();
      this.page9 && this.processPdfFlag && await this.page_9();
      this.page10 && this.processPdfFlag && await this.page_10();
      this.page10 && this.processPdfFlag && await this.page_11();
      this.page10 && this.processPdfFlag && await this.page_12();
      this.page10 && this.processPdfFlag && await this.page_13();
      this.page10 && this.processPdfFlag && await this.page_14();
      this.page10 && this.processPdfFlag && await this.page_15();
      this.page16 && this.processPdfFlag && await this.page_16();


      // Download
      if (this.pdfFormat === 'zipPDF') {
        if (this.readyConsultantPdfs.length < this.totalReports) {
          this.readyConsultantPdfs.push({data: this.pdf.output('blob'), consultant: this.currentProcessedConsultant});
          if (this.readyConsultantPdfs.length == this.totalReports) {
            this.processPdfFlag && await this.downloadPDF();
          }
        }
      } else {
        if (this.pdfFormat === 'multiplePDF') {
          this.processingDownload = true;
          this.readyConsultantPdfs.push({data: this.pdf, consultant: this.currentProcessedConsultant});
        }
        this.processPdfFlag && await this.downloadPDF();
      }
      resolve(true);
    });
  }

  async page_1() {
    const page1 = document.getElementById('page1');
    page1.innerHTML += document.getElementById('parentPDFHTML').innerHTML;
    for (let i = 2; i <= 4; i++) {
      page1.innerHTML = page1.innerHTML.replace(document.getElementById(`mainList1${i}`).innerHTML, '');
    }
    page1.getElementsByClassName('customerNeedDetail0')[0].innerHTML = '';
    page1.getElementsByClassName('customerNeedDetail1')[0].innerHTML = '';
    page1.style.fontFeatureSettings = '"liga" 0';

    const canvas = await html2canvas(page1, {
      scale: 1.2,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async page_2() {
    // Page 2
    const page2 = document.getElementById('page2');
    page2.innerHTML += document.getElementById('parentPDFHTML').innerHTML;
    for (let i = 0; i < 2; i++) {
      page2.innerHTML = page2.innerHTML.replace(document.getElementById(`mainList1${i}`).innerHTML, '');
    }
    page2.innerHTML = page2.innerHTML.replace(document.getElementById(`mainList14`).innerHTML, '');
    document.getElementById('mainList12').style.margin = '0 0 16px 0';
    page2.getElementsByClassName('customerNeedDetail2')[0].innerHTML = '';
    page2.getElementsByClassName('customerNeedDetail3')[0].innerHTML = '';
    page2.style.fontFeatureSettings = '"liga" 0';

    const canvas = await html2canvas(page2, {
      scale: 1.2,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async page_3() {
    // Page 3
    const page3 = document.getElementById('page3');
    page3.innerHTML += document.getElementById('parentPDFHTML').innerHTML;
    for (let i = 0; i <= 3; i++) {
      page3.innerHTML = page3.innerHTML.replace(document.getElementById(`mainList1${i}`).innerHTML, '');
    }
    document.getElementById('mainList14').style.margin = '0 0 16px 0';
    page3.getElementsByClassName('customerNeedDetail4')[0].innerHTML = '';
    page3.style.fontFeatureSettings = '"liga" 0';

    const canvas = await html2canvas(page3, {
      scale: 1.2,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  getCommonCustomerNeedDetail() {
    // Initialize customerNeedDetail for next potential analysis detail pages.
    const customerNeedDetail = document.getElementById('demoCustomerNeedDetail');
    customerNeedDetail.innerHTML += document.getElementById('parentPDFHTML').innerHTML;
    const elements = document.getElementsByClassName('customerNeedTitle');
    for (let i = 0; i < elements.length; i++) {
      customerNeedDetail.innerHTML = customerNeedDetail.innerHTML.replace(elements[i].innerHTML, '');
    }
    return customerNeedDetail;
  }

  async page_4() {
    const page4: any = document.getElementById('page4');
    page4.innerHTML = this.customerNeedDetail.innerHTML;
    page4.getElementsByClassName('customerNeedDetail1')[0].innerHTML = '';
    page4.getElementsByClassName('customerNeedDetail2')[0].innerHTML = '';
    page4.getElementsByClassName('customerNeedDetail3')[0].innerHTML = '';
    page4.getElementsByClassName('customerNeedDetail4')[0].innerHTML = '';
    page4.getElementsByClassName('main-title')[0].innerHTML = page4.getElementsByClassName('sub-title')[0].innerHTML;
    page4.getElementsByClassName('sub-title')[0].innerHTML = '';
    page4.style.fontFeatureSettings = '"liga" 0';

    const lengthPage4 = page4.getElementsByClassName('needsHeader').length;
    const offsetPage4 = document.getElementById('page4').clientHeight;

    await this.getArrayOfGroupedElements(lengthPage4, offsetPage4, page4, this.customerNeedDetail, 0);
  }

  async page_5() {
    const page5: any = document.getElementById('page5');
    page5.innerHTML = this.customerNeedDetail.innerHTML;
    page5.getElementsByClassName('customerNeedDetail0')[0].innerHTML = '';
    page5.getElementsByClassName('customerNeedDetail2')[0].innerHTML = '';
    page5.getElementsByClassName('customerNeedDetail3')[0].innerHTML = '';
    page5.getElementsByClassName('customerNeedDetail4')[0].innerHTML = '';
    page5.getElementsByClassName('main-title')[0].innerHTML = page5.getElementsByClassName('sub-title')[0].innerHTML;
    page5.getElementsByClassName('sub-title')[0].innerHTML = '';
    page5.style.fontFeatureSettings = '"liga" 0';

    const lengthPage5 = page5.getElementsByClassName('needsHeader').length;
    const offsetPage5 = document.getElementById('page5').clientHeight;

    await this.getArrayOfGroupedElements(lengthPage5, offsetPage5, page5, this.customerNeedDetail, 1);
  }

  async page_6() {
    // Page 6
    const page6: any = document.getElementById('page6');
    page6.innerHTML = this.customerNeedDetail.innerHTML;
    page6.getElementsByClassName('customerNeedDetail0')[0].innerHTML = '';
    page6.getElementsByClassName('customerNeedDetail1')[0].innerHTML = '';
    page6.getElementsByClassName('customerNeedDetail3')[0].innerHTML = '';
    page6.getElementsByClassName('customerNeedDetail4')[0].innerHTML = '';
    page6.getElementsByClassName('main-title')[0].innerHTML = page6.getElementsByClassName('sub-title')[0].innerHTML;
    page6.getElementsByClassName('sub-title')[0].innerHTML = '';
    page6.style.fontFeatureSettings = '"liga" 0';

    const lengthPage6 = page6.getElementsByClassName('needsHeader').length;
    const offsetPage6 = document.getElementById('page6').clientHeight;

    await this.getArrayOfGroupedElements(lengthPage6, offsetPage6, page6, this.customerNeedDetail, 2);
  }

  async page_7() {
    const page7 = document.getElementsByClassName('reportForPdf')[0];
    page7.innerHTML = document.getElementsByClassName('PageTitlePDF')[0].innerHTML + page7.innerHTML;
    page7.getElementsByClassName('main-title')[0].innerHTML = 'Qualitätsbericht zum Thema Vermögen';
    const canvas = await html2canvas(page7 as HTMLCanvasElement, {
      scale: 1.2,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async page_8() {
    // Page 8
    const page8: any = document.getElementById('page8');
    page8.innerHTML = this.customerNeedDetail.innerHTML;
    page8.getElementsByClassName('customerNeedDetail0')[0].innerHTML = '';
    page8.getElementsByClassName('customerNeedDetail1')[0].innerHTML = '';
    page8.getElementsByClassName('customerNeedDetail2')[0].innerHTML = '';
    page8.getElementsByClassName('customerNeedDetail4')[0].innerHTML = '';
    page8.getElementsByClassName('main-title')[0].innerHTML = page8.getElementsByClassName('sub-title')[0].innerHTML;
    page8.getElementsByClassName('sub-title')[0].innerHTML = '';
    page8.style.fontFeatureSettings = '"liga" 0';

    const lengthPage8 = page8.getElementsByClassName('needsHeader').length;
    const offsetPage8 = document.getElementById('page8').clientHeight;

    await this.getArrayOfGroupedElements(lengthPage8, offsetPage8, page8, this.customerNeedDetail, 3);
  }

  async page_9() {
    const page9: any = document.getElementById('page9');
    page9.innerHTML = this.customerNeedDetail.innerHTML;
    page9.getElementsByClassName('customerNeedDetail0')[0].innerHTML = '';
    page9.getElementsByClassName('customerNeedDetail1')[0].innerHTML = '';
    page9.getElementsByClassName('customerNeedDetail2')[0].innerHTML = '';
    page9.getElementsByClassName('customerNeedDetail3')[0].innerHTML = '';
    page9.getElementsByClassName('main-title')[0].innerHTML = page9.getElementsByClassName('sub-title')[0].innerHTML;
    page9.getElementsByClassName('sub-title')[0].innerHTML = '';
    page9.style.fontFeatureSettings = '"liga" 0';

    const lengthPage9 = page9.getElementsByClassName('needsHeader').length;
    const offsetPage9 = document.getElementById('page9').clientHeight;

    await this.getArrayOfGroupedElements(lengthPage9, offsetPage9, page9, this.customerNeedDetail, 4);
  }

  async page_10() {
    const page10 = document.getElementsByClassName('KundengerüstTable1Div')[0];
    page10.innerHTML = document.getElementsByClassName('headerKundengerustTable')[0]
      .innerHTML + page10.innerHTML + document.getElementsByClassName('framework1')[0].innerHTML;
    page10.getElementsByClassName('VermogenTable')[0].innerHTML = '';
    page10.getElementsByClassName('kundengruppeTable1Title')[0].innerHTML = 'Anzahl der Haushalte nach Musterkundengruppe und Kontostatus';

    const canvas = await html2canvas(page10 as HTMLCanvasElement, {
      scale: 1.2,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.setFontSize(20);
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }
  async page_11() {
    const page11 = document.getElementsByClassName('KundengerüstTable2Div')[0];
    page11.innerHTML = document.getElementsByClassName('headerKundengerustTable')[0]
      .innerHTML + page11.innerHTML + document.getElementsByClassName('framework2')[0].innerHTML;
    page11.getElementsByClassName('VermogenTable')[0].innerHTML = '';
    page11.getElementsByClassName('kundengruppeTable2Title')[0].innerHTML = 'Anzahl der Personen nach Musterkundengruppe und Kontostatus';

    const canvas = await html2canvas(page11 as HTMLCanvasElement, {
      scale: 1.2,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);

  }
  async page_12() {
    const page12 = document.getElementsByClassName('KundengerüstTable3Div')[0];
    page12.innerHTML = document.getElementsByClassName('headerKundengerustTable')[0]
      .innerHTML + page12.innerHTML + document.getElementsByClassName('framework3')[0].innerHTML;
    page12.getElementsByClassName('KundengerüstMainTable')[0].innerHTML = '';
    page12.getElementsByClassName('kundengruppeTable3Title')[0].innerHTML = 'Anzahl der Haushalte nach Vermögen und Risikoklasse';

    const canvas = await html2canvas(page12 as HTMLCanvasElement, {
      scale: 1.5,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);

  }

  async page_13() {
    const page13 = document.getElementsByClassName('KundengerüstTable4Div')[0];
    page13.innerHTML = document.getElementsByClassName('headerKundengerustTable')[0]
      .innerHTML + page13.innerHTML + document.getElementsByClassName('framework4')[0].innerHTML;
    page13.getElementsByClassName('KundengerüstMainTable')[0].innerHTML = '';
    page13.getElementsByClassName('kundengruppeTable4Title')[0].innerHTML = 'Anzahl der Personen nach Vermögen und Risikoklasse';

    const canvas = await html2canvas(page13 as HTMLCanvasElement, {
      scale: 1.5,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async page_14() {
    const page14 = document.getElementsByClassName('KundengerüstTable5Div')[0];
    page14.innerHTML = document.getElementsByClassName('headerKundengerustTable')[0]
      .innerHTML + page14.innerHTML + document.getElementsByClassName('framework5')[0].innerHTML;
    page14.getElementsByClassName('KundengerüstMainTable')[0].innerHTML = '';
    page14.getElementsByClassName('kundengruppeTable5Title')[0].innerHTML = 'Haushaltsvermögen in EUR nach Vermögen und Risikoklasse';

    const canvas = await html2canvas(page14 as HTMLCanvasElement, {
      scale: 1.5,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async page_15() {
    const page15 = document.getElementsByClassName('KundengerüstTable6Div')[0];
    page15.innerHTML = document.getElementsByClassName('headerKundengerustTable')[0]
      .innerHTML + page15.innerHTML + document.getElementsByClassName('framework6')[0].innerHTML;
    page15.getElementsByClassName('KundengerüstMainTable')[0].innerHTML = '';
    page15.getElementsByClassName('kundengruppeTable6Title')[0]
      .innerHTML = 'Fungibles Haushaltsvermögen in EUR nach Vermögen und Risikoklasse';

    const canvas = await html2canvas(page15 as HTMLCanvasElement, {
      scale: 1.5,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async page_16() {
    const page16 = document.getElementsByClassName('selectedFilterScreen')[0];
    const canvas = await html2canvas(page16 as HTMLCanvasElement, {
      scale: 1.5,
      width: 1100,
      logging: this.html2canvasLogging
    });
    this.pdf.addPage();
    this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
  }

  async downloadPDF() {
    if (!this.page1) {
      this.pdf.deletePage(1);
    }
    this.getFooter(this.pdf);
    let calculationText = this.data.kalkulation.text.replace('/','-');

    if (this.pdfFormat === 'simplePDF') {
      this.pdf.save('Report '+ calculationText + '.pdf');
      // this.spinnerFlag = false;
      this.dialogRef.close();
      } else if (this.pdfFormat === 'zipPDF') {
        // #region [rgba(255,0,0,0.05)] -- 
        const zip = new JSZip();
        this.readyConsultantPdfs.forEach(pdf => {
          zip.file('Report '+ calculationText + ' ' + pdf.consultant.name +' (' + pdf.consultant.consultantClientId + ').pdf', pdf.data);
        });
        const content = await zip.generateAsync({
          type: 'blob',
          //compression: "DEFLATE"
        });
        saveAs(content, 'potential-analysis.zip');
        
        this.dialogRef.close(this.orgConsultantIds);
        // #endregion
    } else if (this.pdfFormat === 'multiplePDF') {

      let readyPDF = this.readyConsultantPdfs.pop();
      readyPDF.data.save('Report '+ calculationText + ' ' + readyPDF.consultant.name +' (' + readyPDF.consultant.consultantClientId + ').pdf');
      this.downloadedConsultantPdfs++;
      if (this.downloadedConsultantPdfs == this.totalReports) {
        this.dialogRef.close(this.orgConsultantIds);
      }
    }
  }

  getFooter(pdf) {
    // Get the current date and formate it as required.
    const pageCount = pdf.internal.getNumberOfPages();
    let today: any = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    today = dd + '.' + mm + '.' + yyyy;

    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      pdf.line(20, pdf.internal.pageSize.getHeight() - 20, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 20);
      pdf.text(`Analyse erstellt am ${today}
      durch ${this.userDetails.LastName} ${this.userDetails.FirstName}`, 20, pdf.internal.pageSize.getHeight() - 10);
      pdf.text(`Seite ${i} von ${pageCount}`, pdf.internal.pageSize.getWidth() - 70, pdf.internal.pageSize.getHeight() - 10);
    }
  }

  getKundengerustLists() {
    this.potentialAnalysis = this.commonUtils.setPotentialAnalysisWithFilters(this.data.selectedCalculationId);
    this.applyFiltersAndFetchFramework();
  }

  applyFiltersAndFetchFramework() {
    this.potentialAnalyseService.applyFilters(this.potentialAnalysis).subscribe((response: any) => {
      KUNDERGUST.forEach(object => {
        this[`framework${object.frameworkNo}`] = object.data;
        this.apiResultMap[object.frameworkNo] = true;
      });
      this.showTables = true;
      this.responseKundengerustSuccess = true;
    });
  }

  getCustomerFramework(currentFrameworkNumber, guidOfAppliedFilter) {
    this.potentialAnalyseService[`getCustomerFramework0${currentFrameworkNumber}`](guidOfAppliedFilter).subscribe((response: any) => {
      switch (response.StatusCode) {
        case 200:
          if (this[`intervalEvent${currentFrameworkNumber}`]) {
            this[`intervalEvent${currentFrameworkNumber}`].unsubscribe();
          }
          this[`framework${currentFrameworkNumber}`] = response.Result.rows;
          this.apiResultMap[currentFrameworkNumber - 1] = true;
          this.allApiKundengerustSuccess();
          break;
        case 206:
          if (!this[`intervalEvent${currentFrameworkNumber}`]) {
            this[`intervalEvent${currentFrameworkNumber}`] = interval(10000).subscribe(x => {
              this.getCustomerFramework(currentFrameworkNumber, guidOfAppliedFilter);
            });
          }
          break;
        case 404:
          if (this[`intervalEvent${currentFrameworkNumber}`]) {
            this[`intervalEvent${currentFrameworkNumber}`].unsubscribe();
          }
          this.getCustomerFramework(currentFrameworkNumber, guidOfAppliedFilter);
          break;
      }

    });
  }

  allApiKundengerustSuccess() {
    if (this.apiResultMap[0] && this.apiResultMap[1] && this.apiResultMap[2] && this.apiResultMap[3] && this.apiResultMap[4] && this.apiResultMap[5]) {
      this.showTables = true;
      this.responseKundengerustSuccess = true;
    }
  }

  async getArrayOfGroupedElements(length, offset, currentPage, customerNeedDetail, currentCustomerNeedDetail) {
    const arrayOfGroupedElements = [];
    const removeElement: RemovedElement[] = [];
    if (offset > 1417) {
      for (let i = 0; i < length; i++) {
        const element = new RemovedElement;
        element.id = i;
        element.removedElement = `customerNeedFieldDiv${i}`;
        element.height = currentPage.getElementsByClassName(`customerNeedFieldDiv${i}`)[0].offsetHeight;
        removeElement.push(element);

        if (arrayOfGroupedElements.length === 0) {
          arrayOfGroupedElements.push([{
            id: element.id,
            height: element.height
          }]);
        } else {
          const content = arrayOfGroupedElements[arrayOfGroupedElements.length - 1];

          if ((content.map(item => item.height)).reduce((a, b) => a + b, 0) + element.height < 1417) {
            content.push({
              id: element.id,
              height: element.height
            });
          } else {
            arrayOfGroupedElements.push([{
              id: element.id,
              height: element.height
            }]);
          }
        }
      }
    }

    for (let i = 0; i < arrayOfGroupedElements.length; i++) {
      const iDiv = document.createElement('DIV');
      iDiv.className = `block${i}`;
      document.getElementById('page5').appendChild(iDiv);
      const newDiv = document.getElementsByClassName(`block${i}`)[0];
      newDiv.innerHTML = customerNeedDetail.innerHTML;

      for (let index = 0; index < 5; index++) {
        if (index === currentCustomerNeedDetail) {
          continue;
        }
        newDiv.getElementsByClassName(`customerNeedDetail${index}`)[0].innerHTML = '';
      }
      newDiv.getElementsByClassName('main-title')[0].innerHTML = newDiv.getElementsByClassName('sub-title')[0].innerHTML;
      newDiv.getElementsByClassName('sub-title')[0].innerHTML = '';
      for (let j = 0; j < removeElement.length; j++) {
        if (!(arrayOfGroupedElements[i].map(item => item.id)).includes(j)) {
          newDiv.getElementsByClassName(`customerNeedFieldDiv${j}`)[0].innerHTML = '';
        }
      }
      const canvas = await html2canvas(newDiv as HTMLCanvasElement, {
        scale: 1.2,
        width: 1100,
        logging: this.html2canvasLogging
      });
      this.pdf.addPage();
      this.pdf.addImage(canvas, 'JPG', 0, this.top_left_margin);
    }
  }

  closeDialog() {
    this.processPdfFlag = false;
    this.dialogRef.close(this.orgConsultantIds);
  }

  dataGeneration() {
    this.disabled = false;
  }
}

export class RemovedElement {
  removedElement: any = '';
  height: any = 0;
  id: any;
}
