import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Injectable({
  providedIn: 'root'
})
export class HighchartService {

  constructor() { }

  vermogensverteilungAktuellChart = new Chart({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: '65%'
    },
    title: {
      text: '<b>Vermögensstruktur Aktuell</b>'
    },
    tooltip: {
      backgroundColor: 'rgb(0,0,0)',
      borderColor: 'rgb(0,0,0)',

      formatter: function () {
        return `<span style="color:white"> ${this.point.category}   </span>`;
      },
      // pointFormat: '{point.name}: {point.y}'
    },
    plotOptions: {
      pie: {
        size: 125,
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: '60%',

        dataLabels: {
          enabled: true,
          format: `{point.name}<br>{point.percentage:.0f}%`
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      type: 'pie',
      name: 'Brands',
      data: []
    }]
  });


  vermogensverteilungNachMusterallokationChart = new Chart({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: '65%'
    },
    title: {
      text: '<b>Vermögensstruktur nach Musterallokation</b>'
    },
    tooltip: {
      backgroundColor: 'rgb(0,0,0)',
      borderColor: 'rgb(0,0,0)',

      formatter: function () {
        return `<span style="color:white"> ${this.point.category}   </span>`;
      },
      // pointFormat: '{point.name}: {point.y}'
    },
    plotOptions: {
      pie: {
        size: 125,
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: '60%',

        dataLabels: {
          enabled: true,
          format: `{point.name}<br>{point.percentage:.0f}%`
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      type: 'pie',
      name: 'Brands',
      data: []
    }]
  });

}
