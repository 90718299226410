export const PRODUCT_CLASSIFICATIONS = [
    {
        id: 1,
        productName: 'Vermittlung: Bausparen (Normal Darlehensphase)',
        productId1: '10900302',
        productId2: 'BSVE000000000000XS01',
        securityNo: '000000',
        isModified: false,
        zourdnung: 1,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 100,
                default: true,
                needFieldId: 'PK05',
                needFieldName: 'Immobilie',
                needFieldType: 'Wohneigentumerwerben/finanzieren',
                needFieldSolution: 'Vermittelte Darlehen',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false,
                    asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    {
        id: 2,
        productName: 'Vermittlung: Bausparen (Normal Ansparphase)',
        productId1: '0010900301',
        productId2: 'BSVE000000000000XV01',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK05',
                needFieldName: 'Immobilie',
                needFieldType: 'Wohneigentumerwerben/finanzieren',
                needFieldSolution: 'Eigenkapital aufbauen',
                changed: false,
                disabled: {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 3
    {
        id: 3,
        productName: 'UNIFONDS',
        productId1: '0010900200',
        productId2: 'FONDSKONTO0000000000',
        securityNo: 'DE0008491002',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 0,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen optimieren',
                needFieldSolution: 'Substanzwerte Fonds',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 4
    {
        id: 4,
        productName: 'UNIIMMO: DEUTSCHLAND',
        productId1: '0010900200',
        productId2: 'FONDSKONTO0000000000',
        securityNo: 'DE0009805507',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 0,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen optimieren',
                needFieldSolution: 'Sachwerte Fonds',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 5
    {
        id: 5,
        productName: 'UNIGLOBAL II A',
        productId1: '0010900200',
        productId2: 'FONDSKONTO0000000000',
        securityNo: 'LU0718610743',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 0,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen optimieren',
                needFieldSolution: 'Substanzwerte Fonds',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 6
    {
        id: 6,
        productName: 'UNIFONDS',
        productId1: '0010900200',
        productId2: 'FOSP00000000000000VL',
        securityNo: 'DE0008491002',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen ansparen',
                needFieldSolution: 'Substanzwerte',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },
        ]
    },

    // 7
    {
        id: 7,
        productName: 'Vermittlung: Ratenkredit',
        productId1: '0010900200',
        productId2: 'FOSP000000000000SPAR',
        securityNo: 'DE0009805507',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen ansparen',
                needFieldSolution: 'Sachwerte',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },
        ]
    },
    // 8
    {
        id: 8,
        productName: 'UNIGLOBAL II A',
        productId1: '0010900200',
        productId2: 'FOSP000000000000SPAR',
        securityNo: 'LU0718610743',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen ansparen',
                needFieldSolution: 'Substanzwerte',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            },
        ]
    },
    // 9
    {
        id: 9,
        productName: 'Vermittlung: Fondskonto',
        productId1: '0010900200',
        productId2: 'FOSP000000000000UPRS',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK04',
                needFieldName: ' Vorsorge',
                needFieldType: 'Für das Alter vorsorgen',
                needFieldSolution: 'gefördert (Fonds)   ',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },
        ]
    },
    // 10
    {
        id: 10,
        productName: 'Vermittlung: Ratenkredit',
        productId1: '0010900500',
        productId2: 'TEAM0000000000000000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 100,
                default: true,
                needFieldId: 'PK01',
                needFieldName: ' Liquidität',
                needFieldType: 'Wünsche finanzieren',
                needFieldSolution: 'Konsumentenkredit (Vermittelt)',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },

        ]
    },
    // 11
    {
        id: 10,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSALLIANZ0BUEINZEL',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Einkommen und Familie absichern',
                needFieldSolution: 'Berufsunfähigkeit',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },

        ]
    },
    // 12
    {
        id: 12,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSALLIANZUNFALLVER',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Einkommen und Familie absichern',
                needFieldSolution: 'Unfallversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            },

        ]
    },
    {
        id: 13,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSAVRULLEBENREGELM',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK04',
                needFieldName: ' Vorsorge',
                needFieldType: 'Für das Alter vorsorgen',
                needFieldSolution: 'gefördert (Versicherung exkl. BaVL',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }



        ]
    },
    {
        id: 14,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUV000000KFZ0',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Haftpflicht',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 15
    {
        id: 15,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVHAFTPFLICH',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Haftpflichtversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 16
    {
        id: 16,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVHAUSRAT000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Hausrat',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 17
    {
        id: 17,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVRECHTSCHUT',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Rechtschutz',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    {
        id: 18,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVWOHNGEBAEU',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Wohngebäude',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 19
    {
        id: 19,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVRUV0000000ZUS0',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Krankenzusatzversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    {
        id: 20,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVRUV000000VOLL0',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Krankenvollversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },

    // 21
    {
        id: 21,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVSDKAUSLAN00000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Auslandsreisekranken',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 22
    {
        id: 22,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVSDKPFLEGE00000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Pflegeversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 23
    {
        id: 23,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSLEBENRUVRISIKOLV',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Einkommen und Familie absichern',
                needFieldSolution: 'Risikolebensversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    }

];

export const PRODUCT_CLASSIFICATIONS_OLD = [
    {
        id: 1,
        productName: 'Vermittlung: Bausparen (Normal Darlehensphase)',
        productId1: '10900302',
        productId2: 'BSVE000000000000XS01',
        securityNo: '000000',
        isModified: false,
        zourdnung: 1,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 100,
                default: true,
                needFieldId: 'PK05',
                needFieldName: 'Immobilie',
                needFieldType: 'Wohneigentumerwerben/finanzieren',
                needFieldSolution: 'Vermittelte Darlehen',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false,
                    asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    {
        id: 2,
        productName: 'Vermittlung: Bausparen (Normal Ansparphase)',
        productId1: '0010900301',
        productId2: 'BSVE000000000000XV01',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK05',
                needFieldName: 'Immobilie',
                needFieldType: 'Wohneigentumerwerben/finanzieren',
                needFieldSolution: 'Eigenkapital aufbauen',
                changed: false,
                disabled: {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 3
    {
        id: 3,
        productName: 'UNIFONDS',
        productId1: '0010900200',
        productId2: 'FONDSKONTO0000000000',
        securityNo: 'DE0008491002',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 0,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen optimieren',
                needFieldSolution: 'Substanzwerte Fonds',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 4
    {
        id: 4,
        productName: 'UNIIMMO: DEUTSCHLAND',
        productId1: '0010900200',
        productId2: 'FONDSKONTO0000000000',
        securityNo: 'DE0009805507',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 0,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen optimieren',
                needFieldSolution: 'Sachwerte Fonds',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 5
    {
        id: 5,
        productName: 'UNIGLOBAL II A',
        productId1: '0010900200',
        productId2: 'FONDSKONTO0000000000',
        securityNo: 'LU0718610743',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 0,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen optimieren',
                needFieldSolution: 'Substanzwerte Fonds',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 6
    {
        id: 6,
        productName: 'UNIFONDS',
        productId1: '0010900200',
        productId2: 'FOSP00000000000000VL',
        securityNo: 'DE0008491002',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen ansparen',
                needFieldSolution: 'Substanzwerte',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },
        ]
    },

    // 7
    {
        id: 7,
        productName: 'Vermittlung: Ratenkredit',
        productId1: '0010900200',
        productId2: 'FOSP000000000000SPAR',
        securityNo: 'DE0009805507',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen ansparen',
                needFieldSolution: 'Sachwerte',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },
        ]
    },
    // 8
    {
        id: 8,
        productName: 'UNIGLOBAL II A',
        productId1: '0010900200',
        productId2: 'FOSP000000000000SPAR',
        securityNo: 'LU0718610743',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK03',
                needFieldName: 'Vermögen',
                needFieldType: 'Vermögen ansparen',
                needFieldSolution: 'Substanzwerte',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            },
        ]
    },
    // 9
    {
        id: 9,
        productName: 'Vermittlung: Fondskonto',
        productId1: '0010900200',
        productId2: 'FOSP000000000000UPRS',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK04',
                needFieldName: ' Vorsorge',
                needFieldType: 'Für das Alter vorsorgen',
                needFieldSolution: 'gefördert (Fonds)   ',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },
        ]
    },
    // 10
    {
        id: 10,
        productName: 'Vermittlung: Ratenkredit',
        productId1: '0010900500',
        productId2: 'TEAM0000000000000000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 100,
                default: true,
                needFieldId: 'PK01',
                needFieldName: ' Liquidität',
                needFieldType: 'Wünsche finanzieren',
                needFieldSolution: 'Konsumentenkredit (Vermittelt)',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },

        ]
    },
    // 11
    {
        id: 10,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSALLIANZ0BUEINZEL',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Einkommen und Familie absichern',
                needFieldSolution: 'Berufsunfähigkeit',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            },

        ]
    },
    // 12
    {
        id: 12,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSALLIANZUNFALLVER',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Einkommen und Familie absichern',
                needFieldSolution: 'Unfallversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            },

        ]
    },
    {
        id: 13,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSAVRULLEBENREGELM',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 100,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK04',
                needFieldName: ' Vorsorge',
                needFieldType: 'Für das Alter vorsorgen',
                needFieldSolution: 'gefördert (Versicherung exkl. BaVL',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }



        ]
    },
    {
        id: 14,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUV000000KFZ0',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Haftpflicht',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }
            }
        ]
    },
    // 15
    {
        id: 15,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVHAFTPFLICH',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Haftpflichtversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 16
    {
        id: 16,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVHAUSRAT000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Hausrat',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 17
    {
        id: 17,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVRECHTSCHUT',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Rechtschutz',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    {
        id: 18,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKOMRUVWOHNGEBAEU',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Sachwerte absichern',
                needFieldSolution: 'Wohngebäude',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 19
    {
        id: 19,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVRUV0000000ZUS0',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Krankenzusatzversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    {
        id: 20,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVRUV000000VOLL0',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Krankenvollversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },

    // 21
    {
        id: 21,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVSDKAUSLAN00000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Auslandsreisekranken',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 22
    {
        id: 22,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSKVSDKPFLEGE00000',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Gesundheit absichern',
                needFieldSolution: 'Pflegeversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    },
    // 23
    {
        id: 23,
        productName: 'Vermittlung: Versicherungsvertrag',
        productId1: '0010900400',
        productId2: 'VERSLEBENRUVRISIKOLV',
        securityNo: '000000',
        zourdnung: 1,
        isModified: false,
        solutions: [
            {
                id: 1,
                solutionName: 'Lösung 1',
                sharedAsset: 100,
                sharedPayment: 0,
                sharedCredit: 0,
                default: true,
                needFieldId: 'PK02',
                needFieldName: ' Absichern',
                needFieldType: 'Einkommen und Familie absichern',
                needFieldSolution: 'Risikolebensversicherung',
                changed: false,
                disabled: {
                    asset: true,
                    payment: true,
                    credit: true
                },
                error:
                {
                    noSolSelected: false, asset: false,
                    payment: false,
                    credit: false
                }

            }
        ]
    }

];
