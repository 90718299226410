import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-consultant-segment-dialog',
  templateUrl: './delete-consultant-segment-dialog.component.html',
  styleUrls: ['./delete-consultant-segment-dialog.component.scss']
})
export class DeleteConsultantSegmentDialogComponent implements OnInit {
  deleteName: any;
  constructor(public dialogRef: MatDialogRef<DeleteConsultantSegmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, ) {
    this.deleteName = data.name;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteConsultantSegment() {
    this.dialogRef.close(true);
  }

}
