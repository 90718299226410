<div >
  <h4 class="m-t-0" mat-dialog-title>{{'resetPassword' | translate}}</h4>
  <p>{{'resetPasswordModalText' | translate}}.</p>
  <form class="w-100">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{'newPasswort' | translate}}</mat-label>
      <input matInput placeholder="" value="" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>


    <!-- </div>
<div mat-dialog-actions > -->
  <div mat-dialog-actions align="end">
      <button mat-raised-button color="basic" (click)="closeDialog()">{{'stop' | translate}}</button>
      <button mat-raised-button cdkFocusInitial class="custom-button" class="m-l-5" (click)="closeDialog()">{{'resetToDefault' | translate}}</button>
    </div>
  </form>
</div>