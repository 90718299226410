<div class="p-l-1vw p-t-1vw p-r-1vw">
    <mat-grid-list cols="2" rowHeight="70px">
        <mat-grid-tile flex-start flex-align-top class="grid-spacing">
            <h1 class="page-title">
                Zweigstellenstruktur > {{(isEdit ? 'editHierarchyEntry' : 'newHierarchyEntry') | translate}}
            </h1>
        </mat-grid-tile>
        <mat-grid-tile flex-end flex-align-top class="grid-spacing">
        </mat-grid-tile>
    </mat-grid-list>


    <div *ngIf="spinnerFlag" class="progress-spinner spin-position">
        <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
    </div>
    <mat-card class="m-t-50 m-b-50 p-t-30" *ngIf="!spinnerFlag">
        <mat-card-content>
            <mat-grid-list cols="12" rowHeight="400px">
                <mat-grid-tile flex-start flex-align-top [colspan]="7">

                    <form class="w-100" novalidate [formGroup]="nodeForm">
                        <div class="form-field">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Typ</mat-label>
                                <mat-select [disabled]="isEdit" formControlName="profitCenterRegionStructureId">
                                    <mat-option *ngFor="let type of regionTypes" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                                <!-- <mat-error *ngIf="hasError('profitCenterRegionStructureId', 'required')">Region type {{'isRequired' | translate}}</mat-error> -->

                            </mat-form-field>
                        </div>

                        <div class="form-field" *ngIf="isEdit && (currentDepartment.profitCenterRegionStructureId == 3)">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Profitcenter</mat-label>
                                <mat-select formControlName="parentProfitCenterId">
                                    <mat-option *ngFor="let filter of regionTypeFilters" [value]="filter.id">
                                        {{filter.descriptionDepartment}}
                                    </mat-option>
                                </mat-select>
                                <!-- <mat-error *ngIf="hasError('profitCenterRegionStructureId', 'required')">Region type {{'isRequired' | translate}}</mat-error> -->

                            </mat-form-field>
                        </div>

                        <div class="form-field">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>{{'name' | translate}}</mat-label>
                                <input formControlName="descriptionDepartment" matInput placeholder="Name">
                                <!-- <mat-error *ngIf="hasError('name', 'required')">name {{'isRequired' | translate}}</mat-error> -->
    
                            </mat-form-field>
                        </div>

                        <div class="form-field" *ngIf="nodeForm.controls['profitCenterRegionStructureId'].value == 3">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Zweigstellennummer</mat-label>
                                <input disabled formControlName="departmentNumber" matInput type="number" placeholder="Zweigstellennummer" [readonly]="isEdit">
                                <!-- <mat-error *ngIf="(hasError('name', 'min') || hasError('name', 'max'))">
                                        Die Abteilungsnummer muss zwischen 1 und 999 liegen</mat-error> -->

                            </mat-form-field>
                        </div>
                    </form>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
        <mat-card-actions class="m-b-5">
            <mat-grid-list cols="12" rowHeight="45px">
                <mat-grid-tile flex-start [colspan]="12" class="grid-spacing">
                    <button mat-raised-button color="basic" class="m-l-10" (click)="abort()">{{'stop' | translate}}</button>
                    <button mat-raised-button class="custom-button m-l-20" [disabled]="nodeForm.invalid" (click)="addDepartment()">{{'save' | translate}}</button>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-actions>
    </mat-card>
</div>