<!--main content-->
<!-- Page Heading -->
<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        <span>{{'settings' | translate}}</span>
        {{'user' | translate}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
      <!-- </ap  p-bv-header-bar> -->
    </mat-grid-tile>
  </mat-grid-list>
  <!-- End: Page Heading -->
  <!-- New Mailn content -->


  <!-- End: New Main Content -->
</div>

<div class="container maincontent-wrapper" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutWrap
  fxLayoutGap="0.5%" fxLayoutAlign="center">
  <div fxFlex="75%" fxFlexOrder="2" fxFlexOrder.gt-md="1" class=" p-r-20 ">

    <mat-tab-group [selectedIndex]="0" (selectedTabChange)="onTabChanged($event);">


      <mat-tab label="Nutzer">
        <!-- table starts -->
        <div class="spinner" *ngIf="spinnerFlag">
          <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
        </div>
        <div class=" card-shadow">
          <table mat-table #userSort="matSort" [dataSource]="dataSource" matSort class="w-100 ">
            <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="FirstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'name/email' | translate}} </th>
              <td class="fw-500" mat-cell *matCellDef="let user"> {{(user.LastName || '').trim()}}
                <span *ngIf="user.LastName && user.FirstName">,</span> {{user.FirstName}}
                <div class="p-t-5 grey-text fw-300 table-text-overflow" [matTooltip]="user.Email">
                  {{user.Email}}</div>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="UserName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'userName' | translate}} </th>
              <td mat-cell *matCellDef="let user">
                <div class="table-text-overflow" [matTooltip]="user.UserName"> {{user.UserName}} </div>
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef> {{'status' | translate}} </th>
              <td mat-cell *matCellDef="let user">
                <div *ngIf="!user.IsDeleted"> {{'active' | translate}}</div>
                <div *ngIf="user.IsDeleted">{{'deactivated' | translate}}</div>

              </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Rollen">
              <th mat-header-cell *matHeaderCellDef> {{'role' | translate}} </th>
              <td mat-cell *matCellDef="let user">
                <div class="role-div role-text" *ngIf="user.roles && user.roles.length > 0">
                  <span *ngFor="let role of user.roles;let i = index">{{role}} </span>
                </div>
                <div class="role-div role-text" *ngIf="!user.roles || !user.roles.length > 0">
                  <span>-</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Letzte Aktivität">
              <th mat-header-cell *matHeaderCellDef> {{'lastActivity' | translate}} </th>
              <td mat-cell  *matCellDef="let user"> {{user.LastLoginDate }} </td>
              <!-- <td mat-cell *ngIf="!user.hasLoginDate" *matCellDef="let user"> {{user.LastLoginDate}} </td> -->
            </ng-container>
            <ng-container matColumnDef=" ">
              <th mat-header-cell width="120" *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let user" class="text-right">
                <button mat-icon-button class="edit-button" (click)="editUser(user.Id)">
                  <i class="fs-16 material-icons"> edit </i>
                </button>
                <button title="Benutzer deaktivieren" mat-icon-button *ngIf="!user.IsDeleted" (click)="confirmDialogToDeleteUser(user.Id, user)">
                  <i class="fs-16 material-icons"> block </i>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
          <!-- <div *ngIf="spinnerFlag" style="display: flex; justify-content: center; align-items: center">
            <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
          </div> -->
          <!-- table end -->
          <mat-paginator class="m-t-5" [length]='totalCount' [pageIndex]="pageIndex" [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 30]" (page)="paginationEvent($event)">
          </mat-paginator>
        </div>
      </mat-tab>
      <mat-tab label="Einladungen">
        <!-- Tab 2 content -->
        <div class="spinner" *ngIf="spinnerFlag">
          <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
        </div>
        <div class=" card-shadow">
          <table mat-table [dataSource]="dataSourceInvite" #invitedSort="matSort" matSort class="w-100 ">
            <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="FirstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'name/email' | translate}} </th>
              <td class="fw-500" mat-cell *matCellDef="let invitedUser"> {{(invitedUser.LastName || '').trim()}}
                <span *ngIf="invitedUser.LastName && invitedUser.FirstName">,</span> {{invitedUser.FirstName}}
                <div class="p-t-5 grey-text fw-300 table-text-overflow" [matTooltip]="invitedUser.Email">
                  {{invitedUser.Email}}</div>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="UserName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'userName' | translate}} </th>
              <td mat-cell *matCellDef="let invitedUser">
                <div class="table-text-overflow" [matTooltip]="invitedUser.UserName"> {{invitedUser.UserName}} </div>
              </td>
            </ng-container>



            <!-- Symbol Column -->
            <ng-container matColumnDef="Rollen">
              <th mat-header-cell *matHeaderCellDef> {{'role' | translate}} </th>
              <td mat-cell *matCellDef="let invitedUser">
                <div class="role-div role-text" *ngIf="invitedUser.roles.length > 0">
                  <span *ngFor="let role of invitedUser.roles;let i = index">{{role}} </span>
                </div>
                <div class="role-div role-text" *ngIf="!(invitedUser.roles.length > 0)">
                  <span>-</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef=" ">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let invitedUser" class="text-right">
                <div class="img-cursor" (click)="sendInvitation(invitedUser)">
                  <img src="../../../../../assets/images/InviteIcon.png">
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsInvite"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsInvite;">
            </tr>
          </table>
          <!-- <div *ngIf="spinnerFlag" style="display: flex; justify-content: center; align-items: center">
            <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
          </div> -->
          <!-- table end -->
          <mat-paginator class="m-t-5" [length]='totalCountInvite' [pageIndex]="pageIndexInvite" [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 30]" (page)="paginationEventInvite($event)">
          </mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>

  <div fxFlex="25%" fxFlexOrder="1" fxFlexOrder.gt-md="2" class="m-l-10">
    <div class="d-flex w-100 fd-col" class="sidebar-form" fxLayout="column" fxLayout.md="row" fxLayout.sm="row"
      fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="flex-start">
      <mat-form-field>
        <mat-select matNativeControl [(value)]="role"  (selectionChange)="filterData($event)" required>
          <mat-option value="Alle">{{'all' | translate}}</mat-option>
          <mat-option value="Aktive Benutzer" *ngIf="selectedTab == 'UsersWithAcceptedInvitation'">
            {{'activeUsers' | translate}}</mat-option>
          <mat-option value="Inaktive Benutzer" *ngIf="selectedTab == 'UsersWithAcceptedInvitation'">
            {{'inactiveUsers' | translate}}</mat-option>
          <mat-option *ngFor="let item of claimGroups" [value]="item.Id">{{item.Name}}</mat-option>
        </mat-select>
      </mat-form-field>


      <div class="">
        <mat-form-field class="w-100">
          <input matInput placeholder="Suchen" (keyup)="applyFilter($event)" [(ngModel)]="payload.SearchKey">
        </mat-form-field>
      </div>

      <button mat-raised-button class="custom-button" (click)="addUser()">
        <i class="material-icons">
          add
        </i>
        {{'addUser' | translate}}</button>
    </div>
  </div>
</div>

