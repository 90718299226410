import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { DataImportService } from '../../services/data-import.service';
import { FILE_INTERFACE, TRANSACTION_STATUS } from 'src/app/app.config';
import { map } from 'rxjs/operators';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import * as moment from 'moment';
import 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DataConfirmCancelImportDialogComponent } from '../data-confirm-cancel-import-dialog/data-confirm-cancel-import-dialog.component';

// import { Observable } from 'rxjs';
@Component({
  selector: 'app-data-import-list',
  templateUrl: './data-import-list.component.html',
  styleUrls: ['./data-import-list.component.scss'],
})
export class DataImportListComponent implements OnInit, OnDestroy {
  breakpoint: number;
  dataSource: any;
  spinValue: number;
  pageIndex: any;
  displayedColumns: string[] = ['fileName', 'interfaceType', 'uploadTimeStamp', 'userName', 'Status'];
  uploadedFiles: any[] = [];
  interfaceTypes = FILE_INTERFACE;
  totalLength: any;
  spinnerFlag = true;
  intervalEvent: any;
  transactionStatus = TRANSACTION_STATUS;
  count = 0;
  importDeletionIsInProgress = false;
  canCancelImport = false;
  isCancellingImport = false;

  progressResponseNull = false;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  latestInProgressFileWithDeadlineId = null;

  constructor(
    private router: Router,
    private dataService: DataImportService,
    private commonUtils: CommonUtilsService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    this.spinValue = 23;
  }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 1207) ? 9 : 12;
    this.getImportedFiles();
  }

  ngOnDestroy() {
    if (this.intervalEvent !== undefined) {
      this.intervalEvent.unsubscribe();
    }
  }

  getImportedFiles() {
    if (this.intervalEvent) {
      this.intervalEvent.unsubscribe();
    }

    this.dataService.getUploadedFileList().pipe(map((data: any) =>
      data.Result.sort((a, b) => new Date(b.uploadTimeStamp).getTime() - new Date(a.uploadTimeStamp).getTime())))
      .subscribe((response: any) => {
        this.uploadedFiles = response;

        this.trimUploadadedFileNames();
        this.updateFileAggregations();

        this.totalLength = this.uploadedFiles.length;
        this.dataSource = new MatTableDataSource(this.uploadedFiles);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        if (this.count > 0) {
          this.spinnerFlag = false;
        }
        if (response.length === 0) {
          this.spinnerFlag = false;
        }

        this.getFileProgress();
        this.intervalEvent = interval(10000).subscribe(x => {
          this.getFileProgress();
        });
      }, (error) => {
        this.spinnerFlag = false;
        this.commonUtils.errorSnackBar('error', '');
      });
  }

  getFileProgress() {
    let apiCount = 0;
    this.dataService.getFileProgressList().subscribe((Response: any) => {
      if (!this.count) {
        this.spinnerFlag = false;
      }

      this.count++;

      if (Response.Result.length && this.count) {
        if (Response.Result.length !== 0) {
          Response.Result.forEach(progressFile => {
            this.uploadedFiles.forEach(file => {
              if (file.id === progressFile.id) {
                if (progressFile.deadlineId) {
                  file.deadlineId = progressFile.deadlineId;
                }
                file.status = progressFile.status;
                file.statusId = progressFile.statusId;
                file.progress = progressFile.progress;
              }
            });
          });

          const existingFileIds = this.uploadedFiles.map(file => file.id);
          const newUploadedFiles = Response.Result.filter(file => !existingFileIds.includes(file.id));
          if (newUploadedFiles.length > 0) {
            newUploadedFiles.sort((a, b) => new Date(b.uploadTimeStamp).getTime() - new Date(a.uploadTimeStamp).getTime());
            newUploadedFiles.forEach(file => {
              file.transferType = (file.transferType || '').trim();
              const interfaceType = this.interfaceTypes.filter(interfase => interfase.name.trim() === file.transferType);
              if (interfaceType.length > 0) {
                file.interfaceType = interfaceType[0].value;
              }
            });

            this.uploadedFiles.splice(0, 0, ...newUploadedFiles);
            this.totalLength = this.uploadedFiles.length;
          }
          this.updateFileAggregations();
        }
      } else {
        // When we get an empty progress list, request a complete file list to ensure that all data is synced to current backend data
        if (apiCount === 0) {
          this.progressResponseNull = true;
          this.intervalEvent.unsubscribe();
          // getFiles
          this.dataService
            .getUploadedFileList()
            .pipe(map((data: any) =>
              // sort files by uploadTimestamp descending
              data.Result.sort((a, b) => new Date(b.uploadTimeStamp).getTime() - new Date(a.uploadTimeStamp).getTime())),
            )
            .subscribe((response: any) => {
              apiCount++;
              this.uploadedFiles = response;

              this.trimUploadadedFileNames();
              this.updateFileAggregations();

              this.totalLength = this.uploadedFiles.length;
              this.dataSource = new MatTableDataSource(this.uploadedFiles);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              if (this.count > 0) {
                this.spinnerFlag = false;
              }
              if (response.length === 0) {
                this.spinnerFlag = false;
              }
            });
        }
      }
    }, (error) => {
      this.spinnerFlag = false;
      this.commonUtils.errorSnackBar('error', '');
    });
    this.dataSource = new MatTableDataSource(this.uploadedFiles);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 1207) ? 9 : 12;
  }

  navigateToNewDataImport() {
    this.router.navigateByUrl('bv-home/new-dataimport');
  }

  isUserNameNull(name) {
    return (name === null || name === '');
  }

  getLocalTime(value) {
    return moment.utc(value).local().format('DD.MM.YYYY HH:mm:ss');
  }

  private updateFileAggregations() {
    if (this.uploadedFiles.length) {
      this.latestInProgressFileWithDeadlineId = this.uploadedFiles.find(file => (file.statusId === this.transactionStatus.InProgress && file.deadlineId)) || null;
    } else {
      this.latestInProgressFileWithDeadlineId = null;
    }

    this.updateImportCancleableStatus();
    this.updateImportDeletionIsInProgress();
  }

  private updateImportCancleableStatus() {
    if (this.latestInProgressFileWithDeadlineId) {
      this.dataService.checkIfImportIsCancelable(this.latestInProgressFileWithDeadlineId.deadlineId)
        .subscribe({
          next: (result: any) => {
            this.canCancelImport = result?.Result === 'true';
          },
        });
    } else {
      this.canCancelImport = false;
    }
  }

  private updateImportDeletionIsInProgress() {
    this.importDeletionIsInProgress = this.uploadedFiles.some(file => (file.statusId === this.transactionStatus.ReadyToDelete || file.statusId === this.transactionStatus.Deleting));
  }

  cancelImport() {
    if (!this.isCancellingImport && this.latestInProgressFileWithDeadlineId?.deadlineId) {
      this.isCancellingImport = true;

      const dialogRef = this.dialog.open(DataConfirmCancelImportDialogComponent, {
        width: '100%',
        maxWidth: '600px',
      });
      dialogRef.afterClosed()
        .subscribe((res) => {
          if (res) {
            this.dataService.cancelImport(this.latestInProgressFileWithDeadlineId.deadlineId)
              .subscribe({
                next: () => {
                  this.isCancellingImport = false;
                  this.showCancelImportSuccess();
                  this.getImportedFiles();
                },
                error: () => {
                  this.isCancellingImport = false;
                  this.showCancelImportError();
                  this.getImportedFiles();
                },
              });
          }
          this.isCancellingImport = false;
        });
    }
  }

  showCancelImportSuccess() {
    this.commonUtils.successSnackBar(this.translate.instant('cancelImportSuccess'));
  }

  showCancelImportError() {
    this.commonUtils.errorSnackBar('error', this.translate.instant('cancelImportFailure'));
  }

  trimUploadadedFileNames() {
    this.uploadedFiles.forEach(file => {
      this.interfaceTypes.forEach(interfase => {
        interfase.name = interfase.name.trim();
        if (file.transferType != null) {
          file.transferType = file.transferType.trim();
        }
        if (interfase.name === file.transferType) {
          file.interfaceType = interfase.value;
        }
      });
    });
  }
}
