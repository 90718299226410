import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from '../../../shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';

@Injectable({
  providedIn: 'root',
})
export class DataImportService {

  apiUrl = 'https://api.imgur.com/3/image';
  environment: any;
  endpoint: any;
  filesEndpoint: string;
  clientId: string;

  constructor(
    private http: HttpClient,
    private commonUtils: CommonUtilsService, private configurationservice: ConfigurationService,
    private userSessionService: UserSessionService,
  ) {
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.datenImportendpoint}/api/Files`;
    this.filesEndpoint = `${this.environment.musterkundeDevEndpoint}/api/UploadedFiles`;
    this.clientId = userSessionService.getClientId().toString();
  }

  getClientIdHeader(password = null, key = null, name = null, confirmKey = null) {
    const headers: any = {};
    if (password) {
      headers.zip_key = password;
    }
    if (key) {
      headers.anonymous_key = key;
    }
    if (name) {
      headers.zipFileName = name;
    }
    if (confirmKey) {
      headers.confirm_anonymous_key = confirmKey;
    }
    return {headers: new HttpHeaders(headers)};
  }

  createHeaderObject() {
    return this.commonUtils.getHeaderObject();
  }

  getUploadedFiles() {
    const url = `https://musterkundedev.azurewebsites.net/api/UploadedFiles`;
    return this.http.get(url, this.createHeaderObject());
  }

  getUploadedFileList() {
    const url = `${this.endpoint}/import-list`;
    return this.http.get(url, this.getClientIdHeader());
  }

  getFileProgressList() {
    const url = `${this.endpoint}/inprogress-import-list`;
    return this.http.get(url, this.getClientIdHeader());
  }

  uploadFiles(data) {
    const url = `${this.endpoint}/Upload`;
    return this.http.post(url, data, this.getClientIdHeader());
  }

  extractZipFiles(data, password) {
    const url = `${this.endpoint}/ExtractZipFile`;
    return this.http.post(url, data, this.getClientIdHeader(password));
  }

  transferFilesToDb(data, key) {
    const url = `${this.endpoint}/TransferToDB`;
    return this.http.post(url, data, this.getClientIdHeader(null, key));
  }

  transferFilesToDbWithMode(data, key, mode, uploadedDeadline?, deadline?, hierarchyIds?) {
    if (deadline && hierarchyIds) {
      const url = `${this.endpoint}/TransferToDBWithMode/?importMode=${mode}&uploadedDeadline=${uploadedDeadline}&deadlineToCopyFrom=${deadline}&hierarchyIdsToCopy=${hierarchyIds}`;
      return this.http.post(url, data, this.getClientIdHeader(null, key));
    } else {
      const url = `${this.endpoint}/TransferToDBWithMode/?importMode=${mode}&uploadedDeadline=${uploadedDeadline}`;
      return this.http.post(url, data, this.getClientIdHeader(null, key));
    }
  }

  validateTransferToDB(data, key, confirmKey) {
    const url = `${this.endpoint}/ValidateTransferToDB`;
    if (confirmKey) {
      return this.http.post(url, data, this.getClientIdHeader(null, key, null, confirmKey));
    }
    return this.http.post(url, data, this.getClientIdHeader(null, key));
  }

  deleteZipFile(name) {
    const url = `${this.endpoint}/DeleteZipFile`;
    return this.http.delete(url, this.getClientIdHeader(null, null, name));
  }

  checkIfClientExists() {
    const url = `${this.endpoint}/ClientExists`;
    return this.http.get(url, this.getClientIdHeader());
  }

  checkClientIdExistIfNotCreate() {
    const url = `${this.endpoint}/CheckClientIdExistIfNotCreate`;
    return this.http.get(url, this.getClientIdHeader());
  }

  checkIfImportIsCancelable(deadlineId: any) {
    return this.http.get(`${this.filesEndpoint}/cancellationPossible/${deadlineId}`, this.getClientIdHeader());
  }

  cancelImport(deadlineId: any) {
    return this.http.post(`${this.filesEndpoint}/cancelImport/${deadlineId}`, this.getClientIdHeader());
  }
}
