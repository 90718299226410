<!--main content-->
<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="2" rowHeight="70px">
    <mat-grid-tile flex-start flex-align-top class="grid-spacing">
      <h1 class="page-title">
        <span class="">{{'settings' | translate}}</span>
        <div class="w-px-500">
          {{'user' | translate}} > {{'editUserLabel' | translate}}
        </div>
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>
  <!-- End: Page Heading -->
  <!-- New Mailn content -->


  <mat-card class="m-t-50 m-b-50 p-t-30" >
    <mat-card-content>
      <mat-grid-list cols="12" rowHeight="530px">

        <mat-grid-tile flex-start flex-align-top [colspan]="6">
          <mat-grid-list cols="12" rowHeight="70px"  class="w-100">


            <form  [formGroup]="userForm" novalidate >
              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <b>{{'userInfo' | translate}}</b>
              </mat-grid-tile>


              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'firstName' | translate}}</mat-label>
                  <input matInput placeholder="Vorname" formControlName="firstName" name="firstName">
                  <mat-error *ngIf="hasError('firstName', 'required')">{{'firstName' | translate}}
                    {{'isRequired' | translate}}</mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'lastName' | translate}}</mat-label>
                  <input matInput placeholder="Nachname" formControlName="lastName" name="lastName">
                  <mat-error *ngIf="hasError('lastName', 'required')">{{'lastName' | translate}}
                    {{'isRequired' | translate}}</mat-error>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'userEmail' | translate}}</mat-label>
                  <input matInput placeholder="E- Mail-Adresse" formControlName="email" name="email">
                  <mat-error *ngIf="hasError('email', 'required')">{{'userEmail' | translate}}
                    {{'isRequired' | translate}}
                  </mat-error>
                  <mat-error *ngIf="hasError('email', 'email')">{{'userEmail' | translate}}
                    {{'validEmail' | translate}}
                  </mat-error>
                </mat-form-field>
              </mat-grid-tile>

              <mat-grid-tile class="grid-spacing" flex-start flex-align-top [colspan]="12">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'userName' | translate}}</mat-label>
                  <input matInput placeholder="Benutzername" formControlName="userName" name="userName">
                  <mat-error *ngIf="hasError('userName', 'required')">{{'userName' | translate}}
                    {{'isRequired' | translate}}</mat-error>
                </mat-form-field>
              </mat-grid-tile>
            </form>

            <mat-grid-tile class="grid-spacing" flex-start flex-col flex-align-top [colspan]="12">

              <div class="d-flex align-center">
                <span class="d-flex w-100 m-b-5">
                  <b> {{'roleLabel' | translate}}</b>
                </span>
                <span class="d-flex w-100 align-center">
                  <span>
                    <div class="custom-grid">
                      <mat-checkbox class="custom-checkbox" (change)="roleCheckedEvent()" *ngFor="let role of roles" [(ngModel)]="role.checked"
                        class="m-r-5">
                        {{role.Name}}</mat-checkbox>
                    </div>
                  </span>
                </span>
              </div>
            </mat-grid-tile>

          </mat-grid-list>

        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>

    <!-- actions -->
    <mat-card-actions class="m-b-5">
      <mat-grid-list cols="12" rowHeight="80px">
        <mat-grid-tile flex-start [colspan]="9" class="grid-spacing top-10">


          <span>
            <button mat-raised-button color="basic" class="m-l-10"
              (click)="navigateToUserList()">{{'back' | translate}}</button>

            <button mat-raised-button class="custom-button m-l-20" [disabled]='disableForm()' (click)="updateUser()">
              {{'save' | translate}}
            </button>
          </span>
        </mat-grid-tile>
        <mat-grid-tile flex-start [colspan]="3" class="grid-spacing top-10">
          <button mat-raised-button class="custom-button-reverse m-t-5 m-b-20 m-l-5 btn-border"
            (click)="lockOrUnlockUser()">
            {{benutzer.IsDeleted ? ('unlockUser' | translate) : ('lockUser' | translate) }}
          </button>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-actions>
  </mat-card>
  <div class="spinner" *ngIf="spinnerFlag">
    <mat-spinner class="custom-field" mode="indeterminate"></mat-spinner>
  </div>
