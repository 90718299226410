<mat-sidenav class="custom-right-sidebar m-t-50" #rightSidenav mode="side" [fixedInViewport]="true" [fixedTopGap]="250">
  <div *ngIf="getType() == '2'">
    <h3 class="sidenav-title">{{'filterHouseholds/persons' | translate}}</h3>
    <div class="checkbox-item" *ngFor="let filter of potentialAnalysisFilters">
      <mat-checkbox class="custom-checkbox" [(ngModel)]="filter.select" (change)="filterCheckedEvent()">
        {{filter.name}}
      </mat-checkbox>
      <!-- 1. musterkundegruppen -->

      <div *ngIf="filter.select && filter.id == 1" class="dropdown">
        <div class="custom-chips">
          <mat-chip *ngFor="let group of filter.values" [selectable]="selectable" [removable]="removable"
                    (removed)="removeSelectedElement(filter.id,group.id)">
            {{group.name}}
            <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </div>
        <div>
          <mat-form-field class="w-100 side-bar-select">
            <mat-select multiple [(ngModel)]="filter.values" name=group panelClass="example-panel"
                        class="display-notext" placeholder="{{'sampleCustomerGroupChoose' | translate}}">
              <div class="dropdown">
                <mat-option *ngFor="let group of musterkundeGruppen" [value]="group">
                  {{group.name}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <section class="slide-toggle">
            <mat-slide-toggle [checked]="excludeMusterkundeGruppenInCalculation"
                              (change)="toggleMusterkundeGroupIdNegation()"
                              matTooltip="Ausgewählte Musterkundengruppen ausschließen">
              <p class="section-p">{{'excludeSelectedCustomerGroup' | translate}}</p>
            </mat-slide-toggle>
          </section>
        </div>
      </div>


      <!-- 2. vermogen -->
      <div *ngIf="filter.select && filter.id == 2">
        <div>
          <mat-form-field class="w-100 side-bar-select">
            <mat-select panelClass="example-panel" multiple [(ngModel)]="vermogen" name=vermogen
                        placeholder="Für Auswahl hier klicken ..." (selectionChange)="selectVermogen($event)">
              <mat-option *ngFor="let vermogen of vermogenList" [value]="vermogen">
                {{vermogen.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="m-b-20" *ngFor="let value of filter.values">
          <div class="slider-label">
            <mat-label>{{value.name}}</mat-label>
          </div>
          <!-- slider -->
          <div class="vermogen-div" *ngIf="value.isSlider">
            <ng5-slider [(value)]="value.minValue" class="slider-icon m-t-10 slider-div" [(highValue)]="value.maxValue"
                        [options]="value.options">
            </ng5-slider>
          </div>

          <!-- inputs -->
          <div class="vermogen-div" *ngIf="!value.isSlider">
            <span>
              <mat-label class="min-max-label">Von</mat-label>
              <mat-form-field class="form-field form-field-width" appearance="outline"
                              [ngClass]="{'error-form-field' : value.error}">
                <input matInput [(ngModel)]="value.minValue" (change)="compareVermogenInputValues($event,value, 'min')"
                       onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57"
                       name="firstName">
              </mat-form-field>

              <mat-label class="min-max-label m-l-10">bis</mat-label>

              <mat-form-field class="form-field form-field-width" appearance="outline"
                              [ngClass]="{'error-form-field' : value.error}">
                <input matInput [(ngModel)]="value.maxValue" (change)="compareVermogenInputValues($event,value, 'max')"
                       onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57"
                       name="firstName">
              </mat-form-field>

              <!-- <mat-label class="min-max-label m-l-10">bis {{value.maxValue}} {{value.minValue}}</mat-label>
              <mat-form-field class="form-field form-field-width" appearance="outline"
                [ngClass]="{'error-form-field' : value.error}">
                <input (change)="compareVermogenInputValues($event,value, 'max')"
                matInput [(ngModel)]="value.maxValue" type="number" name="firstName">
              </mat-form-field> -->
              <!-- onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 46 && event.charCode <= 57" -->
            </span>
          </div>

          <div>
            <span>
              <button mat-raised-button class="m-b-20 m-r-10" (click)="sliderSelectionVermogen(value)"
                      [ngClass]="{'custom-button' : value.isSlider, 'custom-button-reverse' : !value.isSlider}">Sliderauswahl</button>
              <button mat-raised-button class="m-b-20" (click)="directEntryVermogen(value)"
                      [ngClass]="{'custom-button-reverse' : value.isSlider,  'custom-button': !value.isSlider}">Direkteingabe</button>
            </span>
          </div>

        </div>

      </div>
      <!-- 3. Area status -->
      <div *ngIf="filter.select && filter.id == 3">

        <div class="custom-chips">
          <mat-chip *ngFor="let area of filter.values" [selectable]="selectable" [removable]="removable"
                    (removed)="removeSelectedElement(filter.id, area.id)">
            {{area.name}}
            <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </div>

        <div>
          <mat-form-field class="w-100 side-bar-select">
            <mat-select panelClass="example-panel" multiple [(ngModel)]="filter.values" name=areaStatus
                        class="display-notext" placeholder="Für Auswahl hier klicken ..." I>
              <mat-option *ngFor="let area of areaStatusList" [value]="area">
                {{area.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <!-- 4. property status -->
      <div *ngIf="filter.select && filter.id == 4">

        <div class="custom-chips">
          <mat-chip *ngFor="let property of filter.values" [selectable]="selectable" [removable]="removable"
                    (removed)="removeSelectedElement(filter.id, property.id)">
            {{property.name}}
            <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </div>

        <div>
          <mat-form-field class="w-100 side-bar-select">
            <mat-select panelClass="example-panel" multiple [(ngModel)]="filter.values" name="propertStatus"
                        class="display-notext" placeholder="Für Auswahl hier klicken ..." I>
              <mat-option *ngFor="let property of propertyStatusList" [value]="property">
                {{property.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- 5. Kontonutzung -->
      <div *ngIf="filter.select && filter.id == 5">
        <div class="custom-chips">
          <mat-chip *ngFor="let account of filter.values" [selectable]="selectable" [removable]="removable"
                    (removed)="removeSelectedElement(filter.id, account.id)">
            {{account.type}}
            <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </div>

        <div>
          <mat-form-field class="w-100 side-bar-select">
            <mat-select panelClass="example-panel" multiple [(ngModel)]="filter.values" name="accontUsage"
                        class="display-notext" placeholder="Für Auswahl hier klicken ...">
              <mat-option *ngFor="let account of accountUsageList" [value]="account">
                {{account.type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- 6. Risikoneigung -->
      <div *ngIf="filter.select && filter.id == 6">
        <div class="custom-chips">
          <mat-chip *ngFor="let class of filter.values" [selectable]="selectable" [removable]="removable"
                    (removed)="removeSelectedElement(filter.id, class.id)">
            {{class.description}}
            <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </div>

        <div>
          <mat-form-field class="w-100 side-bar-select">
            <mat-select panelClass="example-panel" multiple [(ngModel)]="filter.values" name="riskClass"
                        class="display-notext" placeholder="Für Auswahl hier klicken ...">
              <mat-option *ngFor="let riskClass of riskClassesList" [value]="riskClass">
                {{riskClass.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Additional filters -->
    </div>

    <!-- additional filters -->


    <div class="checkbox-item" *ngFor="let filter of selectedAdditionalFilterList">
      <!-- additional filters checkboxes -->
      <div class="main">
        <div class="child-div1" *ngIf="filter.addInList">
          <mat-checkbox class="custom-checkbox" [(ngModel)]="filter.select"
                        (change)="additionalFilterCheckedEvent($event, filter)">
            {{filter.name}}
          </mat-checkbox>
          <span class="icon">
            <i *ngIf="filter.addInList" class="material-icons" (click)="removeAdditionalFilter(filter)">
              clear
            </i>
          </span>
          <!-- additional filter options -->

          <!-- 1. -->
          <div class="p-t-10"
               *ngIf="(filter.matchValue == 'oldestPersonInHousehold') || (filter.matchValue == 'customerSegmentBank') || (filter.matchValue == 'noOfPersonInHouse') || (filter.matchValue == 'noOfAdultsInHouse') || (filter.matchValue == 'noOfMinorsInHouse') || (filter.matchValue == 'purchasingPower')">

            <div class="p-b-10">
              <mat-button-toggle-group name="toggle" #group="matButtonToggleGroup"
                                       value="!filter.options.selectOneElement">
                <mat-button-toggle value="!filter.options.selectOneElement" (click)="selectRange(filter)">
                  {{'selectValueRange' | translate}}</mat-button-toggle>
                <mat-button-toggle value="filter.options.selectOneElement" (click)="selectOneElement(filter)">
                  {{'selectIndividualValue' | translate}}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <!---------------------------------------------- one element --------------------->
            <div *ngIf="filter.options.selectOneElement">
              <ng5-slider *ngIf="filter.options.selectOneElement" [(value)]="filter.minValue"
                          class="slider-icon-no-range" [options]="filter.options">
              </ng5-slider>
            </div>
            <!--------------------------------------------- range selectioon---------------- -->
            <div *ngIf="!filter.options.selectOneElement">
              <ng5-slider [(value)]="filter.minValue" class="slider-icon" [(highValue)]="filter.maxValue"
                          [options]="filter.options">
              </ng5-slider>
            </div>
          </div>

          <!-- 2. -->
          <div class="custom-chips" *ngIf="filter.matchValue == 'ratingHousehold'">
            <mat-chip *ngFor="let class of filter.values" [selectable]="selectable" [removable]="removable"
                      (removed)="removeInnerAdditionalChip(filter.id, class)">
              {{class}}
              <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </div>


          <div class="p-t-10" *ngIf="filter.matchValue == 'ratingHousehold'">
            <mat-form-field class="w-100 side-bar-select">
              <mat-select panelClass="example-panel" multiple [(ngModel)]="filter.values" name=group
                          class="display-notext" placeholder="Für Auswahl hier klicken ...">
                <div class="dropdown">
                  <mat-option *ngFor="let rating of ratingsList" [value]="rating.value">
                    {{rating.text}}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>

          </div>

          <!-- 3 -->
          <!-- <div class="p-t-10"
						*ngIf="">
						<ng5-slider *ngIf="filter.select" [(value)]="filter.value" class="slider-icon-no-range"
							[options]="filter.options">
						</ng5-slider>
					</div> -->

          <!-- 4 -->
          <div class="custom-chips"
               *ngIf="(filter.matchValue == 'customerSegmentVRCP') || (filter.matchValue == 'customerSegmentBVR')">
            <mat-chip *ngFor="let class of filter.values" [selectable]="selectable" [removable]="removable"
                      (removed)="removeInnerAdditionalChip(filter.id, class.shl_num)">
              {{class.shl_name}}
              <mat-icon matChipRemove class="icon" *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </div>


          <div class="p-t-10"
               *ngIf="(filter.matchValue == 'customerSegmentVRCP') || (filter.matchValue == 'customerSegmentBVR')">
            <mat-form-field class="w-100 side-bar-select ">
              <mat-select panelClass="example-panel" multiple [(ngModel)]="filter.values" name=group
                          class="display-notext" placeholder="Für Auswahl hier klicken ...">
                <div class="dropdown">
                  <mat-option *ngFor="let value of filter.select_options" [value]="value">
                    {{value.shl_name}}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>

          </div>

          <!-- 5 -->
          <div class="p-t-10 m-l-30" *ngIf="(filter.matchValue == 'datesPast') || (filter.matchValue == 'datesFuture')">
            <mat-label class="grey-text">
              {{'selectionArea' | translate}}
            </mat-label>
            <div>
              <span>
                <mat-form-field class="form-field" appearance="outline" [ngClass]="{'error-form-field' : filter.error}">
                  <mat-label>{{'min' | translate}}</mat-label>
                  <input matInput [(ngModel)]="filter.minValue" (change)="pastDateInputEvent($event, 'min', filter)"
                         onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                         placeholder="min" type="number" min="0" name="firstName">
                </mat-form-field>
                <mat-form-field class="form-field" appearance="outline" [ngClass]="{'error-form-field' : filter.error}">
                  <mat-label>{{'max' | translate}}</mat-label>
                  <input matInput placeholder="max" [(ngModel)]="filter.maxValue" type="number"
                         (change)="pastDateInputEvent($event, 'max' , filter)" min="filter.minValue"
                         onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                         name="firstName">
                </mat-form-field>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- addtional filter dropdown -->
    <div class="cst-link">
      <span>
        <a (click)="select.open()"> {{'moreFilters' | translate}}</a>
        <!--        <a *ngIf="showResetFilter()" (click)="resetAllFilters()">{{'resetAllFilters' | translate}}</a>-->
      </span>
      <mat-select panelClass="example-panel" #select class="additional-filter-arrow" [(ngModel)]="additionalFilter"
                  (selectionChange)="selectAdditionalFilter($event.value)" placeholder="Für Auswahl hier klicken ...">
        <mat-option [value]="filter" *ngFor="let filter of additionalFilters">{{filter.name}}</mat-option>
      </mat-select>
    </div>
    <span>
      <button mat-raised-button color="basic" class="m-l-40" (click)="close(false)">{{'stop' | translate}}</button>
      <button mat-raised-button class="custom-button m-l-20"
              (click)="addFilters()">{{'reCalculate' | translate}}</button>
    </span>
  </div>

  <!-- profit center -->

  <div *ngIf="getType() == '1'">
    <h3 class="sidenav-title">{{'selectProfitCenter' | translate}} </h3>
    <mat-label class="profit-center">{{'salesStructure' | translate}} </mat-label>

    <div class="d-flex flex-row">
      <mat-form-field class="profit-center" appearance="outline" class="w-75">
        <!-- <mat-select panelClass="example-panel-organization" placeholder="{{'VertriebsstrukturWahlen' | translate}}"
                    [(ngModel)]="organisation" required (selectionChange)="getSalesOrganisation(organisation)"
                    placeholder="Für Auswahl hier klicken ...">
          <mat-option value="branchOrg">{{'branchStructure' | translate}} </mat-option>
          <mat-option value="consultantOrg">{{'consultantStructure' | translate}}</mat-option>
        </mat-select> -->

        <mat-select panelClass="example-panel-organization" placeholder="{{'VertriebsstrukturWahlen' | translate}}"
              [(ngModel)]="organisation" required (ngModelChange)="onSalesOrganistationChanged($event)"
              placeholder="Für Auswahl hier klicken ...">
          <mat-option value="branchOrg">{{'branchStructure' | translate}} </mat-option>
          <mat-option value="consultantOrg">{{'consultantStructure' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <div *ngIf="organisation == 'consultantOrg'" class="d-flex flex-row m-l-10" >
          <mat-form-field class="m-l-10 m-b-0" appearance="outline">
            <mat-label>{{'hierarchy' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedHierarchy" (ngModelChange)="onHierarchySelectionChanged($event)" [disabled]="hierarchies.length == 0">
              <mat-option *ngFor="let hierarchy of hierarchies" [value]="hierarchy">{{hierarchy.hierarchyDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    </div>
    
          

    <div class="profit-center" *ngIf="spinnerFlag" class="m-l-70">
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>

    <mat-radio-group aria-labelledby="example-radio-group-label"
                     class="example-radio-group"
                     [(ngModel)]="selectedNode"
                     (change)="onChange($event)">

      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <mat-radio-button [value]="node.id">{{node.name}}</mat-radio-button>
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <li>
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-radio-button [value]="node.id">{{node.name}}</mat-radio-button>
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
    </mat-radio-group>

    <span>
      <div class="m-l-40 p-b-15 ">{{houseHoldCount}} {{'keepHouse' | translate}} / {{privatePersonCount}}
        {{'individuals' | translate}}</div>

      <button mat-raised-button color="basic" class="m-l-40 m-b-20"
              (click)="clearProfitFilter()">{{'stop' | translate}}</button>
      <button mat-raised-button class="custom-button m-l-20 m-b-20"
              (click)="organisation && addProfileFilters()">{{'reCalculate' | translate}}</button>
    </span>

    <a class="reset-filter" (click)="clearProfitCenterFilter()"> {{'resetFilter' | translate}}</a>
  </div>
</mat-sidenav>
