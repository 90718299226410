import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { UserSessionService } from '../../../user-profile/services/user-session.service';
@Injectable({
  providedIn: 'root'
})
export class KalkulationService {

  environment: any;
  endpoint: any;
  clientId: any;
  calculationEndpoint: any;
  calculationDeadlineEndPoint: any;
  hierarchyEndpoint: any;

  getClientIdHeader(deadline = null, deadlineId = null) {
    const headers: any = {};
    if (deadline) {
      headers.deadline = deadline;
    }
    if (deadlineId) {
      headers.deadlineId = deadlineId;
    }
    return { headers: new HttpHeaders(headers) };
  }

  getClientHeader() {
    const headers: any = {
      'clientId': this.clientId
    };
    return { headers: new HttpHeaders(headers) };

  }
  constructor(private http: HttpClient,
    private commonUtils: CommonUtilsService, private configurationservice: ConfigurationService,
    private userSessionService: UserSessionService) {
    this.clientId = this.userSessionService.getClientId().toString();
    this.environment = this.configurationservice.getEnvironmentVariables();
    this.endpoint = `${this.environment.musterkundeDevEndpoint}/api/Deadlines`;
    this.calculationEndpoint = `${this.environment.musterkundeDevEndpoint}/api/Calculation`;
    this.calculationDeadlineEndPoint = `${this.environment.musterkundeDevEndpoint}/api/CalculationDeadlines`;
    this.hierarchyEndpoint = `${this.environment.musterkundeDevEndpoint}/api/Hierarchies`;
  }

  getAllDeadlines() {
    const url = `${this.endpoint}`;
    return this.http.get(url, this.getClientIdHeader());
  }

  getDeadlines() {
    const url = `${this.endpoint}/ForCalculation`;
    return this.http.get(url, this.getClientIdHeader());
  }

  getHierarchiesByDeadline(deadlineDate) {
    const url = `${this.hierarchyEndpoint}/?deadlineDate=${deadlineDate}`;
    return this.http.get(url, this.getClientIdHeader());
  }


  /* includeCalculationDeadlineFiles = 1 then it will include CalculationDeadlines,
   CalculationDeadlineFiles, UploadedFile & TransferType object.
   else it will return only deadlines object.*/
  getKalkulationFileByDeadLineId(id, includeCalculationDeadlineFiles) {
    const url = `${this.endpoint}/${id}/${includeCalculationDeadlineFiles}`;
    return this.http.get(url);
  }

  validatePushCalculation(deadline) {
    const url = `${this.calculationEndpoint}/ValidatePushCalculation`;
    return this.http.get(url, this.getClientIdHeader(deadline));
  }

  calculateDeadline(deadline, data) {
    const url = `${this.calculationEndpoint}/PushCalculation`;
    return this.http.post(url, data, this.getClientIdHeader(deadline));
  }

  canCalculationStarted(deadline) {
    const url = `${this.calculationEndpoint}/CanCalculationStart`;
    return this.http.get(url, this.getClientIdHeader(deadline));
  }

  getCalculationDeadlines(deadlineId) {
    const url = this.calculationDeadlineEndPoint;
    return this.http.get(url, this.getClientIdHeader(null, deadlineId.toString()));
  }

  getNewestDeadline() {
    const url = `${this.endpoint}/newest`;
    return this.http.get(url, this.getClientIdHeader  ());
  }

  deleteKalkulation(id){
    const url = `${this.endpoint}/CalculationDeadlineFiles/${id}`;
    return this.http.delete(url);
  }

  deleteKalulationDeadlines(id, isCancel: boolean = false){
    const url = `${this.calculationDeadlineEndPoint}/${id}?isCancel=${String(isCancel)}`;
    return this.http.delete(url);

  }

  deleteDeadline(id){
    const url = `${this.endpoint}/deleteCalculations/${id}`;
    return this.http.delete(url);
  }
}
