import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  get(key) {
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;
  }

  set(key, value) {
    const data = JSON.stringify(value);
    if (data) {
      sessionStorage.setItem(key, data);
    }
  }

  remove(key){
    sessionStorage.removeItem(key);
  }

  clearAll() {
    sessionStorage.clear();
  }
}
