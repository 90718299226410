<div >
    <div>
      <h3 class="fw-500">
      <span *ngIf="type == 'delete'">  {{'deleteKalkulationModalHeading' | translate}}</span>
      <span *ngIf="type == 'stop'">  {{'stopKalkulationModalHeading' | translate}}</span>
      </h3>
      
    </div>
  

  
    <div>
      <div class="calculation-list">

         <p *ngIf="type == 'delete'">{{'shouldTheCalculation' | translate}} {{calculation.kalkulationNumber}} {{'reportingDate' | translate}} {{parent.deadlineDate  | dateFormat : 'dd/MM/yyyy'}} {{'toBeDeleted' | translate}} </p>
         <p *ngIf="type == 'stop'">{{'shouldTheCalculation' | translate}} {{calculation.kalkulationNumber}} {{'reportingDate' | translate}} {{parent.deadlineDate  | dateFormat : 'dd/MM/yyyy'}} {{'toBeStop' | translate}}</p>
      </div>
   
  
      <div mat-dialog-actions class="m-t-20 btn-center"  >
        <button mat-raised-button  class="btn-outline" (click)="closeDialog(false)">{{'stop' | translate}}</button>
        <button mat-raised-button cdkFocusInitial class="custom-button m-l-10" (click)="closeDialog(true)">{{'continue' | translate}}</button>
    
        <!-- <button mat-raised-button color=" "  (click)="closeDialog()">{{'continue' | translate}}</button>
        <button mat-raised-button color=" "  (click)="closeDialog()">{{'stop' | translate}}</button> -->
      </div>
    </div>
  
  
  </div>