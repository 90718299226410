import { Component, OnInit } from '@angular/core';
import { PotentialAnalyseService } from 'src/app/benutzer-verwaltung/v-musterkunde/services/potential-analyse.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesOrganisationService } from '../../service/sales-organisation.service';

@Component({
  selector: 'app-add-edit-zweigstellen',
  templateUrl: './add-edit-zweigstellen.component.html',
  styleUrls: ['./add-edit-zweigstellen.component.scss'],
})
export class AddEditZweigstellenComponent implements OnInit {

  spinnerFlag = true;
  regionTypes: any[] = [];
  regionTypeFilters: any[] = [];
  nodeForm: FormGroup;
  isEdit: boolean;
  profitCenterId: number;
  currentDepartment: any;
  disabledNumber: number;
  canAddRegion: boolean;

  constructor(
    private fb: FormBuilder,
    private potentialAnalysisService: PotentialAnalyseService,
    private salesOrganisationService: SalesOrganisationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    // ignore route param and only allow editing
    this.isEdit = this.route.snapshot.paramMap.get('edit') === 'true';
    this.profitCenterId = +(this.route.snapshot.paramMap.get('id'));
    this.canAddRegion = this.route.snapshot.paramMap.get('canAddRegion') === 'true';
    this.initForm();
    this.fetchAllData();
  }

  fetchDepartment(id) {
    this.salesOrganisationService.getProfitCenterById(this.profitCenterId).subscribe((response: any) => {
      this.currentDepartment = response.Result;
      if (this.currentDepartment.profitCenterRegionStructureId == 1) {
        this.regionTypes = [{
          id: 1,
          name: 'Kreditinstitut',
          typeId: 1,
        }];
      }
      this.setFormData();
    });
  }

  setFormData() {
    this.nodeForm.controls['descriptionDepartment'].setValue(this.currentDepartment.descriptionDepartment);
    this.nodeForm.controls['profitCenterRegionStructureId'].setValue(this.currentDepartment.profitCenterRegionStructureId);
    this.nodeForm.controls['departmentNumber'].setValue(+this.currentDepartment.departmentNumber);
    this.nodeForm.controls['parentProfitCenterId'].setValue(this.currentDepartment.parentProfitCenterId);
    this.nodeForm.controls['departmentNumber'].disable();
    this.disabledNumber = this.currentDepartment.departmentNumber;
  }

  fetchAllData() {
    const req1 = this.potentialAnalysisService.getRegionTypes();
    const req2 = this.potentialAnalysisService.getRegionFilters();
    const arr = [req1, req2];
    if (this.isEdit) {
      const req3 = this.salesOrganisationService.getProfitCenterById(this.profitCenterId);
      arr.push(req3);
    }
    forkJoin(arr).subscribe((responses: any) => {
      this.regionTypes = responses[0].Result;
      this.regionTypeFilters = responses[1].Result;
      this.regionTypes.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.regionTypeFilters.sort((a, b) => {
        if (a.descriptionDepartment < b.descriptionDepartment) {
          return -1;
        }
        if (a.descriptionDepartment > b.descriptionDepartment) {
          return 1;
        }
        return 0;
      });
      if (this.isEdit) {
        this.currentDepartment = responses[2].Result;
        if (this.currentDepartment.profitCenterRegionStructureId == 1) {
          this.regionTypes = [{
            id: 1,
            name: 'Kreditinstitut',
            typeId: 1,
          }];
        }
        this.setFormData();
      } else {
        if (this.canAddRegion) {
          // only allow creation of regions
          this.nodeForm.controls['profitCenterRegionStructureId'].setValue(2);
          this.nodeForm.controls['profitCenterRegionStructureId'].disable();
        } else {
          // only allow creation of branch offices
          this.nodeForm.controls['profitCenterRegionStructureId'].setValue(3);
          this.nodeForm.controls['profitCenterRegionStructureId'].disable();
        }
      }
      this.spinnerFlag = false;
    });

  }

  initForm() {
    this.nodeForm = this.fb.group({
      descriptionDepartment: new FormControl('', [Validators.required]),
      profitCenterRegionStructureId: new FormControl('', [Validators.required]),
      departmentNumber: new FormControl('', [Validators.min(1), Validators.max(999)]),
    });
    if (this.isEdit) {
      this.nodeForm.addControl('parentProfitCenterId', new FormControl('', [Validators.required]));
    }
  }

  abort() {
    this.router.navigateByUrl('bv-home/sales-organization-list/true?consultantOrganisation=false');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.nodeForm.controls[controlName].hasError(errorName);
  };

  addDepartment() {
    this.spinnerFlag = true;
    const departmentObj: any = {
      descriptionDepartment: this.nodeForm.value.descriptionDepartment,
      profitCenterRegionStructureId: this.nodeForm.value.profitCenterRegionStructureId,
    };
    departmentObj.organisationUnitId = '0';
    departmentObj.shortDescription = '';
    if (!this.canAddRegion && !this.nodeForm.value.profitCenterRegionStructureId) {
      departmentObj.profitCenterRegionStructureId = '3';
    }
    if (!this.isEdit) {
      departmentObj.departmentNumber = (this.nodeForm.value.departmentNumber).toString();
    }
    if (!this.isEdit) {
      departmentObj.parentProfitCenterId = this.profitCenterId;
      // departmentObj.parentProfitCenterId = this.nodeForm.value.parentProfitCenterId;
      this.salesOrganisationService.addProfitCenters(departmentObj).subscribe(response => {
        this.spinnerFlag = false;
        this.router.navigateByUrl('bv-home/sales-organization-list/true?consultantOrganisation=false');
      }, err => {
        this.spinnerFlag = false;
      });
    } else {
      if (this.currentDepartment.profitCenterRegionStructureId != 1) {
        departmentObj.parentProfitCenterId = this.nodeForm.value.parentProfitCenterId;
      }
      this.salesOrganisationService.editProfitCenter(this.profitCenterId, departmentObj).subscribe((response: any) => {
        this.spinnerFlag = false;
        this.router.navigateByUrl('bv-home/sales-organization-list/true?consultantOrganisation=false');
      }, err => {
        this.spinnerFlag = false;
      });
    }
  }
}
