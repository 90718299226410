import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DeadlineHierarchiesService {

  readonly selectedDeadlineKey = "selectedDeadlineId";
  readonly selectedHierarchyKey = "selectedHierarchyId";

  constructor(private sessionStorageService: SessionStorageService) { }

  getSelectedDeadline(deadlines: any[]): any {
    if (this.sessionStorageService.get(this.selectedDeadlineKey)) {
      let foundDeadline = deadlines.find(x => x.id == this.sessionStorageService.get(this.selectedDeadlineKey));
      if (foundDeadline) {
        return foundDeadline;
      } else {
        this.sessionStorageService.set(this.selectedDeadlineKey, deadlines[0].id);
        this.sessionStorageService.remove(this.selectedHierarchyKey);
        return deadlines[0];
      }
    } else {
      let selectedDeadline = deadlines[0];
      this.sessionStorageService.set(this.selectedDeadlineKey, selectedDeadline.id);
      this.sessionStorageService.remove(this.selectedHierarchyKey);
      return selectedDeadline;
    }
  }

  getSelectedHierarchy(hierarchies: any[]): any {
    if (this.sessionStorageService.get(this.selectedHierarchyKey)) {
      return hierarchies.find(x => x.id == this.sessionStorageService.get(this.selectedHierarchyKey));
    } else {
      let selectedHierarchy = hierarchies[0];
      this.sessionStorageService.set(this.selectedHierarchyKey, selectedHierarchy.id);
      return selectedHierarchy;
    }
  }

  setSelectedDeadline(selectedDeadline: any) {
    this.sessionStorageService.set(this.selectedDeadlineKey, selectedDeadline.id);
    // when a new deadline was set, the hierachy is obsolete and must be removed
    this.sessionStorageService.remove(this.selectedHierarchyKey);
  }

  setSelectedHierarchy(selectedHierarchy: any) {
    this.sessionStorageService.set(this.selectedHierarchyKey, selectedHierarchy.id);
  }

}
