import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(date: any, args?: any): any {
    let dateFormat: any;
    if (args) {
      dateFormat = super.transform(date, args);
    } else {
      dateFormat = super.transform(date, 'dd/MM/yyyy');
    }
    return dateFormat.replace(/\//g, '.');
  }

}
