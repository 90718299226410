import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { AssetClassesService } from '../../service/asset-classes.service';
import { AssetClass } from '../../models/assetClasses';
import { CommonUtilsService } from 'src/app/shared/services/common-utils.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-assetklassen-edit',
  templateUrl: './assetklassen-edit.component.html',
  styleUrls: ['./assetklassen-edit.component.scss']
})
export class AssetklassenEditComponent implements OnInit {

  assetClass = new AssetClass();
  id: any;
  spinnerFlag = true;
  assetEditForm: FormGroup;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private assetService: AssetClassesService,
    private commonUtils: CommonUtilsService,
    private translateService: TranslateService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getAssetClassById();
    this.initForm();
  }
  setForm() {
    this.assetEditForm = new FormGroup({
      liquiditat: new FormControl(this.assetClass.liquidityPercentage, Validators.required),
      geldwerte: new FormControl(this.assetClass.shareMonetaryValue, Validators.required),
      substanzwerte: new FormControl(this.assetClass.proportionSubstanceValues, Validators.required),
      sachwerte: new FormControl(this.assetClass.shareAssets, Validators.required),
      alternativ: new FormControl(this.assetClass.shareAlternatives, Validators.required),
      gesamt: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });

    this.assetEditForm.controls['gesamt'].setValue(this.assetEditForm.get('liquiditat').value +
      this.assetEditForm.get('geldwerte').value +
      this.assetEditForm.get('substanzwerte').value +
      this.assetEditForm.get('sachwerte').value +
      this.assetEditForm.get('alternativ').value);
      this.spinnerFlag = false
  }
  initForm() {
    this.assetEditForm = new FormGroup({
      liquiditat: new FormControl('', Validators.required),
      geldwerte: new FormControl('', Validators.required),
      substanzwerte: new FormControl('', Validators.required),
      sachwerte: new FormControl('', Validators.required),
      alternativ: new FormControl('', Validators.required),
      gesamt: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    this.assetEditForm.controls['gesamt'].setValue(this.assetEditForm.get('liquiditat').value +
      this.assetEditForm.get('geldwerte').value +
      this.assetEditForm.get('substanzwerte').value +
      this.assetEditForm.get('sachwerte').value +
      this.assetEditForm.get('alternativ').value);
  }

  isValidValue(formControlName) {
    return this.assetEditForm.get(formControlName).value < 0
      || this.assetEditForm.get(formControlName).value > 100;
  }

  getTotalValue() {
    return this.assetEditForm.get('gesamt').value != 100;
  }

  ngOnInit() {
  }

  revertChanges() {
    this.assetEditForm = new FormGroup({
      liquiditat: new FormControl(this.assetClass.riskCategory.liquidityPercentage, Validators.required),
      geldwerte: new FormControl(this.assetClass.riskCategory.monetaryAssetsPercentage, Validators.required),
      substanzwerte: new FormControl(this.assetClass.riskCategory.substanceAssetsPercentage, Validators.required),
      sachwerte: new FormControl(this.assetClass.riskCategory.materialAssetsPercentage, Validators.required),
      alternativ: new FormControl(this.assetClass.riskCategory.alternativeAssetsPercentage, Validators.required),
      gesamt: new FormControl('', Validators.compose([
        Validators.required
      ])),
    });
    this.assetEditForm.controls['gesamt'].setValue(this.assetEditForm.get('liquiditat').value +
      this.assetEditForm.get('geldwerte').value +
      this.assetEditForm.get('substanzwerte').value +
      this.assetEditForm.get('sachwerte').value +
      this.assetEditForm.get('alternativ').value);

  }



  keyUpEvent() {

    const total = this.assetEditForm.get('liquiditat').value +
      this.assetEditForm.get('geldwerte').value +
      this.assetEditForm.get('substanzwerte').value +
      this.assetEditForm.get('sachwerte').value +
      this.assetEditForm.get('alternativ').value;
    this.assetEditForm.controls['gesamt'].setValue(total);
  }


  disableIfNoValueChanged() {
    if (this.assetClass.riskCategory != null) {
      if ((this.assetClass.liquidityPercentage == this.assetEditForm.get('liquiditat').value)
        && (this.assetClass.shareMonetaryValue == this.assetEditForm.get('geldwerte').value)
        && (this.assetClass.proportionSubstanceValues == this.assetEditForm.get('substanzwerte').value)
        && (this.assetClass.shareAssets == this.assetEditForm.get('sachwerte').value)
        && (this.assetClass.shareAlternatives == this.assetEditForm.get('alternativ').value)
      ) {
        return true;
      }
      if (this.assetEditForm.get('gesamt').value != 100) {
        return true;
      }
      if (this.assetEditForm.get('liquiditat').value < 0
        || this.assetEditForm.get('geldwerte').value < 0
        || this.assetEditForm.get('substanzwerte').value < 0
        || this.assetEditForm.get('sachwerte').value < 0
        || this.assetEditForm.get('alternativ').value < 0) {
        return true;
      }
    }

    return false;
  }

  saveAssetClasses() {
    this.assetClass.liquidityPercentage = this.assetEditForm.value.liquiditat;
    this.assetClass.proportionSubstanceValues = this.assetEditForm.value.substanzwerte;
    this.assetClass.shareAlternatives = this.assetEditForm.value.alternativ;
    this.assetClass.shareMonetaryValue = this.assetEditForm.value.geldwerte;
    this.assetClass.shareAssets = this.assetEditForm.value.sachwerte;
    this.assetService.editAssetClasses(this.id, this.assetClass).subscribe((response) => {
      this.router.navigateByUrl('bv-home/asset-classes');
      this.commonUtils.successSnackBar(this.translateService.instant('assetClassesUpdated'));
    }, (err) => {
      this.commonUtils.errorSnackBar('error', null);
    });
  }

  getAssetClassById() {
    this.assetService.getAssetClassesById(this.id).subscribe((Response: any) => {
      this.assetClass = Response.Result;
      this.setForm();
    });
  }

  clearForm() {
    if (!this.assetEditForm.dirty) {
      this.router.navigateByUrl('/bv-home/asset-classes');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/asset-classes');
        }
      });

    }
  }

}
