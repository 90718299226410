import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSessionService } from 'src/app/user-profile/services/user-session.service';
import { ConfigurationService } from 'src/app/configration/configuration.service';
import { headersToString } from 'selenium-webdriver/http';

@Injectable({
  providedIn: 'root'
})
export class DataExportService {
  clientId;
  environment;
  endpoint;
  constructor(private http: HttpClient,
    private userSessionService: UserSessionService,
    private configurationService: ConfigurationService) {
    this.clientId = userSessionService.getClientId().toString();
    this.environment = this.configurationService.getEnvironmentVariables();
    this.endpoint = `${this.environment.datenExportEndpoint}/api/Export`;

  }


  getExportHeader(exportId = null, depseudokey = null, password = null) {

    const headers: any = {
      'password': password,
      'exportId': exportId.toString(),
    };
    if (depseudokey) {
      headers.depseudonymisationKey = depseudokey;

    }
    return { headers: new HttpHeaders(headers) };
  }

  getZipHeader(id) {
    let headers = new HttpHeaders();
    headers = headers.append('exportId', id.toString()); // id needs to be string after update
    headers = headers.append('content-type', 'application/json');
    return headers;
  }

  getAllExportEntries() {
    const url = `${this.endpoint}/GetAllExportEntries`;
    return this.http.get(url);
  }

  startDataExport(data, exportId, pseudoId, password) {
    const url = `${this.endpoint}/StartExport`;
    return this.http.post(url, {}, this.getExportHeader(exportId, pseudoId, password));
  }

  getExportZip(id) {
    const url = `${this.endpoint}/GetExportZip`;
    return this.http.get(url, { headers: this.getZipHeader(id), responseType: 'blob' as 'json' });
  }
}
