import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { DataImportService } from '../../services/data-import.service';
import { v4 as uuid } from 'uuid';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { EncryptionKeyDialogBoxComponent } from '../encryption-key-dialog-box/encryption-key-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { FileSizePipe } from 'src/app/shared/pipes/file-size.pipe';
import { Router } from '@angular/router';
import { ConfirmationDialogBoxComponent } from '../../../../shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { FormGroup } from '@angular/forms';
import { FILE_INTERFACE } from 'src/app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { AddNewEncryptionKeyComponent } from '../add-new-encryption-key/add-new-encryption-key.component';
import { KalkulationService } from '../../services/kalkulation.service';
import { OrgaStructImportMode } from 'src/app/shared/enums/orga-struct-import-mode.enum';
import { X } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-new-data-import',
  templateUrl: './new-data-import.component.html',
  styleUrls: ['./new-data-import.component.scss']
})

export class NewDataImportComponent implements OnInit, AfterViewChecked {

  files: any[] = [];
  fileUploadStep: FormGroup;
  fileOrgaStructStep: FormGroup;
  fileMappingStep: FormGroup;
  interfacetypes = FILE_INTERFACE;
  interfaceArray: any[] = ['Personendaten', 'Bestandsdaten', 'Regelzahlung',
    'Beraterinformationen', 'Produktdaten', 'Umsatzdaten',
    'Wertpapier Stammdaten', 'Fonds Zuordung'];
  password: any;
  status = 1;
  dataImportWithCsv: any[] = [];
  isLinear = false;
  firstStepComplete = false;
  clientExists: any;
  showLess = true;
  isOrgaDataUploadFilePresent: boolean;

  // deadline read from uploaded files, which is considered to be equal in all files
  deadlineDateFromUploadFile: any;

  //  ImportModes
  // orgaStructImportOptions: any[] = [
  //                                   {value: 2, name: this.translateservice.instant("orgaStructImportOption2")}, // from deadline
  //                                   {value: 1, name: this.translateservice.instant("orgaStructImportOption1")}, // from import
  //                                   {value: 3, name: this.translateservice.instant("orgaStructImportOption3")} // overwrite exixiting
  //                                 ];
  // selectedOrgaStructImportOption: any = this.orgaStructImportOptions[0].value;

  orgaStructImportOptions: any[] = [];
  selectedOrgaStructImportOption: any;;

  // Used by ImportMode: FromDeadline
  deadlines = [];
  selectedDeadline: any;
  hierarchies = [];
  selectedHierarchies: any[] = [];

  @ViewChild('inputFile', { static: false }) inputFileRef: ElementRef;

  constructor(private dataImportService: DataImportService,
    private dialog: MatDialog,
    private fileSizePipe: FileSizePipe,
    private router: Router,
    private translateservice: TranslateService,
    private cdRef: ChangeDetectorRef,
    private kalkulationService: KalkulationService,
    ) {
  }
  ngOnInit() {
    this.getAllDeadlines();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      Array.prototype.forEach.call(event.target.files, file => {
        file.password = null;

        file.id = uuid();
        file.isProgress = false;
        file.isUpload = false;
        file.inQueue = true;
        this.files.push(file);
      });
    }
  }

  droppedFile(event) {
    for (const droppedFile of event) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((inputFile: any) => {
          const file = inputFile;
          const fileExtension = file.name.substr((file.name.lastIndexOf('.') + 1));
          if (fileExtension == 'zip') {
            file.id = uuid();
            file.isProgress = false;
            file.isUpload = false;
            file.password = null;
            file.inQueue = true;
            this.files.push(file);
          }
        });
      }
    }
  }

  uploadOneFile(id, password) {
    this.password = password;
    const formData = new FormData();
    this.files.forEach(file => {
      if (file.id == id) {
        file.password = password;
        file.isProgress = true;
        file.inQueue = false;
        file.showLess = true;

        formData.append('files', file);
      }
    });
    this.uploadFileToServer(formData);

  }
  uploadAllWithSamePassword(id, password) {
    const formData = new FormData();
    this.files.forEach(file => {
      if (!file.isUploaded) {
        file.password = password;
        file.isProgress = true;
        file.showLess = true;
        file.inQueue = false;
        formData.append('files', file);
      }
    });
    this.uploadFileToServer(formData);
  }

  uploadFileToServer(fileToUpload) {
    let password: any;
    this.dataImportService.uploadFiles(fileToUpload).subscribe((res) => {

      const fileName: any[] = [];
      fileToUpload.forEach(file => {
        password = file.password;
        fileName.push(file.name);
      });
      this.dataImportService.extractZipFiles(fileName, password).subscribe((response: any) => {
        this.files.forEach(file => {
          response.Result.forEach(extractedFile => {
            if (extractedFile.zipFileName === file.name) {
              file.uploadedFiles = extractedFile.uploadedFiles;
              if (file.uploadedFiles) {
                file.uploadedFiles.forEach(uploadedFile => {
                  this.interfacetypes.forEach(interfase => {
                    if (uploadedFile.transferType != null) {
                      uploadedFile.transferType = uploadedFile.transferType.trim();
                      if (interfase.name === uploadedFile.transferType) {
                        uploadedFile.interfaceType = interfase.value;
                      }
                    }
                    if (uploadedFile.deadlineDate && !this.deadlineDateFromUploadFile) {
                      this.deadlineDateFromUploadFile = uploadedFile.deadlineDate;
                    }
                  });
                });
              }

              if (extractedFile.status === 'Success') {
                file.isSuccess = true;
                file.isProgress = false;
                file.isUploaded = true;
                file.inQueue = false;
              }
              if (extractedFile.status === 'Fail') {
                file.isProgress = false;
                file.isUploaded = true;
                file.inQueue = false;
                file.isError = true;
                file.isSuccess = false;
                if (extractedFile.errorCode == 11) {
                  file.errorDescription = this.translateservice.instant('passwordForDecompressionNecessary');
                } else if (extractedFile.errorCode == 12) {
                  file.errorDescription = this.translateservice.instant('wrongPasswordForDecompression');
                } else if (extractedFile.errorCode == 13) {
                  file.errorDescription = this.translateservice.instant('failToUploadZipFile');
                } else if (extractedFile.errorCode == 14) {
                  file.errorDescription = this.translateservice.instant('noSuitableFilesIncluded');
                } else if (extractedFile.errorCode == 15) {
                file.errorDescription = this.translateservice.instant('deadlineMismatchInUploadedZip');
                } else if (extractedFile.errorCode == 16) {
                  file.errorDescription = this.translateservice.instant('maxFilenameLengthExceeded');
                  }
              }
            }

          });
        });

      }, (error) => {
        this.files.forEach(file => {
          file.isProgress = false;
        });
      });
    });
  }
  addEncryptionKeyIfNotExist() {
    const dialogRef = this.dialog.open(AddNewEncryptionKeyComponent, {
      width: '400px',
      data: { 
        fileNameArray: this.getFileNameArray(),
        orgaStructImportmode: this.getOrgaStructImportMode(this.selectedOrgaStructImportOption),
        uploadedDeadline: this.deadlineDateFromUploadFile,
        deadlineToCopyFrom: this.selectedDeadline?.deadlineDate,
        //hierarchyIds: this.selectedHierarchies.join(',')
        hierarchyIdsToCopy: Object.keys(this.selectedHierarchies).map((k) => {return this.selectedHierarchies[k].id}).join(","),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }



  disableContinueButton() {
    let failedFiles = 0;
    if (this.files.length == 0) {
      return true;
    } else {
      const filesLength = this.files.length;
      let uploadedFilesLength = 0;
      this.files.forEach(file => {
        if (file.isError) {
          failedFiles++;
        }
        if (file.isUploaded) {
          uploadedFilesLength = uploadedFilesLength + 1;
        }
      });
      this.firstStepComplete = (filesLength === uploadedFilesLength && failedFiles !== uploadedFilesLength);
      let returnValue = !(filesLength === uploadedFilesLength && failedFiles !== uploadedFilesLength)
      return returnValue;
    }
  }



  getFileSizeUploaded(percentage, fileSize) {
    // fileSize = fileSize;
    let uploadProgress = percentage * fileSize;
    uploadProgress = uploadProgress / 100;
    const progress = this.fileSizePipe.transform(uploadProgress);
    return progress;
  }

  getTotalFileSize(fileSize) {
    return this.fileSizePipe.transform(fileSize);
  }

  closeFileCard(id) {
    const filteredFiles = this.files.filter(file => file.id != id);
    this.files = filteredFiles;
  }
  deleteZipFile(name) {
    this.resetFileInput();
    this.dataImportService.deleteZipFile(name).subscribe((response) => {
      const filteredFiles = this.files.filter(file => file.name != name);
      this.files = filteredFiles;
    });
  }

  getFileNameArray() {
    const fileNameArray: any[] = [];
    this.files.forEach(file => {
      fileNameArray.push(file.name);
    });
    return fileNameArray;
  }
  redirectToEncryptionKeyDialog() {
    if (this.clientExists) {
      const dialogRef = this.dialog.open(EncryptionKeyDialogBoxComponent, {
        width: '400px',
        data: { 
          fileNameArray: this.getFileNameArray(), 
          orgaStructImportmode: this.getOrgaStructImportMode(this.selectedOrgaStructImportOption),
          uploadedDeadline: this.deadlineDateFromUploadFile,
          deadlineToCopyFrom: this.selectedDeadline?.deadlineDate,
          //hierarchyIds: this.selectedHierarchies.join(',')
          hierarchyIdsToCopy: Object.keys(this.selectedHierarchies).map((k) => {return this.selectedHierarchies[k].id}).join(",")
         }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    } else {
      this.addEncryptionKeyIfNotExist();
    }
  }

  clearNavigateToList() {
    if (this.files.length === 0) {
      this.router.navigateByUrl('bv-home/dataimport-list');
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigateByUrl('bv-home/dataimport-list');
        }
      });
    }
  }
  continueToNextStep() {
    const temp = this.files.filter(file => file.isSuccess);
    this.files = temp;

    // check weather the client exists()
    this.dataImportService.checkIfClientExists().subscribe((Response: any) => {
      if (Response.StatusCode === 200) {
        this.clientExists = true;
      }
    }, (err) => {
      if (err.status == 400) {
        this.clientExists = false;
      }
    });
  }

  continueToOrgaSelectionStep() {
    this.isOrgaDataUploadFilePresent = this.checkIfOrgaDataUploadFileIsPresent();
  }

  conditionForShowing3File(i, showAll) {
    if (!showAll) {
      if (i < 3) {
        return true;
      }
    } else {
      return true;
    }
  }

  showListOfFileIdExceeds(id) {
    this.files.forEach(file => {
      if (file.id == id) {
        file.showLess = false;
      }
    });
  }

  // reset file input
  resetFileInput() {
    this.inputFileRef.nativeElement.value = '';
  }

  getAllDeadlines() {
    this.kalkulationService.getAllDeadlines().subscribe((res: any) => {
      if (res.Result) {
        this.deadlines = res.Result;
        if (this.deadlines.length > 0) {
          this.orgaStructImportOptions = [
            {value: 2, name: this.translateservice.instant("orgaStructImportOption2")}, // from deadline
            {value: 1, name: this.translateservice.instant("orgaStructImportOption1")}, // from import
            {value: 3, name: this.translateservice.instant("orgaStructImportOption3")} // overwrite exixiting
          ];
          this.selectedOrgaStructImportOption = this.orgaStructImportOptions[0].value;

          this.deadlines.sort((one, two) => (new Date(one.deadlineDate).getTime() > new Date(two.deadlineDate).getTime() ? -1 : 1));
          this.selectedDeadline = this.deadlines[0];
          this.getHierarchiesByDeadline(this.deadlines[0]);
        } else {
          this.orgaStructImportOptions = [
            {value: 1, name: this.translateservice.instant("orgaStructImportOption1")}, // from import
            {value: 3, name: this.translateservice.instant("orgaStructImportOption3")} // overwrite exixiting
          ];
          this.selectedOrgaStructImportOption = this.orgaStructImportOptions[0].value;
          //this.spinnerFlag = false;
        }
      }
    });
  }

  getHierarchiesByDeadline(deadline) {
    this.kalkulationService.getHierarchiesByDeadline(deadline.deadlineDate).subscribe((res: any) => {
      if (res.Result) {
        this.hierarchies = res.Result;
        if (this.hierarchies.length > 1) {
          //this.selectedHierarchy = this.hierarchies[0];
          
        } else {
          //this.spinnerFlag = false;
        }
      }
    });
  }

  onOrgaStructImportOptionChanged(event) {
    if (event == 1 || event == 3) {
      this.selectedDeadline = null;
      this.selectedHierarchies = [];
      this.hierarchies = [];
    }
  }

  onDeadlineSelectionChanged(deadline) {
    // this.spinnerFlag = true;
    this.hierarchies = [];
    this.selectedHierarchies = [];
    this.getHierarchiesByDeadline(deadline);
  }

 
  getOrgaStructImportMode(orgaStructImportOption: number): number {
    let orgaStructImportMode: OrgaStructImportMode = OrgaStructImportMode.Unset;
    switch(orgaStructImportOption) {
      case 1:
        orgaStructImportMode = OrgaStructImportMode.CreateFromImport;
        break;
        case 2:
        orgaStructImportMode = OrgaStructImportMode.CreateFromDeadline;
        break;
        case 3:
        orgaStructImportMode = OrgaStructImportMode.OverwriteExisiting;
        break;
    }
    return orgaStructImportMode;
  }

  checkIfOrgaDataUploadFileIsPresent(): boolean {
    let isOrgaFilePresnet : boolean = false;
    if (this.files && this.files.length > 0) {
      this.files.forEach((file) => {
        if (file.uploadedFiles && file.uploadedFiles.length > 0 && file.uploadedFiles.some(x => x.transferType == "OrganisationData")) {
          isOrgaFilePresnet = true;
        } 
      });
    }
    return isOrgaFilePresnet;
  }

  
}
