import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-bussiness-areas-dialogbox',
  templateUrl: './delete-bussiness-areas-dialogbox.component.html',
  styleUrls: ['./delete-bussiness-areas-dialogbox.component.scss']
})
export class DeleteBussinessAreasDialogboxComponent implements OnInit {
  deleteName: any;
  constructor(public dialogRef: MatDialogRef<DeleteBussinessAreasDialogboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, ) {
    this.deleteName = data.Karlsruhe;
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }
  deleteBussinessArea() {
    this.dialogRef.close(true);
  }


}
