import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataexportEncryptionModalComponent } from "src/app/benutzer-verwaltung/data-transfer-center/components/dataexport-encryption-modal/dataexport-encryption-modal.component";
import { CommonUtilsService } from "src/app/shared/services/common-utils.service";
import { BookingtypesService } from "../../service/bookingtypes.service";

@Component({
  selector: "app-buchungsarten-edit",
  templateUrl: "./buchungsarten-edit.component.html",
  styleUrls: ["./buchungsarten-edit.component.scss"],
})
export class BuchungsartenEditModalComponent implements OnInit {
  currentGroup: any;
  currentGroupId: any;
  currentDescription: any;
  currentName: any;
  typeGroups: any[] = [];
  bookingTypeId: any;
  spinnerFlag: boolean = true;
  bookingTypeDescription: any;
  bookingTypeGroup: any;
  bookingTypeName: any;
  constructor(
    public dialogRef: MatDialogRef<BuchungsartenEditModalComponent>,
    public bookingCategoryService: BookingtypesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.bookingCategoryService.getBookingTypeGroups().subscribe(async (response: any) => {
      this.typeGroups = await response.Result;
      this.bookingTypeGroup = this.currentGroup = this.data.bookingTypeGroup;
      this.currentGroupId = this.bookingTypeId = this.currentGroup.id;
      this.bookingTypeDescription = this.currentDescription = this.data.description;
      this.bookingTypeName = this.currentName = this.data.name;
      this.spinnerFlag = !this.spinnerFlag;
    });
    
  }

  abort() {
    this.dialogRef.close();
  }

  save() {
    this.bookingTypeGroup = this.typeGroups.filter(type => type.id == this.bookingTypeId)[0];
    if (!(this.bookingTypeName === this.currentName)) {
      return; // if user tries to change name
    } else {
      this.dialogRef.close({
        bookingTypeGroup: this.bookingTypeGroup,
        bookingTypeDescription: this.bookingTypeDescription,
        bookingTypeName: this.bookingTypeName,
      });
    }
  }
}
