<mat-card class="m-t-0 m-b-20 p-t-10 p-b-0" >
    <mat-card-content>
        <div class="d-flex flex-row" >
            <mat-form-field class="m-b-5"  appearance="outline">
                <mat-label>{{'deadline' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedDeadline" (ngModelChange)="onDeadlineSelectionChanged($event)" [disabled]="deadlines.length == 0 || isBusy"> 
                  <mat-option *ngFor="let deadline of deadlines" [value]="deadline">{{deadline.deadlineDate | date}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="m-l-10 m-b-5" appearance="outline">
                <mat-label>{{'hierarchy' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedHierarchy" (ngModelChange)="onHierarchySelectionChanged($event)" [disabled]="hierarchies.length == 0 || isBusy">
                  <mat-option *ngFor="let hierarchy of hierarchies" [value]="hierarchy">{{hierarchy.hierarchyDescription}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button mat-button class="custom-button duplicate-button" (click)="duplicateOrgaStruct()" [disabled]="hierarchies.length == 0 || !selectedHierarchy || isBusy">
                {{'duplicate' | translate}}
              </button>
              <button mat-stroked-button class="duplicate-button" (click)="editHierarchyName(selectedHierarchy.id)" [disabled]="hierarchies.length == 0 || !selectedHierarchy || isBusy">
                Umbenennen
              </button>
              <button mat-stroked-button color="warn" class="duplicate-button" (click)="deleteOrgaStruct(selectedHierarchy)" [disabled]="hierarchies.length == 0 || !selectedHierarchy || isBusy">
                Löschen
              </button>
        </div>
        
    </mat-card-content>
    
  </mat-card>

<mat-accordion multi="true" class="custom example-headers-align mat-elevation-z8 w-100">
    <mat-expansion-panel hideToggle *ngFor="let data of beraterOrganisation" #expId ngDefaultControl
        [(ngModel)]="data.expanded" [expanded]="data.expanded">
        <mat-expansion-panel-header class="pt-10" (click)="storeState(data)">
            <mat-panel-title>
                <i class="material-icons" *ngIf="!expId.expanded">
                    keyboard_arrow_right
                </i>
                <i class="material-icons" *ngIf="expId.expanded">
                    keyboard_arrow_down
                </i>
                <span> {{data.description}}
                    <div class="p-t-5 grey-text fw-300">
                        {{ getType(data.profitCenterConsultantStructure?.typeId)}}</div>
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <span class="action-icons">

                    <span>

                        <!-- profitcenter and consultant add -->
                        <i class="material-icons" (click)="addProfitCenter(data.id); $event.stopPropagation();">
                            group_add
                        </i>
                        <!-- <i class="material-icons" (click)="addConsultants(data.id); $event.stopPropagation();">
                            person_add
                        </i> -->


                        <i class="material-icons" (click)="editProfitCenter(data.id);$event.stopPropagation();">
                            edit
                        </i>
                        <i class="material-icons" (click)="deleteProfitcenter(data);$event.stopPropagation();">
                            delete
                        </i>
                    </span>
                </span>




            </mat-panel-description>


        </mat-expansion-panel-header>
        <hr>

        <!-- expansion panel body (childProfitcenter 1)-->
        <mat-expansion-panel *ngFor="let childProfitCenter1 of data.childProfitCenter" #expId2 ngDefaultControl
            class="inner-expand consultant-expand" [(ngModel)]="childProfitCenter1.expanded"
            [expanded]="childProfitCenter1.expanded">
            <mat-expansion-panel-header (click)="storeState(childProfitCenter1)">
                <mat-panel-title>
                    <i class="material-icons" *ngIf="!childProfitCenter1.expanded">
                        keyboard_arrow_right
                    </i>
                    <i class="material-icons" *ngIf="childProfitCenter1.expanded">
                        keyboard_arrow_down
                    </i>
                    <span> {{childProfitCenter1.description}}
                        <div class="p-t-5 grey-text fw-300">
                            {{ getType(childProfitCenter1.profitCenterConsultantStructure?.typeId)}}</div>
                    </span>
                </mat-panel-title>
                <mat-panel-description>
                    <span class="action-icons">
                        <i class="material-icons action-comment" (click)="$event.stopPropagation();">
                        </i>
                        <span>


                            <!-- profitcenter and consultant add -->
                            <i class="material-icons" (click)="addProfitCenter(childProfitCenter1.id); $event.stopPropagation();">
                                group_add
                            </i>
                            <!-- <i class="material-icons" (click)="addConsultants(childProfitCenter1.id); $event.stopPropagation();">
                                person_add
                            </i> -->

                            <mat-menu #menu2="matMenu">
                                <button mat-menu-item>{{'addCompetenceCenter' | translate}}</button>
                                <button mat-menu-item>{{'addTeam' | translate}}</button>
                                <button mat-menu-item>{{'addConsultant' | translate}}</button>
                            </mat-menu>

                            <i class="material-icons"
                                (click)="editProfitCenter(childProfitCenter1.id); $event.stopPropagation();">
                                edit
                            </i>
                            <i class="material-icons"
                                (click)="deleteProfitcenter(childProfitCenter1);$event.stopPropagation();">
                                delete
                            </i>
                        </span>
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>


            <!-- expansion panel body childProfitCenter 2 -->
            <mat-expansion-panel *ngFor="let childProfitCenter2 of childProfitCenter1.childProfitCenter" #expId2
                ngDefaultControl class="inner-expand consultant-expand child-profit-2"
                [(ngModel)]="childProfitCenter2.expanded" [expanded]="childProfitCenter2.expanded">
                <mat-expansion-panel-header (click)="storeState(childProfitCenter2)">
                    <mat-panel-title>
                        <i class="material-icons" *ngIf="!childProfitCenter2.expanded">
                            keyboard_arrow_right
                        </i>
                        <i class="material-icons" *ngIf="childProfitCenter2.expanded">
                            keyboard_arrow_down
                        </i>
                        <span> {{childProfitCenter2.description}}
                            <div class="p-t-5 grey-text fw-300">
                                {{ getType(childProfitCenter2.profitCenterConsultantStructure?.typeId)}}</div>
                        </span>
                    </mat-panel-title>
                    <mat-panel-description style="margin-right :-9px !important">
                        <span class="action-icons">

                            <span>


                                <!-- profitcenter and consultant add -->
                                <i class="material-icons" (click)="addProfitCenter(childProfitCenter2.id); $event.stopPropagation();">
                                    group_add
                                </i>
                                <!-- <i class="material-icons" (click)="addConsultants(childProfitCenter2.id); $event.stopPropagation();">
                                    person_add
                                </i> -->


                                <mat-menu #menu2="matMenu">
                                    <button mat-menu-item>{{'addCompetenceCenter' | translate}}</button>
                                    <button mat-menu-item>{{'addTeam' | translate}}</button>
                                    <button mat-menu-item>{{'addConsultant' | translate}}</button>
                                </mat-menu>

                                <i class="material-icons"
                                    (click)="editProfitCenter(childProfitCenter2.id);$event.stopPropagation();">
                                    edit
                                </i>
                                <i class="material-icons"
                                    (click)="deleteProfitcenter(childProfitCenter2);$event.stopPropagation();">
                                    delete
                                </i>
                            </span>
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- expansion panel body childProfitCenter 3 -->
                <mat-expansion-panel *ngFor="let childProfitCenter3 of childProfitCenter2.childProfitCenter" #expId2
                    ngDefaultControl class="inner-expand consultant-expand" [(ngModel)]="childProfitCenter3.expanded"
                    [expanded]="childProfitCenter3.expanded">
                    <mat-expansion-panel-header class="child-profit-3" (click)="storeState(childProfitCenter3)">
                        <mat-panel-title>
                            <i class="material-icons" *ngIf="!childProfitCenter3.expanded">
                                keyboard_arrow_right
                            </i>
                            <i class="material-icons" *ngIf="childProfitCenter3.expanded">
                                keyboard_arrow_down
                            </i>
                            <span> {{childProfitCenter3.description}}
                                <div class="p-t-5 grey-text fw-300">
                                    {{ getType(childProfitCenter3.profitCenterConsultantStructure?.typeId)}}</div>
                            </span>
                        </mat-panel-title>
                        <mat-panel-description>
                            <span class="action-icons">
                                <i class="material-icons action-comment" (click)="$event.stopPropagation();">
                                </i>
                                <span>


                                    <!-- profitcenter and consultant add -->
                                    <i class="material-icons"
                                        (click)="addProfitCenter(childProfitCenter3.id); $event.stopPropagation();">
                                        group_add
                                    </i>
                                    <!-- <i class="material-icons"
                                        (click)="addConsultants(childProfitCenter3.id); $event.stopPropagation();">
                                        person_add
                                    </i> -->

                                    <mat-menu #menu2="matMenu">
                                        <button mat-menu-item>{{'addCompetenceCenter' | translate}}</button>
                                        <button mat-menu-item>{{'addTeam' | translate}}</button>
                                        <button mat-menu-item>{{'addConsultant' | translate}}</button>
                                    </mat-menu>

                                    <i class="material-icons"
                                        (click)="editProfitCenter(childProfitCenter3.id);$event.stopPropagation();">
                                        edit
                                    </i>
                                    <i class="material-icons"
                                        (click)="deleteProfitcenter(childProfitCenter3);$event.stopPropagation();">
                                        delete
                                    </i>
                                </span>
                            </span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- expansion panel body childProfitCenter 4 -->

                    <mat-expansion-panel *ngFor="let childProfitCenter4 of childProfitCenter3.childProfitCenter" #expId2
                        ngDefaultControl class="inner-expand consultant-expand"
                        [(ngModel)]="childProfitCenter4.expanded" [expanded]="childProfitCenter4.expanded">
                        <mat-expansion-panel-header (click)="storeState(childProfitCenter4)">
                            <mat-panel-title>
                                <i class="material-icons" *ngIf="!childProfitCenter4.expanded">
                                    keyboard_arrow_right
                                </i>
                                <i class="material-icons" *ngIf="childProfitCenter4.expanded">
                                    keyboard_arrow_down
                                </i>
                                <span> {{childProfitCenter4.description}}
                                    <div class="p-t-5 grey-text fw-300">
                                        {{ getType(childProfitCenter4.profitCenterConsultantStructure?.typeId)}}</div>
                                </span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span class="action-icons">
                                    <i class="material-icons action-comment" (click)="$event.stopPropagation();">
                                    </i>
                                    <span>


                                        <!-- profitcenter and consultant add -->
                                        <i class="material-icons"
                                            (click)="addProfitCenter(childProfitCenter4.id); $event.stopPropagation();">
                                            group_add
                                        </i>
                                        <!-- <i class="material-icons"
                                            (click)="addConsultants(childProfitCenter4.id); $event.stopPropagation();">
                                            person_add
                                        </i> -->

                                        <mat-menu #menu2="matMenu">
                                            <button mat-menu-item>{{'addCompetenceCenter' | translate}}</button>
                                            <button mat-menu-item>{{'addTeam' | translate}}</button>
                                            <button mat-menu-item>{{'addConsultant' | translate}}</button>
                                        </mat-menu>

                                        <i class="material-icons"
                                            (click)="editProfitCenter(childProfitCenter4.id);$event.stopPropagation();">
                                            edit
                                        </i>
                                        <i class="material-icons"
                                            (click)="deleteProfitcenter(childProfitCenter4);$event.stopPropagation();">
                                            delete
                                        </i>
                                    </span>
                                </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                       <!-- expansion panel body childProfitCenter 5 -->

                        <mat-expansion-panel *ngFor="let childProfitCenter5 of childProfitCenter4.childProfitCenter" #expId2
                        ngDefaultControl class="inner-expand consultant-expand"
                        [(ngModel)]="childProfitCenter5.expanded" [expanded]="childProfitCenter5.expanded">
                        <mat-expansion-panel-header (click)="storeState(childProfitCenter5)">
                            <mat-panel-title>
                                <i class="material-icons" *ngIf="!childProfitCenter5.expanded">
                                    keyboard_arrow_right
                                </i>
                                <i class="material-icons" *ngIf="childProfitCenter5.expanded">
                                    keyboard_arrow_down
                                </i>
                                <span> {{childProfitCenter5.description}}
                                    <div class="p-t-5 grey-text fw-300">
                                        {{ getType(childProfitCenter5.profitCenterConsultantStructure?.typeId)}}</div>
                                </span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span class="action-icons">
                                    <i class="material-icons action-comment" (click)="$event.stopPropagation();">
                                    </i>
                                    <span>


                                        <!-- profitcenter and consultant add -->
                                        <i class="material-icons"
                                            (click)="addProfitCenter(childProfitCenter5.id); $event.stopPropagation();">
                                            group_add
                                        </i>
                                        <!-- <i class="material-icons"
                                            (click)="addConsultants(childProfitCenter5.id); $event.stopPropagation();">
                                            person_add
                                        </i> -->

                                        <mat-menu #menu2="matMenu">
                                            <button mat-menu-item>{{'addCompetenceCenter' | translate}}</button>
                                            <button mat-menu-item>{{'addTeam' | translate}}</button>
                                            <button mat-menu-item>{{'addConsultant' | translate}}</button>
                                        </mat-menu>

                                        <i class="material-icons"
                                            (click)="editProfitCenter(childProfitCenter5.id);$event.stopPropagation();">
                                            edit
                                        </i>
                                        <i class="material-icons"
                                            (click)="deleteProfitcenter(childProfitCenter5);$event.stopPropagation();">
                                            delete
                                        </i>
                                    </span>
                                </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- expansion panel body childProfitCenter 6 -->

                        <mat-expansion-panel *ngFor="let childProfitCenter6 of childProfitCenter5.childProfitCenter" #expId2
                        ngDefaultControl class="inner-expand consultant-expand"
                        [(ngModel)]="childProfitCenter6.expanded" [expanded]="childProfitCenter6.expanded">
                        <mat-expansion-panel-header (click)="storeState(childProfitCenter6)">
                            <mat-panel-title>
                                <i class="material-icons" *ngIf="!childProfitCenter6.expanded">
                                    keyboard_arrow_right
                                </i>
                                <i class="material-icons" *ngIf="childProfitCenter6.expanded">
                                    keyboard_arrow_down
                                </i>
                                <span> {{childProfitCenter6.description}}
                                    <div class="p-t-5 grey-text fw-300">
                                        {{ getType(childProfitCenter6.profitCenterConsultantStructure?.typeId)}}</div>
                                </span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span class="action-icons">
                                    <i class="material-icons action-comment" (click)="$event.stopPropagation();">
                                    </i>
                                    <span>


                                        <!-- profitcenter and consultant add -->
                                        <i class="material-icons"
                                            (click)="addProfitCenter(childProfitCenter6.id); $event.stopPropagation();">
                                            group_add
                                        </i>
                                        <!-- <i class="material-icons"
                                            (click)="addConsultants(childProfitCenter6.id); $event.stopPropagation();">
                                            person_add
                                        </i> -->

                                        <mat-menu #menu2="matMenu">
                                            <button mat-menu-item>{{'addCompetenceCenter' | translate}}</button>
                                            <button mat-menu-item>{{'addTeam' | translate}}</button>
                                            <button mat-menu-item>{{'addConsultant' | translate}}</button>
                                        </mat-menu>

                                        <i class="material-icons"
                                            (click)="editProfitCenter(childProfitCenter6.id);$event.stopPropagation();">
                                            edit
                                        </i>
                                        <i class="material-icons"
                                            (click)="deleteProfitcenter(childProfitCenter6);$event.stopPropagation();">
                                            delete
                                        </i>
                                    </span>
                                </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- Seventh consultant -->
                        <mat-list *ngFor="let consultant of childProfitCenter6.consultants"
                            class="custom1 consultant-inner-list">
                            <mat-list-item class="expansion-inner-list">

                                <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                                    <i class="material-icons problem-icon">report_problem</i>
                                </span> -->
                                
                                <span>
                                    {{consultant.name}}
                                    <div class="p-t-5 grey-text fw-300">
                                        ({{'consultantNumber' | translate}}
                                        {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                                    </div>
                                    <span class="action-icons" style="text-align: right;  position:absolute;
                            right:0px;">

                                        <span class="expansion-inner-icon">


                                            <!-- profitcenter and consultant add -->
                                            <!-- <i class="material-icons"
                                                (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                                group_add
                                            </i>
                                            <i class="material-icons"
                                                (click)="addConsultants(data.id); $event.stopPropagation();">
                                                person_add
                                            </i> -->

                                            <i class="material-icons"
                                                (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                                edit
                                            </i>
                                            <i class="material-icons"
                                                (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                                delete
                                            </i>
                                        </span>
                                    </span>
                                </span>
                            </mat-list-item>
                        </mat-list>
                        </mat-expansion-panel>

                        <!-- Sixth Consultant -->
                        <mat-list *ngFor="let consultant of childProfitCenter5.consultants"
                            class="custom1 consultant-inner-list">
                            <mat-list-item class="expansion-inner-list">

                                <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                                    <i class="material-icons problem-icon">report_problem</i>
                                </span> -->

                                <span>
                                    {{consultant.name}}
                                    <div class="p-t-5 grey-text fw-300">
                                        ({{'consultantNumber' | translate}}
                                        {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                                    </div>
                                    <span class="action-icons" style="text-align: right;  position:absolute;
                                right:0px;">

                                        <span class="expansion-inner-icon">


                                            <!-- profitcenter and consultant add -->
                                            <!-- <i class="material-icons"
                                                (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                                group_add
                                            </i>
                                            <i class="material-icons"
                                                (click)="addConsultants(data.id); $event.stopPropagation();">
                                                person_add
                                            </i> -->

                                            <i class="material-icons"
                                                (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                                edit
                                            </i>
                                            <i class="material-icons"
                                                (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                                delete
                                            </i>
                                        </span>
                                    </span>
                                </span>
                            </mat-list-item>
                        </mat-list>
                        
                        </mat-expansion-panel>

                        <!-- Fifth Consultant -->
                        <mat-list *ngFor="let consultant of childProfitCenter4.consultants"
                            class="custom1 consultant-inner-list">
                            <mat-list-item class="expansion-inner-list">

                                <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                                    <i class="material-icons problem-icon">report_problem</i>
                                </span> -->

                                <span>
                                    {{consultant.name}}
                                    <div class="p-t-5 grey-text fw-300">
                                        ({{'consultantNumber' | translate}}
                                        {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                                    </div>
                                    <span class="action-icons" style="text-align: right;  position:absolute;
                                right:0px;">

                                        <span class="expansion-inner-icon">


                                            <!-- profitcenter and consultant add -->
                                            <!-- <i class="material-icons"
                                                (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                                group_add
                                            </i>
                                            <i class="material-icons"
                                                (click)="addConsultants(data.id); $event.stopPropagation();">
                                                person_add
                                            </i> -->

                                            <i class="material-icons"
                                                (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                                edit
                                            </i>
                                            <i class="material-icons"
                                                (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                                delete
                                            </i>
                                        </span>
                                    </span>
                                </span>
                            </mat-list-item>
                        </mat-list>

                    </mat-expansion-panel>

                    <!-- Fourth Consultant -->
                    <mat-list *ngFor="let consultant of childProfitCenter3.consultants"
                        class="custom1 consultant-inner-list">
                        <mat-list-item class="expansion-inner-list">

                            <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                                <i class="material-icons problem-icon">report_problem</i>
                            </span> -->

                            <span>
                                {{consultant.name}}
                                <div class="p-t-5 grey-text fw-300">
                                    ({{'consultantNumber' | translate}}
                                    {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                                </div>
                                <span class="action-icons" style="text-align: right;  position:absolute;
                            right:0px;">

                                    <span class="expansion-inner-icon">


                                        <!-- profitcenter and consultant add -->
                                        <!-- <i class="material-icons"
                                            (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                            group_add
                                        </i>
                                        <i class="material-icons"
                                            (click)="addConsultants(data.id); $event.stopPropagation();">
                                            person_add
                                        </i> -->

                                        <i class="material-icons"
                                            (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                            edit
                                        </i>
                                        <i class="material-icons"
                                            (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                            delete
                                        </i>
                                    </span>
                                </span>
                            </span>
                        </mat-list-item>
                    </mat-list>

                </mat-expansion-panel>

                <!-- Third consultant -->
                <mat-list *ngFor="let consultant of childProfitCenter2.consultants"
                    class="custom1 consultant-inner-list">
                    <mat-list-item class="expansion-inner-list">

                        <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                            <i class="material-icons problem-icon">report_problem</i>
                        </span> -->

                        <span>
                            {{consultant.name}}
                            <div class="p-t-5 grey-text fw-300">
                                ({{'consultantNumber' | translate}}
                                {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                            </div>
                            <span class="action-icons" style="text-align: right;  position:absolute;
                            right:0px;">



                                <span class="expansion-inner-icon">


                                    <!-- profitcenter and consultant add -->
                                    <!-- <i class="material-icons"
                                        (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                        group_add
                                    </i>
                                    <i class="material-icons"
                                        (click)="addConsultants(data.id); $event.stopPropagation();">
                                        person_add
                                    </i> -->

                                    <i class="material-icons"
                                        (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                        edit
                                    </i>
                                    <i class="material-icons"
                                        (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                        delete
                                    </i>
                                </span>
                            </span>
                        </span>
                    </mat-list-item>
                </mat-list>

            </mat-expansion-panel>

            <!-- second consultants -->
            <mat-list *ngFor="let consultant of childProfitCenter1.consultants" class="custom1 consultant-inner-list">
                <mat-list-item class="expansion-inner-list">

                    <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                        <i class="material-icons problem-icon">report_problem</i>
                    </span> -->

                    <span>
                        {{consultant.name}}
                        <div class="p-t-5 grey-text fw-300">
                            ({{'consultantNumber' | translate}}
                            {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                        </div>
                        <span class="action-icons" style="text-align: right;  position:absolute;
                          right:0px;">

                            <span class="expansion-inner-icon">


                                <!-- profitcenter and consultant add -->
                                <!-- <i class="material-icons" (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                    group_add
                                </i>
                                <i class="material-icons" (click)="addConsultants(data.id); $event.stopPropagation();">
                                    person_add
                                </i> -->

                                <i class="material-icons"
                                    (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                    edit
                                </i>
                                <i class="material-icons"
                                    (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                    delete
                                </i>
                            </span>
                        </span>
                    </span>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>

        <!--first consultants  -->
        <mat-list *ngFor="let consultant of data.consultants" class="custom1 consultant-inner-list">
            <mat-list-item class="expansion-inner-list">

                <!-- <span *ngIf="consultant.customersCount == 0" title="{{ 'consultantHasNoCustomers' | translate }}">
                    <i class="material-icons problem-icon">report_problem</i>
                </span> -->

                <span>
                    {{consultant.name}}
                    <div class="p-t-5 grey-text fw-300">
                        ({{'consultantNumber' | translate}}
                        {{consultant.consultantClientId == null  ?   0  : consultant.consultantClientId}})
                    </div>
                    <span class="action-icons" style="text-align: right;  position:absolute;
                        right:0px;">

                        <span class="expansion-inner-icon">


                            <!-- profitcenter and consultant add -->
                            <!-- <i class="material-icons" (click)="addProfitCenter(data.id); $event.stopPropagation();">
                                group_add
                            </i>
                            <i class="material-icons" (click)="addConsultants(data.id); $event.stopPropagation();">
                                person_add
                            </i> -->


                            <i class="material-icons"
                                (click)="editConsultants(consultant.id); $event.stopPropagation();">
                                edit
                            </i>
                            <i class="material-icons"
                                (click)="deleteConsultants(consultant); $event.stopPropagation();">
                                delete
                            </i>
                        </span>
                    </span>
                </span>
            </mat-list-item>
        </mat-list>


    </mat-expansion-panel>
</mat-accordion>