<div class="p-l-1vw p-t-1vw p-r-1vw">
  <mat-grid-list cols="3" rowHeight="50px">
    <mat-grid-tile flex-start flex-align-top colspan="2" class="grid-spacing">
      <h1 class="page-title">
        {{'calculations' | translate}} > {{'newCalculationLabel' | translate}} {{deadlindeDate | dateFormat}}
      </h1>
    </mat-grid-tile>
    <mat-grid-tile flex-end flex-align-top colspan="1" class="grid-spacing">
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="12" class="m-t-50 m-b-50 p-t-30 tile-height" rowHeight="450px">
    <mat-grid-tile flex-start flex-align-top [colspan]="12">
      <div style="width:100%">
        <mat-label class="info-text d-flex nowrap p-10 note-width m-b-5">
          <i class="material-icons">info</i>
          <span class="m-l-10">{{'newCalculationPageNote' | translate}}
          </span>
        </mat-label>


        <div class="calculation-list card-shadow p-l-25">
          <div class="calculation-list--item" *ngFor="let files of interfaceFiles">
            <div *ngIf="files.files != undefined">
              <h4 class="list-header" *ngIf="files.files.length > 0">{{files.value}}</h4>
              <div>
                <p class="list-sub-text" *ngIf="files.files.length > 0">{{'youMustSelectAtLeastOneFile' | translate}}
                </p>
                <mat-list class="check-list">
                  <mat-list-item *ngFor="let file of files.files">
                    <mat-checkbox class="custom-checkbox" [checked]="file.selected"
                      (change)="selectOrUnselectFile(file)">{{file.fileName}}
                    </mat-checkbox>
                    <span class="sub-text">{{'imported' | translate}} {{'atThe' | translate}}
                      {{file.uploadTimeStamp}} {{'from' | translate}}
                      {{file.userName}}</span>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>

          </div>
        </div>
        <div class="m-b-10">
          <button mat-raised-button color="basic" class="m-l-10"
            (click)="confirmDialogToGoBack()">{{'stop' | translate}}</button>
          <button mat-raised-button class="custom-button m-l-20" (click)="calculateDeadline()"
            [disabled]="disableSaveButton() && calculationDone">{{'calculate' | translate}}</button>
        </div>
      </div>

    </mat-grid-tile>
  </mat-grid-list>
</div>