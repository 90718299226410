import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { HighchartService } from '../../services/highchart.service';
import { AlterChartTableDataPipe } from 'src/app/alter-chart-table-data.pipe';
import { VERMOGEN_REPORT_DATA, AllDataLoadDetail } from 'src/app/app.config';

@Component({
  selector: 'app-potentialanalysis-vermogen-report',
  templateUrl: './potentialanalysis-vermogen-report.component.html',
  styleUrls: ['./potentialanalysis-vermogen-report.component.scss']
})
export class PotentialanalysisVermogenReportComponent implements OnInit, AfterViewInit {
  @Input('dataForPDF') dataForPDF: any;
  @Output() closeReport = new EventEmitter<string>();
  @Input() openedFromSideMenu: any;
  @Input() selectedCalculationId: any;
  @Output() dataEvent = new EventEmitter<string>();
  @Output() onInitDoneEvent: EventEmitter<any> = new EventEmitter();


  VermogensverteilungAktuell: any;
  VermogensverteilungNachMusterallokation: any;
  displayedColumns: string[] = ['Title', 'StichtagsbestandAbsolut', 'StichtagsbestandPercentage', 'UmschichtungsbedarfAnlegen', 'UmschichtungsbedarfVerkaufvolumen', 'UmschichtungsbedarfOptimieren', 'Potentialbestand1Absolut', 'Potentialbestand1Percentage'];

  vermogensverteilungAktuellChart: any;
  vermogensverteilungNachMusterallokationChart: any;
  dataSource: any[] = [];
  spinnerFlag = AllDataLoadDetail.loaded;
  dataError = false;
  potentialanalysis: any;
  intervalEvent: any;

  constructor(private highChartService: HighchartService, private alterChartData: AlterChartTableDataPipe) {
  }

  ngOnInit() {
    // API call to fetch data for diagram and table
    if (this.dataForPDF) {
      this.spinnerFlag = true;
    }
    this.vermogensverteilungAktuellChart = this.highChartService.vermogensverteilungAktuellChart;
    this.vermogensverteilungNachMusterallokationChart = this.highChartService.vermogensverteilungNachMusterallokationChart;
    if (this.spinnerFlag) {
      this.initVermogen();
    }
  }

  ngAfterViewInit(): void {
    this.onInitDoneEvent.emit();
  }

  initVermogen() {
    if (VERMOGEN_REPORT_DATA.data) {
      this.dataSource = VERMOGEN_REPORT_DATA.data.table.rows;
      this.VermogensverteilungAktuell = VERMOGEN_REPORT_DATA.data.diagrams[0];
      this.VermogensverteilungNachMusterallokation = VERMOGEN_REPORT_DATA.data.diagrams[1];
      this.setCharts();
    }


  }

  setCharts() {
    this.dataSource.forEach(data => {
      if (data.title.includes('Geldwerte')) {
        data.color = '#70ad47';
      } else if (data.title.includes('Sachwerte')) {
        data.color = '#ffc000';

      } else if (data.title.includes('Substanzwerte')) {
        data.color = '#ff0000';

      } else if (data.title.includes('Alternative')) {
        data.color = '#bfbfbf';
      }

    });
    this.setColorForCharts();
    this.setChartForVermogensverteilungAktuell();
    this.setChartForVermogensverteilungNachMusterallokation();

  }

  setChartForVermogensverteilungAktuell() {
    const dataArray = this.setChartDataVermogensverteilungAktuell();
    this.vermogensverteilungAktuellChart.removeSeries(0);
    this.vermogensverteilungAktuellChart.addSeries({
      type: 'pie',
      name: 'Brands',
      colorByPoint: true,
      data: dataArray
    }, true, true);

  }

  setChartForVermogensverteilungNachMusterallokation() {

    const dataArray = this.setChartDataVermogensverteilungNachMusterallokation();
    this.vermogensverteilungNachMusterallokationChart.removeSeries(0);
    this.vermogensverteilungNachMusterallokationChart.addSeries({
      type: 'pie',
      name: 'Brands',
      colorByPoint: true,
      data: dataArray
    }, true, true);
  }

  setChartDataVermogensverteilungNachMusterallokation() {
    const chartDataArray: any[] = [];
    this.VermogensverteilungNachMusterallokation.valuePieces.forEach((point, index) => {
      chartDataArray.push({
        y: point.value != 0 ? point.value : null,
         category: point.name +` ${this.alterChartData.transform(point.value, false)}`, 
         name: this.alterChartData.transform(point.value, false), color: point.color
      });
    });
    return chartDataArray;
  }
  setChartDataVermogensverteilungAktuell() {
    const chartDataArray: any[] = [];
    this.VermogensverteilungAktuell.valuePieces.forEach((point, index) => {

      chartDataArray.push({
        y: point.value != 0 ? point.value : null, category: point.name +
          ` ${this.alterChartData.transform(point.value, false)}`, name: this.alterChartData.transform(point.value, false), color: point.color
      });
    });
    return chartDataArray;
  }


  setColorForCharts() {
    this.VermogensverteilungAktuell.valuePieces.forEach(data1 => {
      if (data1.name.includes('Liquidität')) {
        data1.color = '#009ecf';
      } else if (data1.name.includes('Geldwerte')) {
        data1.color = '#70ad47';
      } else if (data1.name.includes('Sachwerte')) {
        data1.color = '#ffc000';
      } else if (data1.name.includes('Substanzwerte')) {
        data1.color = '#ff0000';
      } else if (data1.name.includes('Alternative')) {
        data1.color = '#bfbfbf';
      }
    });
    this.VermogensverteilungNachMusterallokation.valuePieces.forEach(data2 => {
      if (data2.name.includes('Liquidität')) {
        data2.color = '#009ecf';
      } else if (data2.name.includes('Geldwerte')) {
        data2.color = '#70ad47';
      } else if (data2.name.includes('Sachwerte')) {
        data2.color = '#ffc000';
      } else if (data2.name.includes('Substanzwerte')) {
        data2.color = '#ff0000';
      } else if (data2.name.includes('Alternative')) {
        data2.color = '#bfbfbf';
      }
    });
  }
  closeChart() {
    this.closeReport.next();
  }

  parentChartGeneration(value) {
    this.selectedCalculationId = value;
    this.spinnerFlag = true;
  }
}


