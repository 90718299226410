<div>
    <h4 class="m-t-0" mat-dialog-title>{{'deleteHierarchyEntry' | translate}}</h4>
    <p>{{'askEntry' | translate }} "{{profitCenter.descriptionDepartment}}" {{'reallyDelete' | translate}}


    </p>
    <div mat-dialog-actions align="end">
        <button mat-raised-button color="basic" (click)="closeDialog(false)">{{'stop' | translate}}</button>
        <button mat-raised-button cdkFocusInitial class="custom-button m-l-10"
            (click)="closeDialog(true)">{{'continue' | translate}}</button>
    </div>
</div>