import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-consultant-organization-dialog',
  templateUrl: './delete-consultant-organization-dialog.component.html',
  styleUrls: ['./delete-consultant-organization-dialog.component.scss']
})
export class DeleteConsultantOrganizationDialogComponent implements OnInit {

  deleteName: any;
  
  constructor(public dialogRef: MatDialogRef<DeleteConsultantOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, ) {
    this.deleteName = data.Karlsruhe;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close(true);
  }

}
