import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vertriebs-organization-comment',
  templateUrl: './vertriebs-organization-comment.component.html',
  styleUrls: ['./vertriebs-organization-comment.component.scss']
})
export class VertriebsOrganizationCommentComponent implements OnInit {
  comment: any;
  name: any;
  constructor(public dialogRef: MatDialogRef<VertriebsOrganizationCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.comment = this.data.comment;
    this.name = this.data.name;
  }

  ngOnInit() {

  }

  closeDialog() {
    this.dialogRef.close();
  }
}
