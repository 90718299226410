import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-consultant-dialog',
  templateUrl: './delete-consultant-dialog.component.html',
  styleUrls: ['./delete-consultant-dialog.component.scss']
})
export class DeleteConsultantDialogComponent implements OnInit {
  consultant;
  constructor(public dialogRef: MatDialogRef<DeleteConsultantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.consultant = data.object;
  }

  ngOnInit() {
  }

  closeDialog(close) {
    this.dialogRef.close(close);
  }
}
