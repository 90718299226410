import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ConfirmationDialogBoxComponent } from 'src/app/shared/components/confirmation-dialog-box/confirmation-dialog-box.component';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Consultant } from '../../models/consultant';
import { SalesOrganisationService } from '../../service/sales-organisation.service';
import { CommonUtilsService } from '../../../../shared/services/common-utils.service';

@Component({
  selector: 'app-vertriebs-add-consultant',
  templateUrl: './vertriebs-add-consultant.component.html',
  styleUrls: ['./vertriebs-add-consultant.component.scss']
})
export class VertriebsAddConsultantComponent implements OnInit {
  consultantForm: FormGroup;
  id: any;
  partmarketId: any;
  competenceId: any;
  teamId: any;
  navigationExtras: NavigationExtras;
  consultant = new Consultant();
  consultantArray: any[] = [];
  constructor(private router: Router,
    private dialog: MatDialog,
    private commonUtils: CommonUtilsService,
    private route: ActivatedRoute,
    private salesOrganisation: SalesOrganisationService) {
    this.id = this.commonUtils.checkId(this.route.snapshot.paramMap.get('id'));
    this.partmarketId = this.commonUtils.checkId(this.route.snapshot.paramMap.get('partMarketId'));
    this.competenceId = this.commonUtils.checkId(this.route.snapshot.paramMap.get('competenceId'));
    this.teamId = this.commonUtils.checkId(this.route.snapshot.paramMap.get('teamId'));

  }

  ngOnInit() {

    this.getConsultantList();
    this.initForm();
    this.navigationExtras = {
      queryParams: {
        'consultantOrganisation': true
      }
    };
  }

  initForm() {
    this.consultantForm = new FormGroup({
      consultantName: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(100)])),
      consultantNo: new FormControl('', Validators.compose([Validators.required, this.consultantNumberValidation.bind(this)])),
      comment: new FormControl('', Validators.maxLength(500))
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.consultantForm.controls[controlName].hasError(errorName);
  }

  clearForm() {
    if (!this.consultantForm.dirty) {
      this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);

    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        width: '400px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);

        }
      });

    }
  }
  getConsultantList() {
    this.salesOrganisation.getAllConsultant().subscribe((response: any) => {
      this.consultantArray = response.Result;
      this.consultantArray.forEach(element => {
        if (element.consultantClientId) {
          element.consultantClientId = element.consultantClientId.trim();
        }
      });
      const temp = this.consultantArray.filter(element => element.consultantClientId != null);
      this.consultantArray = temp;
    });
  }
  addConsultant() {
    this.consultant.consultantClientId = 0;
    this.consultant.creditInstitutionId = this.id;
    this.consultant.consultantCompetenceCenterId = this.competenceId;
    this.consultant.creditInstitutionPartMarketId = this.partmarketId;
    this.consultant.consultantTeamId = this.teamId;
    this.consultant.description = this.consultantForm.value.comment;
    this.consultant.name = this.consultantForm.value.consultantName;
    this.consultant.consultantClientId = this.consultantForm.value.consultantNo;
    this.salesOrganisation.addConsultant(this.consultant).subscribe((Response: any) => {
      this.router.navigate(['bv-home/sales-organization-list/true'], this.navigationExtras);

    });
  }
  private consultantNumberValidation(control: AbstractControl): { [key: string]: boolean } | null {

    const consultantNo = this.consultantArray.find(x => x.consultantClientId === control.value);
    if (control.value !== undefined && consultantNo != undefined) {
      return {
        'consultantNumberValidation': true
      };
    }
    return null;
  }
}
